import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDataService } from '../../../services/base-data.service';
import {
  BaseDataTemplate,
  CmsSelectionData,
  Create,
  CreateBaseData,
  CreateBaseDataValue,
  ICON,
  Id,
  INPUT_VALUE_TYPES,
  LicenceConfigOptions,
  validateBaseDataTemplate,
} from '@kfd/core';
import { InsertResponse, isFilledInputValueValidator } from '@kfd/web-core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';
import { LABEL_NAME_CONTROLS } from '../../../shared/components/label-name-input/label-name-input.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { ApiBaseDataTemplateService } from '../../../services/api/api-base-data-template.service';

interface ValueForm {
  template?: boolean;
  form: FormGroup;
}

@Component({
  selector: 'kfd-basedata-edit-dialog',
  templateUrl: './basedata-edit-dialog.component.html',
  styleUrls: ['./basedata-edit-dialog.component.scss'],
})
export class BasedataEditDialogComponent extends BaseDialogComponent implements OnDestroy {
  protected readonly ICON = ICON;
  protected readonly INPUT_VALUE_TYPES = INPUT_VALUE_TYPES;
  protected loading = false;
  protected projectId: string;
  protected newAfterSave = false;
  protected licenceLimitData = LicenceConfigOptions.DATA_LIMIT;
  protected licenceLimitReached = false;
  protected baseDataForm: FormGroup | undefined;
  protected baseData: (CreateBaseData & { _id?: Id }) | undefined;
  protected editExisting = false;
  protected selectedTemplate: BaseDataTemplate;
  protected templateValidationMessages: string[] | undefined;
  protected valueForms: ValueForm[] = [];
  protected formGroupDataInvalid = false;
  protected formGroupValuesInvalid = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    protected readonly dialogRef: DynamicDialogRef,
    protected readonly dialogConfig: DynamicDialogConfig,
    protected readonly loggingService: LoggingService,
    private readonly baseDataService: BaseDataService,
    private readonly apiBaseDataTemplateService: ApiBaseDataTemplateService,
    private readonly formBuilder: FormBuilder,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data?.projectId) {
      this.close();
      throw new Error('Missing project id');
    }
    this.projectId = dialogConfig.data?.projectId;

    if (dialogConfig.data?.selectionData) {
      const selectionData: CmsSelectionData = dialogConfig.data?.selectionData;
      this.baseData = {
        _id: selectionData._id,
        label: selectionData.label,
        name: selectionData.name,
        tags: selectionData.tags ?? [],
        image: selectionData.image,
        values:
          selectionData.values?.map((value) => ({
            name: value.identifier,
            label: value.label?.text,
            inputValue: Create.inputValue(value.type, value.value),
          })) ?? [],
        templateName: selectionData.templateName,
      };
      this.editExisting = !!this.baseData._id;
    }

    if (this.baseData?.templateName) {
      this.apiBaseDataTemplateService
        .getBaseDataTemplate(this.projectId, this.baseData.templateName)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (template) => {
            if (template) {
              this.selectTemplate(template);
              this.updateForm();

              if (this.baseData._id) {
                this.templateValidationMessages = validateBaseDataTemplate(template, this.baseData);
              }
            } else {
              this.updateForm();
            }
          },
          error: () => {
            this.updateForm();
          },
        });
    } else {
      this.updateForm();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // private updateFormGroupsValidity() {
  //
  //   this.formGroupValuesInvalid = this.baseDataForm.get('values')?.invalid;
  //   this.formGroupDataInvalid = this.baseDataForm.invalid && this.formGroupDataInvalid !== true;
  // }

  protected submit() {
    let invalidForm = false;
    Object.keys(this.baseDataForm.controls).forEach((fieldName) => {
      this.baseDataForm?.controls[fieldName].markAsDirty();
      this.baseDataForm?.controls[fieldName].markAsTouched();
    });
    this.baseDataForm.updateValueAndValidity();

    this.valueForms.forEach((valueForm) => {
      Object.keys(valueForm.form.controls).forEach((fieldName) => {
        valueForm.form.controls[fieldName].markAsDirty();
        valueForm.form.controls[fieldName].markAsTouched();
      });
      valueForm.form.updateValueAndValidity();
      if (!valueForm.form.valid) {
        invalidForm = true;
      }
      if (!valueForm.form.get('inputValue').valid) {
        invalidForm = true;
      }
    });

    this.formGroupDataInvalid = this.baseDataForm.invalid;
    this.formGroupValuesInvalid = invalidForm;
    if (this.baseDataForm.invalid || invalidForm) {
      return;
    }
    const createBaseData: CreateBaseData & { _id?: Id } = {
      _id: this.baseData?._id,
      label: this.baseDataForm.get('label')?.value,
      name: this.baseDataForm.get('name')?.value,
      tags: this.baseDataForm.get('tags')?.value ?? [],
      templateName: this.selectedTemplate?.name,
      values: this.valueForms.map((valueForm) => ({
        name: valueForm.form.get('name')?.value,
        label: valueForm.form.get('label')?.value,
        inputValue: valueForm.form.get('inputValue').value,
      })),
      image: this.baseDataForm.get('image')?.value ?? undefined,
    };
    if (createBaseData._id) {
      this.updateEntry(createBaseData);
    } else {
      this.createEntry(createBaseData);
    }
  }

  protected selectTemplate(baseDataTemplate: BaseDataTemplate): void {
    if (baseDataTemplate?.values) {
      baseDataTemplate.values.forEach((baseDataTemplateValue) =>
        this.addValue(
          {
            name: baseDataTemplateValue.name,
            label: baseDataTemplateValue.label,
            inputValue: baseDataTemplateValue.inputValue,
          },
          true,
        ),
      );
    }
    this.selectedTemplate = baseDataTemplate;
  }

  protected removeTemplate() {
    if (this.selectedTemplate?.values) {
      this.selectedTemplate.values.forEach((value) => this.removeValueByName(value.name));
    }
    this.selectedTemplate = undefined;
    if (this.baseData) {
      this.baseData.templateName = undefined;
    }
  }

  protected addValue(createBaseDataValue?: CreateBaseDataValue, fromTemplate = false): void {
    const existingPos =
      createBaseDataValue &&
      this.valueForms.findIndex((valueForm) => valueForm.form.get('name')?.value === createBaseDataValue.name);

    //value exists, prefer template settings over existing (except input value)
    if (existingPos >= 0) {
      const existing = this.valueForms[existingPos];
      const existingInputValue = existing.form.get('inputValue');
      if (createBaseDataValue?.inputValue) {
        // we do only set the value if the template type matches the saved type (otherwise it need to be updated manually)
        if (createBaseDataValue.inputValue.type === existingInputValue?.value.type) {
          existing.form.get('inputValue')?.setValue(createBaseDataValue.inputValue);
        } else {
          existing.form.markAsDirty();
          existing.form.markAsTouched();
        }
      }
      //replace at array index
      this.valueForms.splice(existingPos, 1, {
        template: existing.template || fromTemplate,
        form: existing.form,
      });
    } else {
      const form = this.formBuilder.group({
        id: new FormControl(Date.now()),
        ...LABEL_NAME_CONTROLS(
          this.valueNameUniqueValidator.bind(this),
          createBaseDataValue?.label ?? '',
          createBaseDataValue?.name ?? '',
        ),
        inputValue: new FormControl(createBaseDataValue?.inputValue ?? '', isFilledInputValueValidator),
      });
      this.valueForms.push({ template: fromTemplate, form });
    }
    //sort the values so that the template values are always first, then sort alphanumerically by name
    this.valueForms.sort((a, b) => {
      if (a.template && !b.template) {
        return -1;
      }
      if (!a.template && b.template) {
        return 1;
      }
      return a.form.get('name')?.value.localeCompare(b.form.get('name')?.value);
    });

    if (this.baseDataForm) {
      this.baseDataForm.addControl('values', new FormArray(this.valueForms.map((value) => value.form)));
    }
  }

  protected removeValueById(id: string): void {
    if (!this.baseDataForm) {
      return;
    }
    this.valueForms = this.valueForms.filter((valueForm) => valueForm.form.get('id')?.value !== id);
    this.baseDataForm.addControl('values', new FormArray(this.valueForms.map((value) => value.form)));
  }

  protected removeValueByName(name: string): void {
    if (!this.baseDataForm) {
      return;
    }
    this.valueForms = this.valueForms.filter((valueForm) => valueForm.form.get('name')?.value !== name);
    this.baseDataForm.addControl('values', new FormArray(this.valueForms.map((value) => value.form)));
  }

  private updateForm(): void {
    this.baseDataForm = this.formBuilder.group({
      ...LABEL_NAME_CONTROLS(
        this.nameUniqueValidator.bind(this),
        this.baseData?.label ?? '',
        this.baseData?.name ?? '',
      ),
      tags: new FormControl(this.baseData?.tags ?? []),
      image: new FormControl(this.baseData?.image ?? ''),
      values: new FormArray([]),
    });

    if (this.baseData?.values) {
      this.baseData.values.forEach((value) => this.addValue(value));
    }
  }

  private nameUniqueValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }
    const name = control.value.toString();
    if (name.length < 3) {
      return of(null);
    }
    // ignore name if it is the same as the current one
    if (this.baseData?._id && this.baseData?.name === name) {
      return of(null);
    }
    return this.baseDataService.getEntryByName(this.projectId, name).pipe(
      map((dataEntry) => {
        return dataEntry ? { invalidValue: true, message: 'Der Name wird bereits verwendet.' } : null;
      }),
    );
  }

  private valueNameUniqueValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return of(null);
    }
    const name = control.value.toString();
    if (name.length < 3) {
      return of(null);
    }
    if (this.valueForms.filter((valueForm) => valueForm.form.get('name')?.value === name).length === 2) {
      return of({ invalidValue: true, message: 'Der Name wird bereits verwendet.' });
    }
    return of(null);
  }

  private createEntry(createBaseData: CreateBaseData) {
    this.loading = true;

    this.baseDataService.createEntry(this.projectId, createBaseData).subscribe({
      next: (data: InsertResponse) => {
        this.loading = false;
        if (!data) {
          return;
        }

        if (this.newAfterSave) {
          this.baseData = {
            label: '',
            name: '',
            tags: [],
            values: [],
          };
          this.updateForm();
        } else {
          this.close(data);
        }
      },
      error: (e) => {
        this.loading = false;
        throw e;
      },
    });
  }

  private updateEntry(createBaseData: CreateBaseData & { _id?: Id }) {
    this.loading = true;
    if (!createBaseData._id) {
      return;
    }
    if (isEqual(this.baseData, createBaseData)) {
      this.close();
      return;
    }
    this.baseDataService.updateEntry(createBaseData._id, this.projectId, createBaseData).subscribe({
      next: (res) => {
        this.loading = false;
        if (res) {
          this.close(createBaseData);
        }
      },
      error: (e) => {
        this.loading = false;
        throw e;
      },
    });
  }
}
