import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Calculation, Create, Is } from '@kfd/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-calculation-dialog',
  templateUrl: './calculation-dialog.component.html',
  styleUrls: ['./calculation-dialog.component.scss'],
})
export class CalculationDialogComponent extends BaseDialogComponent {
  calculation: Calculation;
  nestingLvl = 0;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
    if (Is.calculation(dialogConfig.data.calculation)) {
      this.calculation = dialogConfig.data.calculation;
    } else {
      this.calculation = Create.calculation();
    }
    if (dialogConfig.data.nestingLvl) {
      this.nestingLvl = dialogConfig.data.nestingLvl;
    }
  }

  save() {
    this.close(this.calculation);
  }
}
