<ng-container *ngIf="usages$ | async as usages">
  <p-message
    *ngIf="usages.static.length > 0"
    data-automationId="info-used-in-published-cfg"
    severity="warn"
    text="Der Datensatz wird aktuell verwendet"
  ></p-message>

  <p-message
    *ngIf="usages.static.length === 0 && usages.dynamic.length > 0"
    severity="info"
    text="Ein Tag dieses Datensatzes wird aktuell verwendet"
  ></p-message>

  <p-message
    *ngIf="usages.static.length === 0 && usages.dynamic.length === 0"
    data-automationId="info-not-used-in-published-cfg"
    severity="success"
    text="Der Datensatz wird nicht in verwendet"
  ></p-message>

  @if (usages.static.length > 0) {
    <h2>Direkte Verwendung</h2>
    <div class="mt-2">
      <p-accordion>
        <p-accordionTab *ngFor="let usageEntry of usages.static">
          <ng-template pTemplate="header">
            <kfd-split-row>
              <ng-container left
                >{{ usageEntry.configuration.label }} (Version {{ usageEntry.configuration.version }})
              </ng-container>
              <ng-container right> </ng-container>
            </kfd-split-row>
          </ng-template>
          <p-table [value]="usageEntry.usage" responsiveLayout="scroll">
            <ng-template let-entry pTemplate="body">
              <tr>
                <td>{{ entry.usedBy.label }}</td>
                <td class="action flex justify-content-end">
                  <button
                    (click)="openEntry(usageEntry.configuration.id, entry.usedBy.name)"
                    class="p-button-text"
                    icon="pi {{ ICON.LINK_EXTERNAL }}"
                    pButton
                    pTooltip="Eintrag anzeigen"
                    type="button"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>
  }

  @if (usages.dynamic.length > 0) {
    <h2>Dynamische Verwendung</h2>
    <div class="mt-2">
      <p-accordion>
        <p-accordionTab *ngFor="let tagUsage of usages.dynamic">
          <ng-template pTemplate="header">
            <kfd-split-row>
              <ng-container left>
                @if (tagUsage.filter.templateName) {
                  Template: {{ tagUsage.filter.templateName }}
                }
                @if (tagUsage.filter.tags) {
                  Tags: {{ tagUsage.filter.tags.join(',') }}
                }
              </ng-container>
            </kfd-split-row>
          </ng-template>
          <p-table [value]="tagUsage.usages" responsiveLayout="scroll">
            <ng-template let-entry pTemplate="body">
              <tr>
                <td>{{ entry.configuration.label }}</td>
                <td>Version {{ entry.configuration.version }}</td>
                <td class="action flex justify-content-end">
                  <button
                    (click)="showConfiguration(entry.configuration)"
                    class="p-button-text"
                    icon="pi pi-external-link"
                    pButton
                    pTooltip="Konfiguration anzeigen"
                    type="button"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>
  }
</ng-container>
