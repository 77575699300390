import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDataService } from '../../../services/base-data.service';
import { Observable } from 'rxjs';
import { LoggingService } from '../../../services/logging.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CmsSelectionData, ICON } from '@kfd/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'kfd-data-view-dialog',
  templateUrl: './data-view-dialog.component.html',
  styleUrls: ['./data-view-dialog.component.scss'],
})
export class DataViewDialogComponent extends BaseDialogComponent {
  protected data$: Observable<CmsSelectionData> | undefined;
  protected headerMenu: MenuItem[] = [
    {
      label: 'Bearbeiten',
      icon: 'pi ' + ICON.EDIT,
      command: () => {
        this.close('edit');
      },
    },
    {
      separator: true,
    },
  ];
  private readonly projectId: undefined;
  private readonly identifier: undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private dataService: BaseDataService,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data?.projectId) {
      throw new Error('Missing projectId config');
    }
    this.projectId = dialogConfig.data?.projectId;

    if (!dialogConfig.data?.identifier) {
      throw new Error('Missing identifier config');
    }
    this.identifier = dialogConfig.data?.identifier;

    if (!this.projectId || !this.identifier) {
      return;
    }
    this.data$ = this.dataService.getEntryByName(this.projectId, this.identifier);
  }
}
