import { Injectable } from '@angular/core';

import { mergeMap, Observable, of, zip } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { UnhealthyDialogComponent } from '../common/unhealthy-dialog/unhealthy-dialog.component';
import { AuthService } from '@kfd/web-core';
import { catchError, map } from 'rxjs/operators';
import { ApiConfigurationService } from '../services/api/api-configuration.service';
import { PublishedCfgService } from '../services/published-cfg.service';

//5 minutes
const HEALTH_CACHE_TIMEOUT = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class HealthGuard {
  private healthy: number | undefined;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private configurationCmsService: ApiConfigurationService,
    private publishedCfgService: PublishedCfgService,
  ) {}

  canActivate(): Observable<boolean> {
    const currentTime = new Date().getTime();
    if (this.healthy && currentTime - this.healthy < HEALTH_CACHE_TIMEOUT) {
      return of(true);
    }
    return zip(
      this.authService.healthy(),
      this.configurationCmsService.healthy(),
      // this.publishedCfgService.healthy(),
    ).pipe(
      catchError(() => of([false])),
      mergeMap((res: boolean[]) => {
        const success = res.indexOf(false) === -1;
        if (success) {
          this.healthy = currentTime;
          return of(true);
        }
        const ref = this.dialogService.open(UnhealthyDialogComponent, {
          styleClass: 'full-screen-dialog',
          closable: false,
        });
        this.healthy = undefined;
        return ref.onClose.pipe(map(() => false));
      }),
    );
  }
}
