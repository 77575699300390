@if (config) {
  <div class="horizontal-line"></div>
  <div class="p-fluid mt-2">
    <div class="p-field">
      <p-radioButton
        (ngModelChange)="updateValueType($event)"
        [ngModel]="valueTypeCls"
        [value]="cls.INPUT_VALUE"
        label="Wert eintragen"
        name="valueType"
      ></p-radioButton>
      <br />
      <p-radioButton
        (ngModelChange)="updateValueType($event)"
        [ngModel]="valueTypeCls"
        [value]="cls.CALCULATION"
        label="Kalkulation"
        name="valueType"
      ></p-radioButton>
      <br />
      <p-radioButton
        (ngModelChange)="updateValueType($event)"
        [ngModel]="valueTypeCls"
        [value]="cls.FIELD_REF"
        label="Eingabe anzeigen"
        name="valueType"
      ></p-radioButton>
    </div>
  </div>

  <ng-container [ngSwitch]="valueTypeCls">
    <ng-container *ngSwitchCase="cls.INPUT_VALUE">
      <ng-container [ngTemplateOutlet]="inputValueConfig"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="cls.CALCULATION">
      <ng-container [ngTemplateOutlet]="calculationConfig"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="cls.FIELD_REF">
      <ng-container [ngTemplateOutlet]="fieldRefConfig"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <p-message class="mt-2" severity="info" text="Nichts ausgewählt"></p-message>
    </ng-container>
  </ng-container>

  <ng-template #inputValueConfig>
    <div class="flex mt-1">
      <div class="flex-1 flex align-items-center">
        <ng-container *ngIf="config.value?.cls === cls.INPUT_VALUE">{{ config.value.value }}</ng-container>
        <ng-container *ngIf="config.value?.cls !== cls.INPUT_VALUE"><i>Kein Wert</i></ng-container>
      </div>
      <div>
        <button
          (click)="inputValueConfigOverlay.toggle($event)"
          class="p-button-rounded p-button-text"
          icon="pi {{ icon.EDIT }}"
          pButton
          type="button"
        ></button>
      </div>
    </div>
    <p-overlayPanel #inputValueConfigOverlay>
      <kfd-data-value-select
        (valueChange)="change('value', $event); inputValueConfigOverlay.hide()"
        [allowedTypes]="inputValueTypes"
        [submitBtn]="true"
        [value]="config?.value"
      ></kfd-data-value-select>
    </p-overlayPanel>
  </ng-template>

  <ng-template #calculationConfig>
    <div class="mt-1">
      <ng-container *ngIf="config.value?.cls === cls.CALCULATION">
        <kfd-calculation-viewer [calculation]="config.value"></kfd-calculation-viewer>
      </ng-container>
      <ng-container *ngIf="config.value?.cls !== cls.CALCULATION"><i>Keine Berechnung</i></ng-container>

      <div class="flex justify-content-end">
        <button
          (click)="showCalculationDialog(config.value)"
          class="p-button-rounded p-button-text"
          icon="pi {{ icon.EDIT }}"
          pButton
          type="button"
        ></button>
      </div>
    </div>
  </ng-template>

  <ng-template #fieldRefConfig>
    <div class="flex mt-1">
      <div class="flex-1 flex align-items-center">
        <ng-container *ngIf="config.value?.cls === cls.FIELD_REF">{{ config.value.name | entrylabel }}</ng-container>
        <ng-container *ngIf="config.value?.cls !== cls.FIELD_REF"><i>Kein Feld</i></ng-container>
      </div>
      <div>
        <button
          (click)="openFieldRefSelection()"
          class="p-button-rounded p-button-text"
          icon="pi {{ icon.EDIT }}"
          pButton
          type="button"
        ></button>
      </div>
    </div>
  </ng-template>
}
