<kfd-dialog-layout
  (accept)="close()"
  (reject)="close()"
  [showAcceptBtn]="false"
  [showRejectBtn]="false"
  [showRejectIcon]="true"
  acceptBtnLabel="OK"
  data-automationId="show-configuration-dialog"
  dialogTitle="Konfiguration anzeigen"
>
  <ng-container *ngIf="configurationId && projectId">
    <ng-container *ngIf="showCfg">
      <kfd-configuration
        class="flex-1"
        hidePreviewFlag="true"
        seamless="true"
        [configuration]="configurationId"
        [preview]="preview"
        [project]="projectId"
      ></kfd-configuration>
    </ng-container>
  </ng-container>

  <ng-container footerLeft>
    <div>
      <p-checkbox
        (ngModelChange)="previewChange($event)"
        [disabled]="disablePreview || disablePublished"
        [ngModel]="preview"
        binary="true"
        data-automationId="checkbox-use-preview"
        label="Vorschau version"
      ></p-checkbox>
    </div>
  </ng-container>
  <ng-container footerRight>
    <button
      (click)="reset()"
      class="p-button-rounded p-button-outlined ml-2"
      icon="pi {{ icon.REFRESH }}"
      pButton
      pTooltip="Formular zurücksetzen"
      type="button"
    ></button>
    <a [href]="externalLink" target="_blank">
      <button
        class="p-button-rounded p-button-outlined ml-2"
        icon="pi {{ icon.LINK_EXTERNAL }}"
        pButton
        pTooltip="In neuem Fenster öffnen"
        type="button"
      ></button>
    </a>
  </ng-container>
</kfd-dialog-layout>
