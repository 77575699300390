import { ChangeDetectorRef, Component } from '@angular/core';
import {
  Create,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  FIELD_TYPES,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  SelectOption,
} from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { CmsContextService } from '../../../../services/cms-context.service';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { BasedataHandlerDialogComponent } from '../../common/basedata-handler-dialog/basedata-handler-dialog.component';
import { TEXTFIELD_VALIDATION_OPTIONS } from '../../../../shared/global';

@Component({
  selector: 'kfd-field-settings-details-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<SelectFieldConfig> {
  protected layoutOptions: SelectOption[] = [
    {
      label: '---',
      value: '',
    },
    {
      label: 'Liste',
      value: SELECT_FIELD_LAYOUTS.BUTTON,
    },
    {
      label: 'Dropdown',
      value: SELECT_FIELD_LAYOUTS.DROPDOWN,
    },
  ];
  protected dataValueType = DATA_VALUE_TYPE;
  protected defaultMinValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
  protected defaultMaxValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1);
  protected dataHandlerTypes = DATAHANDLER_TYPES;
  protected readonly validationOptions = TEXTFIELD_VALIDATION_OPTIONS;

  public constructor(
    protected cd: ChangeDetectorRef,
    protected cmsDialogService: CmsDialogService,
    public ctx: CmsContextService,
  ) {
    super(cd);
  }

  public showDataValuePanel() {
    this.cmsDialogService
      .open(BasedataHandlerDialogComponent, {
        dataHandler: this._config?.dataHandler,
      })
      .onClose.subscribe((dataHandler) => {
        if (dataHandler) {
          this.change('dataHandler', dataHandler);
        }
      });
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.SELECT;
  }
}
