import { Injectable } from '@angular/core';
import { ErrorReportingDialogComponent } from '../common/error-reporting-dialog/error-reporting-dialog.component';
import { CmsDialogService } from './cms-dialog.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  errorDialogRef: DynamicDialogRef | undefined;

  constructor(private readonly cmsDialogService: CmsDialogService) {}

  showErrorReporting() {
    if (this.errorDialogRef) {
      return;
    }
    this.errorDialogRef = this.cmsDialogService.open(
      ErrorReportingDialogComponent,
      {},
      {
        dismissibleMask: false,
      },
    );
    this.errorDialogRef.onClose.subscribe(() => {
      this.errorDialogRef = undefined;
    });
  }
}
