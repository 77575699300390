<kfd-dialog-layout
  (accept)="export()"
  (reject)="close()"
  [acceptDisabled]="!export"
  [kfdBlock]="loading"
  acceptBtnLabel="Exportieren"
  dialogTitle="Konfiguration exportieren"
  docuPageId="create-new-configuration"
  docuSectionId="export-config-dialog"
>
  @if (configurationDataExport && configurationDataExport.configurations.length > 0) {
    <kfd-key-value-list
      [data]="{
        Label: {
          type: 'text',
          value: configurationDataExport.configurations[0].label
        },
        Name: {
          type: 'text',
          value: configurationDataExport.configurations[0].name
        },
        Version: {
          type: 'text',
          value: configurationDataExport.configurations[0].versions.current
        },
        Stammdaten: {
          type: 'number',
          value: configurationDataExport.data.length
        },
        'Stammdaten Vorlagen': {
          type: 'number',
          value: configurationDataExport.baseDataTemplates.length
        },
        Dateien: {
          type: 'number',
          value: configurationDataExport.assets.length
        }
      }"
      class="mt-2"
    ></kfd-key-value-list>
  }
</kfd-dialog-layout>
