import { ElementRef } from '@angular/core';
import interact from 'interactjs';
import { InteractEvent } from '@interactjs/types/index';
import { DragDropService, DragElementInfo } from './drag-drop.service';
import { CmsChildWrapper, ICON } from '@kfd/core';

export interface DragElementOptions {
  createOnDrop?: CmsChildWrapper;
}

export class DragElementHandler {
  /**
   * cloned element which is used during dragging
   */
  private dragClone: HTMLElement | undefined;

  constructor(
    private readonly dragDropService: DragDropService,
    private readonly dragEl: ElementRef<HTMLElement>,
    private readonly initiatorEl: ElementRef<HTMLElement>,
    private readonly dragElementInfo: DragElementInfo,
    private readonly options: DragElementOptions = {},
  ) {
    if (dragEl) {
      this.init();
    }
  }

  public destroy() {
    this.stopDragging();
  }

  private init(): void {
    interact(this.dragEl.nativeElement).draggable({
      allowFrom: this.initiatorEl.nativeElement,
      listeners: {
        move: (event: InteractEvent) => {
          if (this.dragClone) {
            const oldX: number = this.dragClone.hasAttribute('data-x')
              ? parseFloat(this.dragClone.getAttribute('data-x') as string)
              : 0;
            const oldY: number = this.dragClone.hasAttribute('data-y')
              ? parseFloat(this.dragClone.getAttribute('data-y') as string)
              : 0;
            const x = oldX + event.dx;
            const y = oldY + event.dy;
            this.dragClone.setAttribute('data-x', x.toString());
            this.dragClone.setAttribute('data-y', y.toString());

            this.dragClone.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          }
        },
        start: (event: InteractEvent) => {
          this.dragEl.nativeElement.style.filter = 'grayscale(100%)';
          this.dragEl.nativeElement.style.opacity = '0.6';

          this.dragClone = document.createElement('div');
          const image = document.createElement('i');
          image.className = 'pi ' + this.dragElementInfo.icon ?? ICON.MOVE;
          this.dragClone.appendChild(image);
          this.dragClone.className = 'drag-clone';
          const parentElement = document.querySelector('kfd-dnd-area');
          this.dragClone.style.left = event.clientX - parentElement.getClientRects()[0].left + 'px';
          this.dragClone.style.top = event.clientY - parentElement.getClientRects()[0].top + 'px';
          document.querySelector('kfd-dnd-area').appendChild(this.dragClone);
          this.dragDropService.startDragging(this.dragElementInfo);
        },
        end: () => {
          if (this.dragDropService.drop(this.options.createOnDrop)) {
            // dropped successfully
          }

          this.stopDragging();
        },
      },
    });
  }

  private stopDragging(): void {
    this.dragEl.nativeElement.style.filter = '';
    this.dragEl.nativeElement.style.opacity = '';
    if (this.dragClone) {
      this.dragClone.remove();
      this.dragDropService.stopDragging();
    }
  }
}
