import { Injectable } from '@angular/core';
import { ScopedUser } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class UserMandantContextService {
  private _authorizedUser: ScopedUser | undefined;

  get authorizedUser(): ScopedUser | undefined {
    return this._authorizedUser;
  }

  set authorizedUser(value: ScopedUser | undefined) {
    this._authorizedUser = value;
  }
}
