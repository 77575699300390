<div class="page-toolbar">
  <div class="page-limiter page-padding-x flex-column">
    <p-toolbar styleClass="invisible">
      <div class="p-toolbar-group-left">
        <a routerLink="/">
          <img src="assets/images/logo.svg" />
        </a>
      </div>
      <div class="p-toolbar-group-right"></div>
    </p-toolbar>
  </div>
</div>
<div class="flex flex-1 justify-content-center align-items-center">
  <p-messages severity="error">
    <ng-template pTemplate>
      <div class="flex-1 flex flex-column align-items-center">
        <i class="pi pi-exclamation-triangle text-6xl"></i>
        <h2>Service nicht verfügbar</h2>
        <p>Der Service steht aktuell nicht zur Verfügung, bitte probieren sie es später noch einmal.</p>
      </div>
    </ng-template>
  </p-messages>
</div>
<div class="page-toolbar">
  <div class="page-limiter page-padding-x flex-column">
    <p-toolbar styleClass="invisible">
      <div class="p-toolbar-group-left text-xs">
        <a [href]="environment.apps.web">Web</a>
        <a [href]="environment.apps.web + '/content/imprint'" class="ml-2">Impressum</a>
        <a [href]="environment.apps.web + '/content/privacy'" class="ml-2">Datenschutz</a>
        <a [href]="environment.apps.web + '/content/contact'" class="ml-2">Kontakt</a>
        <a [href]="environment.apps.web + '/support'" class="ml-2">Support</a>
      </div>
      <div class="p-toolbar-group-right"></div>
    </p-toolbar>
  </div>
</div>
