@if (usage$ | loading | async; as usage) {
  <kfd-dialog-layout
    (accept)="close()"
    (reject)="close()"
    [loading]="usage.loading"
    [showRejectBtn]="false"
    acceptBtnLabel="OK"
    dialogTitle="Datei Verwendung"
    data-automationId="asset-usage-dialog"
  >
    @if (usage.value) {
      @if (usage.value.inUse) {
        <p-message
          severity="warn"
          text="Die Datei wird aktuell verwendet."
          data-automationId="asset-in-use-info"
        ></p-message>

        @if (usage.value.projectLogo) {
          <h2>Projekt</h2>
          <kfd-action-message
            class="mt-2 block"
            severity="info"
            text="Wird als Projekt-Logo verwendet"
            actionBtnLabel="Logo bearbeiten"
            [actionBtnIcon]="ICON.EDIT"
            [hideActionBtn]="([scopes.ADMIN, scopes.OWNER] | hasScope | async) === false"
            (action)="openSettings()"
          >
          </kfd-action-message>
        }

        @if (usage.value.baseData.length > 0) {
          <h2>Datensätze</h2>
          <p>Wird in folgenden Datensätzen verwendet:</p>
          @for (baseData of usage.value.baseData; track baseData.name) {
            <kfd-action-message
              severity="none"
              class="mt-2 block"
              [text]="baseData.name"
              actionBtnLabel="Datensatz bearbeiten"
              [actionBtnIcon]="ICON.EDIT"
              (action)="openBaseData(baseData.name)"
            >
            </kfd-action-message>
          }
        }
      } @else {
        <p-message
          severity="success"
          text="Die Datei wird aktuell nicht verwendet"
          data-automationId="asset-not-in-use-info"
        ></p-message>
      }
    }
  </kfd-dialog-layout>
}
