import { Component } from '@angular/core';
import { CLS, CmsConfigurator, Configuration, Create, FieldRef, INPUT_FIELD_TYPES } from '@kfd/core';
import { CfgStateService } from '../cfg-state.service';

@Component({
  selector: 'kfd-internal',
  // standalone: true,
  // imports: [CommonModule, CfgEditorModule, WebCoreModule],
  templateUrl: './internal.component.html',
  styleUrl: './internal.component.scss',
})
export class InternalComponent {
  protected readonly INPUT_FIELD_TYPES = INPUT_FIELD_TYPES;
  protected fieldRef: FieldRef | undefined;
  protected fieldRef2: FieldRef | undefined;
  protected readonly JSON = JSON;

  constructor(private readonly cfgStateService: CfgStateService) {
    cfgStateService.insertConfiguration(mockConfiguration1() as CmsConfigurator);
  }
}

export function mockConfiguration1(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: {
      current: 1,
    },
    name: 'simple-cfg1',
    label: 'Simple configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          label: 'Page 1',
          name: 'page1',
          children: [
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                label: 'Question 1',
                name: 'question1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'textfield1',
                      label: 'Field 1',
                      config: Create.textFieldConfig({ required: true }),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'datefield1',
                      label: 'Field 2',
                      config: Create.dateFieldConfig(),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'numberfield1',
                      label: 'Field 3',
                      config: Create.numericFieldConfig(),
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
}
