import { ChangeDetectorRef, Component } from '@angular/core';
import { Create, DateFieldConfig, FIELD_TYPES, RelativeDate } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';

@Component({
  selector: 'kfd-field-settings-details-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<DateFieldConfig> {
  public defaultDateValue: RelativeDate = Create.relativeDate();

  public constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  toggleDisabledDay(dayIndex: number) {
    if (this._config === undefined) {
      return;
    }
    if (!this._config.disabledDays) {
      this._config.disabledDays = [];
    }
    if (this._config.disabledDays.indexOf(dayIndex) === -1) {
      this._config.disabledDays.push(dayIndex);
    } else {
      this._config.disabledDays = this._config.disabledDays.filter(function (value) {
        return value !== dayIndex;
      });
    }
    this.configChange.emit(this.config);
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.DATE;
  }
}
