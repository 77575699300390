@if (asset$ | loading | async; as asset) {
  <div class="preview flex justify-content-center align-items-center" [kfdBlock]="asset.loading">
    @if (asset.value) {
      <div [pTooltip]="asset.value.label" [attr.data-automationId]="'asset-preview-' + asset.value.name">
        <img [src]="asset.value.thumbnailUrl + '?' + cacheBreaker" />
      </div>
    } @else if (asset.error) {
      <span class="pi {{ ICON.WARN }} my-2 mx-4 color-error text-2xl" pTooltip="Asset nicht gefunden"></span>
    } @else {
      <span class="pi {{ ICON.IMAGE }} my-2 mx-4"></span>
    }
  </div>
}
