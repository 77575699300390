<div class="p-inputgroup">
  <input (ngModelChange)="valueChange()" [(ngModel)]="label.text" pInputText placeholder="Label" type="text" />
  <input
    (ngModelChange)="valueChange()"
    *ngIf="label.text.length > 0"
    [(ngModel)]="label.format"
    pInputText
    placeholder="Format"
    type="text"
  />
  <button
    (click)="removeValue()"
    *ngIf="label.text.length > 0"
    icon="pi pi-times"
    pButton
    pRipple
    styleClass="p-button-warn"
    type="button"
  ></button>
</div>
