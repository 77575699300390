import { EventEmitter, Injectable } from '@angular/core';
import { ContextService, Id } from '@kfd/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmsContextService implements ContextService {
  public values = new Map<string, string>();
  private valueChange = new EventEmitter<string>();

  get projectId(): string {
    if (!this.values.has('projectId')) {
      throw new Error('No project id available');
    }
    return this.values.get('projectId') as string;
  }

  set projectId(value: Id | undefined) {
    if (value === undefined) {
      return;
    }
    this.values.set('projectId', value.toString());
    this.valueChange.emit('projectId');
  }

  get projectName(): string {
    if (!this.values.has('projectName')) {
      throw new Error('No projectName available');
    }
    return this.values.get('projectName') as string;
  }

  set projectName(value: string | undefined) {
    if (value === undefined) {
      return;
    }
    this.values.set('projectName', value);
    this.valueChange.emit('projectName');
  }

  get configuratorId(): string {
    if (!this.values.has('configuratorId')) {
      throw new Error('No configuratorId available');
    }
    return this.values.get('configuratorId') as string;
  }

  set configuratorId(value: Id | undefined) {
    if (value === undefined) {
      return;
    }
    this.values.set('configuratorId', value.toString());
    this.valueChange.emit('configuratorId');
  }

  get configurationName(): string {
    if (!this.values.has('configurationName')) {
      throw new Error('No configurationName available');
    }
    return this.values.get('configurationName') as string;
  }

  set configurationName(value: string | undefined) {
    if (value === undefined) {
      return;
    }
    this.values.set('configurationName', value);
    this.valueChange.emit('configurationName');
  }

  get entryId(): string {
    if (!this.values.has('entryId')) {
      throw new Error('No entryId available');
    }
    return this.values.get('entryId') as string;
  }

  set entryId(value: Id | undefined) {
    if (value === undefined) {
      return;
    }
    this.values.set('entryId', value.toString());
    this.valueChange.emit('entryId');
  }

  public onValueChange(property?: string): Observable<string | undefined> {
    return new Observable((subscriber) => {
      this.valueChange.subscribe((changedProperty) => {
        if (!property || !changedProperty || property === changedProperty) {
          subscriber.next(this.values.has(changedProperty) ? this.values.get(changedProperty) : undefined);
        }
      });
      if (property) {
        subscriber.next(this.values.has(property) ? this.values.get(property) : undefined);
      }
    });
  }

  unset() {
    this.values.clear();
    this.valueChange.emit();
  }

  hasMandantId(): boolean {
    return this.values.has('projectId');
  }

  hasConfiguratorId(): boolean {
    return this.values.has('configuratorId');
  }

  hasEntryId(): boolean {
    return this.values.has('entryId');
  }
}
