import { ChangeDetectorRef, Component } from '@angular/core';
import { FIELD_TYPES, ResetButtonFieldConfig } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';

@Component({
  selector: 'kfd-field-settings-details-reset-btn',
  templateUrl: './reset-btn.component.html',
  styleUrls: ['./reset-btn.component.scss'],
})
export class ResetBtnFieldSettingsComponent extends BaseEntryFieldSettingsComponent<ResetButtonFieldConfig> {
  public constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.RESETBTN;
  }
}
