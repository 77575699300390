<kfd-dialog-layout
  (accept)="close()"
  (reject)="close()"
  [loading]="loading"
  [showRejectBtn]="false"
  data-automationId="basedata-dynamic-usage-dialog"
  dialogTitle="Datenverwendung"
>
  @if (dynamicUsageData | async; as templateUsageData) {
    @if (templateUsageData.template) {
      <h2 class="my-2">Vorlage "{{ templateUsageData.template.label }}"</h2>

      @if (templateUsageData.entries && templateUsageData.entries.length > 0) {
        @for (baseData of templateUsageData.entries; track baseData.entry._id) {
          <p-panel
            [toggleable]="true"
            [collapsed]="true"
            class="mt-2"
            data-automationTag="basedata-entry"
            [attr.data-automationId]="'basedata-entry-' + baseData.entry.name"
          >
            <ng-template pTemplate="header">
              <div class="flex justify-content-between align-items-center full-width">
                <div>
                  @if (baseData.warnings.length > 0) {
                    <i class="pi {{ ICON.WARN }} color-warning" data-automationId="template-check-invalid"></i>
                  } @else {
                    <i class="pi {{ ICON.CORRECT }} color-success" data-automationId="template-check-valid"></i>
                  }
                  <strong class="ml-2">{{ baseData.entry.label }}</strong>
                </div>
                <div>
                  <p-button
                    icon="pi {{ ICON.EDIT }}"
                    pTooltip="Datensatz bearbeiten"
                    data-automationId="button-edit-basedata"
                    [rounded]="true"
                    [text]="true"
                    (click)="editBaseDataEntry(baseData.entry)"
                  ></p-button>
                </div>
              </div>
            </ng-template>
            @if (baseData.entry.values && baseData.entry.values.length > 0) {
              <kfd-key-value-list [data]="{}" class="mt-2 block">
                @for (value of baseData.entry.values; track value.identifier) {
                  <div class="row" data-automationId="basedata-value">
                    <label>{{ value.label?.text }}</label>
                    <div class="value">
                      <div class="flex justify-content-end">
                        {{ value.value !== undefined ? value.value : '---' }}
                      </div>
                    </div>
                  </div>
                }
              </kfd-key-value-list>
            }

            @if (baseData.warnings.length > 0) {
              <div class="mt-2">
                @for (warning of baseData.warnings; track warning) {
                  <p-message severity="info" text="{{ warning }}"></p-message>
                }
              </div>
            }
          </p-panel>
        }

        @if (showAddDataBtn) {
          <div class="mt-2 flex justify-content-center full-width">
            <p-button
              icon="pi {{ ICON.ADD }}"
              label="Neuen Datensatz erstellen"
              [rounded]="true"
              [outlined]="true"
              (click)="createBaseDataEntry(templateUsageData.template.name)"
            ></p-button>
          </div>
        }
      } @else {
        <kfd-placeholder-msg
          data-automationId="basedata-dynamic-usage-no-entries-msg"
          infoTitle="Keine zugehörigen Datensätze vorhanden"
          infoText="Es sind keine Datensätze vorhanden, die den Kriterien entsprechen."
          [infoIcon]="ICON.BASE_DATA"
          [btnVisible]="showAddDataBtn"
          btnLabel="Neuen Datensatz erstellen"
          (btnClick)="createBaseDataEntry(templateUsageData.template.name)"
        ></kfd-placeholder-msg>
      }
    } @else {
      <p-message severity="warn" text="Vorlage nicht gefunden"></p-message>
    }
  } @else {
    <kfd-loader></kfd-loader>
  }
</kfd-dialog-layout>
