<nav [ngClass]="{ collapsed: collapsed }" class="left-menu">
  <p-menu [model]="menuItems" styleClass="auto-width invisible dark">
    <ng-template let-item pTemplate="item">
      <a
        *ngIf="!item?.url"
        [attr.data-automationId]="item.automationId"
        [attr.tabindex]="-1"
        [pTooltip]="item.tooltip"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions ?? {}"
        [routerLinkActive]="'p-menuitem-link-active'"
        [routerLink]="item.routerLink"
        class="flex justify-content-between align-items-center p-menuitem-link p-3"
      >
        <div class="no-wrap">
          <span class="p-menuitem-icon {{ item.icon }}"></span>
          <span class="p-menuitem-text no-wrap">{{ item.label }}</span>
        </div>
      </a>
    </ng-template>
  </p-menu>

  @if (collapsed) {
    <div (click)="toggleCollapse()" label="" class="legal-btn mt-4 mb-6">Kontakt & Impressum</div>
  } @else {
    <p-menu [model]="metaMenuItems" class="mt-4 mb-6" styleClass="auto-width invisible dark">
      <ng-template let-item pTemplate="item">
        <a
          *ngIf="!item?.url"
          [attr.data-automationId]="item.automationId"
          [attr.tabindex]="-1"
          [href]="item.routerLink"
          class="flex justify-content-between align-items-center p-menuitem-link"
        >
          <div>
            <span class="p-menuitem-text"> {{ item.label }}</span>
          </div>
        </a>
      </ng-template>
    </p-menu>
  }
</nav>

<p-button
  (click)="toggleCollapse()"
  [icon]="'pi ' + (collapsed ? ICON.EXPAND : ICON.COLLAPSE)"
  [pTooltip]="collapsed ? 'Menü anzeigen' : 'Menü verkleinern'"
  class="collapse-btn"
  severity="secondary"
  styleClass="no-focus slim"
></p-button>
