<kfd-dialog-layout
  (accept)="save()"
  (reject)="close()"
  [loading]="loading"
  [showAcceptBtn]="false"
  dialogTitle="Element verschieben"
>
  <p-tree
    #tree
    (selectionChange)="onSelectionChange($event)"
    [selection]="selectedNode"
    [value]="treeNodes"
    selectionMode="single"
  >
  </p-tree>

  <div class="flex flex-row justify-content-center mt-2">
    <ng-container *ngIf="selectedNode">
      <button
        (click)="moveBefore(selectedNode)"
        [disabled]="!canMoveBefore"
        class="mx-1"
        icon="pi pi-arrow-up"
        label="Davor"
        pButton
        type="button"
      ></button>
      <button
        (click)="moveInto(selectedNode)"
        [disabled]="!canMoveInside"
        class="mx-1"
        icon="pi pi-sign-in"
        label="Hinein"
        pButton
        type="button"
      ></button>
      <button
        (click)="moveAfter(selectedNode)"
        [disabled]="!canMoveAfter"
        class="mx-1"
        icon="pi pi-arrow-down"
        label="Danach"
        pButton
        type="button"
      ></button>
    </ng-container>
  </div>
</kfd-dialog-layout>
