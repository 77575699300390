<kfd-dialog-layout (reject)="close()" [loading]="loading" [showAcceptBtn]="false" dialogTitle="Konfiguration löschen">
  <div class="p-fluid" data-automationId="dialog-delete-cfg">
    <p-message
      *ngIf="hasBeenPublished"
      class="mb-2"
      data-automationId="dialog-delete-published-warning"
      severity="warn"
      text="Die Konfiguration wurde bereits publiziert"
    >
    </p-message>

    <p>Die Konfiguration wird zum Löschen freigegeben und nach 14 Tagen unwiderruflich gelöscht.</p>
    <p>Das Löschen umfasst die Konfiguration, sowie alle dazugehörigen Anfragen und Statistiken.</p>

    <kfd-confirmation-code
      (confirm)="remove()"
      data-automationId="confirmation-code"
      label="Konfiguration löschen"
    ></kfd-confirmation-code>
  </div>
</kfd-dialog-layout>
