<kfd-dialog-layout
  (accept)="close(true)"
  (reject)="close(false)"
  [acceptBtnLabel]="acceptBtnLabel"
  [loading]="loading"
  [showRejectBtn]="showRejectBtn"
  data-automationId="basedata-usage-info-dialog"
  dialogTitle="Datennutzung"
>
  <kfd-data-usage-info (isUsed)="isUsed($event)" [name]="entryName" [projectId]="projectId"></kfd-data-usage-info>
</kfd-dialog-layout>
