import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from '@kfd/web-core';
import { CmsContextService } from '../../../services/cms-context.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';
import { CfgStateService } from '../../cfg-state.service';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { ContextRoutingService } from '../../../services/context-routing.service';

@Component({
  selector: 'kfd-remove-configuration-dialog',
  templateUrl: './remove-configuration-dialog.component.html',
  styleUrls: ['./remove-configuration-dialog.component.scss'],
})
export class RemoveConfigurationDialogComponent extends BaseDialogComponent {
  hasBeenPublished = false;
  loading = false;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private ctx: CmsContextService,
    private cfgStateService: CfgStateService,
    private apiConfigurationService: ApiConfigurationService,
    private messageService: MessageService,
    private contextRoutingService: ContextRoutingService,
  ) {
    super(dialogRef, dialogConfig);
    this.hasBeenPublished = !!this.cfgStateService.getCfgUtil().getCfg().versions.published;
  }

  remove() {
    this.loading = true;
    this.apiConfigurationService.removeConfiguration(this.ctx.projectId, this.ctx.configuratorId).subscribe({
      next: (success) => {
        if (success) {
          this.contextRoutingService.toProjectConfigurations();
          this.messageService.showSuccess('Konfiguration wurde gelöscht');
          this.close();
        } else {
          this.messageService.showError('Fehler beim Löschen');
        }
        this.loading = false;
      },
    });
  }
}
