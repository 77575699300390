import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CACHE_M,
  CLS,
  CMS_CTRL_EP,
  CmsConfigurator,
  CmsGenericEntry,
  CmsProject,
  cmsServiceEP,
  ConditionGroup,
  Create,
  FIELD_TYPES,
  Id,
  MovePosition,
  NewEntry,
  ObjectUtil,
  PublishedConfigurationEntry,
  UsedBy,
  USER_SCOPES,
} from '@kfd/core';
import { Operation } from 'fast-json-patch';
import { BaseHttpService, FeCache, InsertResponse, MessageService, WebEndpointService } from '@kfd/web-core';
import { map } from 'rxjs/operators';

export interface DashboardMandant extends CmsProject {
  scopes: USER_SCOPES[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiConfigurationService {
  private httpService: BaseHttpService;

  private cache = new FeCache();

  constructor(
    httpClient: HttpClient,
    private webEndpointService: WebEndpointService,
    private messageService: MessageService,
  ) {
    this.httpService = new BaseHttpService(httpClient, environment.services.cms);
  }

  healthy(): Observable<boolean> {
    return this.httpService.get<boolean>(`/`).pipe(map(() => true));
  }

  public count(
    projectId: Id,
    useCache = true,
    trash = false,
  ): Observable<typeof cmsServiceEP.configuration.countProjectConfigurations.response> {
    const key = 'cfg-count-' + projectId + '-' + trash;
    const obs$ = this.webEndpointService.get(
      cmsServiceEP.configuration.countProjectConfigurations,
      [projectId.toString()],
      {
        params: {
          trash,
        },
      },
    );
    return useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public getConfigurations(
    projectId: Id,
    useCache = true,
    trash = false,
  ): Observable<typeof cmsServiceEP.configuration.listProjectConfigurations.response> {
    const key = 'cfg-list-' + projectId + '-' + trash;
    const obs$ = this.webEndpointService.get(
      cmsServiceEP.configuration.listProjectConfigurations,
      [projectId.toString()],
      {
        params: {
          trash,
        },
      },
    );
    return useCache === true ? this.cache.cacheObs(obs$, key) : obs$;
  }

  public readPublishedConfigurator(
    projectId: Id,
    configurationId: Id,
    version: number,
  ): Observable<PublishedConfigurationEntry> {
    return this.httpService.get<PublishedConfigurationEntry>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/published/${version}`,
    );
  }

  public checkCfgName(projectId: Id, name: string): Observable<boolean> {
    return this.httpService.get<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/check-name/${name}`,
    );
  }

  public checkCfgCode(projectId: Id, code: string): Observable<boolean> {
    return this.httpService.get<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/check-code/${code}`,
    );
  }

  public checkName(projectId: Id, configurationId: Id, name: string): Observable<boolean> {
    return this.httpService.get<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/check-name/${name}`,
    );
  }

  public createConfigurator(projectId: Id, label: string, name: string, code: string): Observable<CmsConfigurator> {
    return this.httpService.post<CmsConfigurator>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}`,
      {
        label,
        name,
        code,
      },
    );
  }

  public initConfiguration(projectId: Id, configurationId: Id): Observable<CmsConfigurator> {
    return this.httpService.get<CmsConfigurator>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}`,
    );
  }

  /**
   * returns a specific configuration
   */
  public getConfiguration(
    projectId: Id,
    configurationId: Id,
    useCache = true,
  ): Observable<typeof cmsServiceEP.configuration.readLatestCmsConfiguration.response> {
    if (projectId === undefined || projectId.toString().length === 0) {
      return of(undefined);
    }
    if (configurationId === undefined || configurationId.toString().length === 0) {
      return of(undefined);
    }
    const key = 'cfg-' + projectId + '-' + configurationId;
    const obs = this.webEndpointService.get(cmsServiceEP.configuration.readLatestCmsConfiguration, [
      projectId.toString(),
      configurationId.toString(),
    ]);
    return useCache === true ? this.cache.cacheObs(obs, key, CACHE_M) : obs;
  }

  public createEntry(
    projectId: Id,
    configuratorId: string,
    entry: Partial<CmsGenericEntry>,
    position: MovePosition,
  ): Observable<InsertResponse> {
    const updatedEntry: NewEntry<CmsGenericEntry> = ObjectUtil.removeKeys(Create.genericCmsEntry(entry), ['_id']);
    return this.httpService.post<InsertResponse>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/entry`,
      {
        entry: updatedEntry,
        position,
      },
    );
  }

  /**
   * @deprecated use createEntry instead
   */
  public createEntryByType(
    projectId: Id,
    configuratorId: string,
    parentId: string,
    type: CLS,
    label: string,
    name: string,
    fieldType?: FIELD_TYPES,
  ): Observable<InsertResponse> {
    return this.httpService.post<InsertResponse>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/entry/${parentId}/type/${type}`,
      {
        label,
        name,
        fieldType,
      },
    );
  }

  public changeEntry(
    projectId: Id,
    configurationId: Id,
    entryId: Id,
    operations: Operation[],
  ): Observable<InsertResponse> {
    return this.httpService.patch<InsertResponse>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/entry/${entryId}`,
      operations,
    );
  }

  public moveEntry(projectId: Id, configurationId: Id, entryId: Id, moveTo: MovePosition): Observable<boolean> {
    return this.httpService.post<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/entry/${entryId}/move`,
      moveTo,
    );
  }

  public removeConfiguration(projectId: Id, configurationId: Id): Observable<boolean> {
    return this.httpService.delete<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}`,
    );
  }

  public recycleConfiguration(projectId: Id, configurationId: Id): Observable<boolean> {
    return this.httpService.post<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/recycle`,
    );
  }

  public removeEntry(
    projectId: Id,
    configurationId: Id,
    entryId: Id,
    parentId: Id,
    removeChildren = false,
  ): Observable<boolean> {
    if (!configurationId || !entryId || !parentId) {
      this.messageService.showError('Der Eintrag kann nicht gelöscht werden');
      return of(false);
    }
    return this.httpService.delete<boolean>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/entry/${entryId}/parent/${parentId}`,
      {
        params: {
          removeChildren,
        },
      },
    );
  }

  public saveConditions(
    projectId: Id,
    configurationId: Id,
    parentEntryId: Id,
    childEntryId: Id,
    conditionGroup: ConditionGroup | undefined,
  ): Observable<typeof cmsServiceEP.configuration.saveEntryCondition.response> {
    return this.webEndpointService.put(
      cmsServiceEP.configuration.saveEntryCondition,
      [projectId.toString(), configurationId.toString(), parentEntryId.toString(), childEntryId.toString()],
      conditionGroup,
    );
  }

  public removeConditions(
    projectId: Id,
    configurationId: Id,
    parentEntryId: Id,
    childEntryId: Id,
  ): Observable<typeof cmsServiceEP.configuration.removeEntryCondition.response> {
    return this.webEndpointService.delete(cmsServiceEP.configuration.removeEntryCondition, [
      projectId.toString(),
      configurationId.toString(),
      parentEntryId.toString(),
      childEntryId.toString(),
    ]);
  }

  getEntryUsages(projectId: Id, configurationId: Id, entryId: Id): Observable<Record<string, UsedBy[][]>> {
    return this.httpService.get<Record<string, UsedBy[][]>>(
      `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configurationId}/entry/${entryId}/usages`,
    );
  }

  public draft(projectId: Id, configuratorId: string): Observable<InsertResponse> {
    return this.httpService
      .post<InsertResponse>(
        `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/draft`,
      )
      .pipe(
        tap(() => {
          this.messageService.showSuccess('Eine neue Vorschau ist jetzt bereit');
        }),
      );
  }

  public publish(projectId: Id, configuratorId: string): Observable<InsertResponse> {
    return this.httpService
      .post<InsertResponse>(
        `/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/publish`,
      )
      .pipe(
        tap(() => {
          this.messageService.showSuccess('Die Konfiguration wurde publiziert');
        }),
      );
  }

  public removeDraft(projectId: Id, configuratorId: string): Observable<boolean> {
    return this.httpService
      .delete<boolean>(`/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/draft`)
      .pipe(
        tap((success) => {
          if (success) {
            this.messageService.showSuccess('Der Entwurf wurde entfernt');
          }
        }),
      );
  }

  public removePublished(projectId: Id, configuratorId: string): Observable<boolean> {
    return this.httpService
      .delete<boolean>(`/${CMS_CTRL_EP.PROJECTS}/${projectId}/${CMS_CTRL_EP.CONFIGURATIONS}/${configuratorId}/publish`)
      .pipe(
        tap((success) => {
          if (success) {
            this.messageService.showSuccess('Die Publizierung wurde zurückgezogen');
          }
        }),
      );
  }
}
