import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoggingService } from '../../../services/logging.service';
import { BaseDataService } from '../../../services/base-data.service';
import { ApiBaseDataTemplateService } from '../../../services/api/api-base-data-template.service';
import { SharedModule } from '../../../shared/shared.module';
import { CmsContextService } from '../../../services/cms-context.service';
import { combineLatestWith, Observable } from 'rxjs';
import { BaseDataTemplate, CmsSelectionData, ICON, validateSelectionDataTemplate } from '@kfd/core';
import { map } from 'rxjs/operators';
import { BasedataEditDialogComponent } from '../basedata-edit-dialog/basedata-edit-dialog.component';
import { CmsDialogService } from '../../../services/cms-dialog.service';

interface DynamicUsageData {
  template?: BaseDataTemplate;
  tags?: string[];
  entries: {
    entry: CmsSelectionData;
    warnings: string[];
  }[];
}

@Component({
  selector: 'kfd-basedata-dynamic-usage-dialog',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './basedata-dynamic-usage-dialog.component.html',
  styleUrl: './basedata-dynamic-usage-dialog.component.scss',
})
export class BasedataDynamicUsageDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected dynamicUsageData: Observable<DynamicUsageData | undefined>;
  protected showAddDataBtn = false;

  constructor(
    protected readonly dialogRef: DynamicDialogRef,
    protected readonly dialogConfig: DynamicDialogConfig,
    protected readonly cmsDialogService: CmsDialogService,
    protected readonly ctx: CmsContextService,
    protected readonly loggingService: LoggingService,
    private readonly baseDataService: BaseDataService,
    private readonly apiBaseDataTemplateService: ApiBaseDataTemplateService,
  ) {
    super(dialogRef, dialogConfig);

    if (!this.dialogConfig.data?.templateName) {
      throw new Error('No template name provided');
      this.close();
    }

    this.showAddDataBtn = !!this.dialogConfig.data?.showAddDataBtn;

    this.loadData();
  }

  protected loadData() {
    this.dynamicUsageData = this.apiBaseDataTemplateService
      .getBaseDataTemplate(this.ctx.projectId, this.dialogConfig.data.templateName, true)
      .pipe(
        combineLatestWith(
          this.baseDataService.getDataByFilter(this.ctx.projectId, {
            templateName: this.dialogConfig.data?.templateName,
            tags: this.dialogConfig.data?.tags ?? [],
          }),
        ),
        map(([template, baseDataEntries]) => ({
          template,
          tags: this.dialogConfig.data?.tags ?? [],
          entries: baseDataEntries.map((entry) => ({
            entry,
            warnings: validateSelectionDataTemplate(template, entry),
          })),
        })),
      );
  }

  protected editBaseDataEntry(data?: Omit<CmsSelectionData, '_id' | 'meta'>) {
    const ref = this.cmsDialogService.open(BasedataEditDialogComponent, {
      selectionData: data,
      projectId: this.ctx.projectId,
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.loadData();
      }
    });
  }

  protected createBaseDataEntry(templateName: string): void {
    const ref = this.cmsDialogService.open(BasedataEditDialogComponent, {
      selectionData: {
        templateName,
      },
      projectId: this.ctx.projectId,
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.loadData();
      }
    });
  }
}
