<kfd-mandant-selection
  (selection)="select($event)"
  data-automationId="mandant-selection-dialog"
></kfd-mandant-selection>

<div class="flex justify-content-center">
  <button
    (click)="select()"
    class="reload-btn m-2"
    icon="pi {{ icon.CONFIGURATIONS }}"
    label="Zur Übersicht"
    pButton
    type="button"
  ></button>
</div>
