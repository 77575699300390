<p-dropdown
  (ngModelChange)="onTemplateChange($event)"
  [ngModel]="template"
  [options]="baseDataTemplates$ | async"
  appendTo="body"
  data-automationId="basedata-template-selection"
  placeholder="Keine Vorlage ausgewählt"
>
  <ng-template let-item pTemplate="item">
    <strong>{{ item.label }}</strong>
    @if (item.value?.description) {
      <div class="text-xs">{{ item.value.description }}</div>
    }
  </ng-template>
</p-dropdown>
