import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CfgStateService } from '../../cfg-state.service';

@Component({
  selector: 'kfd-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class CfgEditorErrorDialogComponent extends BaseDialogComponent {
  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected cfgStateService: CfgStateService,
  ) {
    super(dialogRef, dialogConfig);
  }
}
