import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Condition, ConditionalEntry, ConditionGroup, FIELD_TYPES, ICON, Is } from '@kfd/core';
import { MessageService } from '@kfd/web-core';
import { LoggingService } from '../../../services/logging.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CfgStateService } from '../../cfg-state.service';

export interface ModifyConditionData {
  parentName: string;
  childName: string;
}

@Component({
  selector: 'kfd-condition-dialog',
  templateUrl: './condition-dialog.component.html',
  styleUrls: ['./condition-dialog.component.scss'],
})
export class ConditionDialogComponent extends BaseDialogComponent {
  loading = true;
  parentEntryName: string;
  childEntryName: string;
  conditionGroup: ConditionGroup | undefined;
  conditions: Condition[] = [];
  isFirstOrLast: boolean | undefined;
  isValid = false;
  protected fieldTypes = FIELD_TYPES;
  protected ICON = ICON;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    protected cfgStateService: CfgStateService,
    protected messageService: MessageService,
  ) {
    super(dialogRef, dialogConfig);
    this.parentEntryName = (dialogConfig.data as ModifyConditionData).parentName;
    this.childEntryName = (dialogConfig.data as ModifyConditionData).childName;
    if (!this.parentEntryName || !this.childEntryName) {
      this.messageService.showError('Failed to initialize condition dialog');
      this.dialogRef.close();
      return;
    }
    this.initialize();
  }

  initialize() {
    this.loading = true;
    this.isFirstOrLast = false;

    const childEntry = this.cfgStateService.getCfgUtil().getEntryByName(this.childEntryName);
    if (!childEntry) {
      throw new Error(`Failed to load current entry "${this.childEntryName}"`);
      this.dialogRef.close();
    }

    if (Is.page(childEntry)) {
      const isFirst = this.cfgStateService.getCfgUtil().isFirst(this.childEntryName);
      const isLast = this.cfgStateService.getCfgUtil().isLast(this.childEntryName);
      this.isFirstOrLast = isFirst || isLast;
    }

    const childWrapper = this.cfgStateService.getCfgUtil().getEntryWrapperByName<ConditionalEntry>(this.childEntryName);
    if (!childWrapper) {
      throw new Error(
        `Failed to load child wrapper for parent "${this.parentEntryName}" and child "${this.childEntryName}" `,
      );
      this.dialogRef.close();
    }

    if (childWrapper.condition) {
      this.conditionGroup = childWrapper.condition;
    }
    this.loading = false;
  }

  groupChange(conditionGroup: ConditionGroup) {
    this.conditionGroup = conditionGroup;
  }

  removeConditions() {
    this.cfgStateService.saveConditions(this.childEntryName, undefined);
    this.close();
  }

  save() {
    if (this.isFirstOrLast) {
      this.close();
      return;
    }

    if (!this.isValid || !this.conditionGroup) {
      return;
    }

    if (this.conditionGroup.conditions.length === 0) {
      this.cfgStateService.saveConditions(this.childEntryName, undefined);
    } else {
      this.cfgStateService.saveConditions(this.childEntryName, this.conditionGroup);
    }

    this.close();
  }
}
