import { Key } from './key';
import { FormValue } from '@kfd/core';

export interface Persistence {
  useKey(key: string): void;

  set(key: Key, value: FormValue): void;

  get(key: Key): FormValue;

  has(key: Key): boolean;

  enable(key: Key): void;

  disable(key: Key): void;

  list(): Key[];

  clear(): void;
}
