<ng-container *ngIf="conditionGroup">
  <div class="m-2">
    <ng-container *ngFor="let condition of conditionGroup.conditions; let i = index">
      <kfd-split-row>
        <ng-container left>
          <kfd-condition
            (conditionChange)="conditionChange()"
            [(condition)]="conditionGroup.conditions[i]"
          ></kfd-condition>
        </ng-container>
        <ng-container right>
          <button
            (click)="remove(i)"
            class="p-button-rounded p-button-text"
            icon="pi {{ ICON.DELETE }}"
            pButton
            type="button"
          ></button>
        </ng-container>
      </kfd-split-row>
    </ng-container>
    <div class="flex mt-2">
      <div *ngIf="conditionGroup.conditions.length > 0" class="flex-1"></div>
      <ng-container *ngIf="conditionGroup.conditions.length > 0 && conditionGroup.conditions.length < 5">
        <button
          (click)="addEmptyCondition()"
          class="p-button-rounded p-button-text"
          icon="pi {{ ICON.ADD }}"
          pButton
          type="button"
        ></button>
      </ng-container>
      <ng-container *ngIf="conditionGroup.conditions.length === 0">
        <div class="margin-center-horizontally">
          <button
            (click)="addEmptyCondition()"
            class="p-button-rounded p-button-outlined"
            icon="pi {{ ICON.ADD }}"
            label="Neue Bedingung"
            pButton
            type="button"
          ></button>
        </div>
      </ng-container>
    </div>
  </div>
  @if (conditionGroup.conditions.length > 1) {
    <p-card class="m-2 mt-4">
      Wie sollen die Bedingungen verknüpft werden?
      <div class="flex flex-row">
        <div class="m-2">
          <p-radioButton
            (ngModelChange)="conditionChange()"
            [(ngModel)]="conditionGroup.lop"
            [value]="LOGICAL_OPERATOR.AND"
            label="UND"
          ></p-radioButton>
          <br />
          <small>Alle Bedingungen müssen erfüllt sein.</small>
        </div>
        <div class="m-2">
          <p-radioButton
            (ngModelChange)="conditionChange()"
            [(ngModel)]="conditionGroup.lop"
            [value]="LOGICAL_OPERATOR.OR"
            label="ODER"
          ></p-radioButton>
          <br />
          <small>Eine Bedingung muss erfüllt sein.</small>
        </div>
      </div>
    </p-card>
  }
</ng-container>
