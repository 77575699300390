import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import interact from 'interactjs';
import { InteractEvent } from '@interactjs/types/index';

export interface InteractPointerConfig {
  eventPropagation: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[interactDrag]',
})
export class InteractDragDirective {
  @Output()
  public readonly dragStart = new EventEmitter<InteractEvent>();
  @Output()
  public readonly dragMove = new EventEmitter<InteractEvent>();
  @Output()
  public readonly dragEnd = new EventEmitter<InteractEvent>();

  @Input()
  public readonly interactOptions: InteractPointerConfig | undefined;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {
    interact(elementRef.nativeElement).draggable({
      listeners: {
        start: (e) => this.handle(e, this.dragStart),
        move: (e) => this.handle(e, this.dragMove),
        end: (e) => this.handle(e, this.dragEnd),
      },
    });
  }

  private handle(e: InteractEvent, emitter: EventEmitter<InteractEvent>) {
    if (this.interactOptions?.eventPropagation === false) {
      e.stopPropagation();
    }
    emitter.emit(e);
  }
}
