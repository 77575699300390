@if (printValueList$ | async; as printValueList) {
  <kfd-value-list [printValueList]="printValueList"></kfd-value-list>

  <div class="flex justify-content-center">
    <p-button
      (click)="refresh()"
      class="mt-2"
      data-automationId="reset-stored-values"
      icon="pi {{ ICON.REFRESH }}"
      label="Werte zurücksetzen"
      [disabled]="printValueList.length === 0"
      [rounded]="true"
      [outlined]="true"
    ></p-button>
  </div>
} @else {
  <div class="full-width text-center font-italic" data-automationId="summary-no-values">Keine Werte</div>
}
