<ng-container *ngIf="configuration$ | loading | async as configuration">
  <kfd-dialog-layout
    (accept)="close()"
    (reject)="close()"
    [showRejectBtn]="false"
    [showRejectIcon]="true"
    acceptBtnLabel="OK"
    data-automationId="publishing-dialog"
    dialogTitle="Publizierung"
    docuPageId="publishing"
  >
    <div class="m-2">
      <kfd-configuration-publishing
        *ngIf="configuration.value"
        [configuration]="configuration.value"
      ></kfd-configuration-publishing>
    </div>
  </kfd-dialog-layout>
</ng-container>
