import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[visible]',
})
export class VisibleDirective implements AfterViewInit {
  // private readonly observer: ResizeObserver;
  // private oldRect?: DOMRectReadOnly;

  @Output()
  public readonly visible = new EventEmitter<Event>();

  constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
  ) {
    // // we observe the directive attached element for changes
    // this.observer = new ResizeObserver((entries) => this.zone.run(() => this.observe(entries[0])));
    // this.observer.observe(this.element.nativeElement);
    // console.log(element)
  }

  ngAfterViewInit() {
    this.visible.next({
      target: this.element.nativeElement,
    } as unknown as Event);
  }

  //
  // public ngOnDestroy(): void {
  //   this.observer.disconnect();
  // }
  //
  // private observe(domSize: ResizeObserverEntry): void {
  //   const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
  //   this.oldRect = domSize.contentRect;
  //   this.sscResized.emit(resizedEvent);
  // }
}
