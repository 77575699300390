<div class="flex-1 flex" data-automationId="preview-dialog" [kfdBlock]="loading">
  <button
    (click)="close()"
    class="close-btn p-button-rounded"
    data-automationId="dialog-accept-btn"
    icon="pi {{ ICON.DECLINE }}"
    pButton
    type="button"
  ></button>

  @if (cmsConfigurator) {
    <div [attr.data-automationId]="'preview-dialog-for-' + cmsConfigurator.code" class="flex-1 kfd-container flex">
      <kfd-cfg-layout-page></kfd-cfg-layout-page>
    </div>
  }
</div>
