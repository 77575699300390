import { Injectable } from '@angular/core';
import { WebEndpointService } from '@kfd/web-core';
import { Observable } from 'rxjs';
import { cmsServiceEP } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private webEndpointService: WebEndpointService) {}

  public markAsRead(notificationId: string): Observable<typeof cmsServiceEP.notifications.markAsRead.response> {
    return this.webEndpointService.post(cmsServiceEP.notifications.markAsRead, [notificationId]);
  }

  public markAllAsRead(): Observable<typeof cmsServiceEP.notifications.markAllAsRead.response> {
    return this.webEndpointService.post(cmsServiceEP.notifications.markAllAsRead, []);
  }
}
