import { Injectable, OnDestroy } from '@angular/core';
import { ApiConfigurationService } from '../services/api/api-configuration.service';
import { BehaviorSubject, catchError, delay, mergeMap, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import {
  CfgSkeleton,
  CfgUtil,
  clearUnvTimeout,
  CmsChildWrapper,
  CmsConfigurator,
  CmsGenericEntry,
  CmsGenericParentEntry,
  ConditionalChildWrapper,
  ConditionGroup,
  MovePosition,
  ObjectUtil,
  unvTimeout,
  UnvTimeoutValue,
} from '@kfd/core';
import { Exception } from '../shared/exceptions';
import { map } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { UnauthorizedError } from '@kfd/web-core';
import { Operation } from 'fast-json-patch';

export enum PersistenceState {
  FINISHED,
  UNSAVED,
  RUNNING,
  RETRY,
  ERROR,
}

export enum EntryStatusChange {
  FINISHED = 'finished',
  UPDATING = 'updating',
}

const MAX_PERSISTENCE_RETRIES = 3;

/**
 * handles configuration state specific logic
 */
@Injectable({
  providedIn: 'root',
})
export class CfgStateService implements OnDestroy {
  public persistenceRunning = false;
  public onPersistenceChange = new BehaviorSubject<PersistenceState>(PersistenceState.FINISHED);
  /**
   * on configuration change to another configuration
   */
  public onConfigurationChange = new BehaviorSubject<CmsConfigurator | undefined>(undefined);
  /**
   * on structure related changes like add,remove,move of elements
   */
  public onStructureChange = new BehaviorSubject<CfgSkeleton | undefined>(undefined);
  /**
   * on entry creation
   */
  public onNewEntry = new Subject<string>();
  /**
   * on entry deletion
   */
  public onEntryDeletion = new Subject<string>();

  //enables editing
  public allowChanges = false;

  /**
   * on single entry changes
   */
  private entryChange = new Subject<string>();
  private destroy$ = new Subject<boolean>();
  private persistenceQueue: Observable<boolean>[] = [];
  private cfgUtil: CfgUtil<CmsConfigurator> | undefined;
  private lastError: Error | undefined;
  /**
   * single entry update status
   */
  private entryStatusChange = new Subject<{ name: string; status: EntryStatusChange }>();
  private entryDiffs = new Map<string, Operation[]>();
  private entryPersistenceTimeouts: UnvTimeoutValue[] = [];

  constructor(
    private loginService: LoginService,
    private readonly apiConfigurationService: ApiConfigurationService,
  ) {}

  private _projectId: string | undefined;

  public get projectId(): string {
    if (!this._projectId) {
      throw new Exception('Missing project id');
    }
    return this._projectId;
  }

  public set projectId(value: string) {
    this._projectId = value;
  }

  private _configurationId: string | undefined;

  public get configurationId(): string {
    if (!this._configurationId) {
      throw new Exception('Missing configuration id');
    }
    return this._configurationId;
  }

  public set configurationId(value: string) {
    this._configurationId = value;
  }

  public get error(): Error | undefined {
    return this.lastError;
  }

  /**
   *  emits the entry wrapper when the enttry changes
   * @param entryName
   * @param parent
   */
  public onEntryChange<T extends CmsGenericParentEntry>(entryName?: string, parent?: true): Observable<T | undefined>;

  public onEntryChange<T extends CmsGenericEntry>(entryName?: string, parent?: false): Observable<T | undefined>;

  public onEntryChange<T extends CmsGenericEntry>(entryName?: string, parent = false): Observable<T | undefined> {
    return new Observable((observer) => {
      if (entryName) {
        if (parent) {
          observer.next(this.getCfgUtil().getEntryWrapperByName<T>(entryName, true));
        } else {
          observer.next(this.getCfgUtil().getEntryByName<T>(entryName, true));
        }
      }

      const subscription = this.entryChange.subscribe((name) => {
        if (!entryName || name === entryName) {
          if (parent) {
            observer.next(this.getCfgUtil().getEntryWrapperByName<T>(name));
          } else {
            observer.next(this.getCfgUtil().getEntryByName<T>(name));
          }
        }
      });
      return () => subscription.unsubscribe();
    });
  }

  public onEntryStatusChange(entryName?: string): Observable<EntryStatusChange> {
    return new Observable((observer) => {
      observer.next(EntryStatusChange.FINISHED);

      return this.entryStatusChange.subscribe((status) => {
        if (!entryName || status.name === entryName) {
          observer.next(status.status);
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public loadConfiguration(projectId: string, configurationId: string): Observable<boolean> {
    // if (this._projectId === projectId && this._configurationId === configurationId) {
    //   return of(true);
    // }
    this.projectId = projectId;
    this.configurationId = configurationId;
    return this.refresh();
  }

  public refresh(): Observable<boolean> {
    this.onStructureChange.next(undefined);
    this.onConfigurationChange.next(undefined);
    return this.apiConfigurationService.getConfiguration(this.projectId, this.configurationId, false).pipe(
      takeUntil(this.destroy$),
      map((configuration) => {
        if (!configuration) {
          throw new Error('Failed to load configuration');
        }
        this.setConfiguration(configuration);
        this.onConfigurationChange.next(this.getCfgUtil().getCfg());
        this.onStructureChange.next(this.getCfgUtil().getSkeleton());
        return true;
      }),
    );
  }

  /**
   * only for testing
   */
  public insertConfiguration(configuration: CmsConfigurator): void {
    this.setConfiguration(configuration);
    this.onConfigurationChange.next(this.getCfgUtil().getCfg());
    this.onStructureChange.next(this.getCfgUtil().getSkeleton());
    // this.onSelectionChange.next(undefined);
  }

  public getCfgUtil(): CfgUtil<CmsConfigurator>;
  public getCfgUtil(optional: true): CfgUtil<CmsConfigurator> | undefined;
  public getCfgUtil(optional?: boolean): CfgUtil<CmsConfigurator> | undefined {
    if (optional && !this.cfgUtil) {
      return;
    }
    if (!this.cfgUtil) {
      throw new Error('configuration is not set');
    }
    return this.cfgUtil;
  }

  public save(): void {
    this.runPersistence();
  }

  public nameExists(entryName: string): boolean {
    return this.getCfgUtil().getEntryByName(entryName, false) !== undefined;
  }

  public createEntry(cmsChildWrapper: CmsChildWrapper, target: MovePosition): void {
    this.validateEditAllowed();
    const newEntryWrapper = ObjectUtil.clone(cmsChildWrapper);
    newEntryWrapper.isNew = true;
    newEntryWrapper.entry.isNew = true;
    this.getCfgUtil().createEntry(newEntryWrapper, target);
    this.emitStructureChange();
    this.onNewEntry.next(newEntryWrapper.entry.name);
    this.entryChange.next(target.name);
  }

  public changeConfiguration(configuration: CmsConfigurator): void {
    const name = this.getCfgUtil().getCfg().name;
    const diff = this.getCfgUtil().getEntryDiff(name, configuration);
    if (diff) {
      this.getCfgUtil().patchEntry(name, configuration);
      const obs = this.apiConfigurationService
        .changeEntry(this.projectId, this.configurationId, configuration._id, diff)
        .pipe(map((resp) => !!resp._id));
      this.persist(obs);
      this.entryChange.next(name);
      this.emitStructureChange();
      this.onConfigurationChange.next(this.getCfgUtil().getCfg());
    }
  }

  public changeEntry(entryName: string, entry: CmsGenericEntry): void {
    this.validateEditAllowed();
    const changedEntry = ObjectUtil.clone(entry);
    this.entryStatusChange.next({ name: entryName, status: EntryStatusChange.UPDATING });

    if (!changedEntry._id) {
      this.getCfgUtil().patchEntry(entryName, changedEntry);
      this.persistNewEntry(changedEntry.name);
      const parent = this.getCfgUtil().getParentEntry(entryName, false);
      if (parent) {
        this.entryChange.next(parent.name);
      }
    } else {
      //get diff from not yet updated configuration
      const diff = this.getCfgUtil().getEntryDiff(entryName, changedEntry);
      if (diff) {
        const existingDiffs = this.entryDiffs.get(entryName) || [];
        this.entryDiffs.set(entryName, [...existingDiffs, ...diff]);
        // const obs = this.apiConfigurationService
        //   .changeEntry(this.projectId, this.configurationId, changedEntry._id, diff)
        //   .pipe(map((resp) => !!resp._id));
        // this.persist(obs.pipe(tap(() => this.entryStatusChange.next({ name: entryName, status: EntryStatusChange.FINISHED }))));
        this.persistEntryDiffs(changedEntry.name, entryName);
        this.getCfgUtil().patchEntry(entryName, changedEntry);
        this.entryChange.next(changedEntry.name);
      }
    }
  }

  public moveEntry(entryName: string, moveTarget: MovePosition): void {
    this.validateEditAllowed();
    const entry = this.getCfgUtil().getEntryByName<CmsGenericEntry>(entryName, true);
    const sourceParent = this.getCfgUtil().getParentEntry(entryName);

    this.getCfgUtil().moveEntry(entryName, moveTarget);

    if (!entry._id) {
      throw new Exception(`Cannot persist entry without id`, entry);
    }

    this.entryChange.next(sourceParent.name);
    this.entryChange.next(moveTarget.name);
    this.emitStructureChange();

    const obs = this.apiConfigurationService.moveEntry(this.projectId, this.configurationId, entry._id, moveTarget);
    this.persist(obs);
  }

  public saveConditions(entryName: string, conditionGroup: ConditionGroup | undefined): void {
    this.validateEditAllowed();
    const parentEntry = this.getCfgUtil().getParentEntry<CmsGenericEntry>(entryName);
    const childEntry = this.getCfgUtil().getEntryByName<CmsGenericEntry>(entryName);
    const childWrapper = this.getCfgUtil().getEntryWrapperByName<ConditionalChildWrapper>(entryName);

    let obs: Observable<boolean>;
    if (conditionGroup === undefined) {
      delete childWrapper.condition;
      obs = this.apiConfigurationService.removeConditions(
        this.projectId,
        this.configurationId,
        parentEntry._id,
        childEntry._id,
      );
    } else {
      childWrapper.condition = conditionGroup;
      obs = this.apiConfigurationService.saveConditions(
        this.projectId,
        this.configurationId,
        parentEntry._id,
        childEntry._id,
        conditionGroup,
      );
    }

    this.getCfgUtil().patchWrapperEntry(entryName, childWrapper);
    this.persist(obs);
    const parent = this.getCfgUtil().getParentEntry(entryName, false);
    if (parent) {
      this.entryChange.next(parent.name);
    }
  }

  public deleteEntry(entryName: string): void {
    this.validateEditAllowed();
    const entry = this.getCfgUtil().getEntryByName<CmsGenericEntry>(entryName, true);
    const parent = this.getCfgUtil().getParentEntry<CmsGenericEntry>(entryName, true);
    this.getCfgUtil().removeWrapperEntry(entryName);

    if (entry._id) {
      const obs = this.apiConfigurationService
        .removeEntry(this.projectId, this.configurationId, entry._id, parent._id, true)
        .pipe(map(() => true));
      this.persist(obs);
    }
    this.onEntryDeletion.next(entryName);
    this.emitStructureChange();
    if (parent) {
      this.entryChange.next(parent.name);
    }
  }

  protected persistNewEntry(entryName: string) {
    this.validateEditAllowed();
    const util = this.getCfgUtil();
    const wrapperEntry = util.getEntryWrapperByName<CmsChildWrapper>(entryName, true);
    const position = util.getRelativePosition(entryName);

    const obs = this.apiConfigurationService
      .createEntry(this.projectId, this.configurationId, wrapperEntry.entry, position)
      .pipe(
        map((insertResponse) => {
          if (insertResponse._id) {
            wrapperEntry.entry._id = insertResponse._id;
            if (wrapperEntry.entry.isNew) {
              delete wrapperEntry.entry.isNew;
              this.emitStructureChange();
            }
            delete wrapperEntry.entry.isDirty;
            delete wrapperEntry.isNew;
            delete wrapperEntry.isDirty;
            util.updateWrapperEntry(entryName, wrapperEntry);
            const parent = this.getCfgUtil().getParentEntry(entryName, false);
            if (parent) {
              this.entryChange.next(parent.name);
            }
            // if (this.selectedEntry === entryName) {
            //   this.selectEntry(entryName, true);
            // }
            return true;
          }
          return false;
        }),
      );
    this.persist(obs, true);
  }

  protected setConfiguration(configuration: CmsConfigurator): void {
    this.cfgUtil = CfgUtil.create<CmsConfigurator>(configuration);
    //run asynchronous to avoid blocking du initialization
    window.setTimeout(() => {
      if (this.cfgUtil) {
        this.cfgUtil.initialize();
      }
    });
  }

  protected emitStructureChange(): void {
    this.onStructureChange.next(this.getCfgUtil().getSkeleton());
  }

  private persistEntryDiffs(entryName: string, oldEntryName?: string): void {
    // all changes are saved with the old name if it has been renamed
    const workingName = oldEntryName ?? entryName;
    if (this.entryPersistenceTimeouts[workingName]) {
      clearUnvTimeout(this.entryPersistenceTimeouts[workingName]);
    }

    this.entryPersistenceTimeouts[workingName] = unvTimeout(() => {
      if (!this.entryDiffs.has(workingName)) {
        return;
      }
      const diffs = this.entryDiffs.get(workingName);
      //fetch the current entry with the updated name
      const entry = this.getCfgUtil().getEntryByName<CmsGenericEntry>(entryName, true);
      const obs = this.apiConfigurationService
        .changeEntry(this.projectId, this.configurationId, entry._id, diffs)
        .pipe(map((resp) => !!resp._id));
      this.persist(
        obs.pipe(
          tap(() => {
            // update parent for name change to initialize wrappers correctly
            if (oldEntryName && oldEntryName !== entryName) {
              const parent = this.getCfgUtil().getParentEntry(entryName, true);
              this.entryChange.next(parent.name);
            }
            this.entryStatusChange.next({
              name: workingName,
              status: EntryStatusChange.FINISHED,
            });
          }),
        ),
      );
    }, 1000);
  }

  private persist(obs: Observable<boolean>, force = false): void {
    if (force) {
      this.persistenceRunning = true;
      obs.subscribe({
        next: () => {
          this.persistenceRunning = false;
          this.runPersistence().subscribe();
        },
        error: (error) => {
          this.onPersistenceChange.next(PersistenceState.ERROR);
          this.persistenceRunning = false;
          this.lastError = error;
          throw error;
        },
      });
      return;
    }

    this.persistenceQueue.push(obs);
    this.onPersistenceChange.next(PersistenceState.UNSAVED);

    this.runPersistence().subscribe();
  }

  private runPersistence(retry = 0): Observable<boolean> {
    this.validateEditAllowed();
    if (this.persistenceRunning === true) {
      return of(false);
    }
    if (this.persistenceQueue.length === 0) {
      this.onPersistenceChange.next(PersistenceState.FINISHED);
      return of(true);
    }
    if (retry > MAX_PERSISTENCE_RETRIES) {
      this.onPersistenceChange.next(PersistenceState.ERROR);
      if (this.lastError) {
        throw this.lastError;
      }
      return of(false);
    }
    this.persistenceRunning = true;
    if (retry === 0) {
      this.onPersistenceChange.next(PersistenceState.RUNNING);
    } else {
      this.onPersistenceChange.next(PersistenceState.RETRY);
    }
    const first = this.persistenceQueue[0];
    return first.pipe(
      map((success) => {
        if (!success) {
          this.persistenceRunning = false;
          this.onPersistenceChange.next(PersistenceState.ERROR);
          return false;
        }
        this.persistenceRunning = false;
        this.persistenceQueue = this.persistenceQueue.slice(1);
        this.runPersistence();
        return true;
      }),
      catchError((error) => {
        this.persistenceRunning = false;
        if (error instanceof UnauthorizedError) {
          return this.loginService.showLogin<boolean>(this.runPersistence(++retry));
        } else {
          this.lastError = error;
          return of(true).pipe(
            delay(retry < MAX_PERSISTENCE_RETRIES ? retry * 1000 : 0),
            mergeMap(() => this.runPersistence(++retry)),
          );
        }
      }),
    );
  }

  private validateEditAllowed(): void {
    if (!this.allowChanges) {
      throw new Error('Editing is not allowed');
    }
  }
}
