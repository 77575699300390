import { Component, Input } from '@angular/core';
import { Asset, ICON, Id, Is } from '@kfd/core';
import { CmsContextService } from '../../../../services/cms-context.service';
import { AssetService } from '../../../../services/asset.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'kfd-asset-thumbnail',
  templateUrl: './asset-thumbnail.component.html',
  styleUrl: './asset-thumbnail.component.scss',
})
export class AssetThumbnailComponent {
  protected asset$: Observable<Asset | undefined> | undefined;
  protected readonly ICON = ICON;

  protected cacheBreaker = Date.now();

  constructor(
    private ctx: CmsContextService,
    private readonly assetService: AssetService,
  ) {}

  @Input()
  public set assetId(value: Id | undefined) {
    if (!Is.id(value)) {
      this.asset$ = of(undefined);
      return;
    }

    this.asset$ = this.assetService.getAsset(this.ctx.projectId, value, true);
  }
}
