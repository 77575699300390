<p-button
  (click)="op.toggle($event)"
  [badge]="unreadNotifications"
  [outlined]="true"
  [rounded]="true"
  badgeClass="p-badge-warning"
  icon="pi {{ icon.NOTIFICATION }}"
  styleClass="white"
></p-button>

<p-overlayPanel #op appendTo="body" styleClass="growable large-content">
  <ng-template pTemplate>
    @if (notifications.length > 0) {
      <p-button
        [text]="true"
        label="Alle als gelesen markieren"
        icon="pi {{ icon.ACCEPT }}"
        (click)="markAllAsRead()"
      ></p-button>
      @for (notification of notifications; track notification._id) {
        <p-messages [severity]="!notification.readDate ? 'info' : 'none'" class="my-2" styleClass="padding-xs">
          <ng-template pTemplate>
            <div class="flex-1 flex flex-column">
              <div class="text-xs">
                {{ notification.date | date: 'short' }}
              </div>
              <strong class="base">
                {{ notification.subject }}
              </strong>
              <div class="text-sm">
                {{ notification.message }}
              </div>
              <div *ngIf="notification.link">
                <button
                  (click)="op.hide(); markAsRead(notification._id)"
                  [label]="notification.link.text"
                  [routerLink]="notification.link.target"
                  class="p-button-text"
                  icon="pi {{ icon.LINK }}"
                  pButton
                  type="button"
                ></button>
              </div>
            </div>
            <button
              (click)="markAsRead(notification._id)"
              *ngIf="!notification.readDate"
              [loading]="loading.has(notification._id)"
              class="p-button-text p-button-rounded"
              icon="pi {{ icon.ACCEPT }}"
              pButton
              pTooltip="Als gelesen markieren"
              type="button"
            ></button>
          </ng-template>
        </p-messages>
      }
    } @else {
      <ng-container *ngTemplateOutlet="emptyNotifications"></ng-container>
    }

    <!--    <button-->
    <!--      pButton-->
    <!--      type="button"-->
    <!--      class="p-button-text footer-button-right"-->
    <!--      label="Alle Nachrichten anzeigen"-->
    <!--      (click)="showNotifications()"-->
    <!--    ></button>-->
  </ng-template>
</p-overlayPanel>

<ng-template #emptyNotifications>
  <kfd-empty-msg info="Keine neuen Nachrichten vorhanden" infoIcon="{{ icon.NOTIFICATION }}"></kfd-empty-msg>
</ng-template>
