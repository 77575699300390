import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ICON, MyProject } from '@kfd/core';

@Component({
  selector: 'kfd-mandant-selection-dialog',
  templateUrl: './mandant-selection-dialog.component.html',
  styleUrls: [],
})
export class MandantSelectionDialogComponent {
  icon = ICON;

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  select(mandant?: MyProject) {
    this.dialogRef.close(mandant);
  }
}
