import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiBaseDataTemplateService } from '../../../services/api/api-base-data-template.service';
import { Observable, tap } from 'rxjs';
import { BaseDataTemplate, SelectOption } from '@kfd/core';
import { CmsContextService } from '../../../services/cms-context.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-basedata-template-selection',
  templateUrl: './basedata-template-selection.component.html',
  styleUrl: './basedata-template-selection.component.scss',
})
export class BasedataTemplateSelectionComponent {
  @Output()
  public templateChange = new EventEmitter<BaseDataTemplate>();

  @Input()
  public template: BaseDataTemplate | undefined;

  protected baseDataTemplates$: Observable<SelectOption<BaseDataTemplate>[]>;

  private templatesLoaded = false;

  constructor(
    private readonly ctx: CmsContextService,
    private readonly apiBaseDataTemplateService: ApiBaseDataTemplateService,
  ) {
    this.loadTemplates();
  }

  private _templateName: string | undefined;

  public get templateName(): string | undefined {
    return this._templateName;
  }

  @Input()
  public set templateName(value: string | undefined) {
    this._templateName = value;
    if (this.templatesLoaded) {
      this.loadTemplates();
    }
  }

  protected loadTemplates(): void {
    this.baseDataTemplates$ = this.apiBaseDataTemplateService.listBaseDataTemplates(this.ctx.projectId).pipe(
      tap((templates) => {
        if (!this.template) {
          const templateWithName = templates.find((template) => template.name === this._templateName);
          if (templateWithName) {
            this.template = templateWithName;
          }
        }
        this.templatesLoaded = true;
      }),
      map((baseDataTemplates) => [
        {
          value: undefined,
          label: '- Keine Vorlage ausgewählt -',
        },
        ...baseDataTemplates.map((baseDataTemplate) => ({
          value: baseDataTemplate,
          label: baseDataTemplate.label,
        })),
      ]),
    );
  }

  protected onTemplateChange(template: BaseDataTemplate): void {
    this.template = template;
    this.templateChange.emit(template);
  }
}
