import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kfd-hidelabel-cfg-setting',
  templateUrl: './hidelabel-cfg-setting.component.html',
  styleUrls: ['./hidelabel-cfg-setting.component.scss'],
})
export class HidelabelCfgSettingComponent {
  @Output()
  public valueChange = new EventEmitter<boolean>();

  private _value = false;

  public get value(): boolean {
    return this._value;
  }

  @Input()
  public set value(value: boolean) {
    this._value = value;
  }
}
