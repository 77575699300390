@if (fieldSelectOptions.length === 0) {
  @if (emptyMsg) {
    <small class="color-error">{{ emptyMsg }}</small>
  }
} @else {
  <div class="flex">
    <div class="flex-1">
      <label>Feld</label>
      <p-dropdown
        (onChange)="onSelectField($event)"
        [ngModel]="fieldRef.name"
        [options]="fieldSelectOptions"
        appendTo="body"
      >
        <ng-template let-item pTemplate="item">
          <div class="flex align-items-center gap-2">
            <i class="pi {{ item.icon }}"></i>
            <div>{{ item.label }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    @if (allowProperties) {
      @if (propertiesSelectOptions$ | async; as propertiesSelectOptions) {
        <div class="flex-1">
          <label>Wert</label>
          <p-dropdown
            [disabled]="propertiesSelectOptions.length === 0"
            (onChange)="onSelectProperty($event)"
            [ngModel]="fieldRef.property"
            [options]="propertiesSelectOptions"
            appendTo="body"
          ></p-dropdown>
        </div>
      }
    }
  </div>
}
