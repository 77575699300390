import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  ArrayUtil,
  clearUnvTimeout,
  CLS,
  CmsSelectionData,
  Create,
  DATA_TYPES,
  EntryMeta,
  ICON,
  Id,
  OWNER_ADMIN,
  PUBLISHED_STATE,
  ScopedUser,
  SelectOption,
  StringUtil,
  unvTimeout,
  UnvTimeoutValue,
  USER_SCOPES,
} from '@kfd/core';
import { BaseDataService } from '../../../services/base-data.service';
import { BasedataEditDialogComponent } from '../basedata-edit-dialog/basedata-edit-dialog.component';
import { FilterService, MenuItem } from 'primeng/api';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { DataUsageDialogComponent } from '../data-usage-dialog/data-usage-dialog.component';
import { ScopeUtil } from '../../../shared/scope.util';
import { CmsDialogService } from '../../../services/cms-dialog.service';
import { DataViewDialogComponent } from '../data-view-dialog/data-view-dialog.component';
import { Table } from 'primeng/table';
import { CmsContextService } from '../../../services/cms-context.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiBaseDataTemplateService } from '../../../services/api/api-base-data-template.service';
import { map } from 'rxjs/operators';

export enum ViewModes {
  MANGAGE = 'manage',
  SELECT = 'select',
}

interface TableData {
  selectionData: CmsSelectionData;
  menuItems: MenuItem[];
}

@Component({
  selector: 'kfd-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss'],
})
export class DataManagementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  public filterChange = new EventEmitter<{ key: string; value: unknown }[]>();

  @Output()
  public viewEntryChange = new EventEmitter<string | undefined>();

  @Output()
  public selectionChange = new EventEmitter<CmsSelectionData[]>();

  @Output()
  public menuItemsChange = new EventEmitter<MenuItem[]>();

  @Input()
  public mandantName = '';

  @Input()
  public projectId: string | undefined;

  @Input()
  public currentUser: ScopedUser | undefined;

  @Input()
  public configuratorId: string | undefined;

  @Input()
  public mode: ViewModes = ViewModes.MANGAGE;

  protected readonly ICON = ICON;
  protected readonly filterNames = {
    'selectionData.label': 'Label',
    'selectionData.tags': 'Tags',
    'selectionData.templateName': 'Template',
    'selectionData.meta': 'Status',
    'selectionData.value.type': 'Datentyp',
  };
  protected readonly statusOptions = Object.values(PUBLISHED_STATE);
  protected init = false;
  protected initTimeout: UnvTimeoutValue | undefined;
  protected dataCount: number | undefined;
  protected dataList$: Observable<TableData[]> | undefined;
  protected tags: string[] | undefined;
  protected baseDataTemplates$: Observable<SelectOption[]> | undefined;
  protected states = PUBLISHED_STATE;
  protected selectedData: CmsSelectionData[] = [];
  protected lastScrollPos: number | undefined;
  protected loading = false;
  protected scopes = USER_SCOPES;
  protected showFilter = false;

  @ViewChild('dataTable')
  protected dataTable: Table | undefined;

  @ViewChild('filterOverlay')
  protected filterOverlay: OverlayPanel | undefined;

  // filters which needs to be applied (set from outside)
  private filtersToApply: { key: string; value: unknown }[] | undefined;
  private destroy$ = new Subject<boolean>();

  constructor(
    private dataService: BaseDataService,
    private apiBaseDataTemplateService: ApiBaseDataTemplateService,
    private cmsDialogService: CmsDialogService,
    private filterService: FilterService,
    private ctx: CmsContextService,
  ) {
    this.filterService.register('inArray', (itemValue: string[], filterValue: string[]): boolean => {
      return filterValue.some((value1) => itemValue.indexOf(value1) >= 0);
    });
    this.filterService.register('meta-status', (meta: EntryMeta, filterValue: string[]): boolean => {
      return meta.state ? filterValue.indexOf(meta.state) >= 0 : false;
    });
  }

  private _selectedIds: string[] = [];

  @Input()
  set selectedIds(value: string[]) {
    this._selectedIds = value;
    if (this._selectedIds) {
      this.selectedData = this._selectedIds.map((id) => {
        return {
          _id: id,
        } as unknown as CmsSelectionData;
      });
      this.selectionChange.emit(this.selectedData);
    }
  }

  @Input()
  public set filterValues(value: { key: string; value: unknown }[]) {
    this.filtersToApply = value;
    if (this.init) {
      this.applyFilters();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit() {
    this.initialized();
    unvTimeout(() => {
      this.updateMainMenuItems();
    });
  }

  initialized() {
    if (this.dataTable) {
      this.init = true;
      clearUnvTimeout(this.initTimeout);
      this.filterListener();
      this.updateMainMenuItems();
      return;
    }
    this.initTimeout = unvTimeout(() => {
      this.initialized();
      this.updateMainMenuItems();
    }, 50);
  }

  /**
   * listen to filter changes end exposes them
   */
  filterListener(): void {
    if (!this.dataTable) {
      throw new Error('dataTable is not defined');
    }
    this.dataTable.onFilter.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      const keys = Object.keys(e.filters);
      const filterValues = keys.map((key) => ({
        key: key.split('.')[1],
        value: e.filters[key].value,
      }));
      this.filterChange.emit(filterValues);
    });

    this.applyFilters();
  }

  applyFilters(): void {
    if (!this.filtersToApply) {
      return;
    }
    this.dataTable.filters = {};
    this.filtersToApply.forEach((filter) => {
      this.applyFilter(filter.key, filter.value);
    });
    // this.dataTable.reset();
    this.filtersToApply = undefined;
  }

  loadData() {
    // this.loading = true;

    this.baseDataTemplates$ = this.apiBaseDataTemplateService
      .listBaseDataTemplates(this.ctx.projectId)
      .pipe(map((templates) => templates.map((template) => ({ label: template.label, value: template.name }))));

    // const filterSet = this.dataTable ? this.dataTable.filterService.filters : {};

    this.dataList$ = this.dataService.getData(this.ctx.projectId).pipe(
      tap((selectionData) => {
        this.dataCount = selectionData.length;

        if (selectionData.length > 0) {
          this.selectedData = ArrayUtil.filterUndefined<CmsSelectionData>(
            this.selectedData.map((entry) => selectionData.find((data) => data._id === entry._id)),
          );
          this.selectionChange.emit(this.selectedData);

          this.tags = [
            ...new Set(
              selectionData.map((value) => value.tags).reduce((pre, cur) => (pre && cur ? pre.concat(cur) : cur)),
            ),
          ];
        }
      }),
      map((selectionData) =>
        selectionData.map((value) => ({
          selectionData: value,
          menuItems: this.getEntryMenuItems(value),
        })),
      ),
    );
  }

  public onSelectionChange(selectionData: CmsSelectionData[]): void {
    this.selectedData = selectionData;
    this.selectionChange.emit(this.selectedData);
  }

  public viewEntry(entryName: string, e?: { layerY: number }) {
    this.viewEntryChange.emit(entryName);
    this.cmsDialogService
      .open(DataViewDialogComponent, {
        identifier: entryName,
        projectId: this.projectId,
      })
      .onClose.subscribe((result: string | undefined) => {
        this.viewEntryChange.emit(undefined);
        if (result === 'edit') {
          this.editElement(entryName, e);
        }
      });
  }

  editElement(entryName: string, e?: { layerY: number }) {
    if (e) {
      this.lastScrollPos = e.layerY;
    }
    this.dataService.getEntryByName(this.ctx.projectId, entryName).subscribe((data) => {
      this.createBaseData(data);
    });
  }

  copyElement(data: CmsSelectionData, e: { layerY: number }) {
    this.lastScrollPos = e.layerY;
    const newEntry: Omit<CmsSelectionData, '_id'> = {
      cls: CLS.SELECTION_DATA,
      type: DATA_TYPES.SELECTION,
      name: '',
      label: `${data.label} (copy)`,
      templateName: data.templateName,
      values: data.values,
      tags: data.tags,
      image: data.image,
      description: data.description,
      info: data.info,
      meta: Create.entryMeta(),
    };
    this.createBaseData(newEntry);
  }

  showUsageInfo(entryName: string) {
    this.cmsDialogService.open(DataUsageDialogComponent, {
      entryName,
      projectId: this.projectId,
    });
  }

  // dataLoaded() {
  //   if (!this.lastScrollPos) {
  //     return;
  //   }
  //   const scrollElement = this.dataTable?.el?.nativeElement?.getElementsByClassName('cdk-virtual-scroll-viewport')[0];
  //   if (scrollElement) {
  //     scrollElement.scrollTop = this.lastScrollPos;
  //   }
  // }

  createBaseData(data?: Omit<CmsSelectionData, '_id' | 'meta'>) {
    const ref = this.cmsDialogService.open(BasedataEditDialogComponent, {
      selectionData: data,
      projectId: this.projectId,
    });

    ref.onClose.subscribe((res) => {
      if (res) {
        this.loadData();
      }
    });
  }

  removeData(id: Id) {
    this.dataService.removeById(this.ctx.projectId, id).subscribe(() => {
      this.loadData();
    });
  }

  tableFilter() {
    this.updateMainMenuItems();
  }

  clearFilter() {
    if (this.dataTable) {
      this.dataTable.filters = {};
      this.dataTable.reset();
    }
    this.filterChange.emit([]);
  }

  /**
   * applies a filter by a name
   * values are set from unsave source and need to be converted to save strings
   */
  protected applyFilter(name: string, value: unknown) {
    if (!this.dataTable) {
      throw new Error('dataTable is not defined');
    }
    if (!value) {
      return;
    }
    const filterValue = StringUtil.toSaveString(value.toString());
    switch (name) {
      case 'templateName':
        this.dataTable.filter(filterValue, 'selectionData.templateName', 'equals');
        break;
      case 'tags':
        this.dataTable.filter(filterValue, 'selectionData.tags', 'inArray');
        break;
      case 'label':
        this.dataTable.filter(filterValue, 'selectionData.label', 'contains');
        break;
    }
  }

  protected updateMainMenuItems(): void {
    const menuItems: MenuItem[] = [];
    if (
      this.currentUser &&
      ScopeUtil.scopeAllowed(this.currentUser.scopes, [...OWNER_ADMIN, USER_SCOPES.MODELLER, USER_SCOPES.AUTHOR])
    ) {
      menuItems.push({
        label: 'Neuer Datensatz',
        icon: 'pi ' + ICON.ADD,
        automationId: 'new-basedata-entry',
        command: () => {
          this.createBaseData();
        },
      });
    }
    // if (this.currentUser && ScopeUtil.scopeAllowed(this.currentUser.scopes, [...OWNER_ADMIN, USER_SCOPES.MODELLER, USER_SCOPES.AUTHOR])) {
    //   menuItems.push({
    //     label: 'Vorlagen',
    //     icon: 'pi ' + ICON.TEMPLATE,
    //     automationId: 'basedata-templates',
    //     command: () => {
    //       this.createBaseData();
    //     },
    //   });
    // }

    menuItems.push({
      label: 'Filter',
      icon: 'pi ' + ICON.FILTER,
      automationId: 'filter-btn',
      // disabled: this.dataList.length === 0,
      command: (e) => {
        this.showFilter = !this.showFilter;
        if (this.filterOverlay) {
          if (this.showFilter) {
            this.filterOverlay.show(e.originalEvent);
          } else {
            this.filterOverlay.hide();
          }
        }
      },
    });

    if (this.dataTable?.filters && Object.keys(this.dataTable.filters).length > 0) {
      menuItems.push({
        label: 'Filter entfernen',
        icon: 'pi ' + ICON.FILTER_REMOVE,
        automationId: 'filter-btn',
        command: () => {
          this.clearFilter();
        },
      });
    }

    this.menuItemsChange.emit(menuItems);
  }

  protected getEntryMenuItems(selectionData: CmsSelectionData) {
    const menuItems: MenuItem[] = [];

    menuItems.push({
      label: 'Anzeigen',
      icon: 'pi ' + ICON.SHOW,
      tooltip: 'Details anzeigen',
      automationId: 'data-view',
      command: (e) => {
        this.viewEntry(selectionData.name, e as { layerY: number });
      },
    });

    if (this.currentUser && ScopeUtil.scopeAllowed(this.currentUser.scopes, [...OWNER_ADMIN, USER_SCOPES.AUTHOR])) {
      menuItems.push({
        label: 'Bearbeiten',
        icon: 'pi ' + ICON.EDIT,
        tooltip: 'Bearbeiten',
        automationId: 'data-edit',
        command: (e) => {
          this.editElement(selectionData.name, e as { layerY: number });
        },
      });
      menuItems.push({
        label: 'Kopieren',
        icon: 'pi ' + ICON.COPY,
        tooltip: 'Kopieren',
        automationId: 'data-copy',
        command: (e) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.copyElement(selectionData, e as { layerY: number });
        },
      });
    }

    menuItems.push({
      label: 'Genutzt durch...',
      icon: 'pi ' + ICON.LINK,
      tooltip: 'Genutzt durch...',
      automationId: 'data-usage-info',
      command: () => {
        this.showUsageInfo(selectionData.name);
      },
    });

    if (this.currentUser && ScopeUtil.scopeAllowed(this.currentUser.scopes, [...OWNER_ADMIN, USER_SCOPES.AUTHOR])) {
      menuItems.push({
        label: 'Löschen',
        icon: 'pi ' + ICON.DELETE,
        automationId: 'data-delete',
        command: () => {
          if (selectionData._id) {
            this.removeData(selectionData._id);
          }
        },
      });
    }

    return menuItems;
  }
}
