import { FieldRefResolver } from './calculation/calculation-resolver';
import { CalcOperation, Calculation, DATA_VALUE_TYPE, FieldRef, FormValue } from '../dtos/index';
import { Create } from '../dtos/cls-creator';

export const FIELD_REF_NUMBER_1 = 'field-number-1';
export const FIELD_REF_NUMBER_5 = 'field-number-5';
export const FIELD_REF_READONLY_1 = 'field-readonly-1';
export const FIELD_REF_READONLY_2 = 'field-readonly-2';
export const FIELD_REF_EMPTY = 'field-empty';

export class MockFieldRefResolver implements FieldRefResolver {
  resolve(fieldRef: FieldRef): Promise<FormValue | Calculation> {
    switch (fieldRef.name) {
      case FIELD_REF_EMPTY:
        return Promise.resolve(Create.emptyFormValue());
      case FIELD_REF_NUMBER_1:
        return Promise.resolve(Create.singleFormValue(Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1)));
      case FIELD_REF_NUMBER_5:
        return Promise.resolve(Create.singleFormValue(Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 5)));

      case FIELD_REF_READONLY_1:
        return Promise.resolve({
          cls: 'Calculation',
          op: CalcOperation.ADD,
          params: [Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1), Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 2)],
        } as Calculation);

      case FIELD_REF_READONLY_2:
        return Promise.resolve({
          cls: 'Calculation',
          op: CalcOperation.ADD,
          params: [
            {
              cls: 'FieldRef',
              name: FIELD_REF_NUMBER_1,
            } as FieldRef,
            {
              cls: 'FieldRef',
              name: FIELD_REF_NUMBER_5,
            } as FieldRef,
          ],
        } as Calculation);
      default:
        return Promise.reject('unknown (mock) field');
    }
  }
}
