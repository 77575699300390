import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-data-usage-dialog',
  templateUrl: './data-usage-dialog.component.html',
  styleUrls: ['./data-usage-dialog.component.scss'],
})
export class DataUsageDialogComponent extends BaseDialogComponent {
  loading = false;
  entryName: string;
  projectId: string;
  closeIfNotUsed = false;
  acceptBtnLabel = 'OK';
  showRejectBtn = false;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data.projectId) {
      throw new Error('Cannot show usage without mandant id');
    }
    if (!dialogConfig.data.entryName) {
      throw new Error('Cannot show usage without entry name');
    }
    this.entryName = dialogConfig.data.entryName;
    this.projectId = dialogConfig.data.projectId;

    if (dialogConfig.data.closeIfNotUsed !== undefined) {
      this.closeIfNotUsed = dialogConfig.data.closeIfNotUsed;
    }
    if (dialogConfig.data.acceptBtnLabel !== undefined) {
      this.acceptBtnLabel = dialogConfig.data.acceptBtnLabel;
    }
    if (dialogConfig.data.showRejectBtn !== undefined) {
      this.showRejectBtn = dialogConfig.data.showRejectBtn;
    }
  }

  isUsed(isUsed: boolean) {
    if (!isUsed && this.closeIfNotUsed) {
      this.close(true);
    }
  }
}
