<form (ngSubmit)="verify()">
  <div class="p-field my-2">
    <div class="grid grid-nogutter">
      <div class="col-12 md:col-6 p-inputgroup">
        <span class="p-inputgroup-addon code">{{ confirmationCode }}</span>
        <input
          [(ngModel)]="enteredCode"
          [disabled]="confirmed"
          name="confirmation-code"
          pInputText
          placeholder="Bestätigungscode"
          type="number"
        />
      </div>
      <div class="col-12 md:col-6">
        <div class="mt-2 md:mt-0 md:ml-2">
          <button
            (click)="verify()"
            [disabled]="confirmed"
            [label]="label"
            class="p-button-danger"
            icon="pi pi-exclamation-triangle"
            pButton
            type="button"
          ></button>
        </div>
      </div>
    </div>
    <small *ngIf="invalidCode" class="p-error" id="username2-help"> Bestätigungscode ist nicht korrekt </small>
  </div>
</form>
