import {
  AdvancedLicenceOptions,
  CalcOperation,
  CLS,
  CmsChildWrapper,
  CONDITIONAL_OPERATOR,
  Create,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  DateRef,
  Field,
  FIELD_TYPES,
  FunctionCommand,
  GenericEntry,
  ICON,
  LicenceConfigOptions,
  LicenceOptions,
  LicencePackage,
  SELECT_FIELD_LAYOUTS,
  SelectOption,
  START_DATES,
  StarterLicenceOptions,
  StatisticRange,
  TEXTFIELD_VALIDATIONS,
  UNIT_TYPES,
  USER_SCOPES,
} from '@kfd/core';

/**
 * @deprecated use global CMS_APP_ROUTE_PARTS_ROOT instead
 */
export enum ROUTE_PARTS {
  PROJECT = 'project',
}

/**
 * @deprecated use global CMS_APP_ROUTE_PARTS_ROOT instead
 */
export enum PROJECT_ROUTE_PARTS {
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  BASE_DATA = 'base-data',
  STATISTIC = 'statistics',
  CUSTOMER_REQUESTS = 'customer-requests',
  CONFIGURATIONS = 'forms',
}

/**
 * @deprecated use global CMS_APP_ROUTE_PARTS_ROOT instead
 */
export enum PROJECT_SETTINGS {
  PERSONAL = 'user-settings',
  BUSINESS_CARD = 'business-card',
  MEMBERSHIP = 'membership',
  USERS = 'users',
  PROJECT = 'project-settings',
}

export enum Events {
  Auth,
  Error,
}

export const DIALOG_WIDTH = '90%';
export const DIALOG_CLS_FOOTER = 'footer-dialog';

export const inputDataValueTypeList: SelectOption[] = [
  { label: 'Ja/Nein (bool)', value: DATA_VALUE_TYPE.BOOL },
  { label: 'Numerisch', value: DATA_VALUE_TYPE.NUMERIC },
  { label: 'Text', value: DATA_VALUE_TYPE.STRING },
  { label: 'Datum', value: DATA_VALUE_TYPE.DATE },
];

export const dataValueTypeList: SelectOption[] = [
  {
    label: 'Anzeigen (readonly)',
    value: DATA_VALUE_TYPE.EMPTY,
  },
  ...inputDataValueTypeList,
];

export const fieldTypeList: SelectOption[] = [
  { label: 'Anzeigen (readonly)', value: FIELD_TYPES.DISPLAY_DATA },
  { label: 'Ja/Nein (bool)', value: FIELD_TYPES.YESNO },
  { label: 'Numerisch', value: FIELD_TYPES.NUMBER },
  { label: 'Textfeld', value: FIELD_TYPES.TEXT },
  { label: 'Auswahl (select)', value: FIELD_TYPES.SELECT },
  { label: 'Datum', value: FIELD_TYPES.DATE },
  { label: 'Übersicht', value: FIELD_TYPES.SUMMARY },
  { label: 'Daten absenden', value: FIELD_TYPES.SUBMITBTN },
];

export const calcOperationList: SelectOption[] = [
  { label: 'Addition (+)', value: CalcOperation.ADD },
  { label: 'Division (/)', value: CalcOperation.DIV },
  { label: 'Multiplikation (*)', value: CalcOperation.MUL },
  { label: 'Subtraktion (-)', value: CalcOperation.SUB },
  { label: 'Differenz', value: CalcOperation.DATEDIFF },
];

export const functionCommandList: SelectOption[] = [
  { label: 'Runden', value: FunctionCommand.ROUND },
  { label: 'Abrunden', value: FunctionCommand.FLOOR },
  { label: 'Aufrunden', value: FunctionCommand.CEIL },
  { label: 'Absolute', value: FunctionCommand.ABS },
];

export const ENTRY_TYPE_I18N: Record<CLS, string> = {
  [CLS.PAGE]: 'Seite',
  [CLS.FIELD_GROUP]: 'Feld / Gruppe',
  [CLS.FIELD]: 'Feld',
} as Record<CLS, string>;

export const SCOPES_LIST: SelectOption[] = [
  {
    value: USER_SCOPES.OWNER,
    label: 'Eigentümer',
    description: 'Uneingeschränkte Rechte',
  },
  {
    value: USER_SCOPES.ADMIN,
    label: 'Administrator',
    description: 'Alle Rechte - außer das Löschen und die Lizensverwaltung des Projekts',
  },
  {
    value: USER_SCOPES.MODELLER,
    label: 'Modeller',
    description: 'Erstellen und Bearbeiten von Formular-Konfigurationen',
  },
  {
    value: USER_SCOPES.AUTHOR,
    label: 'Autor',
    description: 'Bearbeiten von Texten und Übersetzungen',
  },
  {
    value: USER_SCOPES.PUBLISHER,
    label: 'Publisher',
    description: 'Publizieren von Daten und Konfigurationen',
  },
  {
    value: USER_SCOPES.CUSTOMER_SERVICE,
    label: 'Kunden-Service',
    description: 'Bearbeiten von Kundenanfragen',
  },
];

export interface ConditionOperation {
  label: string;
  op: CONDITIONAL_OPERATOR;
  value: boolean;
  allowedTypes: DATA_VALUE_TYPE[];
}

export const CONDITION_OPERATIONS: ConditionOperation[] = [
  {
    label: 'Enthält',
    op: CONDITIONAL_OPERATOR.CONTAINS,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.STRING],
  },
  {
    label: 'Entspricht',
    op: CONDITIONAL_OPERATOR.EQUALS,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.BOOL, DATA_VALUE_TYPE.STRING, DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Entspricht nicht',
    op: CONDITIONAL_OPERATOR.NOT_EQUALS,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.BOOL, DATA_VALUE_TYPE.STRING, DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Größer als',
    op: CONDITIONAL_OPERATOR.GREATER,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Größer gleich',
    op: CONDITIONAL_OPERATOR.GREATER_OR_EQUAL,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Kleiner',
    op: CONDITIONAL_OPERATOR.LESS,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Kleiner gleich',
    op: CONDITIONAL_OPERATOR.LESS_OR_EQUAL,
    value: true,
    allowedTypes: [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Ausgefüllt',
    op: CONDITIONAL_OPERATOR.NOT_EMPTY,
    value: false,
    allowedTypes: [DATA_VALUE_TYPE.STRING, DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
  {
    label: 'Nicht Ausgefüllt',
    op: CONDITIONAL_OPERATOR.EMPTY,
    value: false,
    allowedTypes: [DATA_VALUE_TYPE.STRING, DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
  },
];

export const LICENCE_PACKAGE_I18N: Record<LicencePackage, string> = {
  [LicencePackage.STARTER]: 'Starter',
  [LicencePackage.ADVANCED]: 'Plus',
  [LicencePackage.PRO]: 'Professional',
  [LicencePackage.CUSTOM]: 'Individuell',
};

export const LICENCE_CONFIG_OPTION_I18N: Record<LicenceConfigOptions, string> = {
  [LicenceConfigOptions.CONFIG_LIMIT]: 'Konfigurationen',
  [LicenceConfigOptions.USER_LIMIT]: 'Mitarbeiter',
  [LicenceConfigOptions.VIEW_LIMIT]: 'Ansichten',
  [LicenceConfigOptions.REQUEST_LIMIT]: 'Anfragen',
  [LicenceConfigOptions.DATA_LIMIT]: 'Datensätze',
  [LicenceConfigOptions.ASSET_LIMIT]: 'Dateien',
};

export const CLS_ICON: Record<string, string | Record<string, string>> = {
  [CLS.CONFIGURATION]: ICON.CONFIGURATION,
  [CLS.PAGE]: ICON.PAGE,
  [CLS.FIELD_GROUP]: ICON.GROUP,
  [CLS.FIELD]: {
    [FIELD_TYPES.NUMBER]: ICON.ENTRY_NUMBER,
    [FIELD_TYPES.TEXT]: ICON.ENTRY_TEXT,
    [FIELD_TYPES.DISPLAY_DATA]: ICON.ENTRY_DISPLAY_DATA,
    [FIELD_TYPES.YESNO]: ICON.ENTRY_YESNO,
    [FIELD_TYPES.SELECT]: ICON.ENTRY_SELECT,
    [FIELD_TYPES.DATE]: ICON.ENTRY_DATE,
    [FIELD_TYPES.RESETBTN]: ICON.ENTRY_ACTION,
    [FIELD_TYPES.SUBMITBTN]: ICON.ENTRY_ACTION,
    [FIELD_TYPES.SUMMARY]: ICON.ENTRY_SUMMARY,
  },
};

export function entryIcon(entry: GenericEntry): ICON | undefined {
  if (!entry?.cls) {
    return ICON.ENTRY;
  }
  // return entry.cls === CLS.FIELD ? CLS_ICON[entry.cls][(entry as Field).config.type] : CLS_ICON[entry.cls];
  if (entry.cls === CLS.FIELD) {
    if (!CLS_ICON[entry.cls]) {
      return undefined;
    }
    const subEntry = CLS_ICON[entry.cls] as Record<string, string>;
    if (!subEntry[(entry as Field).config.type]) {
      return undefined;
    }
    return subEntry[(entry as Field).config.type] as ICON;
  }
  return CLS_ICON[entry.cls] as ICON;
}

export const ENTRY_TYPE_LABEL: Record<string, string | Record<string, string>> = {
  [CLS.CONFIGURATION]: 'Konfiguration',
  [CLS.PAGE]: 'Seite',
  [CLS.FIELD_GROUP]: 'Gruppe',
  [CLS.FIELD]: {
    [FIELD_TYPES.NUMBER]: 'Zahlenfeld',
    [FIELD_TYPES.TEXT]: 'Textfeld',
    [FIELD_TYPES.DISPLAY_DATA]: 'Anzeigefeld',
    [FIELD_TYPES.YESNO]: 'Ja/Nein Feld',
    [FIELD_TYPES.SELECT]: 'Auswahlfeld',
    [FIELD_TYPES.DATE]: 'Datumsfeld',
    [FIELD_TYPES.RESETBTN]: 'Zurücksetzen Button',
    [FIELD_TYPES.SUBMITBTN]: 'Absenden Button',
    [FIELD_TYPES.SUMMARY]: 'Zusammenfassung',
  },
};

export enum CFG_ENTRY_TYPE {
  PAGE = 'page',
  GROUP = 'group',
  INPUT_FIELD = 'inputfield',
  OTHER_FIELD = 'otherfield',
}

export const CFG_NEW_PAGE_ENTRY = {
  cls: CLS.PAGE_WRAPPER,
  entry: Create.cmsPage({ label: 'Neue Seite' }),
};

export interface NewEntryItem {
  type: CFG_ENTRY_TYPE;
  label: string;
  name: string;
  description: string;
  icon: ICON;
  entry: CmsChildWrapper;
  fieldType?: FIELD_TYPES;
}

export const CFG_ENTRY_LIST: NewEntryItem[] = [
  {
    type: CFG_ENTRY_TYPE.PAGE,
    label: 'Seite',
    name: 'page',
    description: 'Neue Seite für Gruppen und Felder',
    icon: ICON.PAGE,
    entry: CFG_NEW_PAGE_ENTRY,
  },
  {
    type: CFG_ENTRY_TYPE.GROUP,
    label: 'Feldgruppe',
    name: 'fieldgroup',
    description: 'Element um mehrere Felder zu einer Gruppe zusammenzufassen',
    icon: ICON.GROUP,
    entry: {
      cls: CLS.FIELD_GROUP_WRAPPER,
      entry: Create.cmsFieldGroup({ label: 'Neue Gruppe' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.INPUT_FIELD,
    label: 'Text',
    name: 'textfield',
    description: 'Texte wie Namen, Links, E-Mail Adressen, Kommentare',
    icon: ICON.ENTRY_TEXT,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.TEXT, { label: 'Neues Textfeld' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.INPUT_FIELD,
    label: 'Zahlen',
    name: 'numberfield',
    description: 'Numerische Werte wie Anzahl, Größe, Gewicht.',
    icon: ICON.ENTRY_NUMBER,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.NUMBER, { label: 'Neues Zahlenfeld' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.INPUT_FIELD,
    label: 'Datum',
    name: 'datefield',
    description: 'Auswahl von Datum und oder Zeitangaben.',
    icon: ICON.ENTRY_DATE,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.DATE, { label: 'Datum' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.INPUT_FIELD,
    label: 'Bool',
    name: 'boolfield',
    description: 'Fragen die mit Ja oder Nein zu beantworten sind.',
    icon: ICON.ENTRY_YESNO,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.YESNO, { label: 'Frage' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.INPUT_FIELD,
    label: 'Auswahl',
    name: 'selectfield',
    description: 'Auswahlliste von Datensätzen',
    icon: ICON.ENTRY_SELECT,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.SELECT, { label: 'Auswahlliste' }),
      // entry: EntryFactory.createField('', '', FIELD_TYPES.SELECT),
    },
  },
  {
    type: CFG_ENTRY_TYPE.OTHER_FIELD,
    label: 'Anzeige',
    name: 'displayfield',
    description: 'Anzeigen von Werten und Berechnungen',
    icon: ICON.ENTRY_DISPLAY_DATA,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.DISPLAY_DATA, { label: 'Wert' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.OTHER_FIELD,
    label: 'Absenden',
    name: 'submitbtn',
    description: 'Element um Benutzereingaben abzusenden und ein Ticket zu erzeugen',
    icon: ICON.ENTRY_ACTION,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.SUBMITBTN, { label: 'Absenden' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.OTHER_FIELD,
    label: 'Zurücksetzen',
    name: 'resetbutton',
    description: 'Neustart- Formular auf Initiale Werte zurücksetzen',
    icon: ICON.ENTRY_ACTION,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.RESETBTN, { label: 'Zurücksetzen' }),
    },
  },
  {
    type: CFG_ENTRY_TYPE.OTHER_FIELD,
    label: 'Zusammenfassung',
    name: 'summaryfield',
    description: 'Zeigt eine Zusammenfassung aller Benutzereingaben an.',
    icon: ICON.ENTRY_SUMMARY,
    entry: {
      cls: CLS.FIELD_WRAPPER,
      entry: Create.cmsField(FIELD_TYPES.SUMMARY, { label: 'Zusammenfassung' }),
    },
  },
];

export const DATE_MANIPULATION_OPTIONS: SelectOption[] = [
  { label: 'plus', value: '+' },
  { label: 'minus', value: '-' },
];
export const DATE_UNIT_OPTIONS: SelectOption[] = [
  { label: 'Tage', value: UNIT_TYPES.DAY },
  { label: 'Wochen', value: UNIT_TYPES.WEEK },
  { label: 'Monate', value: UNIT_TYPES.MONTH },
  { label: 'Quartale', value: UNIT_TYPES.QUARTER },
  { label: 'Jahre', value: UNIT_TYPES.YEAR },
];
export const DATE_BASE_TYPE_OPTIONS: SelectOption[] = [
  { label: 'Relativ', value: CLS.RELATIVE_DATE },
  { label: 'Fest', value: CLS.INPUT_VALUE },
];

export const DATE_RELATIVE_START_OPTIONS: SelectOption<DateRef>[] = [
  {
    label: 'Heute',
    value: {
      cls: CLS.DATE_REF,
      name: START_DATES.TODAY,
    },
  },
  {
    label: 'Wochenanfang (Montag)',
    value: {
      cls: CLS.DATE_REF,
      name: START_DATES.WEEK_START,
    },
  },
  {
    label: 'Wochenende (Sonntag)',
    value: {
      cls: CLS.DATE_REF,
      name: START_DATES.WEEK_END,
    },
  },
  {
    label: 'Monatsanfang (01.)',
    value: {
      cls: CLS.DATE_REF,
      name: START_DATES.MONTH_START,
    },
  },
  {
    label: 'Monatsende (28./30./31.)',
    value: {
      cls: CLS.DATE_REF,
      name: START_DATES.MONTH_END,
    },
  },
];

export function entryTypeLabel(entry: GenericEntry): string {
  if (!entry?.cls) {
    return '';
  }
  if (entry.cls === CLS.FIELD) {
    if (!ENTRY_TYPE_LABEL[entry.cls]) {
      return '';
    }
    const label = ENTRY_TYPE_LABEL[entry.cls] as Record<string, string>;
    if (!label[(entry as Field).config.type]) {
      return '';
    }
    return label[(entry as Field).config.type];
  }
  return ENTRY_TYPE_LABEL[entry.cls] as string;
}

export const TEXTFIELD_VALIDATION_OPTIONS: SelectOption[] = [
  { label: 'Text', value: TEXTFIELD_VALIDATIONS.TEXT },
  { label: 'E-Mail', value: TEXTFIELD_VALIDATIONS.EMAIL },
  { label: 'Link', value: TEXTFIELD_VALIDATIONS.LINK },
  { label: 'Telefonnummer', value: TEXTFIELD_VALIDATIONS.PHONE },
];

export const STATISTIC_RANGE_OPTION_LIST: SelectOption[] = [
  { label: 'Tage', value: StatisticRange.DAY },
  { label: 'Wochen', value: StatisticRange.WEEK },
  { label: 'Monate', value: StatisticRange.MONTH },
  { label: 'Jahre', value: StatisticRange.YEAR },
];

export const SELECT_FIELD_LAYOUT_OPTION_LIST: SelectOption[] = [
  // { name: 'Bitte wählen', code: '' },
  // { name: 'Buttons', code: SELECT_FIELD_LAYOUTS.BUTTON },
  { label: 'Auswahlliste', value: SELECT_FIELD_LAYOUTS.DROPDOWN },
  // { name: 'Suche', code: SELECT_FIELD_LAYOUTS.SEARCH },
];

export const DATAHANDLER_TYPE_OPTION_LIST: SelectOption[] = [
  {
    label: 'Liste',
    value: DATAHANDLER_TYPES.LIST,
    description: 'Manuelle Liste von Optionen hinterlegen',
  },
  {
    label: 'Dynamisch',
    value: DATAHANDLER_TYPES.DYNAMIC,
    description: 'Optionsliste basierend auf Tags ',
  },
];

export const FONT_OPTION_LIST: SelectOption[] = [
  { label: 'Verdana (sans-serif)', value: 'Verdana' },
  { label: 'Tahoma (sans-serif)', value: 'Tahoma' },
  { label: 'Times New Roman (serif)', value: 'Times New Roman' },
  { label: 'Georgia (serif)', value: 'Georgia' },
  { label: 'Courier New (monospace)', value: 'Courier New' },
  { label: 'Brush Script MT (cursive)', value: 'Brush Script MT' },
];

export interface VisibleLicence extends LicenceOptions {
  label: string;
  description: string;
}

export const visibleLicences: VisibleLicence[] = [
  {
    ...new StarterLicenceOptions(),
    label: LICENCE_PACKAGE_I18N[LicencePackage.STARTER],
    description:
      'Unser dauerhaft kostenloses Starterpaket. Testen Sie unseren Service ohne Risiko, solange Sie möchten',
  },
  {
    ...new AdvancedLicenceOptions(),
    label: LICENCE_PACKAGE_I18N[LicencePackage.ADVANCED],
    description:
      'Unser Premium Paket für wachsende Platformen. In unserer Testphase können sie das Paket kostenlos für 6 Monate buchen.',
  },
];
