import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CLS, CmsConfiguratorField, ICON } from '@kfd/core';
import { combineLatestWith, Observable } from 'rxjs';
import { DragDropService } from '../drag-drop/drag-drop.service';
import { CfgStateService, EntryStatusChange } from '../../cfg-state.service';
import { entryIcon } from '../../../shared/global';
import { DragElementHandler } from '../drag-drop/drag-element.handler';
import { map } from 'rxjs/operators';
import { CfgEditorService } from '../../cfg-editor.service';

@Component({
  selector: 'kfd-dnd-area-field',
  templateUrl: './dnd-area-field.component.html',
  styleUrls: ['./dnd-area-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaFieldComponent {
  protected readonly ICON = ICON;
  protected readonly entryStatusChange = EntryStatusChange;

  protected viewData$: Observable<{
    editMode: boolean;
    field: CmsConfiguratorField;
    entryStatus: EntryStatusChange;
    selected: boolean;
    entryIcon: ICON;
  }>;

  private name: string | undefined;
  private dragElementHandler: DragElementHandler | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly dragDropService: DragDropService,
    private readonly cfgEditorService: CfgEditorService,
    private readonly cfgStateService: CfgStateService,
  ) {}

  @ViewChild('dragInitiator')
  public set dragInitiator(element: ElementRef<HTMLDivElement> | undefined) {
    if (!element) {
      if (this.dragElementHandler) {
        this.dragElementHandler.destroy();
        this.dragElementHandler = undefined;
      }
      return;
    }
    if (this.dragElementHandler) {
      return;
    }

    new DragElementHandler(this.dragDropService, this.elementRef, element, {
      elementName: this.name,
      parentName: this.cfgStateService.getCfgUtil().getParentEntry(this.name, true).name,
      position: this.cfgStateService.getCfgUtil().getRelativePosition(this.name).pos,
      cls: CLS.FIELD_WRAPPER,
      icon: ICON.ENTRY,
    });
  }

  @Input()
  set fieldName(value: string | undefined) {
    if (!value) {
      return;
    }
    this.name = value;
    this.viewData$ = this.cfgStateService.onEntryChange<CmsConfiguratorField>(this.name, true).pipe(
      combineLatestWith(
        this.cfgEditorService.onEditModeChange,
        this.cfgStateService.onEntryStatusChange(this.name),
        this.cfgEditorService.onSelectionChange,
      ),
      map(([field, editMode, entryStatus, selection]) => ({
        editMode,
        field,
        entryStatus,
        selected: selection && selection.name === this.name,
        entryIcon: entryIcon(field.entry) ?? ICON.ENTRY,
      })),
    );
  }

  protected select(): void {
    this.cfgEditorService.selectEntry(this.name);
  }
}
