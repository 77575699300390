import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckError, CheckResult, ConfigurationChecker, ICON } from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';
import { CfgEditorService } from '../../cfg-editor.service';

@Component({
  selector: 'kfd-check-viewer',
  templateUrl: './check-viewer.component.html',
  styleUrls: ['./check-viewer.component.scss'],
})
export class CheckViewerComponent implements OnInit {
  @Input()
  public checkResults: CheckResult[] = [];
  @Output()
  public checkSelection = new EventEmitter<void>();
  protected readonly ICON = ICON;
  protected readonly CheckError = CheckError;

  constructor(
    private readonly cfgStateService: CfgStateService,
    protected readonly cfgEditorService: CfgEditorService,
  ) {}

  ngOnInit() {
    this.updateCheckResults();
  }

  private updateCheckResults(): void {
    try {
      const cfgChecker = new ConfigurationChecker(this.cfgStateService.getCfgUtil());
      this.checkResults = cfgChecker.check();
    } catch (_) {
      this.checkResults = [];
    }
  }
}
