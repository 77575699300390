import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CLS,
  Create,
  DATA_VALUE_TYPE,
  DateRef,
  DateValueTypes,
  FIELD_TYPES,
  FieldRef,
  ICON,
  Is,
  ObjectUtil,
  ParentSelectOption,
  RelativeDateManipulation,
  SAVE_DATE_FORMAT,
  SelectOption,
} from '@kfd/core';
import dayjs from 'dayjs';
import { CfgStateService } from '../../cfg-state.service';
import {
  DATE_BASE_TYPE_OPTIONS,
  DATE_MANIPULATION_OPTIONS,
  DATE_RELATIVE_START_OPTIONS,
  DATE_UNIT_OPTIONS,
} from '../../../shared/global';
import { SharedModule } from '../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

export const READABLE_DATE_FORMAT = 'dddd,D. MMMM YYYY';

@Component({
  selector: 'kfd-date-value',
  templateUrl: './date-value.component.html',
  styleUrls: ['./date-value.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateValueComponent {
  @Output()
  public dateValueChange = new EventEmitter<DateValueTypes>();
  @Input()
  public refFieldName: string | undefined;

  protected readonly cls = CLS;
  protected readonly icon = ICON;
  protected readonly Is = Is;
  protected readonly dateBaseTypeOptions = DATE_BASE_TYPE_OPTIONS;
  protected readonly dateManipulationOptions = DATE_MANIPULATION_OPTIONS;
  protected readonly dateUnitOptions = DATE_UNIT_OPTIONS;

  protected valueType: string | undefined;
  protected dateObject: Date | undefined;
  protected startDateValueList: ParentSelectOption<DateRef | FieldRef>[] = [];

  private _originValue: DateValueTypes = Create.emptyInputValue();

  constructor(private cfgStateService: CfgStateService) {}

  private _dateValue: DateValueTypes = Create.emptyInputValue();

  public get dateValue(): DateValueTypes {
    return this._dateValue;
  }

  @Input()
  public set dateValue(value: DateValueTypes) {
    if (Is.relativeDate(value)) {
      this._dateValue = ObjectUtil.clone(value);
      this.valueType = this.dateValue?.cls;
    } else if (Is.dateDataValue(value)) {
      this._dateValue = ObjectUtil.clone(value);
      this.valueType = this.dateValue?.cls;
      const parsedDate = dayjs(value.value, SAVE_DATE_FORMAT);
      this.dateObject = parsedDate.isValid() ? parsedDate.toDate() : new Date();
    } else {
      this.defaultValueTypeChange(CLS.RELATIVE_DATE);
    }

    this._originValue = ObjectUtil.clone(this._dateValue);
    this.startDateValueList = this.getStartDateValueList();
  }

  protected defaultValueTypeChange(value: string) {
    switch (value) {
      case CLS.RELATIVE_DATE:
        if (!Is.relativeDate(this._dateValue)) {
          this.updateDateValue(Create.relativeDate());
        }
        // this.relativeDateChange();
        break;
      case CLS.INPUT_VALUE:
        if (!Is.value(this._dateValue)) {
          this.dateObject = new Date();
          this.updateDateValue(
            Create.inputValue(DATA_VALUE_TYPE.DATE, dayjs(this.dateObject).format(SAVE_DATE_FORMAT)),
          );
        }
        break;
      default:
        this.updateDateValue(Create.emptyInputValue());
    }
    this.valueType = value;
    // this.change();
  }

  protected defaultDateChange() {
    const dateValue = Create.inputValue(DATA_VALUE_TYPE.DATE, dayjs(this.dateObject).format(SAVE_DATE_FORMAT));
    this.updateDateValue(dateValue);
    // this.change();
  }

  protected setStartDate(value: DateRef | FieldRef): void {
    if (!Is.relativeDate(this._dateValue)) {
      return;
    }
    const dateValue = this._dateValue;
    dateValue.start = value;
    this.updateDateValue(dateValue);
  }

  protected setDateManipulation(value: RelativeDateManipulation): void {
    if (!Is.relativeDate(this._dateValue)) {
      return;
    }
    const dateValue = this._dateValue;
    dateValue.manipulation = value;
    this.updateDateValue(dateValue);
  }

  protected setManipulationValue(value: number): void {
    if (!Is.relativeDate(this._dateValue)) {
      return;
    }
    const dateValue = this._dateValue;
    dateValue.value = value;
    this.updateDateValue(dateValue);
  }

  // protected cancel() {
  //   this.dateValueChange.emit(this._originValue);
  // }

  protected emitValue() {
    if (Is.relativeDate(this.dateValue) || Is.dateInputValue(this.dateValue)) {
      this.dateValueChange.emit(this.dateValue);
      return;
    }
    this.dateValueChange.emit(Create.emptyInputValue());
  }

  protected getStartDateValueList(excludedField?: string): ParentSelectOption<DateRef | FieldRef>[] {
    const excludeList = excludedField ? [excludedField] : [];
    const fields: SelectOption<FieldRef>[] = this.cfgStateService
      .getCfgUtil()
      .getFieldsOfType(FIELD_TYPES.DATE, excludeList)
      .map((field) => {
        const fieldRef = Create.fieldRef(field.name);
        delete fieldRef.label;
        return {
          label: field.label ? field.label : field.name,
          value: fieldRef,
        };
      });

    return [
      {
        label: 'Aktuelle Zeit',
        value: 'generic',
        items: DATE_RELATIVE_START_OPTIONS,
      },
      {
        label: 'Andere Datumsfelder',
        value: 'field',
        items: fields,
      },
    ];
  }

  /**
   * internal update of the date value
   */
  private updateDateValue(value: DateValueTypes): void {
    this._dateValue = value;
    this.emitValue();
  }
}
