import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateValueTypes, ICON, Is } from '@kfd/core';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { DateSelectionDialogComponent } from '../date-selection-dialog/date-selection-dialog.component';

@Component({
  selector: 'kfd-editor-date-input',
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
})
export class DateInputComponent {
  @Input()
  public dateValue: DateValueTypes;
  @Output()
  public dateValueChange = new EventEmitter<DateValueTypes>();
  protected readonly ICON = ICON;

  constructor(private readonly cmsDialogService: CmsDialogService) {}

  protected openDateSelectionDialog(): void {
    this.cmsDialogService
      .open(DateSelectionDialogComponent, {
        dateValue: this.dateValue,
      })
      .onClose.subscribe((result: DateValueTypes) => {
        if (Is.relativeDate(result) || Is.inputValue(result) || Is.emptyInputValue(result)) {
          this.dateValueChange.emit(result);
        }
      });
  }
}
