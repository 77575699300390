import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ScopeUtil } from '../scope.util';
import { USER_SCOPES } from '@kfd/core';
import { ProjectService } from '../../services/project.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'hasScope',
})
export class HasScopePipe implements PipeTransform, OnDestroy {
  private destroy$ = new Subject<boolean>();

  constructor(private projectService: ProjectService) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  transform(allowedScopes: USER_SCOPES[]): Observable<boolean> {
    return this.projectService
      .getUserScopesForCurrentProject(true)
      .pipe(takeUntil(this.destroy$))
      .pipe(map((scopes) => ScopeUtil.scopeAllowed(scopes, allowedScopes)));
  }
}
