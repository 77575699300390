<ng-container *ngIf="condition">
  <div class="flex">
    <div class="flex flex-column justify-content-center p-2">
      <i *ngIf="isValid" class="pi pi-check color-success"></i>
      <i *ngIf="!isValid" class="pi pi-exclamation-triangle color-warning" pTooltip="Unvollständige Bedingung"></i>
    </div>
    <kfd-field-ref
      (fieldRefChange)="fieldRefChange($event); onChange()"
      [(fieldRef)]="condition.value1"
      [fieldTypes]="fieldTypes"
      class="flex-1 full-width"
    >
    </kfd-field-ref>
    <p-dropdown
      (onChange)="operationChange($event.value); onChange()"
      *ngIf="operation"
      [disabled]="operationList.length === 0"
      [ngModel]="operation.op"
      [options]="operationList"
      appendTo="body"
      class="flex-1"
      optionLabel="label"
      optionValue="op"
    >
    </p-dropdown>
    <kfd-data-value
      (valueChange)="onChange()"
      *ngIf="operation && operation.value"
      [(value)]="condition.value2"
      [type]="dataValueType"
      class="flex-1 full-width"
    >
    </kfd-data-value>
  </div>
</ng-container>
