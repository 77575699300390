import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CfgContextService,
  ConfigService,
  DATA_PROVIDER,
  MemoryPersistence,
  PERSISTENCE_TOKEN,
  ScreenSize,
} from '@kfd/cfg-core';
import { CONTEXT_SERVICE } from '@kfd/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationStateService } from '../../../../../../libs/cfg-core/src/lib/configuration/service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SessionPersistence } from '../../../../../../libs/cfg-core/src/lib/configuration/service/persistence/session.persistence';
import { v4 as uuidv4 } from 'uuid';
import { ConfigurationDataService } from '@kfd/web-core';

@Component({
  selector: 'kfd-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
    {
      provide: CONTEXT_SERVICE,
      useClass: CfgContextService,
    },
    {
      provide: ConfigService,
      useFactory: () => {
        const configService = new ConfigService();
        configService.screenSize = ScreenSize.XS;
        return configService;
      },
    },
    {
      provide: PERSISTENCE_TOKEN,
      useFactory: () => {
        if (SessionPersistence.available()) {
          const persistence = new SessionPersistence();
          persistence.useKey(uuidv4());
          return persistence;
        } else {
          return new MemoryPersistence();
        }
      },
    },
    ConfigurationStateService,
  ],
})
export class FormComponent {
  @Output()
  submitted: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  project = 'konfidoo';

  @Input()
  configuration: string | undefined;

  @Input()
  data: Record<string, unknown> = {};
}
