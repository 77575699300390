import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { CmsDialogService } from '../../services/cms-dialog.service';
import { EventService } from '@kfd/web-core';
import { ServerErrorDialogComponent } from '../server-error-dialog/server-error-dialog.component';

/**
 * intercepts http requests to handle responses which are unauthorized
 **/
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  dynamicDialogRef: DynamicDialogRef | undefined;

  constructor(
    private readonly eventService: EventService,
    private readonly cmsDialogService: CmsDialogService,
  ) {}

  public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(httpRequest).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      catchError((httpErrorResponse) => {
        if (httpErrorResponse.status >= 500) {
          this.cmsDialogService.open(
            ServerErrorDialogComponent,
            {},
            {
              dismissibleMask: false,
            },
          );
          return;
        }
        throw httpErrorResponse;
      }),
    );
  }
}
