<kfd-dialog-layout
  (accept)="close()"
  [loading]="loading"
  [showRejectBtn]="false"
  acceptBtnLabel="OK"
  dialogTitle="Tipps & Tricks"
>
  <p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel header="Navigieren">
      <p>Der Editor-Bereich kann einfach mit der Maus oder dem Finger verschoben werden.</p>
      <p>
        Zum Skalieren muss die Strg/Ctrl Taste gedrückt werden. Dann kann mit dem Mausrad oder den +/- Tasten der
        Ausschnitt verändert werden. Um die gesamte Konfiguration anzuzeigen kann der
        <i class="pi {{ icon.MINIMIZE }}"></i> Button in der Toolbar verwendet werden. Ein Doppelklick auf einen
        Seitentitel, fokusiert einen einzelne Seite
      </p>
    </p-tabPanel>
    <p-tabPanel header="Verschieben">
      <p>
        Zum Verschieben einzelner Seiten, Gruppen oder Felder kann das
        <i>Verschieben</i> in der Toolbar aktiviert werden. Alternativ ist das Verschieben aktiviert, solange man die
        Strg/Ctrl-Taste gedrückt hält.
      </p>
    </p-tabPanel>
    <p-tabPanel header="Speichern">
      <p>Alle Änderungen werden automatisch gespeichert.</p>
    </p-tabPanel>
  </p-tabView>
</kfd-dialog-layout>
