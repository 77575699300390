<ng-container *ngIf="entry && entry.config">
  <kfd-hidelabel-cfg-setting (valueChange)="change()" [(value)]="entry.hideLabel" class="p-field">
  </kfd-hidelabel-cfg-setting>
  <kfd-hint-cfg-setting (valueChange)="change()" [(value)]="entry.hint" class="p-field"> </kfd-hint-cfg-setting>

  <ng-container [ngSwitch]="entry.config.type">
    <kfd-field-settings-details-bool
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.YESNO"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-bool>
    <kfd-field-settings-details-date
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.DATE"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-date>
    <kfd-field-settings-details-text
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.TEXT"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-text>
    <kfd-field-settings-details-numeric
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.NUMBER"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-numeric>
    <kfd-field-settings-details-displaydata
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.DISPLAY_DATA"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-displaydata>
    <kfd-field-settings-details-reset-btn
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.RESETBTN"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-reset-btn>
    <kfd-field-settings-details-select
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.SELECT"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-select>
    <kfd-field-settings-details-submit-btn
      (configChange)="configChange($event)"
      *ngSwitchCase="fieldTypes.SUBMITBTN"
      [config]="entry.config"
      [refFieldName]="entry.name"
    >
    </kfd-field-settings-details-submit-btn>
  </ng-container>
</ng-container>
