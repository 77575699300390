@if (disabled) {
  <ng-container *ngTemplateOutlet="content"></ng-container>
} @else {
  @if (licenceLimit$ | loading | async; as licenceLimit) {
    <div [kfdBlock]="licenceLimit.loading">
      @if (licenceLimit.value) {
        @if (licenceLimit.value.limitReached) {
          <kfd-action-message
            severity="warn"
            text="Limit erreicht"
            info="Es wurden alle verfügbaren {{ licenceOptionLabel }} ({{ licenceLimit.value.value }}) verbraucht."
            (action)="showInfo()"
          ></kfd-action-message>
        } @else if (showMessage) {
          <kfd-action-message
            severity="info"
            info="Verfügbare {{ licenceOptionLabel }}: {{ licenceLimit.value.value - licenceLimit.value.used }}"
            size="small"
            (action)="showInfo()"
          ></kfd-action-message>
          <div class="mt-2">
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </div>
        }
      }
    </div>
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
