<ng-container *ngIf="calculation">
  <ul class="param-list operation-{{ calculation.op }} text-sm">
    <li *ngFor="let param of calculation.params">
      <ng-container [ngSwitch]="param.cls">
        <ng-container
          *ngSwitchCase="cls.CALCULATION"
          [ngTemplateOutletContext]="{ param: param }"
          [ngTemplateOutlet]="calculationTpl"
        ></ng-container>
        <ng-container
          *ngSwitchCase="cls.INPUT_VALUE"
          [ngTemplateOutletContext]="{ param: param }"
          [ngTemplateOutlet]="inputValueTpl"
        ></ng-container>
        <ng-container
          *ngSwitchCase="cls.SELECTION_VALUE"
          [ngTemplateOutletContext]="{ param: param }"
          [ngTemplateOutlet]="selectionValueTpl"
        ></ng-container>
        <ng-container
          *ngSwitchCase="cls.ALIAS_VALUE"
          [ngTemplateOutletContext]="{ param: param }"
          [ngTemplateOutlet]="aliasValueTpl"
        ></ng-container>
        <ng-container
          *ngSwitchCase="cls.FIELD_REF"
          [ngTemplateOutletContext]="{ param: param }"
          [ngTemplateOutlet]="fieldRefTpl"
        ></ng-container>
      </ng-container>
    </li>
    <div class="result">
      =
      <ng-container *ngIf="calculation.label">
        {{ calculation.label.text }}
      </ng-container>
    </div>
  </ul>
</ng-container>

<ng-template #calculationTpl let-param="param">
  <kfd-calculation-viewer [calculation]="param"></kfd-calculation-viewer>
</ng-template>
<ng-template #inputValueTpl let-param="param">
  <span class="single-line-text">
    {{ param.value }}
  </span>
</ng-template>
<ng-template #selectionValueTpl let-param="param">
  <i class="single-line-text">not yet supported</i>
</ng-template>
<ng-template #aliasValueTpl let-param="param">
  <span class="single-line-text" pTooltip="Ergebnis einer anderen Kalkulation">
    {{ param.name }}
  </span>
</ng-template>
<ng-template #fieldRefTpl let-param="param">
  <span class="single-line-text" pTooltip="Wert des referenzierten Feldes">
    {{ param.name | entrylabel }}
  </span>
</ng-template>
