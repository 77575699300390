<ng-container *ngIf="pageRef">
  <p-dropdown
    (onChange)="onChange($event.value)"
    [ngModel]="pageRef.name"
    [options]="options"
    appendTo="body"
    id="operation"
    optionLabel="label"
    optionValue="name"
    placeholder="Feld wählen"
  >
    <ng-template let-item pTemplate="selectedItem">
      {{ item.label }}
      <ng-container *ngIf="item.name">({{ item.name }})</ng-container>
    </ng-template>
    <ng-template let-item pTemplate="item">
      {{ item.label }}
      <ng-container *ngIf="item.name">({{ item.name }})</ng-container>
    </ng-template>
  </p-dropdown>
</ng-container>
