import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { MyProject, USER_SCOPES } from '@kfd/core';
import { ProjectService } from '../../../services/project.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-mandant-selection',
  templateUrl: './mandant-selection.component.html',
  styleUrls: ['./mandant-selection.component.scss'],
})
export class MandantSelectionComponent implements OnInit {
  @Output() public selection = new EventEmitter<MyProject>();
  @Output() public mandantsChange = new EventEmitter<MyProject[]>();
  @Input()
  public scopes: USER_SCOPES[] = [];

  @Input()
  public excludeScopes: USER_SCOPES[] = [];

  loadingMandants = true;
  projects$: Observable<MyProject[]> | undefined;

  constructor(private mandantService: ProjectService) {}

  ngOnInit(): void {
    this.loadProjects();
  }

  public loadProjects() {
    this.loadingMandants = true;
    this.projects$ = this.mandantService.listProjects().pipe(
      map((projects) =>
        this.scopes.length === 0
          ? projects
          : projects.filter((project) => project.scopes.some((scope) => this.scopes.includes(scope))),
      ),
      map((projects) =>
        this.excludeScopes.length === 0
          ? projects
          : projects.filter((project) => project.scopes.some((scope) => !this.excludeScopes.includes(scope))),
      ),
      tap((mandants) => this.mandantsChange.emit(mandants)),
      tap(() => (this.loadingMandants = false)),
    );
  }
}
