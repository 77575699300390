import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Asset, AVAILABLE_FEATURES, ICON, LicenceConfigOptions, StringUtil, USER_SCOPES } from '@kfd/core';
import { FileSelectEvent, FileUpload } from 'primeng/fileupload';
import { AssetService } from '../../../../services/asset.service';
import { CmsContextService } from '../../../../services/cms-context.service';
import { Observable } from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { AssetUsageDialogComponent } from '../asset-usage-dialog/asset-usage-dialog.component';

const MAX_UPLOAD_SIZE = 1024;

@Component({
  selector: 'kfd-asset-manager',
  templateUrl: './asset-manager.component.html',
  styleUrl: './asset-manager.component.scss',
})
export class AssetManagerComponent implements OnInit {
  @Output()
  public assetSelection = new EventEmitter<Asset | undefined>();
  @Output()
  public menuItems = new EventEmitter<MenuItem[]>();
  protected readonly features = AVAILABLE_FEATURES;
  protected readonly ICON = ICON;
  protected readonly scopes = USER_SCOPES;
  protected cacheBreaker = Date.now();
  protected showAddAssetDialog = false;
  protected showAssetViewDialog = false;
  protected selectedFile: File | undefined;
  // converted file data
  protected base64FileData: string | undefined;
  // converted file size in kb
  protected base64FileSize: number | undefined;
  protected fileName: string | undefined;
  protected fileLabel: string | undefined;
  protected assets$: Observable<Asset[]> | undefined;
  protected uploadingAsset = false;
  protected selectedAsset: Asset | undefined;
  protected thumbnailMenuItems: MenuItem[] = [];
  protected selectionMenuItems: MenuItem[] = [];
  protected licenceLimitConfigurations = LicenceConfigOptions.ASSET_LIMIT;
  protected forceDeletion = false;
  protected readonly Math = Math;
  @ViewChild('fileUpload')
  private fileUpload: FileUpload | undefined;

  constructor(
    private readonly cmsDialogService: CmsDialogService,
    private readonly assetService: AssetService,
    private readonly cmsContextService: CmsContextService,
    private confirmationService: ConfirmationService,
  ) {}

  public ngOnInit(): void {
    this.loadAssets();
    this.deSelect();
    this.selectionMenuItems = this.getSelectionMenuItems(true);
  }

  public newAssetDialog() {
    this.deSelect();
    this.showAddAssetDialog = true;
    this.selectedFile = undefined;
    this.fileLabel = undefined;
    this.fileName = undefined;
  }

  protected loadAssets() {
    this.assets$ = this.assetService.listAssets(this.cmsContextService.projectId);
  }

  protected select(asset: Asset): void {
    this.selectedAsset = asset;
    this.thumbnailMenuItems = this.getMenuItems();
    this.menuItems.emit(this.thumbnailMenuItems);
    this.assetSelection.emit(asset);
  }

  protected deSelect(): void {
    this.selectedAsset = undefined;
    this.thumbnailMenuItems = this.getMenuItems();
    this.menuItems.emit(this.thumbnailMenuItems);
    this.assetSelection.emit(undefined);
  }

  protected onFileUploadSelection(event: FileSelectEvent) {
    if (!event.files) {
      return;
    }
    this.selectedFile = event.files[0];
    this.base64FileData = undefined;
    this.base64FileSize = undefined;

    const blob = new Blob([this.selectedFile], { type: this.selectedFile.type });

    this.blobToBase64(blob)
      .then((data) => {
        this.base64FileSize = Math.round((Math.ceil(data.length / 4) * 3) / 1024);
        if (this.base64FileSize > MAX_UPLOAD_SIZE && this.selectedFile.type.startsWith('image/')) {
          const imgEl = new Image();
          imgEl.addEventListener('load', () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (imgEl.width > imgEl.height) {
              canvas.width = 1024;
              canvas.height = imgEl.height * (1024 / imgEl.width);
            } else {
              canvas.height = 1024;
              canvas.width = imgEl.width * (1024 / imgEl.height);
            }

            // Draw image and export to a data-uri
            ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
            const dataURI = canvas.toDataURL();

            // Do something with the result, like overwrite original
            this.base64FileData = dataURI;
            this.base64FileSize = Math.round((Math.ceil(this.base64FileData.length / 4) * 3) / 1024);
          });
          imgEl.src = data;
          return;
        }
        this.base64FileData = data;
        this.base64FileSize = Math.round((Math.ceil(data.length / 4) * 3) / 1024);
      })
      .then(() => {
        if (this.base64FileSize > MAX_UPLOAD_SIZE) {
          return;
        }

        const nameWithoutEnding = this.selectedFile.name.replace(/\.[^/.]+$/, '');
        if (!this.fileName) {
          this.fileName = StringUtil.toSaveString(nameWithoutEnding);
        }
        if (!this.fileLabel) {
          this.fileLabel = StringUtil.toReadableString(nameWithoutEnding);
        }
      });
  }

  protected createAsset() {
    if (!this.base64FileData) {
      throw new Error('No file data available');
    }
    this.uploadingAsset = true;
    this.assetService
      .createAsset(this.cmsContextService.projectId, {
        name: this.fileName,
        label: this.fileLabel,
        data: this.base64FileData.replace(/^(.*?),(.*?)$/, '$2'),
      })
      .subscribe({
        next: () => {
          this.uploadingAsset = false;
          this.showAddAssetDialog = false;
          this.selectedFile = undefined;
          this.base64FileData = undefined;
          if (this.fileUpload) {
            this.fileUpload.clear();
          }
          this.loadAssets();
        },
        error: (e) => {
          this.uploadingAsset = false;
          throw e;
        },
      });
  }

  protected async updateAsset() {
    this.uploadingAsset = true;

    this.assetService
      .updateAsset(this.cmsContextService.projectId, this.selectedAsset._id, {
        name: this.fileName,
        label: this.fileLabel,
        data: this.base64FileData ? this.base64FileData.replace(/^(.*?),(.*?)$/, '$2') : undefined,
      })
      .subscribe({
        next: () => {
          this.uploadingAsset = false;
          this.showAddAssetDialog = false;
          this.selectedFile = undefined;
          this.base64FileData = undefined;
          if (this.fileUpload) {
            this.fileUpload.clear();
          }
          this.cacheBreaker = Date.now();
          this.loadAssets();
        },
        error: (e) => {
          this.uploadingAsset = false;
          throw e;
        },
      });
  }

  protected deleteAsset() {
    if (!this.selectedAsset) {
      return;
    }
    this.assetService.getAssetUsages(this.cmsContextService.projectId, this.selectedAsset._id).subscribe({
      next: (usages) => {
        this.confirmationService.confirm({
          message: `Soll die Datei "${this.selectedAsset.name}" wirklich gelöscht werden?`,
          header: 'Datei löschen?',
          key: 'confirmDeletion',
          acceptVisible: !usages.inUse,
          accept: () => {
            this.assetService
              .deleteAsset(this.cmsContextService.projectId, this.selectedAsset._id, this.forceDeletion)
              .subscribe({
                next: () => {
                  this.loadAssets();
                  this.deSelect();
                },
                error: (e) => {
                  throw e;
                },
              });
          },
        });
      },
    });
  }

  protected showAssetUsageDialog(): void {
    this.cmsDialogService.open(AssetUsageDialogComponent, {
      projectId: this.cmsContextService.projectId,
      assetId: this.selectedAsset?._id,
    });
  }

  private getMenuItems(): MenuItem[] {
    return [
      {
        label: 'Neue Datei',
        icon: 'pi ' + ICON.UPLOAD,
        automationId: 'new-asset-btn',
        command: () => {
          this.newAssetDialog();
        },
      },
      ...this.getSelectionMenuItems(!!this.selectedAsset),
    ];
  }

  private getSelectionMenuItems(isSelected: boolean): MenuItem[] {
    return [
      {
        label: 'Anzeigen',
        icon: 'pi ' + ICON.SHOW,
        disabled: !isSelected,
        automationId: 'show-asset-btn',
        command: () => {
          this.showAssetViewDialog = true;
        },
      },
      {
        label: 'Bearbeiten',
        icon: 'pi ' + ICON.EDIT,
        disabled: !isSelected,
        automationId: 'edit-asset-btn',
        command: () => {
          this.fileName = this.selectedAsset.name;
          this.fileLabel = this.selectedAsset.label;
          this.showAddAssetDialog = true;
        },
      },
      {
        label: 'Genutzt durch',
        automationId: 'asset-usage-btn',
        icon: 'pi ' + ICON.USAGES,
        disabled: !isSelected,
        command: () => {
          this.showAssetUsageDialog();
        },
      },
      {
        label: 'Löschen',
        automationId: 'delete-asset-btn',
        icon: 'pi ' + ICON.DELETE,
        disabled: !isSelected,
        command: () => {
          this.deleteAsset();
        },
      },
    ];
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.toString());
      reader.readAsDataURL(blob);
    });
  }
}
