import { ChangeDetectorRef, Component } from '@angular/core';
import { BooleanFieldConfig, Create, DATA_VALUE_TYPE, FIELD_TYPES, SelectOption } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';

@Component({
  selector: 'kfd-field-settings-details-bool',
  templateUrl: './bool.component.html',
  styleUrls: ['./bool.component.scss'],
})
export class BoolEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<BooleanFieldConfig> {
  public boolValues: SelectOption[] = [
    {
      label: 'Ja',
      icon: 'pi pi-check-circle',
      value: true,
    },
    {
      label: 'Nein',
      icon: 'pi pi-times-circle',
      value: false,
    },
  ];
  public defaultValue = Create.inputValue(DATA_VALUE_TYPE.BOOL, false);
  public defaultLabels = {
    yes: '',
    no: '',
  };

  public constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.YESNO;
  }
}
