import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsContextService } from '../services/cms-context.service';

@Injectable({ providedIn: 'root' })
export class UnsetCtxGuard {
  constructor(private ctx: CmsContextService) {}

  canDeactivate(): Observable<boolean> | boolean {
    //the timeout avoids unset before components has been destroyed and trying to access the properties
    window.setTimeout(() => {
      this.ctx.unset();
    }, 1000);
    return true;
  }
}
