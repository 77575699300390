import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Configuration } from '@kfd/core';
import { BaseHttpService } from '@kfd/web-core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PublishedCfgService {
  private httpService: BaseHttpService;

  constructor(httpClient: HttpClient) {
    this.httpService = new BaseHttpService(httpClient, environment.services.cfg);
  }

  healthy(): Observable<boolean> {
    return this.httpService.get<boolean>(`/`).pipe(map(() => true));
  }

  public getPublished(mandant: string, configuration: string): Observable<Configuration> {
    return this.httpService.get<Configuration>(`/published/mandant/${mandant}/configuration/${configuration}`);
  }
}
