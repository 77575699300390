<div #overlayPanelTarget *ngIf="config" class="p-fluid">
  <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
  </kfd-required-cfg-setting>
  <div class="horizontal-line"></div>
  <kfd-toggle-setting
    (disable)="change('min', undefined)"
    (enable)="change('min', defaultMinValue)"
    [object]="config"
    [property]="'min'"
    class="p-field"
    data-automationId="numeric-config-min"
    label="Min. Anzahl"
  >
    <kfd-data-value
      (valueChange)="change('min', $event)"
      [properties]="{ showButtons: true }"
      [type]="dataValueType.NUMERIC"
      [value]="config.min"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <kfd-toggle-setting
    (disable)="change('max', undefined)"
    (enable)="change('max', defaultMaxValue)"
    [object]="config"
    [property]="'max'"
    class="p-field"
    data-automationId="numeric-config-max"
    label="Max. Anzahl"
  >
    <kfd-data-value
      (valueChange)="change('max', $event)"
      [properties]="{ showButtons: true }"
      [type]="dataValueType.NUMERIC"
      [value]="config.max"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <div class="horizontal-line"></div>

  <div class="setting-label mt-2">Auswahl Optionen</div>
  <div class="flex" data-automationId="select-option-settings">
    <div class="flex-1">
      <ng-container *ngIf="!config.dataHandler">
        <i>Keine Optionen ausgewählt</i>
      </ng-container>
      <ng-container *ngIf="config.dataHandler?.type === dataHandlerTypes.LIST">
        {{ config.dataHandler.data ? config.dataHandler.data.length : 0 }}
        Optionen
      </ng-container>
      <ng-container *ngIf="config.dataHandler?.type === dataHandlerTypes.DYNAMIC">
        @if (config.dataHandler.templateName) {
          <div>Vorlage: {{ config.dataHandler.templateName }}</div>
        }
        @if (config.dataHandler.tags && config.dataHandler.tags.length > 0) {
          <div>Tags: {{ config.dataHandler.tags.join(', ') }}</div>
        }
      </ng-container>
    </div>
    <div>
      <p-button
        (click)="showDataValuePanel()"
        [text]="true"
        data-automationId="edit-select-options"
        icon="pi {{ icon.EDIT }}"
        pTooltip="Auswahloptionen bearbeiten"
      ></p-button>
    </div>
  </div>

  <kfd-toggle-setting
    (disable)="change('emptySelection', undefined)"
    (enable)="change('emptySelection', '---')"
    [object]="config"
    [property]="'emptySelection'"
    class="mt-2"
    label="Leer-Wert anzeigen"
  >
    <input
      (ngModelChange)="change('emptySelection', $event)"
      [ngModel]="config.emptySelection"
      maxlength="30"
      pInputText
      type="text"
    />
  </kfd-toggle-setting>

  <div class="p-field mt-2">
    <label>Anzeige</label>
    <p-dropdown
      (onChange)="change('layout.type', $event.value)"
      [ngModel]="config.layout?.type"
      [options]="layoutOptions"
      appendTo="body"
      data-automationId="config-layout-type"
    >
    </p-dropdown>
  </div>
</div>
