import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CmsConfigurator, ICON } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'kfd-publishing-dialog',
  templateUrl: './publishing-dialog.component.html',
  styleUrls: ['./publishing-dialog.component.scss'],
})
export class PublishingDialogComponent extends BaseDialogComponent {
  public icon = ICON;
  public configuration$: Observable<CmsConfigurator | undefined>;
  projectId: string;
  configurationId: string;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected apiConfigurationService: ApiConfigurationService,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data.projectId) {
      throw new Error('No projectId given');
    }
    if (!dialogConfig.data.configurationId) {
      throw new Error('No configurationId given');
    }
    this.projectId = dialogConfig.data.projectId;
    this.configurationId = dialogConfig.data.configurationId;

    this.configuration$ = this.apiConfigurationService.getConfiguration(this.projectId, this.configurationId, false);
  }
}
