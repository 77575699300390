<ng-container *ngIf="pageRef">
  <p-dropdown
    (onChange)="onChange($event.value)"
    [ngModel]="pageRef.name"
    [options]="options"
    appendTo="body"
    id="operation"
    optionLabel="label"
    optionValue="name"
    placeholder="Feld wählen"
  >
    <ng-template let-item pTemplate="selectedItem">
      @if (item) {
        {{ item.label }}
        @if (item.name) {
          ({{ item.name }})
        }
      }
    </ng-template>
    <ng-template let-item pTemplate="item">
      @if (item) {
        {{ item.label }}
        @if (item.name) {
          ({{ item.name }})
        }
      }
    </ng-template>
  </p-dropdown>
</ng-container>
