import { flatMap, Observable } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { Injectable } from '@angular/core';
import { FullscreenLoginDialogComponent } from '../common/fullscreen-login-dialog/fullscreen-login-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private dialogService: DialogService) {}

  showLogin<T>(cbFn: Observable<T>): Observable<T> {
    return this.dialogService
      .open(FullscreenLoginDialogComponent, {
        styleClass: 'full-screen-dialog',
        closable: false,
      })
      .onClose.pipe(flatMap(() => cbFn));
  }
}
