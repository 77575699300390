import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CmsFieldGroup, ObjectUtil } from '@kfd/core';

@Component({
  selector: 'kfd-entry-fieldgroup-settings',
  templateUrl: './entry-fieldgroup-settings.component.html',
  styleUrls: ['./entry-fieldgroup-settings.component.scss'],
})
export class EntryFieldGroupSettingsComponent {
  @Output()
  public entryChange: EventEmitter<CmsFieldGroup> = new EventEmitter<CmsFieldGroup>();

  private _entry: CmsFieldGroup | undefined;

  get entry() {
    return this._entry;
  }

  @Input()
  set entry(value) {
    this._entry = ObjectUtil.clone(value);
  }

  public change(): void {
    this.entryChange.emit(this.entry);
  }
}
