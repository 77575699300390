import { Injectable, Type } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { LoggingService } from './logging.service';

export interface CmsDialogOptions {
  dismissibleMask?: boolean;
  fullHeight?: boolean;
  fixedHeight?: boolean;
  fullScreen?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CmsDialogService {
  constructor(
    private dialogService: DialogService,
    private loggingService: LoggingService,
  ) {}

  open(componentType: Type<unknown>, data = {}, options: CmsDialogOptions = {}): DynamicDialogRef {
    const styleClass =
      options.fullScreen === true
        ? 'custom-dialog full-screen'
        : options.fullHeight === true
          ? 'custom-dialog full-height'
          : options.fixedHeight === true
            ? 'custom-dialog fixed-height'
            : 'custom-dialog';

    this.loggingService.addLog({
      type: 'dialog',
      message: 'Opened dialog ' + componentType.name,
    });

    return this.dialogService.open(componentType, {
      styleClass,
      closeOnEscape: options.dismissibleMask === false ? false : true,
      dismissableMask: options.dismissibleMask === false ? false : true,
      showHeader: false,
      transitionOptions: '300ms ease-in-out',
      //set to allow drag n drop in dialogs
      autoZIndex: false,
      baseZIndex: 999,
      data,
    });
  }
}
