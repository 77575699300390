export class Dirty {
  originValue: string;
  value: unknown;
  public isDirty: boolean;

  constructor(value: unknown) {
    this.value = value;
    this.originValue = JSON.stringify(value);
    this.isDirty = false;
  }

  update() {
    this.isDirty = JSON.stringify(this.value) !== this.originValue;
  }

  reset() {
    this.value = JSON.parse(this.originValue);
  }
}
