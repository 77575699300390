import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ALLOWED_CHILDREN, CLS, CmsConfiguratorPage, ICON, PageSkeleton } from '@kfd/core';
import { combineLatestWith, Observable, Subject } from 'rxjs';
import { CfgStateService, EntryStatusChange } from '../../cfg-state.service';
import { DragDropService } from '../drag-drop/drag-drop.service';
import { CfgEditorService } from '../../cfg-editor.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { map } from 'rxjs/operators';
import { DragElementHandler } from '../drag-drop/drag-element.handler';

export const CONTENT_MARGIN = 15;

@Component({
  selector: 'kfd-dnd-area-page',
  templateUrl: './dnd-area-page.component.html',
  styleUrls: ['./dnd-area-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaPageComponent implements OnDestroy {
  @Input()
  @HostBinding('style.width.px')
  public pageWidth = 0;

  @Input()
  @HostBinding('style.height.px')
  public pageHeight = 0;

  @ViewChild('isNewOverlay')
  protected isNewOverlay: OverlayPanel | undefined;

  protected viewData$: Observable<{
    page: CmsConfiguratorPage;
    isFirst: boolean;
    isLast: boolean;
    pageSkeleton: PageSkeleton;
    entryStatus: EntryStatusChange;
    selected: boolean;
    dndActive: string | undefined;
    editMode: boolean;
  }>;

  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly allowedChildren = ALLOWED_CHILDREN;
  protected readonly contentMargin = CONTENT_MARGIN;

  protected isVisible$: Observable<boolean>;

  private destroy$ = new Subject<boolean>();
  private dragElementHandler: DragElementHandler | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly viewRef: ViewContainerRef,
    protected readonly cfgStateService: CfgStateService,
    private readonly dragDropService: DragDropService,
    protected readonly cfgEditorService: CfgEditorService,
  ) {
    this.isVisible$ = this.onVisibilityChange();
  }

  private _pageName: string | undefined;

  get pageName(): string | undefined {
    return this._pageName;
  }

  @Input()
  set pageName(value: string | undefined) {
    this._pageName = value;
    this.updatePage();
  }

  @HostBinding('class') get hostClasses() {
    return 'selectable';
  }

  @ViewChild('dragInitiator')
  private set dragInitiator(element: ElementRef<HTMLDivElement> | undefined) {
    if (!element) {
      if (this.dragElementHandler) {
        this.dragElementHandler.destroy();
        this.dragElementHandler = undefined;
      }
      return;
    }
    if (this.dragElementHandler) {
      return;
    }

    this.dragElementHandler = new DragElementHandler(this.dragDropService, this.elementRef, element, {
      elementName: this._pageName,
      parentName: this.cfgStateService.getCfgUtil().getParentEntry(this._pageName, true).name,
      position: this.cfgStateService.getCfgUtil().getRelativePosition(this._pageName).pos,
      cls: CLS.PAGE_WRAPPER,
      icon: ICON.PAGE,
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public updatePage(): void {
    if (!this._pageName) {
      this.viewData$ = undefined;
      return;
    }

    this.viewData$ = this.cfgStateService.onEntryChange<CmsConfiguratorPage>(this._pageName, true).pipe(
      combineLatestWith(
        this.cfgStateService.onEntryStatusChange(this._pageName),
        this.cfgEditorService.onSelectionChange,
        // of({name: 'foo'}),
        this.dragDropService.onDndActive,
        this.cfgEditorService.onEditModeChange,
      ),
      map(([page, entryStatus, selection, dndActive, editMode]) => ({
        page,
        isFirst: this.cfgStateService.getCfgUtil().isFirst(this._pageName, false),
        isLast: this.cfgStateService.getCfgUtil().isLast(this._pageName, false),
        pageSkeleton: this.cfgStateService.getCfgUtil().getPageSkeleton(page.entry),
        entryStatus,
        selected: selection && selection.name === this._pageName,
        dndActive,
        editMode,
      })),
    );
  }

  protected selectPage(pageName: string): void {
    this.cfgEditorService.selectEntry(pageName);
  }

  protected select(): void {
    this.cfgEditorService.selectEntry(this._pageName);
  }

  private onVisibilityChange(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const obs = new IntersectionObserver(
        (res) => {
          observer.next(res.find((r) => r.isIntersecting) !== undefined);
        },
        {
          root: document.querySelector('.preview-wrapper'),
          rootMargin: '200px',
          threshold: 0,
        },
      );
      obs.observe(this.viewRef.element.nativeElement);
    });
  }
}
