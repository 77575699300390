import { ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  Calculation,
  CLS,
  Create,
  DisplayDataFieldConfig,
  FIELD_TYPES,
  FieldRef,
  INPUT_VALUE_TYPES,
  Is,
  VALUE_FIELD_TYPES,
} from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { CalculationDialogComponent } from '../../../dialogs/calculation-dialog/calculation-dialog.component';
import { FieldRefDialogComponent } from '../../../dialogs/field-ref-dialog/field-ref-dialog.component';

@Component({
  selector: 'kfd-field-settings-details-displaydata',
  templateUrl: './display-data.component.html',
  styleUrls: ['./display-data.component.scss'],
})
export class DisplayDataEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<DisplayDataFieldConfig> {
  public cls = CLS;
  public valueTypeCls: CLS | undefined;
  public inputValueTypes = INPUT_VALUE_TYPES;

  public constructor(
    protected cd: ChangeDetectorRef,
    protected readonly cmsDialogService: CmsDialogService,
  ) {
    super(cd);
  }

  public get config(): DisplayDataFieldConfig | undefined {
    return this._config;
  }

  @Input()
  public set config(value: DisplayDataFieldConfig | undefined) {
    super.config = value;
    if (this._config?.value) {
      this.valueTypeCls = this._config.value.cls;
    } else {
      this.valueTypeCls = undefined;
    }
  }

  public getType(): FIELD_TYPES {
    return FIELD_TYPES.DISPLAY_DATA;
  }

  public updateValueType(cls: CLS): void {
    this.valueTypeCls = cls;
  }

  public showCalculationDialog(calculation: Calculation): void {
    this.cmsDialogService
      .open(CalculationDialogComponent, {
        calculation,
      })
      .onClose.subscribe((calculation: Calculation | undefined) => {
        if (calculation) {
          this.change('value', calculation);
        }
      });
  }

  protected openFieldRefSelection(): void {
    this.cmsDialogService
      .open(FieldRefDialogComponent, {
        fieldRef: Is.fieldRef(this._config.value) ? this._config.value : Create.fieldRef(),
        fieldTypes: VALUE_FIELD_TYPES,
        allowProperties: true,
      })
      .onClose.subscribe((fieldRef: FieldRef | undefined) => {
        if (Is.fieldRef(fieldRef)) {
          this.change('value', fieldRef);
        }
      });
  }
}
