import {
  CLS,
  CmsField,
  CmsPage,
  CONDITIONAL_OPERATOR,
  Configuration,
  ConfiguratorPage,
  DATA_VALUE_TYPE,
  DATAHANDLER_TYPES,
  DataRef,
  DisplayDataFieldConfig,
  Field,
  FIELD_TYPES,
  LOGICAL_OPERATOR,
  Page,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  SimpleListHandler,
} from '../dtos/index';
import { Create } from '../dtos/cls-creator';

/**
 * simple configuration with basic field types
 */
export function mockConfiguration1(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: {
      current: 1,
    },
    name: 'simple-cfg1',
    label: 'Simple configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          label: 'Page 1',
          name: 'page1',
          children: [
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                label: 'Question 1',
                name: 'question1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'textfield1',
                      label: 'Field 1',
                      config: Create.textFieldConfig({ required: true }),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'datefield1',
                      label: 'Field 2',
                      config: Create.dateFieldConfig(),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'numberfield1',
                      label: 'Field 3',
                      config: Create.numericFieldConfig(),
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
}

/**
 * configuration with simple calculation
 */
export function mockConfiguration2(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    _id: '',
    versions: {
      current: 1,
      published: 1,
      drafted: 1,
    },
    name: 'simple-cfg2',
    label: 'Simple configuration 2',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page1',
          children: [
            {
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'question1',
                children: [
                  {
                    entry: {
                      cls: CLS.FIELD,
                      name: 'numberfield1',
                      config: Create.fieldConfig(FIELD_TYPES.NUMBER, DATA_VALUE_TYPE.NUMERIC, { required: true }),
                    } as Field,
                  },
                  {
                    entry: {
                      cls: CLS.FIELD,
                      name: 'numberfield2',
                      config: Create.fieldConfig(FIELD_TYPES.NUMBER, DATA_VALUE_TYPE.NUMERIC, { required: true }),
                    },
                  },
                  {
                    entry: {
                      cls: CLS.FIELD,
                      name: 'calculation',
                      config: {
                        cls: CLS.FIELD_CONFIG,
                        type: FIELD_TYPES.DISPLAY_DATA,
                        dataType: DATA_VALUE_TYPE.NUMERIC,
                        value: Create.calculation([Create.fieldRef('numberfield1'), Create.fieldRef('numberfield2')]),
                      } as DisplayDataFieldConfig,
                    },
                  },
                ],
              },
            },
          ],
        } as CmsPage,
      } as ConfiguratorPage,
    ],
  } as Configuration;
}

export function mockConfiguration(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: {
      current: 1,
    },
    name: 'cfg1',
    label: 'Configuration 3',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page1',
          label: 'Page 1',
          children: [
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'question1',
                label: 'Question 1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'field1',
                      label: 'Field 1',
                      config: Create.fieldConfig(FIELD_TYPES.TEXT, DATA_VALUE_TYPE.STRING, { required: true }),
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'field2',
                      label: 'Field 2',
                      config: Create.fieldConfig(FIELD_TYPES.DATE),
                    },
                  },
                ],
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'page1_field3',
                label: 'Field 3',
                config: Create.fieldConfig(FIELD_TYPES.TEXT),
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'page1_field4',
                label: 'Field 4',
                config: Create.fieldConfig(FIELD_TYPES.NUMBER, DATA_VALUE_TYPE.NUMERIC, { required: true }),
              },
            },
          ],
        },
      },
      {
        cls: CLS.PAGE_WRAPPER,
        condition: {
          cls: CLS.CONDITION_GROUP,
          lop: LOGICAL_OPERATOR.AND,
          conditions: [
            {
              cls: CLS.CONDITION,
              value1: Create.fieldRef('page1_field3'),
              value2: Create.inputValue(DATA_VALUE_TYPE.STRING, 'foo'),
              op: CONDITIONAL_OPERATOR.CONTAINS,
            },
          ],
        },
        entry: {
          cls: CLS.PAGE,
          name: 'page2',
          label: 'Page 2',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'field5',
                label: 'Field 5',
                config: Create.fieldConfig(FIELD_TYPES.SUBMITBTN),
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'field6',
                label: 'Field 5',
                config: Create.fieldConfig(FIELD_TYPES.NUMBER),
              },
              condition: {
                cls: CLS.CONDITION_GROUP,
                lop: LOGICAL_OPERATOR.AND,
                conditions: [
                  {
                    cls: CLS.CONDITION,
                    value1: Create.fieldRef('field3'),
                    value2: Create.inputValue(DATA_VALUE_TYPE.STRING, 'foo'),
                    op: CONDITIONAL_OPERATOR.EQUALS,
                  },
                ],
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'dynamic-select-field',
                label: 'Field 7',
                hint: 'Select field with dynamic handler to test tag handling',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.SELECT,
                  dataType: DATA_VALUE_TYPE.STRING,
                  layout: {
                    type: SELECT_FIELD_LAYOUTS.BUTTON,
                  },
                  dataHandler: {
                    cls: CLS.DATA_HANDLER,
                    type: DATAHANDLER_TYPES.DYNAMIC,
                    tags: ['tag1', 'tag2'],
                  },
                } as SelectFieldConfig,
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'static-select-field',
                label: 'Field 8',
                hint: 'Select field with simple list handler to test data references',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  dataType: DATA_VALUE_TYPE.STRING,
                  layout: {
                    type: SELECT_FIELD_LAYOUTS.DROPDOWN,
                  },
                  type: FIELD_TYPES.SELECT,
                  dataHandler: {
                    cls: CLS.DATA_HANDLER,
                    type: DATAHANDLER_TYPES.LIST,
                    data: [
                      {
                        cls: CLS.DATA_REF,
                        name: 'data-12345-a',
                      } as DataRef,
                      {
                        cls: CLS.DATA_REF,
                        name: 'data-12345-b',
                      } as DataRef,
                    ],
                  },
                } as SelectFieldConfig,
              },
            },
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'page2_group1',
                label: 'Group 1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'page2_group1_select1',
                      label: 'Field 9',
                      config: {
                        cls: CLS.FIELD_CONFIG,
                        dataType: DATA_VALUE_TYPE.STRING,
                        layout: {
                          type: SELECT_FIELD_LAYOUTS.BUTTON,
                        },
                        type: FIELD_TYPES.SELECT,
                        dataHandler: {
                          cls: CLS.DATA_HANDLER,
                          type: DATAHANDLER_TYPES.DYNAMIC,
                          tags: ['tag1', 'tag3'],
                        },
                      } as SelectFieldConfig,
                    },
                  },
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'page2_group1_select2',
                      hint: 'Select field with simple list handler to test data references',
                      label: 'Field 10',
                      config: {
                        cls: CLS.FIELD_CONFIG,
                        dataType: DATA_VALUE_TYPE.STRING,
                        layout: {
                          type: SELECT_FIELD_LAYOUTS.DROPDOWN,
                        },
                        type: FIELD_TYPES.SELECT,
                        dataHandler: {
                          cls: CLS.DATA_HANDLER,
                          type: DATAHANDLER_TYPES.LIST,
                          data: [
                            {
                              cls: CLS.DATA_REF,
                              name: 'data-12345-b',
                            },
                            {
                              cls: CLS.DATA_REF,
                              name: 'data-12345-c',
                            },
                          ],
                        },
                      } as SelectFieldConfig,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  };
}

export function mockCfgSelect(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: {
      current: 1,
    },
    name: 'invalid-cfg1',
    label: 'Invalid configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page1',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                _id: '',
                cls: CLS.FIELD,
                name: 'static-select-field',
                hint: 'Select field with simple list handler to test data references',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  dataType: DATA_VALUE_TYPE.STRING,
                  layout: {
                    type: SELECT_FIELD_LAYOUTS.DROPDOWN,
                  },
                  type: FIELD_TYPES.SELECT,
                  emptySelection: '---',
                  required: true,
                  dataHandler: {
                    cls: CLS.DATA_HANDLER,
                    type: DATAHANDLER_TYPES.LIST,
                    data: [
                      {
                        cls: CLS.DATA_REF,
                        name: 'data-12345-a',
                      } as DataRef,
                      {
                        cls: CLS.DATA_REF,
                        name: 'data-12345-b',
                      } as DataRef,
                    ],
                  } as SimpleListHandler,
                } as SelectFieldConfig,
                meta: Create.entryMeta(),
              } as CmsField,
            },
          ],
        },
      },
    ],
  };
}

/**
 * mock configuration with empty group and pages
 */
export function mockCfgInvalid1(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    _id: '',
    versions: {
      current: 1,
      published: 1,
      drafted: 1,
    },
    name: 'invalid-cfg1',
    label: 'Invalid configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page1',
          children: [
            // invalid: empty group
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'group1',
                label: 'Group 1',
                config: {},
                children: [],
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'textfeld_1',
                label: 'Textfeld 1',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                  // invalid: is required but conditional
                  required: true,
                },
              },
              condition: {
                cls: CLS.CONDITION_GROUP,
                lop: LOGICAL_OPERATOR.AND,
                conditions: [
                  // invalid: references to a field after the current
                  {
                    cls: CLS.CONDITION,
                    value1: {
                      cls: CLS.FIELD_REF,
                      name: 'textfeld_2',
                    },
                    op: CONDITIONAL_OPERATOR.NOT_EMPTY,
                  },
                ],
              },
            },
            // valid field
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'textfeld_2',
                label: 'Textfeld 2',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                },
              },
            },
            // invalid: references to a field which does not exist
            // invalid: required but conditional
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'textfeld_3',
                label: 'Textfeld 3',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                  required: true,
                },
              },
              condition: {
                cls: CLS.CONDITION_GROUP,
                lop: LOGICAL_OPERATOR.AND,
                conditions: [
                  {
                    cls: CLS.CONDITION,
                    value1: {
                      cls: CLS.FIELD_REF,
                      name: 'textfeld_x',
                    },
                    op: CONDITIONAL_OPERATOR.NOT_EMPTY,
                  },
                ],
              },
            },
          ],
        } as Page,
      } as ConfiguratorPage,
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page2',
          children: [],
        },
        condition: {
          cls: CLS.CONDITION_GROUP,
          lop: LOGICAL_OPERATOR.AND,
          conditions: [
            {
              cls: CLS.CONDITION,
              value1: {
                cls: CLS.FIELD_REF,
                name: 'textfeld_3',
              },
              op: CONDITIONAL_OPERATOR.NOT_EMPTY,
            },
          ],
        },
      } as ConfiguratorPage,
    ],
  } as Configuration;
}

/**
 * mock configuration with submit buttons to test checks
 */
export function mockCfgSubmitButtons(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    _id: '',
    versions: {
      current: 1,
      published: 1,
      drafted: 1,
    },
    name: 'invalid-cfg1',
    label: 'Invalid configuration 1',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page1',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'textfeld_1',
                label: 'Textfeld 1',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                },
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'submitbtn_1',
                label: 'Submit Btn 1',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.SUBMITBTN,
                },
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'textfeld_2',
                label: 'Textfeld 2',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                },
              },
            },
          ],
        } as Page,
      } as ConfiguratorPage,
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'page2',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'submitbtn_2',
                label: 'Submit Btn 2',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.SUBMITBTN,
                },
              },
            },
          ],
        },
      } as ConfiguratorPage,
    ],
  } as Configuration;
}

/**
 * mock configuration with empty group and pages
 */
export function mockCfgConditional1(): Configuration {
  return {
    cls: CLS.CONFIGURATION,
    versions: { current: 1 },
    name: 'required_field_condition',
    label: 'Required Field Condition',
    settings: {
      cls: CLS.CONFIGURATION_SETTINGS,
      customerEmailField: Create.fieldRef('email'),
    },
    children: [
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'seite_1',
          label: 'Seite 1',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'alter',
                label: 'Alter',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.NUMBER,
                  dataType: DATA_VALUE_TYPE.NUMERIC,
                },
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'name',
                label: 'Name',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                  required: true,
                },
              },
              condition: {
                cls: CLS.CONDITION_GROUP,
                lop: LOGICAL_OPERATOR.AND,
                conditions: [
                  {
                    cls: CLS.CONDITION,
                    value1: {
                      cls: CLS.FIELD_REF,
                      name: 'alter',
                    },
                    op: CONDITIONAL_OPERATOR.NOT_EMPTY,
                  },
                ],
              },
            },
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'gruppe_1',
                label: 'Gruppe 1',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'datum',
                      label: 'Datum',
                      config: {
                        cls: CLS.FIELD_CONFIG,
                        type: FIELD_TYPES.DATE,
                        dataType: DATA_VALUE_TYPE.DATE,
                        required: true,
                      },
                    },
                    condition: {
                      cls: CLS.CONDITION_GROUP,
                      lop: LOGICAL_OPERATOR.AND,
                      conditions: [
                        {
                          cls: CLS.CONDITION,
                          value1: { cls: CLS.FIELD_REF, name: 'alter' },
                          op: CONDITIONAL_OPERATOR.NOT_EMPTY,
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'seite_2',
          label: 'Seite 2',
          children: [
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'text_3',
                label: 'Text 3',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                },
              },
            },
            {
              cls: CLS.FIELD_WRAPPER,
              entry: {
                cls: CLS.FIELD,
                name: 'text_4',
                label: 'Text 4',
                config: {
                  cls: CLS.FIELD_CONFIG,
                  type: FIELD_TYPES.TEXT,
                  dataType: DATA_VALUE_TYPE.STRING,
                },
              },
            },
            {
              cls: CLS.FIELD_GROUP_WRAPPER,
              entry: {
                cls: CLS.FIELD_GROUP,
                name: 'gruppe_2',
                label: 'Gruppe 2',
                children: [
                  {
                    cls: CLS.FIELD_WRAPPER,
                    entry: {
                      cls: CLS.FIELD,
                      name: 'text_5',
                      label: 'Text 5',
                      config: {
                        cls: CLS.FIELD_CONFIG,
                        type: FIELD_TYPES.TEXT,
                        dataType: DATA_VALUE_TYPE.STRING,
                        required: true,
                      },
                    },
                  },
                ],
              },
              condition: {
                cls: CLS.CONDITION_GROUP,
                lop: LOGICAL_OPERATOR.AND,
                conditions: [
                  {
                    cls: CLS.CONDITION,
                    value1: { cls: CLS.FIELD_REF, name: 'name' },
                    op: CONDITIONAL_OPERATOR.NOT_EMPTY,
                  },
                ],
              },
            },
          ],
        },
        condition: {
          cls: CLS.CONDITION_GROUP,
          lop: LOGICAL_OPERATOR.AND,
          conditions: [
            {
              cls: CLS.CONDITION,
              value1: { cls: CLS.FIELD_REF, name: 'alter' },
              op: CONDITIONAL_OPERATOR.NOT_EMPTY,
            },
          ],
        },
      },
      {
        cls: CLS.PAGE_WRAPPER,
        entry: {
          cls: CLS.PAGE,
          name: 'seite_3',
          label: 'Seite 3',
          children: [],
        },
      },
    ],
  };
}
