import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CLS, DateInputValue, ICON, Is, RelativeDate } from '@kfd/core';
import { CfgStateService } from '../../../cfg-state.service';
import { DATE_MANIPULATION_OPTIONS, DATE_RELATIVE_START_OPTIONS, DATE_UNIT_OPTIONS } from '../../../../shared/global';

@Component({
  selector: 'kfd-date-value-viewer',
  templateUrl: './date-value-viewer.component.html',
  styleUrls: ['./date-value-viewer.component.scss'],
})
export class DateValueViewerComponent {
  public startDateValue: string | undefined;
  public dateManipulation: string | undefined;
  public dateManipulationValue: string | undefined;
  public dateManipulationUnit: string | undefined;
  @Input()
  public showEditBtn = false;
  @Output()
  public editBtnClick = new EventEmitter<void>();
  protected readonly CLS = CLS;
  protected readonly ICON = ICON;
  protected readonly Is = Is;

  constructor(private readonly cfgStateService: CfgStateService) {}

  private _dateValue: DateInputValue | RelativeDate | undefined;

  get dateValue(): DateInputValue | RelativeDate | undefined {
    return this._dateValue;
  }

  @Input()
  set dateValue(value: DateInputValue | RelativeDate | undefined) {
    if (!value) {
      return;
    }
    if (Is.relativeDate(value)) {
      this.dateManipulation = DATE_MANIPULATION_OPTIONS.find((v) => v.value === value.manipulation)?.label;
      if (Is.fieldRef(value.start)) {
        this.startDateValue = this.cfgStateService.getCfgUtil().getEntryByName(value.start.name)?.label;
      } else if (Is.dateRef(value.start)) {
        this.startDateValue = DATE_RELATIVE_START_OPTIONS.find(
          (option) => option.value.name === value.start.name,
        )?.label;
      } else {
        this.startDateValue = undefined;
      }
      this.dateManipulationValue = value.value.toString();
      this.dateManipulationUnit = DATE_UNIT_OPTIONS.find((option) => option.value === value.unit)?.label;
    }
    this._dateValue = value;
  }
}
