import { Component } from '@angular/core';
import { Create, FieldRef, INPUT_FIELD_TYPES, ObjectUtil } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-field-ref-dialog',
  templateUrl: './field-ref-dialog.component.html',
  styleUrls: ['./field-ref-dialog.component.scss'],
})
export class FieldRefDialogComponent extends BaseDialogComponent {
  protected fieldRef: FieldRef = Create.fieldRef();
  protected fieldTypes = INPUT_FIELD_TYPES;
  protected allowProperties = false;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.fieldRef) {
      this.fieldRef = ObjectUtil.clone(dialogConfig.data.fieldRef);
    }
    if (dialogConfig.data.fieldTypes) {
      this.fieldTypes = dialogConfig.data.fieldTypes;
    }
    if (dialogConfig.data.allowProperties) {
      this.allowProperties = dialogConfig.data.allowProperties === true;
    }
  }

  save() {
    this.close(this.fieldRef);
  }
}
