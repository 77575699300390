import { Component } from '@angular/core';
import { ExportData, ICON } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from '@kfd/web-core';
import { CmsContextService } from '../../../services/cms-context.service';
import { ImExportService } from '../../../services/im-export.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-export-configuration-dialog',
  templateUrl: './export-configuration-dialog.component.html',
  styleUrls: ['./export-configuration-dialog.component.scss'],
})
export class ExportConfigurationDialogComponent extends BaseDialogComponent {
  loading = false;
  icons = ICON;
  configurationDataExport: ExportData | undefined;
  projectId: string;
  configurationId: string;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private messageService: MessageService,
    private ctx: CmsContextService,
    private imExportService: ImExportService,
  ) {
    super(dialogRef, dialogConfig);
    if (!dialogConfig.data.projectId) {
      throw new Error('No projectId given');
    }
    if (!dialogConfig.data.configurationId) {
      throw new Error('No configurationId given');
    }
    this.projectId = dialogConfig.data.projectId;
    this.configurationId = dialogConfig.data.configurationId;

    this.loading = true;
    this.imExportService.exportConfiguration(this.projectId, this.configurationId).subscribe({
      next: (configurationDataExport) => {
        this.configurationDataExport = configurationDataExport;
        this.loading = false;
      },
    });
  }

  export() {
    if (this.configurationDataExport === undefined) {
      return;
    }
    const file = new Blob([JSON.stringify(this.configurationDataExport)], {
      type: 'application/json',
    });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);

    const names = this.configurationDataExport.configurations.map((c) => `${c.name}-v${c.versions.current}`);
    a.download = `${names.join('-')}.json`;
    a.click();
    this.messageService.showSuccess('Die Konfiguration wird heruntergeladen');
    this.close();
  }
}
