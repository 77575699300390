import { Component, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsChildWrapper,
  CmsConfiguratorField,
  CmsConfiguratorFieldGroup,
  CmsConfiguratorPage,
  GenericEntry,
  ICON,
  Is,
  isCmsConfiguratorField,
  isCmsConfiguratorFieldGroup,
  isCmsConfiguratorPage,
} from '@kfd/core';
import { ButtonModule } from 'primeng/button';
import { NewEntryItem } from '../../../shared/global';
import { DragElementHandler } from '../../dnd-area/drag-drop/drag-element.handler';
import { DragDropService } from '../../dnd-area/drag-drop/drag-drop.service';
import { CfgStateService } from '../../cfg-state.service';
import { v4 as uuidv4 } from 'uuid';
import { CfgEditorService } from '../../cfg-editor.service';

@Component({
  selector: 'kfd-new-entry-item',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './new-entry-item.component.html',
  styleUrl: './new-entry-item.component.scss',
})
export class NewEntryItemComponent {
  protected readonly ICON = ICON;

  constructor(
    private readonly dragDropService: DragDropService,
    private readonly elementRef: ElementRef,
    private readonly cfgStateService: CfgStateService,
    private readonly cfgEditorService: CfgEditorService,
  ) {}

  private _newEntryItem: NewEntryItem | undefined;

  public get newEntryItem(): NewEntryItem | undefined {
    return this._newEntryItem;
  }

  @Input()
  public set newEntryItem(value: NewEntryItem | undefined) {
    this._newEntryItem = value;

    new DragElementHandler(
      this.dragDropService,
      this.elementRef,
      this.elementRef,
      {
        elementName: value.name,
        cls: value.entry.cls,
        icon: value.icon,
      },
      {
        createOnDrop: value.entry,
      },
    );
  }

  /**
   * adds an element direct without dnd
   * element will be inserted in current selected page/group or after a selected field
   */
  protected addElement(cmsChildWrapper: CmsChildWrapper): void {
    let selection = this.cfgEditorService.currentSelection;

    if (!cmsChildWrapper.entry.name) {
      cmsChildWrapper.entry.name = uuidv4();
    }

    if (!selection) {
      selection = this.cfgStateService.getCfgUtil().getCfg().name;
    }

    let selectedEntry = this.cfgStateService.getCfgUtil().getEntryByName(selection, false);
    // add to last page if nothing is selected
    if (!selectedEntry) {
      const pages = this.cfgStateService.getCfgUtil().getPages();
      if (pages.length === 0) {
        throw new Error('No page found');
      }
      selectedEntry = pages[pages.length - 1];
    }

    if (isCmsConfiguratorField(cmsChildWrapper)) {
      this.addField(cmsChildWrapper, selectedEntry);
    }

    if (isCmsConfiguratorFieldGroup(cmsChildWrapper)) {
      this.addGroup(cmsChildWrapper, selectedEntry);
    }

    if (isCmsConfiguratorPage(cmsChildWrapper)) {
      this.addPage(cmsChildWrapper, selectedEntry);
    }
  }

  private addField(field: CmsConfiguratorField, selectedEntry: GenericEntry): void {
    if (Is.configuration(selectedEntry)) {
      const lastPage = this.cfgStateService.getCfgUtil().getCfg().children.pop();
      if (lastPage) {
        selectedEntry = lastPage.entry;
      }
    }

    if (Is.page(selectedEntry) || Is.fieldGroup(selectedEntry)) {
      this.cfgStateService.createEntry(field, {
        name: selectedEntry.name,
        pos: selectedEntry.children.length,
      });
      return;
    }

    if (Is.field(selectedEntry)) {
      const selectedEntryPosition = this.cfgStateService.getCfgUtil().getRelativePosition(selectedEntry.name);
      this.cfgStateService.createEntry(field, {
        name: selectedEntryPosition.name,
        pos: selectedEntryPosition.pos + 1,
      });
      return;
    }
    throw new Error(`Cannot add new element. The current selected type "${selectedEntry.cls}" is not supported`);
  }

  private addGroup(cmsConfiguratorFieldGroup: CmsConfiguratorFieldGroup, selectedEntry: GenericEntry): void {
    if (Is.configuration(selectedEntry)) {
      const lastPage = this.cfgStateService.getCfgUtil().getCfg().children.pop();
      if (lastPage) {
        selectedEntry = lastPage.entry;
      }
    }

    if (Is.page(selectedEntry)) {
      this.cfgStateService.createEntry(cmsConfiguratorFieldGroup, {
        name: selectedEntry.name,
        pos: selectedEntry.children.length,
      });
      return;
    }

    if (Is.field(selectedEntry) || Is.fieldGroup(selectedEntry)) {
      const selectedEntryPosition = this.cfgStateService.getCfgUtil().getRelativePosition(selectedEntry.name);
      this.cfgStateService.createEntry(cmsConfiguratorFieldGroup, {
        name: selectedEntryPosition.name,
        pos: selectedEntryPosition.pos + 1,
      });
      return;
    }
    throw new Error(`Cannot add new element. The current selected type "${selectedEntry.cls}" is not supported`);
  }

  private addPage(cmsConfiguratorPage: CmsConfiguratorPage, selectedEntry: GenericEntry): void {
    if (Is.field(selectedEntry)) {
      selectedEntry = this.cfgStateService.getCfgUtil().getParentEntry(selectedEntry.name, true);
    }

    if (Is.fieldGroup(selectedEntry)) {
      selectedEntry = this.cfgStateService.getCfgUtil().getParentEntry(selectedEntry.name, true);
    }

    if (Is.page(selectedEntry)) {
      const selectedEntryPosition = this.cfgStateService.getCfgUtil().getRelativePosition(selectedEntry.name);
      this.cfgStateService.createEntry(cmsConfiguratorPage, {
        name: selectedEntryPosition.name,
        pos: selectedEntryPosition.pos + 1,
      });
      return;
    }

    if (Is.configuration(selectedEntry)) {
      this.cfgStateService.createEntry(cmsConfiguratorPage, {
        name: selectedEntry.name,
        pos: this.cfgStateService.getCfgUtil().getCfg().children.length,
      });
      return;
    }
    throw new Error(`Cannot add new element. The current selected type "${selectedEntry.cls}" is not supported`);
  }
}
