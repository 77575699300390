<kfd-dialog-layout
  (accept)="close()"
  (reject)="close()"
  [loading]="loading"
  [showRejectBtn]="false"
  [showRejectIcon]="true"
  acceptBtnLabel="OK"
  data-automationId="data-usage-info-dialog"
  dialogTitle="Genutze Datensätze und Tags"
>
  @if (loading) {
    <ng-container>
      <p-message severity="info" text="Verwendete Datensätze werden geprüft..."></p-message>
    </ng-container>
  } @else {
    @if (dataRefEntries$ | async; as dataRefEntries) {
      <h2>Datensätze</h2>
      @if (dataRefEntries.length === 0) {
        <p-message
          class="mt-2"
          severity="info"
          text="Es sind keine Datensätze in Verwendung"
          data-automationId="no-data-entries-in-use-info"
        ></p-message>
      } @else {
        <div class="mt-2">
          <ng-container
            [ngTemplateOutletContext]="{ entries: dataRefEntries, usage: true }"
            [ngTemplateOutlet]="dataTable"
          ></ng-container>
        </div>
      }
    }

    <h2>Dynamische Daten</h2>
    @if (filterUsage.length > 0) {
      @for (filter of filterUsage; track filter.usedBy.name) {
        <p-panel [toggleable]="true" [collapsed]="true" class="mt-2" data-automationTag="dynamic-usage-entry">
          <ng-template pTemplate="header">
            <div>
              <strong>{{ filter.usedBy.label }}</strong>
            </div>
          </ng-template>
          <strong>Filter</strong>
          <div>Template: {{ filter.entry.templateName ?? '---' }}</div>
          <div>
            Tags:
            @if (filter.entry.tags.length > 0) {
              {{ filter.entry.tags.join(', ') }}
            } @else {
              ---
            }
          </div>

          <p-button
            class="mt-2 block"
            icon="pi {{ ICON.LINK }}"
            label="Datensätze anzeigen"
            [rounded]="true"
            [outlined]="true"
            (click)="showDynamicUsage(filter.entry.templateName, filter.entry.tags)"
          ></p-button>
        </p-panel>
      }
    } @else {
      <p-message
        class="mt-2"
        severity="info"
        text="Es sind keine Filter in Verwendung"
        data-automationId="no-data-filter-in-use-info"
      >
      </p-message>
    }
  }

  <ng-container footerLeft>
    <!--    <button-->
    <!--      (click)="close()"-->
    <!--      [routerLink]="'/project/' + ctx.projectId + '/data'"-->
    <!--      class="p-button-secondary"-->
    <!--      data-automationId="edit-data-entries-btn"-->
    <!--      icon="pi pi-external-link"-->
    <!--      iconPos="left"-->
    <!--      label="Datensätze bearbeiten"-->
    <!--      pButton-->
    <!--      type="button"-->
    <!--    ></button>-->
  </ng-container>
</kfd-dialog-layout>

<ng-template #dataTable let-entries="entries" let-usage="usage">
  <p-contextMenu #tableUsageInfoMenu [model]="usageInfoMenuItems" appendTo="body"></p-contextMenu>
  <p-table
    *ngIf="entries && entries.length"
    [value]="entries"
    dataKey="_id"
    responsiveLayout="scroll"
    styleClass="p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Label</th>
        <th>Name</th>
        <th>Tags</th>
        <th *ngIf="usage" class="action">&nbsp;</th>
      </tr>
    </ng-template>
    <ng-template let-data pTemplate="body">
      <tr [id]="'dataRow-' + data.name" data-automationId="data-row">
        <td class="label">{{ data.label }}</td>
        <td class="name">{{ data.name }}</td>
        <td>
          <ng-container *ngIf="data.tags?.length > 0">
            {{ data.tags.join(', ') }}
          </ng-container>
        </td>
        <td *ngIf="usage" class="action">
          <p-button
            (click)="showUsedBy($event, data.name)"
            [text]="true"
            icon="pi {{ ICON.LINK }}"
            pTooltip="Genutzt durch..."
          ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
