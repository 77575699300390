<div
  (isLoading)="loading = $event"
  (isUnique)="uniqueChange($event)"
  *ngIf="name"
  [kfdBlock]="loading"
  [name]="name"
  [type]="type"
  kfdValidName
>
  <p-message *ngIf="loading === true" [text]="'&quot;' + name + '&quot; wird geprüft...'" severity="info"> </p-message>
  <p-message
    *ngIf="checkedName && loading === false && unique === true"
    [text]="'&quot;' + checkedName + '&quot; wird noch nicht verwendet'"
    severity="success"
  ></p-message>
  <p-message
    *ngIf="checkedName && loading === false && unique === false"
    [text]="'&quot;' + checkedName + '&quot; wird bereits verwendet'"
    severity="warn"
  ></p-message>
</div>
