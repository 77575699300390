import { Component, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomTreeNode, PrimeTreeUtil } from '../../../common/prime-tree.util';
import { Tree } from 'primeng/tree';
import { CAN_MOVE_TO, CmsGenericEntry, MovePosition } from '@kfd/core';
import { LoggingService } from '../../../services/logging.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CfgStateService } from '../../cfg-state.service';

@Component({
  selector: 'kfd-move-entry-dialog',
  templateUrl: './move-entry-dialog.component.html',
  styleUrls: ['./move-entry-dialog.component.scss'],
})
export class MoveEntryDialogComponent extends BaseDialogComponent {
  loading = false;
  // entryId: string | undefined;
  // parentId: string | undefined;
  entryName: string | undefined;
  treeNodes: CustomTreeNode[] = [];
  selectedNode: CustomTreeNode | undefined;
  canMoveBefore = false;
  canMoveInside = false;
  canMoveAfter = false;
  private treeElement: Tree | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private cfgStateService: CfgStateService,
  ) {
    super(dialogRef, dialogConfig);
    if (!this.dialogConfig.data.entryName) {
      this.close();
      return;
    }
    // this.entryId = this.dialogConfig.data.entryId;
    // this.parentId = this.dialogConfig.data.parentId;
    this.entryName = this.dialogConfig.data.entryName;
    this.treeNodes = PrimeTreeUtil.mapConfigToTreeStructure(this.cfgStateService.getCfgUtil().getCfg());
  }

  @ViewChild('tree')
  set tree(value: Tree) {
    this.treeElement = value;
    window.setTimeout(() => {
      if (this.treeElement && this.entryName) {
        const entryNode = this.treeElement.getNodeWithKey(this.entryName, this.treeElement.getRootNode());
        if (entryNode) {
          entryNode.selectable = false;
        }
      }
    });
  }

  onSelectionChange(node: CustomTreeNode) {
    if (this.entryName === undefined) {
      return;
    }

    const entry = this.cfgStateService.getCfgUtil().getEntryByName<CmsGenericEntry>(this.entryName);
    if (entry === undefined) {
      return;
    }

    this.selectedNode = node;

    this.canMoveInside = CAN_MOVE_TO[entry.cls].indexOf(this.selectedNode.data.cls) >= 0;
    if (this.selectedNode.parent) {
      this.canMoveBefore = this.canMoveAfter = CAN_MOVE_TO[entry.cls].indexOf(this.selectedNode.parent.data.cls) >= 0;
    } else {
      this.canMoveAfter = this.canMoveBefore = false;
    }
  }

  moveBefore(node: CustomTreeNode) {
    if (this.entryName === undefined || node.parent?.key === undefined || node.pos === undefined) {
      return;
    }
    const entryPosition = this.cfgStateService.getCfgUtil().getRelativePosition(this.entryName);
    if (entryPosition === undefined) {
      return;
    }
    if (entryPosition?.pos > node.pos) {
      this.moveTo(node.parent.key, node.pos);
    } else {
      this.moveTo(node.parent.key, node.pos - 1);
    }
  }

  moveAfter(node: CustomTreeNode) {
    if (this.entryName === undefined || node.parent?.key === undefined || node.pos === undefined) {
      return;
    }

    const entryPosition = this.cfgStateService.getCfgUtil().getRelativePosition(this.entryName);
    if (entryPosition === undefined) {
      return;
    }
    if (entryPosition?.pos > node.pos) {
      this.moveTo(node.parent.key, node.pos + 1);
    } else {
      this.moveTo(node.parent.key, node.pos);
    }
  }

  moveInto(node: CustomTreeNode) {
    if (node.key === undefined) {
      return;
    }
    this.moveTo(node.key, 0);
  }

  moveTo(parentId: string, targetPos: number) {
    if (this.entryName === undefined) {
      return;
    }
    this.loading = true;
    const move = {
      name: parentId,
      pos: targetPos,
    } as MovePosition;
    this.cfgStateService.moveEntry(this.entryName, move);
    this.close();
  }

  save() {
    this.close();
  }
}
