import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

/**
 * usage
 * *ngIf="'feature-name' | feature | async"
 */
@Pipe({
  name: 'feature',
})
export class UserFeaturePipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(feature: string): Observable<boolean> {
    return this.userService
      .getFeatures()
      .pipe(map((features) => Array.isArray(features) && features.indexOf(feature) >= 0));
  }
}
