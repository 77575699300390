<kfd-dialog-layout
  (accept)="accept()"
  (reject)="close()"
  data-automationId="date-selection-dialog"
  dialogTitle="Datum auswählen"
>
  <div class="m-2">
    <kfd-date-value [(dateValue)]="dateValue"></kfd-date-value>
  </div>
</kfd-dialog-layout>
