@if (assets$ | loading | async; as assets) {
  <div [kfdBlock]="assets.loading" class="scroll-area flex-1 mx-2">
    @if (assets.value?.length > 0) {
      <div class="grid-wrapper">
        <div class="grid">
          @for (asset of assets.value; track asset._id) {
            <div class="col-6 md:col-4 xl:col-2 flex">
              <div
                class="thumbnail flex-1 flex justify-content-center align-items-center"
                [attr.data-automationId]="'asset-thumbnail-' + asset.name"
                [ngClass]="{ selected: selectedAsset?._id === asset._id }"
                (click)="select(asset)"
              >
                @if (asset.type === 'image') {
                  <img [src]="asset.thumbnailUrl + '?' + cacheBreaker" [alt]="asset.label" />
                } @else {
                  <i class="pi {{ ICON.FILE }} text-4xl"></i>
                }
                <div class="name">{{ asset.label }}</div>
                <p-button
                  class="menu-btn"
                  severity="white"
                  icon="pi {{ ICON.MENU }}"
                  [rounded]="true"
                  [outlined]="true"
                  (click)="selectionItemMenu.toggle($event)"
                ></p-button>
              </div>
            </div>
          }
          <div class="col-6 md:col-4 xl:col-2 flex">
            <div class="add-new-thumb flex-1 flex justify-content-center align-items-center">
              <p-button
                [outlined]="true"
                [rounded]="true"
                [icon]="'pi ' + ICON.UPLOAD"
                label="Neue Datei"
                (click)="newAssetDialog()"
              ></p-button>
            </div>
          </div>
        </div>
      </div>
    } @else if (assets.value?.length === 0) {
      <kfd-empty-msg
        (startCreation)="newAssetDialog()"
        [showAddBtn]="true"
        [btnIcon]="ICON.UPLOAD"
        label="Datei hochladen"
        data-automationId="empty-asset-list-info"
        info="Es sind keine Dateien vorhanden"
      ></kfd-empty-msg>
    }
  </div>
}

<p-menu #selectionItemMenu [model]="selectionMenuItems" [popup]="true"></p-menu>

<p-dialog
  [(visible)]="showAddAssetDialog"
  [closeOnEscape]="true"
  [header]="selectedAsset ? 'Datei bearbeiten' : 'Neue Datei'"
>
  <div data-automationId="add-asset-dialog">
    @if (showAddAssetDialog) {
      <kfd-licence-limit [configOption]="licenceLimitConfigurations" [disabled]="selectedAsset !== undefined">
        <p-fileUpload
          #fileUpload
          (onClear)="selectedFile = undefined"
          (onSelect)="onFileUploadSelection($event)"
          [showUploadButton]="false"
          [showCancelButton]="false"
          [multiple]="false"
          data-automationId="file-upload-selector"
          chooseLabel="Datei auswählen"
          accept="image/*,application/pdf"
          maxFileSize="10000000"
        >
          <ng-template pTemplate="content" let-files>
            @if (selectedFile || selectedAsset) {
              <div class="flex flex-column">
                <label>Bezeichnung</label>
                <input pInputText [(ngModel)]="fileLabel" data-automationId="asset-label-input" />
              </div>
              <div class="flex flex-column mt-2">
                <label>Name</label>
                <input pInputText [(ngModel)]="fileName" data-automationId="asset-name-input" />
              </div>
            } @else {
              <p-message severity="info" text="Bitte wählen Sie eine Datei aus"></p-message>
            }
          </ng-template>
        </p-fileUpload>
        @if (base64FileSize > 1000) {
          <kfd-action-message
            class="mt-2 block"
            severity="error"
            text="Die Datei ist zu groß"
            info="Die Datei darf maximal 1 MB groß sein"
            data-automationId="file-size-error-message"
            [hideActionBtn]="true"
          >
          </kfd-action-message>
        }
        <div class="mt-2 p-fluid flex justify-content-between">
          <p-button
            (click)="showAddAssetDialog = false"
            [outlined]="true"
            data-automationId="btn-close-upload-dialog"
            icon="pi {{ ICON.DECLINE }}"
            label="Abbrechen"
          ></p-button>
          <p-button
            (click)="selectedAsset ? updateAsset() : createAsset()"
            [disabled]="
              (!selectedFile && !selectedAsset) || !fileLabel || !fileName || !base64FileData || base64FileSize > 1000
            "
            [outlined]="true"
            class="ml-2"
            data-automationId="btn-upload-assets"
            icon="pi {{ ICON.ACCEPT }}"
            label="Dateien speichern"
          ></p-button>
        </div>
      </kfd-licence-limit>
    }
  </div>
</p-dialog>

<p-dialog
  [(visible)]="showAssetViewDialog"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [modal]="true"
  header="Datei Anzeigen"
>
  @if (showAssetViewDialog && selectedAsset) {
    <kfd-meta-view [meta]="selectedAsset.meta"></kfd-meta-view>
    <div class="mt-2 asset-view-dialog">
      @if (selectedAsset?.type === 'image') {
        <img [src]="selectedAsset?.url" [alt]="selectedAsset?.name" class="image" />
        <a [href]="selectedAsset.url" target="_blank" class="block mt-2" download>
          <p-button
            [text]="true"
            [rounded]="true"
            data-automationId="btn-download-asset"
            icon="pi {{ ICON.DOWNLOAD }}"
            label="Datei herunterladen"
          ></p-button>
        </a>
      } @else {
        <div>
          <p-message severity="info" text="Keine Vorschau verfügbar"></p-message>
        </div>
        <a [href]="selectedAsset.url" target="_blank" class="block mt-2" download>
          <p-button
            [outlined]="true"
            [rounded]="true"
            data-automationId="btn-download-asset"
            icon="pi {{ ICON.DOWNLOAD }}"
            label="Datei herunterladen"
          ></p-button>
        </a>
      }
    </div>
  }
</p-dialog>

<p-confirmDialog #cd key="confirmDeletion">
  <ng-template let-message pTemplate="headless">
    <div class="flex flex-column p-5 surface-overlay border-round">
      <div>
        <h2 class="m-0">{{ message.header }}</h2>
      </div>
      <div class="mt-4">{{ message.message }}</div>
      <div [ngClass]="{ hidden: message.acceptVisible === true }">
        <kfd-action-message
          (action)="cd.reject(); showAssetUsageDialog()"
          [actionBtnIcon]="ICON.USAGES"
          [actionBtnTooltip]="'Verwendung anzeigen'"
          class="mt-2 block"
          severity="warn"
          text="Die Datei wird noch verwendet"
        ></kfd-action-message>
        <p-checkbox
          #forceDeletionCheckbox
          [(ngModel)]="forceDeletion"
          [binary]="true"
          class="mt-2"
          label="Löschen bestätigen"
        ></p-checkbox>
      </div>

      <div class="flex justify-content-between mt-4">
        <p-button (click)="cd.reject()" [outlined]="true" [rounded]="true" label="Abbrechen"></p-button>
        <p-button
          (click)="cd.accept()"
          [disabled]="message.acceptVisible !== true && !forceDeletionCheckbox.checked()"
          [icon]="'pi ' + ICON.DELETE"
          [outlined]="true"
          [rounded]="true"
          label="Datei Löschen"
          severity="warning"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
