import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ALLOWED_CHILDREN, CLS, CmsConfiguratorFieldGroup, GroupSkeleton, ICON } from '@kfd/core';
import { combineLatestWith, Observable } from 'rxjs';
import { DragDropService } from '../drag-drop/drag-drop.service';
import { map } from 'rxjs/operators';
import { CfgEditorService } from '../../cfg-editor.service';
import { CfgStateService, EntryStatusChange } from '../../cfg-state.service';
import { DragElementHandler } from '../drag-drop/drag-element.handler';

@Component({
  selector: 'kfd-dnd-area-group',
  templateUrl: './dnd-area-group.component.html',
  styleUrls: ['./dnd-area-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaGroupComponent {
  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly allowedChildren = ALLOWED_CHILDREN;

  protected viewData$: Observable<{
    group: CmsConfiguratorFieldGroup;
    groupSkeleton: GroupSkeleton;
    entryStatus: EntryStatusChange;
    selected: boolean;
    editMode: boolean;
  }>;

  private name: string | undefined;
  private dragElementHandler: DragElementHandler | undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly dragDropService: DragDropService,
    protected readonly cfgEditorService: CfgEditorService,
    protected readonly cfgStateService: CfgStateService,
  ) {}

  @Input()
  set groupName(name: string | undefined) {
    this.name = name;
    this.updateGroup();
  }

  @HostBinding('class') get hostClasses() {
    return 'selectable';
  }

  @ViewChild('dragInitiator')
  private set dragInitiator(element: ElementRef<HTMLDivElement> | undefined) {
    if (!element) {
      if (this.dragElementHandler) {
        this.dragElementHandler.destroy();
        this.dragElementHandler = undefined;
      }
      return;
    }
    if (this.dragElementHandler) {
      return;
    }

    new DragElementHandler(this.dragDropService, this.elementRef, element, {
      elementName: this.name,
      parentName: this.cfgStateService.getCfgUtil().getParentEntry(this.name, true).name,
      position: this.cfgStateService.getCfgUtil().getRelativePosition(this.name).pos,
      cls: CLS.FIELD_GROUP_WRAPPER,
      icon: ICON.GROUP,
    });
  }

  public updateGroup(): void {
    if (!this.name) {
      this.viewData$ = undefined;
      return;
    }
    this.viewData$ = this.cfgStateService.onEntryChange<CmsConfiguratorFieldGroup>(this.name, true).pipe(
      combineLatestWith(
        this.cfgStateService.onEntryStatusChange(this.name),
        this.cfgEditorService.onSelectionChange,
        this.cfgEditorService.onEditModeChange,
      ),
      map(([group, entryStatus, selection, editMode]) => ({
        group,
        groupSkeleton: this.cfgStateService.getCfgUtil().getGroupSkeleton(group.entry),
        entryStatus,
        selected: selection && selection.name === this.name,
        editMode,
      })),
    );
  }

  protected select(): void {
    this.cfgEditorService.selectEntry(this.name);
  }
}
