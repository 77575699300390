<div data-automationId="cfg-check-viewer">
  <ng-container *ngIf="checkResults.length > 0">
    <div class="m-1">
      <div class="text-lg font-bold">Hinweise</div>
      <div class="text-xs">Bitte prüfen Sie die Hinweise vor der Veröffentlichung</div>
    </div>
    <ng-container *ngFor="let checkResult of checkResults">
      <p-messages [severity]="checkResult.severity ?? 'none'" class="m-1" styleClass="no-padding">
        <ng-template pTemplate>
          <div
            [attr.data-automationId]="'check-hint-' + checkResult.error + '-for-' + checkResult.name"
            class="flex-1 flex flex-row"
          >
            <div class="flex-1 flex flex-column mr-2">
              <ng-container [ngSwitch]="checkResult.error">
                <ng-container *ngSwitchCase="CheckError.EMPTY_ELEMENT">
                  <div class="text-md font-bold">Leeres Element</div>
                  <div class="text-sm">
                    Das Element <i>{{ checkResult.name | entrylabel }}</i> ist leer und enthält keine Felder.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.REQUIRED_BUT_CONDITIONAL">
                  <div class="text-md font-bold">Pflichtfeld mit Bedingung</div>
                  <div class="text-sm">
                    Das Feld <i>{{ checkResult.name | entrylabel }}</i> ist ein Pflichfeld, wird aber möglicherweise
                    durch Bedingungen ausgeblendet.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.INPUT_AFTER_SUBMIT">
                  <div class="text-md font-bold">Eingabefeld nach dem Absenden</div>
                  <div class="text-sm">
                    Das Feld <i>{{ checkResult.refName | entrylabel }}</i> ist nach dem Absenden-Element
                    <i>{{ checkResult.name | entrylabel }}</i> eingefügt und wird daher nicht mit übertragen.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.INVALID_REFERENCE">
                  <div class="text-md font-bold">Ungültige Referenz</div>
                  <div class="text-sm">
                    Das Feld
                    <i>{{ checkResult.name | entrylabel }}</i> verwendet ein anderes Feld, das nicht exisitiert
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.REFERENCE_TO_FIELD_AFTER">
                  <div class="text-md font-bold">Referenz auf nachfolgendes Feld</div>
                  <div class="text-sm">
                    Das Feld
                    <i>{{ checkResult.name | entrylabel }}</i> verwendet das Feld
                    <i>{{ checkResult.refName | entrylabel }}</i
                    >, das später kommt. Dadurch ist der Wert möglicherweise noch nicht gefüllt.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.REFERENCE_TO_CONDITIONAL">
                  <div class="text-md font-bold">Referenz auf Feld mit Bedingungen</div>
                  <div class="text-sm">
                    Das Feld <i>{{ checkResult.name | entrylabel }}</i> verweist auf das Feld
                    <i>{{ checkResult.refName | entrylabel }}</i> welches Bedingungen hat. Dadurch kann es sein, dass
                    der Wert nicht zur Verfügung steht.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.CONDITION_ON_FIRST_OR_LAST_PAGE">
                  <div class="text-md font-bold">Bedingung auf erste oder letzter Seite</div>
                  <div class="text-sm">
                    Die Seite <i>{{ checkResult.name | entrylabel }}</i> hat Bedingungen. Bedingungen auf der ersten und
                    letzten Seite werden ignoriert da diese immer sichtbar sein müssen.
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="CheckError.MULTIPLE_SUBMIT_BTN">
                  <div class="text-md font-bold">Mehrere Absende-Buttons</div>
                  <div class="text-sm">
                    Das Formular enthält mehrere Elemente mit Absende-Buttons. Ein Formular kann nur einmal abgesendet
                    werden. Es sollte durch Bedingungen sichergestellt sein, das immer nur ein Absende-Button zu sehen
                    ist.
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="text-md font-bold">{{ checkResult.error }}</div>
                </ng-container>
              </ng-container>
            </div>
            <div>
              <button
                (click)="cfgEditorService.selectEntry(checkResult.name, true); checkSelection.emit()"
                class="p-button-text p-button-rounded"
                icon="pi {{ ICON.LINK }}"
                pButton
                type="button"
              ></button>
            </div>
          </div>
        </ng-template>
      </p-messages>
    </ng-container>
  </ng-container>
  <kfd-empty-msg *ngIf="checkResults.length === 0" [infoIcon]="ICON.ACCEPT" info="Keine Probleme gefunden">
  </kfd-empty-msg>
</div>
