@if (form) {
  <ng-container [formGroup]="form">
    <div
      class="p-field"
      data-automationId="field-label"
      [ngClass]="{ invalid: form.get('label')?.touched && form.get('label')?.invalid }"
    >
      <label>Label*</label>
      <input data-automationId="field-label-input" formControlName="label" pInputText required type="text" />
      @if (form.get('label')?.getError('required')) {
        <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
      } @else if (form.get('label')?.getError('minlength')) {
        <small class="p-error">Das Label muss mindestens 4 Zeichen lang sein</small>
      } @else if (form.get('label')?.getError('maxlength')) {
        <small class="p-error">Das Label darf maximal 50 Zeichen lang sein</small>
      }
    </div>
    <div
      class="p-field"
      data-automationId="field-name"
      [ngClass]="{ invalid: form.get('name')?.touched && form.get('name')?.invalid }"
    >
      <label>Name*</label>
      <kfd-toggle-input-field #toggleInputField formControlName="name"></kfd-toggle-input-field>
      @if (form.get('name')?.getError('required')) {
        <small class="p-error">Diese Feld ist ein Pflichtfeld</small>
      } @else if (form.get('name')?.getError('minlength')) {
        <small class="p-error">Der Name muss mindestens 4 Zeichen lang sein</small>
      } @else if (form.get('name')?.getError('maxlength')) {
        <small class="p-error">Der Name darf maximal 30 Zeichen lang sein</small>
      } @else {
        @if (form.get('name')?.errors?.message) {
          <small class="p-error">{{ form.get('name')?.errors.message }}</small>
        }
      }
    </div>
  </ng-container>
}
