<ng-container *ngIf="configuration$ | async as configuration; else loading">
  <ng-container *ngIf="editMode$ | loading | async as editMode">
    <ng-container *ngIf="actionsEnabled$ | async as actionsEnabled">
      <ng-container *ngIf="persistenceRunning$ | loading | async as persistenceRunning">
        <div
          [kfdBlock]="persistenceRunning.value >= persistenceState.RETRY"
          [text]="'Bitte warten'"
          class="flex flex-column flex-1 overflow-hidden"
        >
          <kfd-header-toolbar [headline]="configuration.label" data-automationId="cfg-editor">
            <ng-container topRight>
              <ng-container *ngIf="editMode.value.enabled === true">
                <p-button
                  (click)="cfgChecksOverlay.toggle($event)"
                  *ngIf="cfgEditorService.checkResults.length > 0"
                  [badge]="'' + cfgEditorService.checkResults.length"
                  [disabled]="!actionsEnabled.enabled"
                  [outlined]="true"
                  [rounded]="true"
                  badgeClass="p-badge-warning"
                  class="rounded mx-1"
                  data-automationId="cfg-check-btn-with-warnings"
                  icon="pi {{ ICON.WARN }}"
                  pTooltip="Überprüfen"
                  severity="warning"
                ></p-button>
                <p-button
                  (click)="cfgChecksOverlay.toggle($event)"
                  *ngIf="cfgEditorService.checkResults.length === 0"
                  [disabled]="!actionsEnabled.enabled"
                  [outlined]="true"
                  [rounded]="true"
                  class="mx-1"
                  data-automationId="cfg-check-btn-no-warnings"
                  icon="pi {{ ICON.ACCEPT }}"
                  pTooltip="Überprüfen"
                ></p-button>
                <div class="vertical-line"></div>
              </ng-container>
              <p-button
                (click)="openPreview()"
                [disabled]="!actionsEnabled.enabled || persistenceRunning.value !== persistenceState.FINISHED"
                [outlined]="true"
                [rounded]="true"
                class="mx-1"
                data-automationId="cfg-preview-btn"
                icon="pi {{ ICON.PREVIEW }}"
                pTooltip="Vorschau"
              ></p-button>
              <p-button
                (click)="openPublishing()"
                [disabled]="!actionsEnabled.enabled || persistenceRunning.value !== persistenceState.FINISHED"
                [outlined]="true"
                [rounded]="true"
                class="mx-1"
                data-automationId="cfg-publish-btn"
                icon="pi {{ ICON.PUBLISH }}"
                pTooltip="Veröffentlichen"
              ></p-button>
              <div class="vertical-line"></div>
              <p-button
                (click)="enableEditMode(); editHintOverlay.hide()"
                (visible)="editHintOverlay.show($event)"
                *ngIf="editMode.value.enabled === false"
                [outlined]="true"
                [rounded]="true"
                class="mx-1"
                data-automationId="cfg-btn-editmode"
                icon="pi {{ ICON.EDIT }}"
                pTooltip="Bearbeiten"
                visible
              ></p-button>

              <ng-container *ngIf="editMode.value.enabled === false">
                <p-button
                  (click)="menu.toggle($event)"
                  [disabled]="!actionsEnabled.enabled"
                  [outlined]="true"
                  [rounded]="true"
                  class="mx-1"
                  data-automationId="cfg-viewmode-menu"
                  icon="pi {{ ICON.MENU }}"
                ></p-button>
                <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-menu>
              </ng-container>
              <ng-container *ngIf="editMode.value.enabled">
                <p-button
                  (click)="this.cfgEditorService.createNewElement()"
                  [disabled]="!actionsEnabled.enabled || persistenceRunning.value !== persistenceState.FINISHED"
                  [outlined]="true"
                  [rounded]="true"
                  class="mx-1"
                  data-automationId="main-toolbar-new-element-btn"
                  icon="pi {{ ICON.ADD }}"
                  pTooltip="Neues Element"
                ></p-button>
                <p-button
                  (click)="openSettingsDialog()"
                  [disabled]="!actionsEnabled.enabled"
                  [outlined]="true"
                  [rounded]="true"
                  class="mx-1"
                  data-automationId="cfg-settings-btn"
                  icon="pi {{ ICON.SETTINGS }}"
                  pTooltip="Einstellungen"
                ></p-button>
                <p-button
                  (click)="menu.toggle($event)"
                  [disabled]="!actionsEnabled.enabled"
                  [outlined]="true"
                  [rounded]="true"
                  class="mx-1"
                  data-automationId="cfg-editmode-menu"
                  icon="pi {{ ICON.MENU }}"
                ></p-button>
                <p-menu #menu [model]="editMenuItems" [popup]="true" appendTo="body"></p-menu>
              </ng-container>
            </ng-container>
          </kfd-header-toolbar>
          <ng-container *ngIf="selection$ | loading | async as selection">
            <div class="flex flex-1 flex-row overflow-hidden">
              <div class="panel center">
                <kfd-dnd-area></kfd-dnd-area>
              </div>
              <div
                *ngIf="editMode.value.enabled"
                [ngClass]="{ collapsed: !selection.value && !newEntry }"
                class="panel right"
              >
                <kfd-cfg-settings *ngIf="newEntry === undefined"></kfd-cfg-settings>
                <kfd-cfg-new-entry (cancel)="cfgEditorService.removeNewElement()" *ngIf="newEntry"></kfd-cfg-new-entry>
              </div>
            </div>
          </ng-container>
          <div class="toolbar px-2 py-1 flex justify-content-between">
            <div class="flex flex-row">
              <button
                (click)="dndPreviewValuesOverlay.toggle($event)"
                class="p-button-text p-button-rounded mx-1"
                data-automationId="preview-stored-values"
                icon="pi {{ ICON.DATABASE }}"
                pButton
                pTooltip="Werte anzeigen"
                type="button"
              ></button>
            </div>
            <div class="flex flex-row">
              <ng-container *ngIf="editMode.value.enabled">
                <ng-container *ngIf="persistenceRunning$ | loading | async as persistenceRunning">
                  <div class="flex flex-row align-items-center mx-2">
                    <div
                      *ngIf="persistenceRunning.value === persistenceState.RUNNING"
                      class=""
                      pTooltip="Die Änderungen werden gespeichert"
                    >
                      <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
                      <i class="pi {{ ICON.SYNC }} text-lg"></i>
                    </div>
                    <div
                      *ngIf="persistenceRunning.value === persistenceState.RETRY"
                      class="color-warning"
                      pTooltip="Die Änderungen können gerade nicht gespeichert werden"
                    >
                      <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
                      <i class="pi {{ ICON.WARN }} text-lg"></i>
                    </div>
                    <div
                      *ngIf="persistenceRunning.value === persistenceState.ERROR"
                      class="color-error"
                      pTooltip="Beim Speichern ist ein Fehler aufgetreten"
                    >
                      <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
                      <i class="pi {{ ICON.WARN }} text-lg"></i>
                    </div>
                    <div
                      *ngIf="persistenceRunning.value === persistenceState.FINISHED"
                      class="color-success"
                      pTooltip="Alle Änderungen sind gespeichert"
                    >
                      <i class="pi {{ ICON.SAVE }} text-lg mr-1"></i>
                      <i class="pi {{ ICON.ACCEPT }} text-lg"></i>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <kfd-placeholder-msg
    [infoIcon]="ICON.CONFIGURATIONS"
    class="flex-1 justify-content-center editor-element"
    infoText="Konfiguration wird geladen"
  ></kfd-placeholder-msg>
</ng-template>

<p-overlayPanel #editHintOverlay styleClass="transparent padding-sm">
  <ng-template pTemplate="content">
    <span class="text-sm">Hier klicken, um in die<br />Bearbeitungs-Ansicht zu wechseln</span>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #cfgChecksOverlay styleClass="no-padding large-content">
  <kfd-check-viewer (checkSelection)="cfgChecksOverlay.hide()" [checkResults]="cfgEditorService.checkResults" />
</p-overlayPanel>

<p-overlayPanel
  #dndPreviewValuesOverlay
  (onShow)="dndPreviewValuesOverlayOpen = true"
  (onHide)="dndPreviewValuesOverlayOpen = false"
  styleClass="no-padding large-content"
>
  <p-button
    class="value-close-icon"
    (click)="dndPreviewValuesOverlay.hide()"
    data-automationId="close-value-list"
    icon="pi {{ ICON.DECLINE }}"
    [rounded]="true"
    [outlined]="true"
  ></p-button>
  <h2 class="ml-4">Benutzereingaben</h2>
  <div class="p-fluid m-2 value-list">
    @if (dndPreviewValuesOverlayOpen) {
      <kfd-configuration-value-editor data-automationId="kfd-value-list"></kfd-configuration-value-editor>
    }
  </div>
</p-overlayPanel>
