import { Component, Input } from '@angular/core';
import { Calculation, CLS, ICON } from '@kfd/core';

@Component({
  selector: 'kfd-calculation-viewer',
  templateUrl: './calculation-viewer.component.html',
  styleUrls: ['./calculation-viewer.component.scss'],
})
export class CalculationViewerComponent {
  public cls = CLS;
  public icon = ICON;

  private _calculation: Calculation | undefined;

  public get calculation(): Calculation | undefined {
    return this._calculation;
  }

  @Input()
  public set calculation(value: Calculation | undefined) {
    this._calculation = value;
  }
}
