import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'kfd-dnd-scroller',
  templateUrl: './dnd-scroller.component.html',
  styleUrls: ['./dnd-scroller.component.scss'],
})
export class DndScrollerComponent implements AfterViewInit {
  public toScroll = 0;
  public scrollTop = 0;
  @ViewChild('contentElement')
  protected contentElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('contentHeightElement')
  protected contentHeightElement: ElementRef<HTMLDivElement> | undefined;

  private _scrollerElement: ElementRef<HTMLDivElement> | undefined;

  @ViewChild('scrollerElement')
  public set scrollerElement(value: ElementRef<HTMLDivElement> | undefined) {
    this._scrollerElement = value;
    this._scrollerElement?.nativeElement.addEventListener('scroll', (event) => {
      this.scrollTop = (event.target as HTMLElement).scrollTop;
    });
  }

  @HostListener('wheel', ['$event'])
  public onMouseWheel(event: WheelEvent) {
    if (this._scrollerElement) {
      this._scrollerElement.nativeElement.scrollBy(0, event.deltaY);
    }
  }

  public ngAfterViewInit(): void {
    if (!this.contentElement || !this._scrollerElement || !this.contentHeightElement) {
      return;
    }
    const contentElementHeight = this.contentElement.nativeElement.clientHeight;
    this.contentHeightElement.nativeElement.style.height = contentElementHeight + 'px';
  }

  protected triggerScrolling() {
    if (this._scrollerElement && this.toScroll) {
      this._scrollerElement.nativeElement.scrollBy(0, this.toScroll);
    }
  }
}
