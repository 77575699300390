<kfd-dialog-layout
  (accept)="save()"
  (reject)="close()"
  [acceptBtnLabel]="isFirstOrLast ? 'OK' : 'Speichern'"
  [acceptDisabled]="!isValid"
  [loading]="loading"
  dialogTitle="Bedingungen"
  docuPageId="conditions"
>
  <ng-container *ngIf="isFirstOrLast; else conditionTpl">
    <p-message severity="info" text="Die erste und letzte Seite kann nicht ausgeblendet werden."></p-message>
    <kfd-empty-msg
      (startCreation)="removeConditions()"
      *ngIf="conditionGroup?.conditions.length > 0"
      [btnIcon]="ICON.DELETE"
      [infoIcon]="ICON.WARN"
      [showAddBtn]="true"
      info="Die Seite enthält aktuell ungültige Bedingungen"
      label="Bedingungen entfernen"
    >
    </kfd-empty-msg>
  </ng-container>
</kfd-dialog-layout>

<ng-template #conditionTpl>
  <!--  <div class="p-fluid">-->
  <!--    &lt;!&ndash;    <p>&ndash;&gt;-->
  <!--    &lt;!&ndash;      Das Element wird nur dann angezeigt, wenn die Bedingungen erfüllt sind.&ndash;&gt;-->
  <!--    &lt;!&ndash;    </p>&ndash;&gt;-->
  <!--  </div>-->
  <kfd-condition-group
    (groupChange)="groupChange($event)"
    (isValid)="isValid = $event"
    [group]="conditionGroup"
  ></kfd-condition-group>
</ng-template>
