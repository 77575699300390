import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService, WebEndpointService } from '@kfd/web-core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { cmsServiceEP, ExportData } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class ImExportService {
  private httpService: BaseHttpService;

  constructor(
    httpClient: HttpClient,
    private readonly webEndpointService: WebEndpointService,
  ) {
    this.httpService = new BaseHttpService(httpClient, environment.services.cms);
  }

  public exportConfiguration(projectId: string, configurationId: string): Observable<ExportData> {
    return this.webEndpointService.get(cmsServiceEP.imexport.export, [projectId, configurationId]);
  }

  public import(
    projectId: string,
    configurationDataExport: ExportData,
  ): Observable<typeof cmsServiceEP.imexport.import.response> {
    return this.webEndpointService.post(cmsServiceEP.imexport.import, [projectId], configurationDataExport);
  }

  public validate(
    projectId: string,
    configurationDataExport: ExportData,
  ): Observable<typeof cmsServiceEP.imexport.validate.response> {
    return this.webEndpointService.post(cmsServiceEP.imexport.validate, [projectId], configurationDataExport);
  }
}
