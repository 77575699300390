import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseDialogComponent } from '../base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { KFD_WEB_APP_ROUTES } from '@kfd/core';

@Component({
  selector: 'kfd-fullscreen-login-dialog',
  templateUrl: './fullscreen-login-dialog.component.html',
  styleUrls: ['./fullscreen-login-dialog.component.scss'],
})
export class FullscreenLoginDialogComponent extends BaseDialogComponent {
  environment = environment;
  protected readonly KFD_WEB_APP_ROUTES = KFD_WEB_APP_ROUTES;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
  ) {
    super(dialogRef, dialogConfig);
  }
}
