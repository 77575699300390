import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '@kfd/web-core';
import { LoggingService } from '../../services/logging.service';
import { BaseDialogComponent } from '../base-dialog.component';
import * as Bowser from 'bowser';

@Component({
  selector: 'kfd-error-reporting-dialog',
  templateUrl: './error-reporting-dialog.component.html',
  styleUrls: ['./error-reporting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ErrorReportingDialogComponent extends BaseDialogComponent {
  loading = false;

  acceptBtnLabel = ['Fehler melden', 'Bericht absenden'];

  step = 0;

  code: string | undefined;

  formData = {
    username: '',
    useremail: '',
    client: '',
    errormessage: '',
    logs: '',
  };

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
    private authService: AuthService,
  ) {
    super(dialogRef, dialogConfig);

    authService.currentUser().subscribe((user) => {
      if (user) {
        this.formData.username = user.name;
        this.formData.useremail = user.email;
      }
    });
    this.readLogs();

    const output = Bowser.parse(window.navigator.userAgent);
    this.formData.client = `${output.browser.name} (${output.browser.version}) // ${output.os.name} (${output.os.versionName}) // ${output.platform.type}`;
  }

  readLogs() {
    const logEntries = this.loggingService.getLogs().reverse().slice(1, 11);
    let lastErrorMessage = '';
    for (const log of logEntries) {
      if (log.stack && log.message) {
        lastErrorMessage = `${log.message} - ${log.stack.slice(0, 50)}...`;
        break;
      }
      if (log.type === 'error' && log.message) {
        lastErrorMessage = `${log.message}`;
        if (log.stack) {
          lastErrorMessage += `- ${log.stack.slice(0, 50)}...`;
        }
        break;
      }
    }

    this.formData.errormessage = lastErrorMessage;
    this.formData.logs = JSON.stringify(logEntries);
  }

  next() {
    if (this.step === 0) {
      this.step++;
    }
  }

  submitted(code: string) {
    this.code = code;
    this.step = 2;
  }
}
