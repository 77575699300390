<kfd-dialog-layout
  (accept)="submit()"
  (reject)="close()"
  [acceptBtnLabel]="!baseData?._id ? 'Erstellen' : 'Speichern'"
  [acceptDisabled]="licenceLimitReached"
  [disableScrolling]="true"
  [loading]="loading"
  data-automationId="base-data-edit-dialog"
  dialogTitle="Datensatz erstellen"
  docuPageId="base-data"
  docuSectionId="create"
>
  <kfd-licence-limit
    (limitReached)="licenceLimitReached = $event"
    (pageChange)="close()"
    [configOption]="licenceLimitData"
  ></kfd-licence-limit>
  @if (selectedTemplate || baseData?.templateName) {
    <div class="flex align-items-center">
      @if (selectedTemplate) {
        Vorlage: <span data-automationId="template-label">{{ selectedTemplate.label }}</span>

        @if (templateValidationMessages && templateValidationMessages.length > 0) {
          <p-button
            class="ml-2 color-warning"
            icon="pi {{ ICON.WARN }}"
            pTooltip="Warnungen anzeigen"
            [text]="true"
            (click)="templateValidationMessageOverlay.toggle($event)"
          ></p-button>
        }
      } @else if (baseData?.templateName) {
        <i>Vorlage "{{ baseData.templateName }}" ist nicht vorhanden.</i>
      }

      <p-button
        class="ml-2"
        icon="pi {{ ICON.DELETE }}"
        label="Template entfernen"
        [text]="true"
        (click)="removeTemplate()"
      ></p-button>
    </div>
  } @else {
    <kfd-basedata-template-selection
      [template]="selectedTemplate"
      (templateChange)="selectTemplate($event)"
    ></kfd-basedata-template-selection>
  }

  <p-overlayPanel #templateValidationMessageOverlay>
    @if (templateValidationMessages && templateValidationMessages.length > 0) {
      Der Datensatz stimmt nicht mit der Vorlage überein.
      <div class="mt-2">
        @for (warning of templateValidationMessages; track warning) {
          <p-message severity="info" text="{{ warning }}"></p-message>
        }
      </div>
      Zum Speichern müssen die Warnungen behoben werden.
    }
  </p-overlayPanel>

  @if (baseDataForm) {
    <p-tabView styleClass="full-height" class="mt-2 block">
      <p-tabPanel>
        <ng-template pTemplate="header">
          @if (formGroupDataInvalid) {
            <span class="pi {{ ICON.WARN }} color-warning mr-1"></span>
          }
          <span>Daten</span>
        </ng-template>
        <form (ngSubmit)="submit()" [formGroup]="baseDataForm">
          <div class="p-fluid">
            <div class="p-field">
              <kfd-label-name-input [form]="baseDataForm" [manualNameChange]="!!baseData?._id"></kfd-label-name-input>
            </div>
            <div class="p-field">
              <label>Tags</label>
              <kfd-tag-input [allowCreation]="true" [projectId]="projectId" formControlName="tags"></kfd-tag-input>
            </div>
            <div class="p-field">
              <label>Bild</label>
              <kfd-asset-selector formControlName="image"></kfd-asset-selector>
            </div>
          </div>
        </form>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div data-automationId="basedata-values">
            @if (formGroupValuesInvalid) {
              <span class="pi {{ ICON.WARN }} color-warning mr-1"></span>
            }
            @if (valueForms.length > 0) {
              <span
                [value]="'' + valueForms.length"
                class="badge-small badge-outline badge-pos-70"
                data-automationId="basedata-value-count"
                pBadge
                >Werte</span
              >
            } @else {
              <span>Werte</span>
            }
          </div>
        </ng-template>

        <div class="p-fluid" data-automationId="value-list">
          @if (valueForms.length > 0) {
            <p-card
              *ngFor="let valueForm of valueForms"
              [formGroup]="valueForm.form"
              class="my-2 value-form"
              [attr.data-automationId]="'value-' + valueForm.form.get('name').value"
            >
              @if (!valueForm.template) {
                <p-button
                  data-automationId="button-template-remove-value"
                  pTooltip="Wert löschen"
                  icon="pi {{ ICON.DELETE }}"
                  class="delete-btn"
                  [rounded]="true"
                  [text]="true"
                  (click)="removeValueById(valueForm.form.get('id').value)"
                ></p-button>
              } @else {
                @if (selectedTemplate) {
                  <p-tag
                    class="template-tag"
                    [value]="selectedTemplate.label"
                    [rounded]="true"
                    data-automationId="value-from-template-tag"
                  ></p-tag>
                }
              }
              <div class="p-field">
                @if (!valueForm.template) {
                  <kfd-label-name-input [form]="valueForm.form"></kfd-label-name-input>
                } @else {
                  <strong>{{ valueForm.form.get('label').value }}</strong>
                }
              </div>
              <div class="p-field">
                <label>Wert*</label>
                <kfd-data-value-select
                  [allowedTypes]="
                    valueForm.template ? [valueForm.form.get(['inputValue']).value.type] : INPUT_VALUE_TYPES
                  "
                  formControlName="inputValue"
                ></kfd-data-value-select>
              </div>
              @if (valueForm.form.touched && valueForm.form.invalid) {
                @if (valueForm.form.get('inputValue').getError('required')) {
                  <small class="p-error" data-automationId="inputvalue-error-no-value"
                    >Es muss ein Wert eingetragen werden.</small
                  >
                } @else {
                  <small class="p-error">Fehlerhafter Wert</small>
                }
              }
            </p-card>

            <div class="flex justify-content-end">
              <p-button
                data-automationId="button-template-add-value"
                pTooltip="Wert hinzufügen"
                class="mt-2"
                icon="pi {{ ICON.ADD }}"
                [rounded]="true"
                [outlined]="true"
                (click)="addValue()"
              ></p-button>
            </div>
          } @else {
            <kfd-placeholder-msg
              infoTitle="Kein Wert vorhanden"
              infoText=""
              [infoIcon]="ICON.TEMPLATE"
              [btnVisible]="true"
              btnLabel="Wert hinzufügen"
              (btnClick)="addValue()"
            ></kfd-placeholder-msg>
          }
        </div>
      </p-tabPanel>
    </p-tabView>
  }

  <div *ngIf="!baseData?._id" class="mr-2" footerRight>
    <p-checkbox
      [(ngModel)]="newAfterSave"
      binary="true"
      data-automationId="checkbox-create-another"
      inputId="newAfterSave"
    ></p-checkbox>
    <label>&nbsp;Weiteren Eintrag erstellen</label>
  </div>
</kfd-dialog-layout>
