<kfd-dialog-layout
  (accept)="acceptDataHandler()"
  (reject)="close()"
  [kfdBlock]="loading"
  [showRejectBtn]="true"
  acceptBtnLabel="Übernehmen"
  data-automationId="basedata-handler-dialog"
  dialogTitle="Daten auswählen"
>
  <div *ngIf="dataHandler" class="data-values-config-overlay">
    <div class="p-field">
      <label> Werte </label>
      <p-dropdown
        (ngModelChange)="setDataHandler($event)"
        [ngModel]="dataHandler.type"
        [options]="dataOptions"
        appendTo="body"
        data-automationId="data-handler-type"
      ></p-dropdown>
    </div>

    @if (dataHandler.type === dataHandlerTypes.LIST) {
      <div class="p-field">
        <p-orderList
          #orderList
          (onReorder)="selectedDataToConfig()"
          [dragdrop]="true"
          [trackBy]="selectedDataTrackBy"
          [value]="selectedData"
          controlsPosition="right"
        >
          <ng-template let-data pTemplate="item">
            <kfd-split-row>
              <div left>
                <ng-container *ngIf="data.value && data.value.type !== expectedDataType">
                  <p-tag icon="pi pi-exclamation-triangle" severity="warning" value="Falscher Datentyp"></p-tag>
                </ng-container>
                {{ data.label }}
              </div>
              <div right>
                <button
                  (click)="removeDataEntry(data.name)"
                  class="p-button-text p-button-inline-text"
                  icon="pi pi-times"
                  pButton
                  type="button"
                ></button>
              </div>
            </kfd-split-row>
          </ng-template>
        </p-orderList>
        <div class="flex justify-content-between mt-2">
          <button
            (click)="addData()"
            class="p-button-text p-button-rounded"
            icon="pi {{ ICON.EDIT }}"
            label="Bearbeiten"
            pButton
            type="button"
          ></button>
          <button
            (click)="createDataEntryOverlay.show($event)"
            class="p-button-text p-button-rounded"
            icon="pi {{ ICON.ADD }}"
            label="Neuer Eintrag"
            pButton
            type="button"
          ></button>
        </div>
      </div>
    }

    @if (dataHandler.type === dataHandlerTypes.DYNAMIC) {
      <h2>Stammdaten Filter:</h2>
      <p>Alle Stammdaten Anzeigen die alle folgende Bedingungen erfüllen:</p>
      <div class="p-field mt-2">
        <label>Mit Vorlage</label>
        <kfd-basedata-template-selection
          (templateChange)="onTemplateSelectionChange($event)"
          [templateName]="dataHandler.templateName"
        ></kfd-basedata-template-selection>
      </div>
      <div class="p-field">
        <label>Mit Tags</label>
        <kfd-tag-input
          (tagSelectionChange)="onTagSelectionChange($event)"
          [tagSelection]="dataHandler.tags"
          [projectId]="ctx.projectId"
        ></kfd-tag-input>
      </div>

      @if (dynamicHandlerData$ | async; as dynamicHandlerData) {
        <div class="p-field">
          <label>Sortierung</label>
          <kfd-sort-input
            [optionList]="sortOptions$ | async"
            [sort]="dataHandler.sort"
            (sortChange)="dataHandler.sort = $event"
          ></kfd-sort-input>
        </div>

        <div class="mt-2">Datensätze: {{ dynamicHandlerData.length }}</div>
      }
    }
  </div>

  @if (error) {
    <div class="mt-2">
      @switch (error) {
        @case ('dynamic-one-filter-required') {
          <p-message
            severity="warn"
            text="Es muss mindestens eine Filteroption (Vorlage oder Tags) ausgewählt werden."
          ></p-message>
        }
        @default {
          <p-message severity="error" text="Ein unbekannter Fehler ist aufgetreten."></p-message>
        }
      }
    </div>
  }
</kfd-dialog-layout>

<p-overlayPanel #createDataEntryOverlay>
  <kfd-data-entry-basic-form
    (dataCreated)="dataEntryCreated($event); createDataEntryOverlay.hide()"
  ></kfd-data-entry-basic-form>
</p-overlayPanel>
