<ng-container *ngIf="dndActive$ | loading | async as dndActive">
  <div
    [accepts]="accepts"
    [ngClass]="{
      visible: dndActive.value,
      vertical: vertical,
      horizontal: !vertical
    }"
    [parent]="parent"
    [position]="position"
    class="drop-zone"
    dropZone
  >
    <i class="icon pi {{ ICON.DROP }}"></i>
    <div class="drop-area"></div>
  </div>

  <ng-container *ngIf="!dndActive.value">
    <ng-content></ng-content>
  </ng-container>
</ng-container>
