import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseDialogComponent } from '../base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LoggingService } from '../../services/logging.service';
import { WebCoreModule } from '@kfd/web-core';

@Component({
  selector: 'kfd-server-error-dialog',
  standalone: true,
  imports: [CommonModule, WebCoreModule],
  templateUrl: './server-error-dialog.component.html',
  styleUrl: './server-error-dialog.component.scss',
})
export class ServerErrorDialogComponent extends BaseDialogComponent {
  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
  }

  protected reload() {
    window.location.reload();
  }
}
