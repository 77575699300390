import { Component, Input } from '@angular/core';
import { CmsConfigurator, ICON } from '@kfd/core';
import { CmsContextService } from '../../../services/cms-context.service';
import { environment } from '../../../../environments/environment';
import { CmsDialogService } from '../../../services/cms-dialog.service';
import { ShowConfigurationDialogComponent } from '../../../shared/components/show-configuration-dialog/show-configuration-dialog.component';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { CfgStateService } from '../../cfg-state.service';
import { mergeMap } from 'rxjs';

// import { DataUsageInfoDialogComponent } from '../../configurator/data-usage-info-dialog/data-usage-info-dialog.component';

@Component({
  selector: 'kfd-configuration-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.scss'],
})
export class PublishingComponent {
  environment = environment;
  loading = false;

  @Input()
  configuration: CmsConfigurator | undefined;
  protected readonly ICON = ICON;

  constructor(
    private apiConfigurationService: ApiConfigurationService,
    private cfgStateService: CfgStateService,
    private cmsDialogService: CmsDialogService,
    public ctx: CmsContextService,
  ) {}

  openDraft() {
    if (this.configuration === undefined) {
      return;
    }
    this.cmsDialogService.open(
      ShowConfigurationDialogComponent,
      {
        projectId: this.ctx.projectId,
        configurationId: this.ctx.configuratorId,
        preview: true,
        versions: this.configuration.versions,
      },
      {
        dismissibleMask: false,
        fullHeight: true,
      },
    );
  }

  openPublished() {
    if (this.configuration === undefined) {
      return;
    }
    this.cmsDialogService.open(
      ShowConfigurationDialogComponent,
      {
        projectId: this.ctx.projectId,
        configurationId: this.ctx.configuratorId,
        preview: false,
        versions: this.configuration.versions,
      },
      {
        dismissibleMask: false,
        fullHeight: true,
      },
    );
  }

  draft() {
    this.loading = true;
    this.apiConfigurationService
      .draft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          // if (this.configuration) {
          //   // this.configurationService.resetEntry(this.configuration._id);
          // }
          this.configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  publish() {
    this.loading = true;
    this.apiConfigurationService
      .publish(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this.configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  removeDraft() {
    this.loading = true;
    this.apiConfigurationService
      .removeDraft(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this.configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }

  removePublished() {
    this.loading = true;
    this.apiConfigurationService
      .removePublished(this.ctx.projectId, this.ctx.configuratorId)
      .pipe(mergeMap(() => this.cfgStateService.refresh()))
      .subscribe({
        next: () => {
          this.loading = false;
          this.configuration = this.cfgStateService.getCfgUtil().getCfg();
        },
        error: (e) => {
          this.loading = false;
          throw e;
        },
      });
  }
}
