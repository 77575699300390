import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Asset } from '@kfd/core';

@Component({
  selector: 'kfd-asset-manager-dialog',
  templateUrl: './asset-manager-dialog.component.html',
  styleUrl: './asset-manager-dialog.component.scss',
})
export class AssetManagerDialogComponent extends BaseDialogComponent {
  protected asset: Asset | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
  ) {
    super(dialogRef, dialogConfig);
  }

  protected selectAsset() {
    this.dialogRef.close(this.asset);
  }
}
