<div *ngIf="config" class="p-fluid">
  <kfd-required-cfg-setting (valueChange)="change('required', $event)" [value]="config.required" class="mt-2">
  </kfd-required-cfg-setting>

  <kfd-disabled-cfg-setting (valueChange)="change('disabled', $event)" [value]="config.disabled" class="mt-2">
  </kfd-disabled-cfg-setting>

  <div class="horizontal-line"></div>

  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="change('multiline', $event.checked)"
      [ngModel]="config.multiline"
      binary="true"
      data-automationId="config-text-multiline"
      label="Mehrzeilig"
      pTooltip="Erlaubt das Einfügen von Zeilenumbrüchen und ändert die Darstellung in ein höheres Eingabefeld"
    ></p-checkbox>
  </div>

  <div [ngClass]="{ disabled: config.multiline }" class="p-field">
    <label> Feldtyp </label>
    <p-dropdown
      (onChange)="change('validation', $event.value)"
      [disabled]="config.multiline"
      [ngModel]="config.validation"
      [options]="validationOptions"
      appendTo="body"
      data-automationId="config-text-type"
      pTooltip="Der Feldtyp beeinflusst welche Werte eingegeben werden können. Auf mobilen Endgeräten ändert sich hierdurch ggf. auch die Darstellung der Tastatur."
    >
    </p-dropdown>
  </div>
  <kfd-toggle-setting
    (disable)="change('default', undefined)"
    (enable)="changeValue('default', defaultValue)"
    [object]="config"
    [property]="'default'"
    class="p-field"
    data-automationId="config-text-default"
    label="Standardwert verwenden"
  >
    <kfd-data-value
      (valueChange)="changeValue('default', $event)"
      [type]="dataValueType.STRING"
      [value]="config.default"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <kfd-toggle-setting
    (disable)="change('min', undefined)"
    (enable)="changeValue('min', defaultMinValue)"
    [object]="config"
    [property]="'min'"
    class="p-field"
    data-automationId="config-text-min"
    label="Mindestlänge festlegen"
  >
    <kfd-data-value
      (valueChange)="changeValue('min', $event)"
      [properties]="{ showButtons: true, max: config.max?.value }"
      [type]="dataValueType.NUMERIC"
      [value]="config.min"
    ></kfd-data-value>
  </kfd-toggle-setting>
  <kfd-toggle-setting
    (disable)="change('max', undefined)"
    (enable)="changeValue('max', defaultMaxValue)"
    [object]="config"
    [property]="'max'"
    class="p-field"
    data-automationId="config-text-max"
    label="Maximale Länge festlegen"
  >
    <kfd-data-value
      (valueChange)="changeValue('max', $event)"
      [properties]="{ showButtons: true, min: config.min?.value }"
      [type]="dataValueType.NUMERIC"
      [value]="config.max"
    ></kfd-data-value>
  </kfd-toggle-setting>
</div>
