export class UnauthorizedError extends Error {}

export class Exception extends Error {
  constructor(
    public message: string,
    public details?: unknown,
  ) {
    super(message);
  }
}
