import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kfd-required-cfg-setting',
  templateUrl: './required-cfg-setting.component.html',
  styleUrls: ['./required-cfg-setting.component.scss'],
})
export class RequiredCfgSettingComponent {
  @Output()
  public valueChange = new EventEmitter<boolean>();

  private _value = false;

  public get value(): boolean {
    return this._value;
  }

  @Input()
  public set value(value: boolean) {
    this._value = value;
  }
}
