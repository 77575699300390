<i *ngIf="showInfoIcon === true" class="icon pi {{ infoIcon }}"></i>
<ng-container *ngIf="info">
  <div class="font-italic text-gray-600 text-center mt-2">{{ info }}</div>
</ng-container>
@if (showAddBtn || btnLink) {
  @if (btnLink) {
    <p-button
      [disabled]="btnDisabled"
      [label]="label"
      [rounded]="true"
      [outlined]="true"
      [routerLink]="btnLink"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  } @else {
    <p-button
      (click)="startCreation.emit()"
      [disabled]="btnDisabled"
      [label]="label"
      [rounded]="true"
      [outlined]="true"
      data-automationId="empty-msg-add-btn"
      class="mt-2"
      icon="pi {{ btnIcon }}"
    ></p-button>
  }
}
