import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ICON } from '@kfd/core';

@Component({
  selector: 'kfd-editor-help-dialog',
  templateUrl: './editor-help-dialog.component.html',
  styleUrls: ['./editor-help-dialog.component.scss'],
})
export class EditorHelpDialogComponent extends BaseDialogComponent {
  public icon = ICON;
  public activeIndex = 0;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
  ) {
    super(dialogRef, dialogConfig);
  }
}
