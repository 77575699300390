@if (meta) {
  <div class="text-xs">
    @if (meta.created) {
      <div>
        Erstellt am <strong pTooltip="{{ meta.updated | date: 'long' }}">{{ meta.created | date }}</strong> von
        <strong pTooltip="{{ meta.createdBy }}">{{ meta.createdBy | username: '(unbekannt)' | async }}</strong>
      </div>
    }

    @if (meta.updated && meta.updated.toString() !== meta.created.toString()) {
      <div>
        Zuletzt Geändert am <strong pTooltip="{{ meta.updated | date: 'long' }}">{{ meta.updated | date }}</strong> von
        <strong pTooltip="{{ meta.updatedBy }}">{{ meta.updatedBy | username: '(unbekannt)' | async }}</strong>
      </div>
    }
  </div>
}
