<kfd-toggle-setting
  (disable)="disable()"
  (enable)="value = ''"
  [object]="valueObject"
  [property]="'hint'"
  class="p-field"
  label="Hilfe Text"
  pTooltip="Der Hilfe Text wird als Hinweis unterhalb des Feldes angezeigt und dient zur näheren Erläuterung."
>
  <input
    #hintInput
    (change)="valueChange.emit(hintInput.value)"
    *ngIf="valueObject.hint !== undefined"
    [(ngModel)]="valueObject.hint"
    maxlength="200"
    pInputText
    type="text"
  />
</kfd-toggle-setting>
