import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ALLOWED_CHILDREN, CfgSkeleton, CLS, ICON, ProjectCI, projectCiToVariables } from '@kfd/core';
import { combineLatestWith, Observable } from 'rxjs';
import { CfgStateService } from '../../cfg-state.service';
import { CfgContextService, ConfigService, ConfigurationService, DATA_PROVIDER, SettingsService } from '@kfd/cfg-core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CalculationService,
  ConfigurationConditionService,
  ConfigurationFieldRefResolver,
  ConfigurationValidationService,
  EventService,
  PaginationService,
} from '../../../../../../../libs/cfg-core/src/lib/configuration/service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationDataService } from '../../../services/configuration-data.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { ProjectService } from '../../../services/project.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'kfd-dnd-area-configuration',
  templateUrl: './dnd-area-configuration.component.html',
  styleUrls: ['../../../../../../../libs/cfg-core/src/theme.scss', './dnd-area-configuration.component.scss'],
  providers: [
    SettingsService,
    ConfigurationService,
    CalculationService,
    ConfigurationFieldRefResolver,
    ConfigurationValidationService,
    ConfigurationConditionService,
    CfgContextService,
    PaginationService,
    EventService,
    {
      provide: DATA_PROVIDER,
      useClass: ConfigurationDataService,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DndAreaConfigurationComponent implements OnInit {
  @Input()
  public pageWidth = 0;

  @Input()
  public pageHeight = 0;

  protected readonly ICON = ICON;
  protected readonly CLS = CLS;
  protected readonly allowedChildren = ALLOWED_CHILDREN;

  protected viewData$:
    | Observable<{
        cfgSkeleton: CfgSkeleton;
      }>
    | undefined;

  constructor(
    private elementRef: ElementRef,
    private readonly cfgStateService: CfgStateService,
    private readonly cfgConfigurationService: ConfigurationService,
    private readonly cfgConfigService: ConfigService,
    private readonly ctx: CmsContextService,
    private readonly cfgCtx: CfgContextService,
    private readonly projectService: ProjectService,
  ) {}

  public ngOnInit() {
    this.cfgCtx.projectId = this.ctx.projectId;
    this.cfgConfigService.embedded = false;

    this.viewData$ = this.cfgStateService.onConfigurationChange.pipe(
      combineLatestWith(this.cfgStateService.onStructureChange),
      tap(() => {
        if (this.cfgStateService.getCfgUtil().configuration) {
          this.cfgCtx.configuratorId = this.cfgStateService.getCfgUtil().configuration._id.toString();
          this.cfgConfigurationService.configuration = this.cfgStateService.getCfgUtil().configuration;
        }
      }),
      map(([, cfgSkeleton]) => ({
        cfgSkeleton,
      })),
    );

    this.projectService.getProject(this.ctx.projectId).subscribe((project) => {
      this.applyStyles(project.ci);
    });
  }

  private applyStyles(projectCI: ProjectCI | undefined) {
    const colors: Record<string, string> = projectCiToVariables(projectCI);
    for (const color of Object.entries(colors)) {
      this.elementRef.nativeElement.style.setProperty(color[0], color[1]);
    }
  }
}
