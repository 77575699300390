import { Component, ViewChild } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CfgStateService, PersistenceState } from '../../cfg-state.service';
import {
  CmsConfigurator,
  Field,
  FIELD_TYPES,
  JsonPatch,
  ObjectUtil,
  RequestStatus,
  TEXTFIELD_VALIDATIONS,
  USER_SCOPES,
} from '@kfd/core';
import { ApiConfigurationService } from '../../../services/api/api-configuration.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { TwoStepInputComponent } from '@kfd/web-core';
import { Observable } from 'rxjs';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'kfd-cfg-settings-dialog',
  templateUrl: './cfg-settings-dialog.component.html',
  styleUrls: ['./cfg-settings-dialog.component.scss'],
})
export class CfgSettingsDialogComponent extends BaseDialogComponent {
  protected fieldTypes = FIELD_TYPES;
  protected textFields: Field[] = [];
  protected textFieldValidations = TEXTFIELD_VALIDATIONS;
  protected configurator: CmsConfigurator | undefined;
  protected nameEditMode = false;
  protected codeEditMode = false;
  protected notUniqueName = '';
  protected notUniqueCode = '';
  protected acceptDisabled = true;
  protected $customerRequestStatusList: Observable<RequestStatus[]>;
  protected readonly userScopes = USER_SCOPES;
  @ViewChild('nameInput')
  private nameInput: TwoStepInputComponent | undefined;
  @ViewChild('codeInput')
  private codeInput: TwoStepInputComponent | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected ctx: CmsContextService,
    protected cfgStateService: CfgStateService,
    protected apiConfigurationService: ApiConfigurationService,
    protected projectService: ProjectService,
  ) {
    super(dialogRef, dialogConfig);

    this.configurator = this.cfgStateService.getCfgUtil().getCfg();
    this.textFields = this.cfgStateService.getCfgUtil().getFieldsOfType(FIELD_TYPES.TEXT);
    this.$customerRequestStatusList = this.projectService.getRequestStatus(true);
  }

  protected nameChange(value: string): void {
    if (this.cfgStateService.getCfgUtil().getCfg().name === value) {
      this.notUniqueName = '';
      return;
    }
    this.apiConfigurationService.checkCfgName(this.ctx.projectId, value).subscribe((inUse) => {
      if (inUse) {
        this.notUniqueName = value;
        this.nameEditMode = true;
        if (this.nameInput && this.configurator) {
          this.nameInput.value = this.configurator.name;
        }
        return;
      }

      this.notUniqueName = '';
      this.change('name', value);
    });
  }

  protected codeChange(value: string): void {
    if (this.cfgStateService.getCfgUtil().getCfg().code === value) {
      this.notUniqueCode = '';
      this.change('code', value);
      return;
    }
    this.apiConfigurationService.checkCfgCode(this.ctx.projectId, value).subscribe((inUse) => {
      if (inUse) {
        this.notUniqueCode = value;
        this.codeEditMode = true;
        if (this.codeInput && this.configurator) {
          this.codeInput.value = this.configurator.code;
        }
        return;
      }

      this.notUniqueCode = '';
      this.change('code', value);
    });
  }

  protected change(property?: string, value?: unknown) {
    if (property) {
      this.configurator = ObjectUtil.setValue<CmsConfigurator>(this.configurator, property, value, true);
    }

    const diff = JsonPatch.compare(
      this.cfgStateService.getCfgUtil().getCfg() as unknown as Record<string, unknown>,
      this.configurator as unknown as Record<string, unknown>,
    );
    this.acceptDisabled = diff.length === 0;
  }

  protected save(): void {
    if (!this.configurator) {
      return;
    }
    this.loading = true;
    this.cfgStateService.changeConfiguration(this.configurator);
    this.cfgStateService.onPersistenceChange.subscribe((state) => {
      if (state === PersistenceState.FINISHED) {
        this.loading = false;
        this.close();
      }
    });
  }
}
