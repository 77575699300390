import { ChangeDetectorRef, Component } from '@angular/core';
import { Create, DATA_VALUE_TYPE, FIELD_TYPES, TextFieldConfig } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { TEXTFIELD_VALIDATION_OPTIONS } from '../../../../shared/global';

@Component({
  selector: 'kfd-field-settings-details-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<TextFieldConfig> {
  public validationOptions = TEXTFIELD_VALIDATION_OPTIONS;
  public dataValueType = DATA_VALUE_TYPE;
  public defaultValue = Create.inputValue(DATA_VALUE_TYPE.STRING, '');
  public defaultMinValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
  public defaultMaxValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 10);

  public constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.TEXT;
  }
}
