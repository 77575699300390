import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kfd-confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.scss'],
})
export class ConfirmationCodeComponent implements OnInit {
  @Output() confirm: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  label = '';

  @Input()
  resetOnConfirm = false;

  confirmationCode: number | undefined;
  enteredCode: number | undefined;
  invalidCode = false;
  confirmed = false;

  ngOnInit(): void {
    this.updateConfirmationCode();
  }

  updateConfirmationCode() {
    this.confirmationCode = Math.floor(Math.random() * 9999) + 1000;
    this.invalidCode = false;
    this.enteredCode = undefined;
    this.confirmed = false;
  }

  verify() {
    if (!this.enteredCode) {
      this.invalidCode = true;
      return;
    }
    if (this.enteredCode !== this.confirmationCode) {
      this.invalidCode = true;
      return;
    }

    this.invalidCode = false;
    this.confirm.emit();
    this.confirmed = true;
    if (this.resetOnConfirm) {
      this.updateConfirmationCode();
    }
  }
}
