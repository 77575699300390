import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CLS, CmsGenericEntry, ICON, ObjectUtil, StringUtil } from '@kfd/core';
import * as uuid from 'uuid';
import { CfgStateService } from '../../cfg-state.service';
import { saveNameValidators } from '@kfd/web-core';

@Component({
  selector: 'kfd-entry-name',
  templateUrl: './entry-name.component.html',
  styleUrls: ['./entry-name.component.scss'],
})
export class EntryNameComponent {
  @Output()
  public entryChange = new EventEmitter<CmsGenericEntry>();
  protected readonly saveNameValidators = saveNameValidators;
  protected readonly cls = CLS;
  protected readonly icon = ICON;
  protected originName: string | undefined;
  protected nameUnique: boolean | undefined;
  protected loading = false;
  private manualNameChange = false;

  constructor(private readonly cfgStateService: CfgStateService) {}

  private _entry: CmsGenericEntry | undefined;

  public get entry(): CmsGenericEntry | undefined {
    return this._entry;
  }

  @Input()
  public set entry(value: CmsGenericEntry | undefined) {
    this._entry = ObjectUtil.clone(value);

    if (this._entry) {
      if (uuid.validate(this._entry?.name)) {
        // this._entry.name = '';
        this.updateName();
      }

      if (this._entry._id) {
        this.nameUnique = true;
        this.manualNameChange = true;
        this.originName = this._entry.name;
      }
    }
  }

  public changedName(newName: string) {
    if (!this._entry) {
      return;
    }
    this.manualNameChange = true;
    this.nameUnique = false;
    this._entry.name = newName;
    this.onChange();
  }

  public updateName() {
    if (this._entry === undefined) {
      return;
    }
    if (!this.manualNameChange && this._entry?.label) {
      this._entry.name = this.uniqueName(this._entry?.label);
    }
    this.onChange();
  }

  public onChange(): void {
    if (!this._entry || this._entry.name === this.originName || this._entry.name.length < 3) {
      return;
    }
    this.nameUnique = this.cfgStateService.nameExists(this._entry.name) === false;
  }

  public accept(): void {
    this.entryChange.emit(this._entry);
  }

  private uniqueName(label: string): string {
    let name = StringUtil.toSaveString(label);

    for (let i = 2; i < 10; i++) {
      if (!this.cfgStateService.nameExists(name)) {
        return name;
      }
      name = StringUtil.toSaveString(label) + `_${i}`;
    }

    return name;
  }
}
