import { USER_SCOPES } from '@kfd/core';

export class ScopeUtil {
  public static scopeAllowed(userScopes: USER_SCOPES[], allowedScopes: USER_SCOPES[]): boolean {
    if (!userScopes || userScopes.length === 0) {
      return false;
    }

    for (const allowedScope of allowedScopes) {
      if (userScopes.indexOf(allowedScope) >= 0) {
        return true;
      }
    }

    return false;
  }
}
