import { Injectable } from '@angular/core';
import { AuthService, BaseHttpService, InsertResponse } from '@kfd/web-core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseFilter, CustomerRequestFilter, SETTINGS_KEYS } from '@kfd/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  featuresSubject$ = new BehaviorSubject<string[]>([]);
  private httpService: BaseHttpService;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.httpService = new BaseHttpService(httpClient, environment.services.cms);
    this.authService.onSignInChange().subscribe({
      next: (signedIn) => {
        if (signedIn) {
          this.loadFeatures();
        } else {
          this._features = [];
          this.featuresSubject$.next(this._features);
        }
      },
    });
  }

  private _features: string[] | undefined;

  get features(): string[] | undefined {
    return this._features;
  }

  public getCustomerRequestFilters(projectId: string): Observable<BaseFilter<CustomerRequestFilter>[]> {
    return this.httpService.get<BaseFilter<CustomerRequestFilter>[]>(
      `/user/settings/${projectId}/customer-request-filter`,
    );
  }

  public saveCustomerRequestFilters(
    projectId: string,
    filterList: BaseFilter<CustomerRequestFilter>[],
  ): Observable<InsertResponse> {
    return this.httpService.post<InsertResponse>(`/user/settings/${projectId}/customer-request-filter`, {
      filterList,
    });
  }

  public getSetting<T>(key: SETTINGS_KEYS): Observable<T> {
    return this.httpService.get<T>(`/user/setting/${key}`);
  }

  public getFeatures(): Observable<string[]> {
    if (this.features === undefined) {
      this.loadFeatures();
    }
    return this.featuresSubject$.asObservable();
  }

  public loadFeatures(): void {
    this.httpService.get<string[]>(`/user/features`).subscribe((features) => {
      this._features = features;
      this.featuresSubject$.next(this._features);
    });
  }
}
