<kfd-dialog-layout
  #dialogLayout
  (accept)="selectAsset()"
  (reject)="close()"
  [acceptDisabled]="!asset"
  [loading]="loading"
  acceptBtnLabel="Auswählen"
  data-automationId="asset-manager-dialog"
  dialogTitle="Datei auswählen"
>
  <kfd-asset-manager
    (assetSelection)="asset = $event"
    (menuItems)="dialogLayout.headerMenu = $event"
  ></kfd-asset-manager>
</kfd-dialog-layout>
