import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ROUTE_PARTS } from '../../global';

@Component({
  selector: 'kfd-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  items: MenuItem[] = [];

  @Input()
  projectId: string | undefined;

  @Input()
  current: MenuItem | undefined;

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

  update() {
    this.items = [
      {
        label: 'Start',
        url: '/dashboard',
      },
    ];

    if (this.projectId) {
      this.items.push({
        label: 'Mandant',
        url: `/${ROUTE_PARTS.PROJECT}/${this.projectId}`,
      });
    }

    if (this.current) {
      this.items.push(this.current);
    }
  }
}
