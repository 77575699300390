import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewModes } from '../data-management/data-management.component';
import { ScopedUser, SelectionData } from '@kfd/core';
import { UserMandantContextService } from '../../../services/user-mandant-context.service';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CmsContextService } from '../../../services/cms-context.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'kfd-data-management-dialog',
  templateUrl: './data-management-dialog.component.html',
  styleUrls: ['./data-management-dialog.component.scss'],
})
export class DataManagementDialogComponent extends BaseDialogComponent {
  protected projectId: string;
  protected configuratorId: string;
  protected mode: ViewModes;
  protected selectedIds: string[] = [];
  protected authorizedUser: ScopedUser | undefined;
  protected menuItems: MenuItem[] = [];
  protected selectionData: SelectionData[] = [];

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected userContextService: UserMandantContextService,
    public ctx: CmsContextService,
  ) {
    super(dialogRef, dialogConfig);
    if (!this.dialogConfig.data) {
      throw new Error('Missing configuration data');
    }
    this.projectId = dialogConfig.data.projectId;
    this.configuratorId = dialogConfig.data.configuratorId;
    this.mode = dialogConfig.data.mode;
    this.selectedIds = dialogConfig.data.selectedIds;
    this.authorizedUser = userContextService.authorizedUser;
  }
}
