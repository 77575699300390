import { WebEndpointService } from '@kfd/web-core';
import { Injectable } from '@angular/core';
import { cmsServiceEP } from '@kfd/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiLogsService {
  lastLogs: string[] = [];

  constructor(private webEndpointService: WebEndpointService) {}

  public addLogMsg(data: typeof cmsServiceEP.log.addLog.body): Observable<typeof cmsServiceEP.log.addLog.response> {
    const logString = JSON.stringify([data.msg, data.severity, data.url]);
    if (this.lastLogs.includes(logString)) {
      return of();
    }
    this.lastLogs.splice(0, this.lastLogs.length - 10);
    this.lastLogs.push(logString);
    return this.webEndpointService.post(cmsServiceEP.log.addLog, [], data);
  }
}
