import { ChangeDetectorRef, Component } from '@angular/core';
import { FIELD_TYPES, SubmitButtonFieldConfig } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';
import { CmsContextService } from '../../../../services/cms-context.service';

@Component({
  selector: 'kfd-field-settings-details-submit-btn',
  templateUrl: './submit-btn.component.html',
  styleUrls: ['./submit-btn.component.scss'],
})
export class SubmitBtnFieldSettingsComponent extends BaseEntryFieldSettingsComponent<SubmitButtonFieldConfig> {
  // linkToRootSettings = '';

  constructor(
    protected cd: ChangeDetectorRef,
    private ctx: CmsContextService,
  ) {
    super(cd);
  }

  // ngOnInit() {
  //   // this.linkToRootSettings = `/mandant/${this.ctx.projectId}/configurations/edit/${this.ctx.configuratorId}/${this.ctx.configuratorId}/settings`;
  // }

  getType(): FIELD_TYPES {
    return FIELD_TYPES.SUBMITBTN;
  }
}
