@if (data$ | loading | async; as selectionData) {
  <kfd-dialog-layout
    (accept)="close()"
    (reject)="close()"
    [loading]="selectionData.loading"
    [message]="selectionData.error"
    [showRejectBtn]="false"
    [showRejectIcon]="true"
    [headerMenu]="headerMenu"
    acceptBtnLabel="ok"
    dialogTitle="Datensatz anzeigen"
    docuPageId="base-data"
    docuSectionId="create"
    data-automationId="basedata-view-dialog"
  >
    @if (selectionData.value) {
      <kfd-meta-view [meta]="selectionData.value.meta"></kfd-meta-view>
      <kfd-key-value-list
        class="mt-2"
        [data]="{
          Label: {
            type: 'text',
            value: selectionData.value.label
          },
          Name: {
            type: 'text',
            value: selectionData.value.name
          },
          Tags: {
            type: 'text',
            value: selectionData.value.tags.join(',')
          }
        }"
      >
        @if (selectionData.value.image) {
          <div class="row" data-automationId="basedata-image">
            <label>Bild: </label>
            <div class="value">
              <div class="flex justify-content-end">
                <kfd-asset-thumbnail [assetId]="selectionData.value.image"></kfd-asset-thumbnail>
              </div>
            </div>
          </div>
        }
        @if (selectionData.value.values) {
          <div class="row">
            <label><strong>Werte</strong></label>
          </div>
          @for (value of selectionData.value.values; track value.identifier) {
            <div class="row" data-automationId="basedata-value">
              <label>{{ value.label?.text }}</label>
              <div class="value">
                <div class="flex justify-content-end">
                  {{ value.value !== undefined ? value.value : '---' }}
                </div>
              </div>
            </div>
          }
        }
      </kfd-key-value-list>
    }
  </kfd-dialog-layout>
}
