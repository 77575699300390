import { Injectable } from '@angular/core';
import { DataManagementDialogComponent } from './data-management-dialog/data-management-dialog.component';
import { ViewModes } from './data-management/data-management.component';
import { Observable } from 'rxjs';
import { CmsSelectionData, Id } from '@kfd/core';
import { CmsDialogService } from '../../services/cms-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class BaseDataManagementService {
  constructor(private cmsDialogService: CmsDialogService) {}

  openDataManagement(projectId: string, configuratorId: string) {
    this.cmsDialogService.open(DataManagementDialogComponent, {
      projectId,
      configuratorId,
      mode: ViewModes.MANGAGE,
    });
  }

  selectData(projectId: Id, configuratorId: Id, selectedIds: Id[] = []): Observable<CmsSelectionData[]> {
    return new Observable((observer) => {
      const ref = this.cmsDialogService.open(DataManagementDialogComponent, {
        projectId,
        configuratorId,
        mode: ViewModes.SELECT,
        selectedIds,
      });

      ref.onClose.subscribe((selectionData) => {
        observer.next(selectionData);
        observer.complete();
      });
    });
  }
}
