<kfd-dialog-layout
  (accept)="save()"
  (reject)="close()"
  [acceptDisabled]="acceptDisabled"
  [loading]="loading"
  acceptBtnLabel="Speichern"
  data-automationId="cfg-settings-dialog"
  dialogTitle="Einstellungen"
>
  <div *ngIf="configurator" class="p-flex-1">
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div data-automationId="settings-tab-configuration">Konfiguration</div>
        </ng-template>
        <div class="p-fluid">
          <div class="p-field">
            <label for="label">Label</label>
            <input
              (change)="change()"
              [(ngModel)]="configurator.label"
              data-automationId="cfg-label-input"
              id="label"
              maxlength="50"
              pInputText
              type="text"
            />
          </div>
          <div class="p-field">
            <label for="label">Beschreibung</label>
            <textarea
              (change)="change()"
              [(ngModel)]="configurator.description"
              [rows]="2"
              autoResize="autoResize"
              data-automationId="cfg-description-input"
              pInputTextarea
            >
            </textarea>
          </div>

          <div class="p-field">
            <label for="label">Name</label>
            <kfd-two-step-input
              #nameInput
              (valueChange)="nameChange($event)"
              [(editMode)]="nameEditMode"
              [inputType]="'save_string'"
              [value]="configurator.name"
              data-automationId="cfg-name-input"
              hint="Der Name muss eindeutig sein und ist öffentlich sichtbar"
            >
            </kfd-two-step-input>
            <small *ngIf="notUniqueName" class="color-error">
              Der Name "{{ notUniqueName }}" wird bereits verwendet.
            </small>
          </div>

          <p-message
            *ngIf="nameEditMode"
            class="my-2"
            severity="warn"
            text="Nach dem Veröffentlichen kann das Ändern des Namens zu fehlerhaften Links führen"
          >
          </p-message>

          <div class="p-field">
            <label for="label">Code</label>
            <kfd-two-step-input
              #codeInput
              (valueChange)="codeChange($event)"
              [(editMode)]="codeEditMode"
              [inputType]="'save_string_uc'"
              [value]="configurator.code"
              data-automationId="cfg-code-input"
              hint="Der Code dient zur Identifikation und ist nur intern sichtbar"
              maxLength="6"
              minlength="3"
            >
            </kfd-two-step-input>
            <small *ngIf="notUniqueCode" class="color-error">
              Der Code "{{ notUniqueCode }}" wird bereits verwendet.
            </small>
          </div>

          <p-message
            *ngIf="codeEditMode"
            class="my-2"
            severity="warn"
            text="Bereits eingegangen Kundenanfragen behalten den alten Code und werden nicht aktualisiert."
          >
          </p-message>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div data-automationId="settings-tab-customer-requests">Kunden-Anfragen</div>
        </ng-template>
        <div class="p-fluid">
          <div class="p-field">
            <kfd-toggle-setting
              (disable)="change('settings.customerEmailField', undefined)"
              (enable)="change('settings.customerEmailField', {})"
              (enabledChange)="change()"
              [object]="configurator"
              [property]="'settings.customerEmailField'"
              label="Kunden E-Mail versenden"
            >
              <kfd-field-ref
                (fieldRefChange)="change('settings.customerEmailField', $event)"
                [fieldRef]="configurator.settings?.customerEmailField"
                [fieldTypes]="[fieldTypes.TEXT]"
                [filterValue]="textFieldValidations.EMAIL"
                emptyMsg="Es können nur Textfelder vom Typ 'E-Mail' ausgewählt werden"
                filterProperty="config.validation"
              >
              </kfd-field-ref>
            </kfd-toggle-setting>
            <small>
              Beim Absenden des Formulars wird eine Bestätigungsmail an die E-Mail Adresse des Kunden geschickt.
              <br />Hierfür ist ein Feld erforderlich, welches die E-Mail Adresse beinhaltet.
            </small>
          </div>

          <div class="p-field mt-4">
            <label for="label">Titel Felder</label>
            <p-multiSelect
              (ngModelChange)="change('internalSettings.titleFields', $event)"
              [filter]="false"
              [ngModel]="configurator?.internalSettings?.titleFields"
              [options]="textFields"
              appendTo="body"
              optionLabel="label"
              optionValue="name"
            ></p-multiSelect>
            <small>
              Die Werte der Felder werden als Titel für Kundenanfragen verwendet und sollten deshalb aussagekräftige
              Inhalte haben.
            </small>
          </div>

          <div class="p-field mt-4">
            <label for="label">Status für neue Anfragen</label>
            <p-dropdown
              (ngModelChange)="change('internalSettings.initialState', $event)"
              [ngModel]="configurator.internalSettings?.initialState"
              [options]="$customerRequestStatusList | async"
              [showClear]="true"
              data-automationId="cfg-new-order-request-status-dropdown"
              placeholder="Status auswählen"
            ></p-dropdown>
            <small> Status der für neue Kundenanfragen initial verwendet wird. </small>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</kfd-dialog-layout>
