import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseDataService } from '../../../services/base-data.service';
import { Observable, tap } from 'rxjs';
import { ConfigurationInfo, DataEntryUsage, ICON } from '@kfd/core';
import { CmsContextService } from '../../../services/cms-context.service';
import { ContextRoutingService } from '../../../services/context-routing.service';

@Component({
  selector: 'kfd-data-usage-info',
  templateUrl: './data-usage-info.component.html',
  styleUrls: ['./data-usage-info.component.scss'],
})
export class DataUsageInfoComponent {
  @Output()
  public isUsed: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected ICON = ICON;
  protected usages$: Observable<DataEntryUsage> | undefined;
  private entryName: string | undefined;

  constructor(
    private readonly dataService: BaseDataService,
    private readonly contextRoutingService: ContextRoutingService,
    protected readonly ctx: CmsContextService,
  ) {}

  get name(): string | undefined {
    return this.entryName;
  }

  @Input()
  set name(value: string | undefined) {
    if (!value) {
      return;
    }
    this.entryName = value;
    this.loadUsage();
  }

  private _projectId: string | undefined;

  get projectId(): string | undefined {
    return this._projectId;
  }

  @Input()
  set projectId(value: string | undefined) {
    if (!value) {
      return;
    }
    this._projectId = value;
    this.loadUsage();
  }

  loadUsage() {
    if (!this.entryName || !this._projectId) {
      return;
    }

    this.usages$ = this.dataService.dataUsageInfo(this._projectId, this.entryName).pipe(
      tap((usages) => {
        this.isUsed.emit(usages.dynamic.length > 0 || usages.static.length > 0);
      }),
    );
  }

  public openEntry(configurationId: string, entryName?: string): void {
    if (!configurationId) {
      throw new Error('Missing configuration id to navigate to usage');
    }

    const entryId = !entryName
      ? configurationId
      : // todo convert to entryId if exist in current configuration
        entryName;
    this.contextRoutingService.toConfigurationEntry(configurationId, entryId);
  }

  public showConfiguration(configuration: ConfigurationInfo): void {
    this.contextRoutingService.toConfiguration(configuration.id);
  }
}
