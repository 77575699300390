/**
 * Represents a two-dimensional point or size
 */
export class D2 {
  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public valid(): boolean {
    return isNaN(this.x) === false && isNaN(this.y) === false;
  }

  /**
   * Multiplies both sizes with the given value
   */
  public multiply(value: D2 | number): D2 {
    return typeof value === 'number'
      ? new D2(this.x * value, this.y * value)
      : new D2(this.x * value.x, this.y * value.y);
  }

  /**
   * Divides both sizes with the given number or point
   */
  public divide(value: D2 | number): D2 {
    return typeof value === 'number'
      ? new D2(this.x / value, this.y / value)
      : new D2(this.x / value.x, this.y / value.y);
  }

  /**
   * Divides both sizes with the given number or point
   */
  public safeDivide(value: D2 | number): D2 {
    return typeof value === 'number'
      ? this.divide(value === 0 ? 1 : value)
      : this.divide(new D2(value.x === 0 ? 1 : value.x, value.y === 0 ? 1 : value.y));
  }

  /**
   * Adds the given point values to the own values
   */
  public add(value: D2 | number): D2 {
    return typeof value === 'number'
      ? new D2(this.x + value, this.y + value)
      : new D2(this.x + value.x, this.y + value.y);
  }

  /**
   * Subtracts the given point values from the own values
   */
  public subtract(value: D2 | number): D2 {
    return typeof value === 'number'
      ? new D2(this.x - value, this.y - value)
      : new D2(this.x - value.x, this.y - value.y);
  }

  /**
   * returns absolute values
   */
  public abs(): D2 {
    return new D2(Math.abs(this.x), Math.abs(this.y));
  }

  /**
   * returns the lower value of both dimensions
   */
  public lowerValue(): number {
    return this.x < this.y ? this.x : this.y;
  }

  /**
   * Compares all values of the point to values of the given one
   */
  public equals(value: D2): boolean {
    return this.x === value.x && this.y === value.y;
  }

  /**
   * Subtracts the given point values from the own values
   */
  public distance(point: D2): number {
    const diff = this.subtract(point);
    return Math.sqrt(Math.pow(diff.x, 2) + Math.pow(diff.y, 2));
  }
}

/**
 * represents a rectangular by two 2d points (start = top,left and end = bottom,right)
 */
export class Area {
  public start: D2;
  public end: D2;

  constructor(start: D2, end: D2) {
    this.start = start;
    this.end = end;
  }

  public valid(): boolean {
    if (!this.start.valid() || !this.end.valid()) {
      return false;
    }
    const diff = this.end.subtract(this.start);
    return diff.x > 0 && diff.y > 0;
  }

  /**
   * Divides both area points by the given point
   * eg start.x and end.x will be divided by value.x
   */
  public divide(value: D2): Area {
    return new Area(this.start.divide(value), this.end.divide(value));
  }

  /**
   * uses divide but ignores division by zero
   */
  public safeDivide(value: D2): Area {
    return new Area(this.start.safeDivide(value), this.end.safeDivide(value));
  }

  /**
   * Multiplies all area point values with given number
   */
  public multiply(value: number): Area {
    return new Area(this.start.multiply(value), this.end.multiply(value));
  }

  /**
   * Compares all values of the area to the given area
   */
  public equals(value: Area): boolean {
    return this.start.equals(value.start) && this.end.equals(value.end);
  }
}
