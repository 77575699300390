<kfd-dialog-layout (accept)="close()" [showRejectBtn]="false" acceptBtnLabel="OK" dialogTitle="Einbindung">
  <div *ngIf="configuration" class="m-1">
    <p-card *ngIf="configuration" class="mt-2">
      <p-header>
        <p-toolbar styleClass="invisible px-3 pb-0">
          <div class="p-toolbar-group-left">
            <div class="p-card-title">Optionen</div>
          </div>
          <div class="p-toolbar-group-right">
            <kfd-docu-btn class="ml-2" pageId="integration"></kfd-docu-btn>
          </div>
        </p-toolbar>
      </p-header>
      <ng-container *ngIf="!configuration.versions.drafted && !configuration.versions.published">
        <p-message severity="info" text="Zum Einbinden muss die Konfiguration publiziert werden."> </p-message>
      </ng-container>
      <div class="p-field-checkbox">
        <p-checkbox
          [(ngModel)]="usePreview"
          [disabled]="!configuration.versions.drafted"
          binary="true"
          label="Entwurf-Version verwenden"
        ></p-checkbox>
      </div>
    </p-card>

    <p-card *ngIf="configuration" class="mt-2" header="Web-Komponente">
      JavaScript einbinden:
      <pre><code>
&lt;script src="{{environment.apps.cfg}}/elements/v01/main.js"&gt;&lt;/script&gt;
  </code></pre>

      <p-tabView>
        <p-tabPanel header="Inline">
          Das Formular wird direkt in die Seite integriert und angezeigt.
          <h4 class="mt-2">Optionen</h4>
          <div class="p-field">
            <p-checkbox #inlineSeamless binary="true" label="Nahtlos einbinden"></p-checkbox>
            <br />
            <small>Blendet Kopf und Fußleiste aus.</small>
          </div>

          <h4>Konfiguration einbinden:</h4>
          <pre><code>
&lt;kfd-inline
 project="{{ctx.projectName}}"
 configuration="{{configuration.name}}"<ng-container *ngIf="usePreview">
 preview="true"</ng-container><ng-container *ngIf="inlineSeamless.model">
 seamless="true"</ng-container>
&gt;&lt;/kfd-inline&gt;
        </code></pre>
        </p-tabPanel>
        <p-tabPanel header="Intro">
          Im Intro Modus wird ein Intro Text und Button angezeigt. Erst nach einen Klick erscheint das Formular.
          <h4 class="mt-2">Optionen</h4>
          <div class="p-field">
            <p-checkbox #editIntro binary="true" label="Startseite anpassen"></p-checkbox>
            <br />
            <small *ngIf="!editIntro.model">Eigene Texte auf der Startseite setzen</small>
            <div [ngClass]="{ hidden: !editIntro.model }" class="ml-2">
              <div class="p-float-label mt-2">
                <input #introTitle class="p-inputtext-sm" pInputText type="text" />
                <label>Title</label>
              </div>
              <div class="p-float-label mt-2">
                <input #introSubtitle class="p-inputtext-sm" pInputText type="text" />
                <label>Untertitel</label>
              </div>
              <div class="p-float-label mt-2">
                <input #introBtnText class="p-inputtext-sm" pInputText type="text" />
                <label>Button Label</label>
              </div>
            </div>
          </div>
          <div class="p-field">
            <p-checkbox #introSeamless binary="true" label="Nahtlos einbinden"></p-checkbox>
            <br />
            <small>Blendet Kopf und Fußleiste aus.</small>
          </div>

          <h4>Konfiguration einbinden:</h4>
          <pre><code>
&lt;kfd-intro
 project="{{ctx.projectName}}"
 configuration="{{configuration.name}}"<ng-container *ngIf="usePreview">
 preview="true"</ng-container><ng-container *ngIf="introSeamless.model">
 seamless="true"</ng-container><ng-container *ngIf="editIntro.model"><ng-container *ngIf="introTitle">
 title="{{introTitle.value}}"</ng-container><ng-container *ngIf="introSubtitle">
 subtitle="{{introSubtitle.value}}"</ng-container><ng-container *ngIf="introBtnText">
 btntext="{{introBtnText.value}}"</ng-container></ng-container>
&gt;&lt;/kfd-intro&gt;
        </code></pre>
        </p-tabPanel>
        <p-tabPanel header="Popup">
          Im Popup oder Modal Modus wird ein Button angezeigt der die Konfiguration in einem Fenster auf der Seite
          öffnet.
          <h4 class="mt-2">Optionen</h4>
          <div class="p-field">
            <p-checkbox #editPopupIntro binary="true" label="Startseite anpassen"></p-checkbox>
            <br />
            <small *ngIf="!editPopupIntro.model">Eigene Texte auf der Startseite setzen</small>
            <div [ngClass]="{ hidden: !editPopupIntro.model }" class="ml-2">
              <div class="p-float-label mt-2">
                <input #popupIntroTitle class="p-inputtext-sm" pInputText type="text" />
                <label>Title</label>
              </div>
              <div class="p-float-label mt-2">
                <input #popupIntroSubtitle class="p-inputtext-sm" pInputText type="text" />
                <label>Untertitel</label>
              </div>
              <div class="p-float-label mt-2">
                <input #popupIntroBtnText class="p-inputtext-sm" pInputText type="text" />
                <label>Button Label</label>
              </div>
            </div>
          </div>
          <div class="p-field">
            <p-checkbox #popupIntroSeamless binary="true" label="Nahtlos einbinden"></p-checkbox>
            <br />
            <small>Blendet Kopf und Fußleiste aus.</small>
          </div>

          <h4>Konfiguration einbinden:</h4>
          <pre><code>
&lt;kfd-modal
 project="{{ctx.projectName}}"
 configuration="{{configuration.name}}"<ng-container *ngIf="usePreview">
 preview="true"</ng-container><ng-container *ngIf="popupIntroSeamless.model">
 seamless="true"</ng-container><ng-container *ngIf="editPopupIntro.model"><ng-container *ngIf="popupIntroTitle">
 title="{{popupIntroTitle.value}}"</ng-container><ng-container *ngIf="popupIntroSubtitle">
 subtitle="{{popupIntroSubtitle.value}}"</ng-container><ng-container *ngIf="popupIntroBtnText">
 btntext="{{popupIntroBtnText.value}}"</ng-container></ng-container>
&gt;&lt;/kfd-modal&gt;
        </code></pre>
        </p-tabPanel>
      </p-tabView>
    </p-card>

    <p-card class="mt-2" header="Link">
      <pre><code>
&lt;a href="{{environment.apps.cfg}}/configurator/{{ctx.projectName}}/{{configuration.name}}
    <ng-container *ngIf="usePreview">/preview</ng-container>" target="_blank"&gt;
  Formular öffnen
&lt;/a&gt;
    </code></pre>
    </p-card>
  </div>
</kfd-dialog-layout>
