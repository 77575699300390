<ng-container *ngIf="entry">
  <div class="p-fluid">
    <div class="p-field">
      <label>Label</label>
      <input
        (change)="updateName()"
        (keyup)="updateName()"
        [(ngModel)]="entry.label"
        data-automationId="entry-name-label-input"
        maxlength="50"
        pInputText
        type="text"
      />
    </div>

    <div class="p-field">
      <label>Name</label>
      <kfd-toggle-input-field
        (valueChange)="changedName($event)"
        [validator]="saveNameValidators"
        [value]="entry.name"
        data-automationId="entry-name-name-input"
        hint="Der Name wird als interne referenz verwendet und darf nur einmal vorkommen"
      >
      </kfd-toggle-input-field>
    </div>

    <ng-container *ngIf="nameUnique === false">
      <p-message
        [text]="'&quot;' + entry.name + '&quot; wird bereits verwendet'"
        class="mt-2"
        severity="warn"
      ></p-message>
    </ng-container>

    <div class="mt-2">
      <button
        (click)="accept()"
        [disabled]="nameUnique !== true"
        [loading]="loading"
        class="p-button-secondary p-button-outlined p-button-rounded"
        data-automationId="entry-name-accept-btn"
        icon="pi {{ icon.ACCEPT }}"
        label="Übernehmen"
        pButton
        type="button"
      ></button>
    </div>
  </div>
</ng-container>
