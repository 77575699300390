import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { saveNameValidators } from '@kfd/web-core';
import { AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDataTemplate, StringUtil } from '@kfd/core';
import { Subject, takeUntil } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ToggleInputFieldComponent } from '../../../../../../../libs/web-core/src/lib/components/toggle-input-field/toggle-input-field.component';

export const LABEL_NAME_CONTROLS = (
  asyncNameValidator: AsyncValidatorFn = undefined,
  labelValue = '',
  nameValue = '',
) => {
  return {
    label: new FormControl(labelValue ?? '', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]),
    name: new FormControl(nameValue, saveNameValidators, asyncNameValidator),
  };
};

@Component({
  selector: 'kfd-label-name-input',
  templateUrl: './label-name-input.component.html',
  styleUrl: './label-name-input.component.scss',
})
export class LabelNameInputComponent implements OnDestroy {
  @Input()
  public manualNameChange = false;

  protected readonly saveNameValidators = saveNameValidators;
  protected template: BaseDataTemplate | undefined;

  @ViewChild('toggleInputField')
  protected toggleInputField: ToggleInputFieldComponent | undefined;

  private destroy$ = new Subject<boolean>();
  private _asyncNameValidator: AsyncValidatorFn | AsyncValidatorFn[] | undefined;

  get asyncNameValidator(): AsyncValidatorFn | AsyncValidatorFn[] | undefined {
    return this._asyncNameValidator;
  }

  @Input()
  public set asyncNameValidator(value: AsyncValidatorFn | AsyncValidatorFn[] | undefined) {
    this._asyncNameValidator = value;
  }

  private _form: FormGroup | undefined;

  get form(): FormGroup | undefined {
    return this._form;
  }

  @Input()
  public set form(value: FormGroup | undefined) {
    if (value === undefined) {
      return;
    }
    this._form = value;
    this._form
      .get('label')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateLabel());
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected updateLabel() {
    if (!this._form) {
      return;
    }
    const label = this._form.get('label')?.value;
    if (!this.manualNameChange) {
      if (label.length >= 4) {
        this._form.get('name').setValue(StringUtil.toSaveString(label, 30));
        this.toggleInputField.triggerValidation();
      }
    }
  }

  protected changedNameValidity(valid: boolean) {
    this.form.get('name').setErrors(valid ? null : { invalidValue: true });
  }
}
