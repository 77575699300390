import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CLS, FIELD_TYPES } from '@kfd/core';

interface Entry {
  name: string;
  visible: boolean;
  children: {
    header: string;
    description: string;
    entryType: string;
    fieldType: string | null;
  }[];
}

@Component({
  selector: 'kfd-entry-type-selection',
  templateUrl: './entry-type-selection.component.html',
  styleUrls: ['./entry-type-selection.component.scss'],
})
export class EntryTypeSelectionComponent {
  @Output() selectionChange = new EventEmitter<{
    entryType: CLS;
    fieldType?: FIELD_TYPES;
  }>();
  cls = CLS;
  fieldTypes = FIELD_TYPES;
  selectedGroup = 0;
  entries: Entry[] = [];

  constructor() {
    this.updateEntries();
  }

  private _showGroup = false;

  @Input()
  set showGroup(value: boolean) {
    this._showGroup = value;
    this.updateEntries();
  }

  private _selection:
    | {
        entryType: CLS;
        fieldType?: FIELD_TYPES;
      }
    | undefined;

  get selection(): { entryType: CLS; fieldType?: FIELD_TYPES } | undefined {
    return this._selection;
  }

  @Input()
  set selection(value: { entryType: CLS; fieldType?: FIELD_TYPES } | undefined) {
    if (value === undefined) {
      return;
    }
    this._selection = value;
    loop1: for (const index in this.entries) {
      for (const item of this.entries[index].children) {
        if (this._selection.entryType === item.entryType && this._selection.fieldType === item.fieldType) {
          this.selectedGroup = parseInt(index);
          break loop1;
        }
      }
    }
  }

  updateEntries() {
    this.entries = [
      {
        name: 'Gruppe',
        visible: this._showGroup,
        children: [
          {
            header: 'Feldgruppe',
            description: 'Element um mehrere Felder zu einer Gruppe zusammenzufassen',
            entryType: CLS.FIELD_GROUP,
            fieldType: null,
          },
        ],
      },
      {
        name: 'Eingabe',
        visible: true,
        children: [
          {
            header: 'Text',
            description: 'Texte wie Namen, Links, E-Mail Adressen, Kommentare',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.TEXT,
          },
          {
            header: 'Zahlen',
            description: 'Numerische Werte wie Anzahl, Größe, Gewicht.',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.NUMBER,
          },
          {
            header: 'Datum',
            description: 'Auswahl von Datum und oder Zeitangaben.',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.DATE,
          },
          {
            header: 'Bool',
            description: 'Fragen die mit Ja oder Nein zu beantworten sind.',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.YESNO,
          },
          {
            header: 'Auswahl',
            description: 'Auswahlliste von Datensätzen',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.SELECT,
          },
        ],
      },
      {
        name: 'Interaktion',
        visible: true,
        children: [
          {
            header: 'Werte',
            description: 'Anzeigen von Werten und Berechnungen',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.DISPLAY_DATA,
          },
          {
            header: 'Absenden',
            description: 'Element um Benutzereingaben abzusenden und ein Ticket zu erzeugen',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.SUBMITBTN,
          },
          {
            header: 'Zurücksetzen',
            description: 'Neustart- Formular auf Initiale Werte zurücksetzen',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.RESETBTN,
          },
          {
            header: 'Zusammenfassung',
            description: 'Zeigt eine Zusammenfassung aller Benutzereingaben an.',
            entryType: CLS.FIELD,
            fieldType: FIELD_TYPES.SUMMARY,
          },
        ],
      },
    ];
  }

  select(entryType: CLS, fieldType?: FIELD_TYPES) {
    this._selection = {
      entryType,
      fieldType,
    };
    this.selectionChange.emit(this._selection);
  }
}
