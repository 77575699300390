<ng-container *ngIf="conditionalEntry">
  <div *ngIf="conditionalEntry.condition; else withoutConditions" class="condition" (dblclick)="showConditionDialog()">
    <kfd-configurator-condition [name]="conditionalEntry.entry.name">
      <ng-template #caseTrue>
        <p-tag pTooltip="Enthält Bedingungen - Element ist aktuell sichtbar" value="">
          <i class="pi {{ icon.CONDITION }}"></i>
          <i class="pi {{ icon.VISIBLE }}"></i>
        </p-tag>
      </ng-template>
      <ng-template #caseFalse>
        <p-tag pTooltip="Enthält Bedingungen - Element ist aktuell versteckt" value="">
          <i class="pi {{ icon.CONDITION }}"></i>
          <i class="pi {{ icon.INVISIBLE }}"></i>
        </p-tag>
      </ng-template>
    </kfd-configurator-condition>
  </div>

  <ng-template #withoutConditions>
    <kfd-configurator-condition [name]="conditionalEntry.entry.name">
      <ng-template #caseFalse>
        <p-tag pTooltip="Element ist aufgrund von Bedingungen ausgeblendet" value="">
          <i class="pi {{ icon.INVISIBLE }}"></i>
        </p-tag>
      </ng-template>
    </kfd-configurator-condition>
  </ng-template>
</ng-container>
