import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiLogsService } from './api/api-logs.service';
import Bowser from 'bowser';

export interface Log {
  hash: string;
  severity?: string;
  type: string;
  message: string;
  date: string;
  details?: string;
  stack?: string;
  code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private logs: Log[] = [];

  constructor(
    private readonly router: Router,
    private readonly apiLogsService: ApiLogsService,
  ) {
    //click events
    addEventListener('click', (event) => {
      const knownElement = (event.target as HTMLElement).closest('[data-automationId]');
      if (knownElement) {
        const id = (knownElement as HTMLElement).getAttribute('id');
        const automationId = (knownElement as HTMLElement).getAttribute('data-automationId');
        this.addLog({
          type: 'click',
          message: `id: ${id}; automationId: ${automationId}`,
        });
      }
    });

    //routing events
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.addLog({
          type: 'routing',
          message: (val as NavigationEnd).urlAfterRedirects,
        });
      }
    });
  }

  public getLogs(): Log[] {
    return this.logs;
  }

  public addLog(log: Omit<Log, 'hash' | 'date'>): Log | undefined {
    const hash = JSON.stringify(log);
    if (this.hasLog(hash)) {
      return;
    }
    const logEntry: Log = {
      date: new Date().toISOString(),
      hash,
      ...log,
    };
    this.logs.push(logEntry);
    // eslint-disable-next-line no-console
    console.log(log);
    return logEntry;
  }

  public addError(log: Omit<Log, 'hash' | 'date'>) {
    const logEntry = this.addLog(log);
    if (!logEntry) {
      return;
    }

    const output = Bowser.parse(window.navigator.userAgent);

    this.apiLogsService
      .addLogMsg({
        severity: 'error',
        msg: log.message,
        stack: JSON.stringify(log),
        url: window.location.href,
        browser: `${output.browser.name}-${output.browser.version}`,
      })
      .subscribe();
  }

  protected hasLog(hash: string): boolean {
    return this.logs.find((log) => log.hash === hash) !== undefined;
  }
}
