import { Pipe, PipeTransform } from '@angular/core';
import { CfgStateService } from '../cfg-state.service';

/**
 * usage
 * [title]="field.name | entrylabel"
 */
@Pipe({
  name: 'entrylabel',
})
export class CfgEntryLabelPipe implements PipeTransform {
  constructor(private cfgStateService: CfgStateService) {}

  transform(entryName: string): string {
    const entry = this.cfgStateService.getCfgUtil().getEntryByName(entryName);
    return entry?.label ? entry.label : entryName;
  }
}
