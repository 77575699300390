<kfd-dialog-layout
  (accept)="remove()"
  (reject)="close()"
  [acceptDisabled]="!entry || (entry?.children?.length && !deleteChildren) || usageCount > 0"
  [loading]="loading || loadingUsageCheck"
  acceptBtnLabel="Entfernen"
  dialogTitle="Element entfernen"
>
  <ng-container *ngIf="entry"> Soll der Eintrag "{{ entry.label }}" gelöscht werden? </ng-container>

  <ng-container *ngIf="entry?.children?.length && usages && usageCount === 0">
    <div class="p-fluid mt-4">
      <p-message severity="warn" text="Es gibt weiter Einträge unterhalb des zu Löschenden"></p-message>
      <div class="p-field-checkbox mt-2">
        <p-checkbox [(ngModel)]="deleteChildren" binary="true" inputId="deleteChildren"></p-checkbox>
        <label>&nbsp;Eintrag mit Kindern löschen?</label>
      </div>
    </div>
  </ng-container>

  <div class="p-fluid mt-4">
    <p-message *ngIf="loadingUsageCheck" severity="info" text="Prüfe auf Verweise..."></p-message>
    <p-message
      *ngIf="usages && usageCount === 0"
      severity="info"
      text="Keine exisitierenden Verweise gefunden"
    ></p-message>
    <p-message
      *ngIf="usages && usageCount > 0"
      severity="error"
      text="Der Eintrag wird noch genutzt und kann nicht gelöscht werden"
    ></p-message>
  </div>

  <ng-container *ngIf="usages && usageCount > 0">
    <div class="mt-2">
      Bitte entfernen Sie die Referenzen in folgenden Elementen:
      <ul>
        <ng-container *ngFor="let usage of usages">
          <li>Referenz im Eintrag "{{ usage.usedBy.label }}"</li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</kfd-dialog-layout>
