<p-toolbar class="header-toolbar z-1" data-automationId="new-entry-list" styleClass="no-border">
  <div class="p-toolbar-group-left text-lg">Neues Element</div>
  <div class="p-toolbar-group-right">
    <button
      (click)="cancel.emit()"
      class="p-button-text p-button-rounded"
      data-automationId="new-entry-reject-btn"
      icon="pi {{ icon.DECLINE }}"
      pButton
      type="button"
    ></button>
  </div>
</p-toolbar>
<p-toolbar class="header-toolbar z-1" styleClass="no-border no-padding-top">
  <div class="p-toolbar-group-left">
    <div class="p-input-icon-left">
      <i class="pi {{ icon.SEARCH }}"></i>
      <input
        [(ngModel)]="searchString"
        data-automationId="new-entry-filter-input"
        maxlength="50"
        pInputText
        placeholder="Suchen..."
        type="text"
      />
    </div>
  </div>
</p-toolbar>
<div class="flex-1 flex flex-column overflow-y-scroll">
  @if (entryList | filter: 'label,name,description' : searchString; as filteredList) {
    <kfd-placeholder-msg
      *ngIf="filteredList.length === 0"
      [infoIcon]="icon.SEARCH"
      data-automationId="empty-item-list-info"
      infoText="Kein Element gefunden"
    ></kfd-placeholder-msg>
    @for (entryListItem of filteredList; track entryListItem.name) {
      <kfd-new-entry-item [newEntryItem]="entryListItem"></kfd-new-entry-item>
    }
  }
</div>
