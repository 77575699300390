import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Create, Page, PageRef } from '@kfd/core';
import { ConfigurationService } from '../../../services/configuration.service';

/**
 * @deprecated replaced by same name in cfg editor module
 */
@Component({
  selector: 'kfd-page-ref-legacy',
  templateUrl: './page-ref-legacy.component.html',
  styleUrls: ['./page-ref-legacy.component.scss'],
})
export class PageRefLegacyComponent implements OnInit {
  @Output() pageRefChange: EventEmitter<PageRef> = new EventEmitter<PageRef>();
  options: Page[] = [];

  constructor(private _cfgService: ConfigurationService) {}

  private _pageRef: PageRef = Create.pageRef();

  get pageRef(): PageRef {
    return this._pageRef;
  }

  @Input()
  set pageRef(value: PageRef) {
    if (!value) {
      return;
    }
    this._pageRef = JSON.parse(JSON.stringify(value));
  }

  ngOnInit() {
    this.loadFields();
  }

  loadFields() {
    this.options = [
      {
        name: '',
        label: ' - Bitte wählen -',
      } as Page,
    ];
    this.options = this.options.concat(this._cfgService.getPages());
  }

  onChange(value: string): void {
    if (!value) {
      this._pageRef = Create.pageRef();
      this.pageRefChange.emit();
      return;
    }
    this._pageRef = Create.pageRef(value);
    this.pageRefChange.emit(this.pageRef);
  }
}
