import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Create, FIELD_TYPES, FieldConfig, ICON, InputValue, Is, ObjectUtil } from '@kfd/core';

@Component({ template: '' })
export abstract class BaseEntryFieldSettingsComponent<T extends FieldConfig> {
  public icon = ICON;
  @Output()
  public configChange: EventEmitter<T> = new EventEmitter<T>();
  @Input()
  public refFieldName: string | undefined;
  private updateEntryTimeout: number | undefined;

  protected constructor(protected cd: ChangeDetectorRef) {}

  protected _config: T | undefined;

  public get config(): T | undefined {
    return this._config;
  }

  @Input()
  public set config(value: T | undefined) {
    if (this._config !== undefined && JSON.stringify(this._config) !== JSON.stringify(value)) {
      //clear config to ensure all values will be unset
      this._config = undefined;
      this.cd.detectChanges();
    }

    if (!Is.fieldConfig(value) || value?.type !== this.getType()) {
      this._config = Create.fieldConfig<T>(this.getType());
      window.setTimeout(() => {
        this.change();
      });
      return;
    }
    this._config = ObjectUtil.clone(value);
    if (JSON.stringify(this.config) !== JSON.stringify(this._config)) {
      throw new Error('Please implement get and set in child class');
    }
  }

  /**
   * changes an input value in the config
   */
  protected changeValue(property: string, value: InputValue): void {
    if (Is.emptyInputValue(value) || Is.noInputValue(value)) {
      this.change(property, undefined);
      return;
    }
    this.change(property, value);
  }

  /**
   * change a generic config value and emit the change event
   */
  protected change(property?: string, value?: unknown): void {
    if (!property) {
      return;
    }

    if (this.updateEntryTimeout) {
      clearTimeout(this.updateEntryTimeout);
    }
    this.updateEntryTimeout = window.setTimeout(() => {
      const updatedConfig = ObjectUtil.setValue(this._config, property, value, true);
      if (JSON.stringify(updatedConfig) !== JSON.stringify(this._config)) {
        // not using the setter because there is no need to run checks and reset everything
        this._config = updatedConfig;
        this.configChange.emit(this.config);
      }
    }, 50);
  }

  protected abstract getType(): FIELD_TYPES;
}
