<p-tabView>
  <ng-container *ngFor="let entry of entries; let i = index">
    <p-tabPanel *ngIf="entry.visible" [header]="entry.name" [selected]="i === selectedGroup">
      <div class="grid">
        <p-card
          (click)="select(item.entryType, item.fieldType)"
          *ngFor="let item of entry.children"
          [header]="item.header"
          [ngClass]="{
            selected: selection && item.entryType === selection.entryType && item.fieldType === selection.fieldType
          }"
          class="col-6 md:col-4 lg:col-3"
        >
          {{ item.description }}
          <i class="selection-icon pi pi-check-circle"></i>
        </p-card>
      </div>
    </p-tabPanel>
  </ng-container>
</p-tabView>
