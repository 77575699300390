import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CmsGenericParentEntry, EntryUsage, FieldRef } from '@kfd/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { CfgStateService } from '../../cfg-state.service';

export interface RemoveEntryData {
  entryName: string;
}

@Component({
  selector: 'kfd-remove-entry-dialog',
  templateUrl: './remove-entry-dialog.component.html',
  styleUrls: ['./remove-entry-dialog.component.scss'],
})
export class RemoveEntryDialogComponent extends BaseDialogComponent {
  removeEntryData: RemoveEntryData;
  deleteChildren = false;
  entry: CmsGenericParentEntry | undefined;
  usages: EntryUsage<FieldRef>[] | undefined;
  usageCount = 0;
  loadingUsageCheck: boolean | undefined;

  usageTypeIcons = {
    conditions: 'pi-dollar',
    entry: 'pi-cog',
  };

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private cfgStateService: CfgStateService,
  ) {
    super(dialogRef, dialogConfig);
    this.removeEntryData = dialogConfig.data;
    this.entry = cfgStateService.getCfgUtil().getEntryByName(this.removeEntryData.entryName);
    if (!this.entry) {
      this.close();
    }
    this.usageCheck();
  }

  usageCheck() {
    this.loadingUsageCheck = true;
    const usages = this.cfgStateService.getCfgUtil().getEntryUsages(this.removeEntryData.entryName);
    this.usages = usages;
    this.usageCount = usages.length;
    this.loadingUsageCheck = false;
  }

  remove() {
    if (!this.entry) {
      return;
    }

    if (this.entry.children && this.entry.children.length > 0 && this.deleteChildren !== true) {
      return;
    }

    if (this.usages && this.usageCount > 0) {
      return;
    }

    this.cfgStateService.deleteEntry(this.entry.name);
    this.close();
  }
}
