import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CLS,
  CmsSelectionData,
  Create,
  DATA_TYPES,
  DATA_VALUE_TYPE,
  ICON,
  LicenceConfigOptions,
  ObjectUtil,
  StringUtil,
} from '@kfd/core';
import { BaseDataService } from '../../../services/base-data.service';
import { CmsContextService } from '../../../services/cms-context.service';
import { saveNameValidators } from '@kfd/web-core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-data-entry-basic-form',
  templateUrl: './data-entry-basic-form.component.html',
  styleUrls: ['./data-entry-basic-form.component.scss'],
})
export class DataEntryBasicFormComponent {
  @Output()
  public dataCreated = new EventEmitter<CmsSelectionData>();
  @Input()
  public dataValueType: DATA_VALUE_TYPE = DATA_VALUE_TYPE.STRING;
  protected readonly cls = CLS;
  protected readonly icon = ICON;
  protected loading = false;
  protected licenceLimitData = LicenceConfigOptions.DATA_LIMIT;
  protected selectionData: Omit<CmsSelectionData, '_id' | 'meta'>;
  protected saveNameValidators = saveNameValidators;
  protected asyncValidators: AsyncValidatorFn | undefined;
  protected nameValid = false;
  private manualNameChange = false;
  private defaultSelectionData: Omit<CmsSelectionData, '_id' | 'meta'> = {
    cls: CLS.SELECTION_DATA,
    type: DATA_TYPES.SELECTION,
    name: '',
    label: '',
  };

  constructor(
    protected readonly ctx: CmsContextService,
    protected readonly dataService: BaseDataService,
  ) {
    this.selectionData = ObjectUtil.clone(this.defaultSelectionData);
    this.asyncValidators = this.nameUniqueValidator.bind(this);
  }

  public changedName() {
    this.manualNameChange = true;
  }

  public updateName() {
    if (!this.manualNameChange && this.selectionData.label) {
      this.selectionData.name = StringUtil.toSaveString(this.selectionData.label);
    }
  }

  public accept() {
    this.loading = true;
    this.dataService
      .createEntry(this.ctx.projectId, {
        label: this.selectionData.label,
        name: this.selectionData.name,
        tags: [],
        values: [],
      })
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (res._id) {
            this.dataCreated.emit({
              ...this.selectionData,
              _id: res._id,
              meta: Create.entryMeta(),
            });
          }
        },
      });
  }

  protected nameUniqueValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }
    const name = control.value.toString();
    if (name.length < 3) {
      return of(null);
    }
    return this.dataService.getEntryByName(this.ctx.projectId, name).pipe(
      map((selectionData) => {
        return selectionData ? { invalidValue: true, message: 'Der Name wird bereits verwendet.' } : null;
      }),
    );
  }

  private reset(): void {
    this.selectionData = ObjectUtil.clone(this.defaultSelectionData);
  }
}
