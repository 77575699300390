import { Injectable } from '@angular/core';
import { catchError, mergeAll, mergeMap, Observable, of, toArray } from 'rxjs';
import {
  CLS,
  CmsSelectionData,
  Configuration,
  DATA_TYPES,
  PublicProject,
  RequestForm,
  RequestSubmissionResponse,
  SelectionData,
  StringUtil,
} from '@kfd/core';
import { DataFilterOptions, DataProvider } from '@kfd/cfg-core';
import { BaseDataService } from './base-data.service';
import { map } from 'rxjs/operators';
import { AssetService } from './asset.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationDataService implements DataProvider {
  constructor(
    private readonly baseDataService: BaseDataService,
    private readonly assetService: AssetService,
  ) {}

  private _preview = false;

  get preview(): boolean {
    return this._preview;
  }

  set preview(value: boolean) {
    this._preview = value;
  }

  private _embedded = false;

  get embedded(): boolean {
    return this._embedded;
  }

  set embedded(value: boolean) {
    this._embedded = value;
  }

  getProjectInfo(): Observable<PublicProject> {
    return of({} as PublicProject);
  }

  getForm(): Observable<RequestForm> {
    return of({
      id: '',
      project: {} as PublicProject,
      configuration: {} as Configuration,
    });
  }

  getData(projectId: string, configurationId: string, filter: DataFilterOptions): Observable<SelectionData[]> {
    return this.baseDataService
      .getDataByFilter(projectId, filter)
      .pipe(mergeMap((data) => this.convertCmsToSelectionData(projectId, data)));
  }

  getDataByIdentifier(projectId: string, configurationId: string, names: string[]): Observable<SelectionData[]> {
    if (!names || names.length === 0) {
      return of([]);
    }
    return this.baseDataService
      .getDataByNames(projectId, names)
      .pipe(mergeMap((data) => this.convertCmsToSelectionData(projectId, data)));
  }

  submitData(): Observable<RequestSubmissionResponse> {
    const requestId = 'TEST-' + StringUtil.rand(5);
    return of({
      success: true,
      requestCode: requestId,
      link: 'https://konfidoo.de',
    });
  }

  private convertCmsToSelectionData(projectId: string, data: CmsSelectionData[]): Observable<SelectionData[]> {
    return of(data).pipe(
      mergeAll(),
      mergeMap((entry) => {
        if (entry.image) {
          return this.assetService.getImage(projectId, entry.image, true).pipe(
            map((image) => {
              return {
                cls: CLS.SELECTION_DATA,
                type: DATA_TYPES.SELECTION,
                name: entry.name,
                label: entry.label,
                values: entry.values,
                image: image,
              } as SelectionData;
            }),
            catchError(() =>
              of({
                cls: CLS.SELECTION_DATA,
                type: DATA_TYPES.SELECTION,
                name: entry.name,
                label: entry.label,
                values: entry.values,
              } as SelectionData),
            ),
          );
        } else {
          return of({
            cls: CLS.SELECTION_DATA,
            type: DATA_TYPES.SELECTION,
            name: entry.name,
            label: entry.label,
            values: entry.values,
          } as SelectionData);
        }
      }),
      toArray(),
    );
  }
}
