import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArrayUtil, Create, ICON, SelectOption, Sort, SORT_DIRECTION } from '@kfd/core';

@Component({
  selector: 'kfd-sort-input',
  templateUrl: './sort-input.component.html',
  styleUrl: './sort-input.component.scss',
})
export class SortInputComponent {
  @Output()
  public sortChange = new EventEmitter<Sort[]>();

  protected readonly ICON = ICON;
  protected readonly directionOptions: SelectOption[] = [
    {
      value: SORT_DIRECTION.ASC,
      label: 'Aufsteigend',
    },
    {
      value: SORT_DIRECTION.DESC,
      label: 'Absteigend',
    },
  ];
  protected fieldOptions: SelectOption[] = [];

  private _sort: Sort[] = [];

  public get sort(): Sort[] {
    return this._sort;
  }

  @Input()
  public set sort(value: Sort[]) {
    if (!Array.isArray(value)) {
      return;
    }
    this._sort = value;
  }

  @Input()
  public set optionList(values: SelectOption[]) {
    if (!Array.isArray(values)) {
      return;
    }
    this.fieldOptions = values;
  }

  protected addSort() {
    this._sort.push(Create.sort());
  }

  protected removeSort(name: string): void {
    this._sort = this._sort.filter((sort) => sort.field !== name);
    this.onSortChange();
  }

  protected onSortChange(): void {
    const sort = ArrayUtil.uniqueByKey(this._sort, 'field');
    this.sortChange.emit(sort);
  }
}
