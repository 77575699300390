import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';

import { DragDropService } from './drag-drop.service';
import { CLS } from '@kfd/core';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dropZone]',
})
export class DropZoneDirective implements OnDestroy {
  @HostBinding('class.active')
  public active = false;
  @HostBinding('class.focus')
  public focus = false;
  private destroy$ = new Subject<boolean>();
  private element: HTMLElement;
  private acceptCls: CLS[] = [];

  constructor(
    private readonly dragDropService: DragDropService,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    this.element = elementRef.nativeElement;

    // this.dragDropService.onDndEnabled.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
    //   if (enabled === false) {
    //     this.resetElement();
    //   }
    // });
  }

  private _parent: string | undefined;

  @Input()
  set parent(value: string | undefined) {
    this._parent = value;
    if (this._parent) {
      this.subscribe();
    }
  }

  private _position = 0;

  @Input()
  set position(value: number) {
    this._position = value;
    if (this._position) {
      this.subscribe();
    }
  }

  @Input()
  public set accepts(accepts: CLS[]) {
    this.acceptCls = accepts;
    if (this.acceptCls.length > 0) {
      this.subscribe();
    }
  }

  @HostBinding('class')
  get hostClasses() {
    return 'drop-zone';
  }

  public ngOnDestroy(): void {
    this.resetElement();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @HostListener('mouseenter', ['$event'])
  protected onEnter() {
    if (this.active === true && this._parent !== undefined && this._position !== undefined) {
      this.focus = true;
      this.dragDropService.dropElementInfo = {
        name: this._parent,
        pos: this._position,
      };
    }
  }

  @HostListener('mouseleave', ['$event'])
  protected onLeave() {
    this.focus = false;
    this.dragDropService.dropElementInfo = undefined;
  }

  protected subscribe(): void {
    this.resetElement();
    if (!this.acceptCls?.length || this._parent === undefined || this._position === undefined) {
      return;
    }
    this.dragDropService
      .onDropzoneStatusChange(this.acceptCls, this._parent, this._position)
      .pipe(takeUntil(this.destroy$))
      .subscribe((active) => {
        this.active = active;
      });
  }

  protected resetElement() {
    this.active = false;
    this.focus = false;
  }
}
