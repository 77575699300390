import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'kfd-reload-btn',
  templateUrl: './reload-btn.component.html',
  styleUrls: ['./reload-btn.component.scss'],
})
export class ReloadBtnComponent {
  @Output() reload: EventEmitter<void> = new EventEmitter<void>();
}
