@if (newEntryItem) {
  <div [attr.data-automationId]="'new-entry-card-' + newEntryItem.name" class="info-card">
    <i class="pi {{ newEntryItem.icon }} icon"></i>
    <div class="title">{{ newEntryItem.label }}</div>
    <div class="description">{{ newEntryItem.description }}</div>

    <button
      (click)="addElement(newEntryItem.entry)"
      class="p-button-text p-button-rounded add-btn"
      data-automationId="new-entry-add-btn"
      icon="pi {{ ICON.ADD }}"
      pButton
      type="button"
    ></button>
  </div>
}
