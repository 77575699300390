import { Component, HostBinding, Input } from '@angular/core';
import { CLS, ICON } from '@kfd/core';
import { Observable } from 'rxjs';
import { DragDropService } from '../drag-drop.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'kfd-preview-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent {
  @Input()
  public parent: string | undefined;

  @Input()
  public position = 0;

  @Input()
  public accepts: CLS[] = [];

  @Input()
  public vertical = false;

  @HostBinding('class.area')
  @Input()
  public area = false;

  protected readonly ICON = ICON;

  protected dndActive$: Observable<boolean>;

  constructor(private readonly dragDropService: DragDropService) {
    this.dndActive$ = this.dragDropService.onDndActive.pipe(map((active) => !!active));
  }
}
