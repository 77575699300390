import { ChangeDetectorRef, Component } from '@angular/core';
import { Create, DATA_VALUE_TYPE, FIELD_TYPES, NumericFieldConfig } from '@kfd/core';
import { BaseEntryFieldSettingsComponent } from '../base-entry-field-settings.component';

@Component({
  selector: 'kfd-field-settings-details-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss'],
})
export class NumericEntryFieldSettingsComponent extends BaseEntryFieldSettingsComponent<NumericFieldConfig> {
  public dataValueType = DATA_VALUE_TYPE;
  public defaultValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 0);
  public defaultMaxValue = Create.inputValue(DATA_VALUE_TYPE.NUMERIC, 1);

  public constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  protected getType(): FIELD_TYPES {
    return FIELD_TYPES.NUMBER;
  }
}
