import { Component } from '@angular/core';
import { MessagesGatewayService } from '../../services/messages-gateway.service';
import { ArrayUtil, ICON, Notification, WsMessageType } from '@kfd/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'kfd-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
})
export class UserNotificationsComponent {
  icon = ICON;
  unreadNotifications = '';
  notifications: Notification[] = [];
  loading = new Map<string, boolean>();

  constructor(
    private messagesGatewayService: MessagesGatewayService,
    private notificationService: NotificationService,
  ) {
    this.messagesGatewayService.onMessageType<Notification[]>(WsMessageType.NOTIFICATION).subscribe((notifications) => {
      this.notifications = ArrayUtil.uniqueByKey([...notifications, ...this.notifications], '_id');
      this.updateUnread();
    });
  }

  showNotifications() {
    //todo open dialog
    return;
  }

  protected markAsRead(id: string) {
    const notification = this.notifications.find((notification) => notification._id === id);
    if (!notification || notification.readDate) {
      return;
    }
    this.loading.set(id, true);

    this.notificationService.markAsRead(id).subscribe({
      next: () => {
        this.loading.delete(id);
        this.notifications.forEach((notification) => {
          if (notification._id === id) {
            notification.readDate = new Date().toISOString();
          }
        });
        this.updateUnread();
      },
      error: (e) => {
        this.loading.delete(id);
        throw e;
      },
    });
  }

  protected markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe({
      next: () => {
        this.notifications.forEach((notification) => {
          notification.readDate = new Date().toISOString();
        });
        this.updateUnread();
      },
    });
  }

  private updateUnread() {
    const count = this.notifications.filter((notification) => !notification.readDate).length;
    if (count === 0) {
      this.unreadNotifications = '';
      return;
    }

    this.unreadNotifications = count < 9 ? '' + count : '9+';
  }
}
