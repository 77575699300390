import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Calculation,
  CalculationParam,
  CalculationParams,
  CLS,
  Create,
  DATA_VALUE_TYPE,
  Is,
  ObjectUtil,
  SelectOption,
  VALUE_FIELD_TYPES,
} from '@kfd/core';
import { calcOperationList } from '../../../shared/global';
import { CalculationDialogComponent } from '../../dialogs/calculation-dialog/calculation-dialog.component';
import { AliasRefDialogComponent } from '../../../shared/components/alias-ref-dialog/alias-ref-dialog.component';
import { DataValueDialogComponent } from '../../../shared/components/data-value-dialog/data-value-dialog.component';
import { CmsDialogService } from '../../../services/cms-dialog.service';
import { FieldRefDialogComponent } from '../../dialogs/field-ref-dialog/field-ref-dialog.component';

interface IndexedEntry {
  index: number;
  entry: CalculationParam;
}

@Component({
  selector: 'kfd-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
})
export class CalculationComponent {
  @Output() valueChange: EventEmitter<Calculation> = new EventEmitter<Calculation>();

  paramList: IndexedEntry[] = [];
  calcOperationList = calcOperationList;
  label: string | undefined;
  paramOptionList: SelectOption[] = [];
  paramOption: string | undefined;
  nestingLvl = 0;

  protected readonly cls = CLS;

  constructor(private cmsDialogService: CmsDialogService) {}

  private _value: Calculation = Create.calculation();

  get value(): Calculation {
    return this._value;
  }

  @Input()
  set value(value: Calculation) {
    if (value === undefined) {
      return;
    }

    if (Is.calculation(value)) {
      this.setParamList(value.params);
      this._value = ObjectUtil.clone(value);
    } else {
      this._value = Create.calculation();
    }
    this.updateParamOptionList();
  }

  @Input()
  set lvl(lvl: number) {
    this.nestingLvl = lvl;
    this.updateParamOptionList();
  }

  updateParamOptionList() {
    this.paramOptionList = [
      {
        value: CLS.INPUT_VALUE,
        label: 'Wert',
      },
      {
        value: CLS.ALIAS_VALUE,
        label: 'Zwischenergebnis',
      },
      {
        value: CLS.FIELD_REF,
        label: 'Feld',
      },
    ];
    if (this.nestingLvl < 3) {
      this.paramOptionList.push({
        value: CLS.CALCULATION,
        label: 'Kalkulation',
      });
    }
    if (!this.paramOption) {
      this.paramOption = this.paramOptionList[0].value.toString();
    }
  }

  addParam(type: string | undefined) {
    if (type === undefined) {
      return;
    }
    let newParam;
    switch (type) {
      case CLS.INPUT_VALUE: {
        newParam = Create.inputValue(DATA_VALUE_TYPE.NUMERIC);
        break;
      }
      case CLS.ALIAS_VALUE: {
        newParam = Create.aliasValue();
        break;
      }
      case CLS.FIELD_REF: {
        newParam = Create.fieldRef();
        break;
      }
      case CLS.CALCULATION: {
        newParam = Create.calculation();
        break;
      }
    }
    //workaround to trigger change detection
    this.setParamList([...this.getParamList(), newParam] as CalculationParams);
    this.onValueChange();
  }

  editParam(indexedEntry: IndexedEntry) {
    let component;
    let data: Record<string, unknown> = {};
    const param = indexedEntry.entry;
    switch (param.cls) {
      case CLS.CALCULATION:
        component = CalculationDialogComponent;
        data = {
          calculation: param,
          nestingLvl: this.nestingLvl + 1,
        };
        break;
      case CLS.FIELD_REF:
        component = FieldRefDialogComponent;
        data = {
          fieldRef: param,
          fieldTypes: VALUE_FIELD_TYPES,
          allowProperties: true,
        };
        break;
      case CLS.ALIAS_VALUE:
        component = AliasRefDialogComponent;
        data = {
          calculation: this.value,
          aliasValue: param,
        };
        break;
      case CLS.INPUT_VALUE:
        component = DataValueDialogComponent;
        data = {
          dataValue: param,
          allowedTypes: [DATA_VALUE_TYPE.NUMERIC, DATA_VALUE_TYPE.DATE],
        };
        break;
    }

    if (!component) {
      return;
    }
    const ref = this.cmsDialogService.open(component, data);
    ref.onClose.subscribe((result: CalculationParam) => {
      if (result) {
        this.paramList[indexedEntry.index].entry = result;
        this.onValueChange();
      }
    });
  }

  removeParam(index: number) {
    this.paramList.splice(index, 1);
    this.onValueChange();
  }

  setParamList(params: CalculationParams): void {
    let i = 0;
    this.paramList = params.map((param) => ({
      index: i++,
      entry: param,
    }));
  }

  getParamList(): CalculationParams {
    return this.paramList.map((indexedEntry) => indexedEntry.entry);
  }

  newFn() {
    this.change('fn', Create.calculationFunction());
  }

  change(property?: string, value?: unknown) {
    if (property) {
      this._value = ObjectUtil.setValue<Calculation>(this._value, property, value, true);
    }

    this.onValueChange();
  }

  onValueChange() {
    this.value.params = this.getParamList();
    this.valueChange.emit(this.value);
  }
}
