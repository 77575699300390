<div [kfdBlock]="loading" [loader]="false" class="p-2 overflow-auto flex-1">
  <ng-container *ngIf="entry$ | loading | async as entry">
    <ng-container *ngIf="entry.value; else empty">
      <div *ngIf="entry.value.isNew !== true; else isNew" [attr.data-automationId]="'settings-for-' + entry.value.name">
        <div class="flex justify-content-between">
          <div class="flex align-items-center">
            {{ entry.value.label }}
          </div>
          <div>
            <button
              (click)="nameSettingsPanel.toggle($event)"
              class="p-button-text p-button-rounded"
              data-automationId="edit-name-btn"
              icon="pi {{ icon.EDIT }}"
              pButton
              type="button"
            ></button>
            <button
              (click)="menu.toggle($event)"
              class="p-button-rounded p-button-text"
              icon="pi {{ icon.MENU }}"
              pButton
              type="button"
            ></button>
            <p-menu #menu [model]="menuItems" [popup]="true" appendTo="body"></p-menu>
          </div>
        </div>
        <div class="horizontal-line"></div>

        <ng-container [ngSwitch]="entry.value.cls">
          <ng-container *ngSwitchCase="cls.PAGE">
            <kfd-entry-page-settings
              (entryChange)="entryChange(entry.value.name, $event)"
              [entry]="entry.value"
              [isFirst]="isFirst"
              [isLast]="isLast"
            ></kfd-entry-page-settings>
          </ng-container>
          <ng-container *ngSwitchCase="cls.FIELD_GROUP">
            <kfd-entry-fieldgroup-settings
              (entryChange)="entryChange(entry.value.name, $event)"
              [entry]="entry.value"
            ></kfd-entry-fieldgroup-settings>
          </ng-container>
          <ng-container *ngSwitchCase="cls.FIELD">
            <kfd-entry-field-settings
              (entryChange)="entryChange(entry.value.name, $event)"
              [entry]="entry.value"
            ></kfd-entry-field-settings>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="m-2 flex align-items-center justify-content-center">
              <p-message severity="info" text="Keine Einstellungen vorhanden"></p-message>
            </div>
          </ng-container>
        </ng-container>

        <p-overlayPanel #nameSettingsPanel>
          <kfd-entry-name
            (entryChange)="entryChange(entry.value.name, $event); nameSettingsPanel.hide()"
            [entry]="entry.value"
            style="width: 15rem"
          >
          </kfd-entry-name>
        </p-overlayPanel>
      </div>
    </ng-container>

    <ng-template #isNew>
      <div class="flex flex-row justify-content-between" data-automationId="new-entry-form">
        <div class="flex align-items-center">
          <strong>{{ entry.value.label }}</strong>
        </div>
        <div>
          <button
            (click)="delete($any(entry.value))"
            class="p-button-rounded p-button-text mx-1"
            icon="pi {{ icon.DELETE }}"
            pButton
            pTooltip="Löschen"
            type="button"
          ></button>
        </div>
      </div>

      <kfd-entry-name (entryChange)="entryChange(entry.value.name, $event)" [entry]="entry.value"></kfd-entry-name>
    </ng-template>
  </ng-container>
</div>

<ng-template #empty>
  <kfd-empty-msg
    [infoIcon]="icon.SETTINGS"
    class="align-self-center"
    data-automationId="empty-cfg-settings"
    info="Kein Eintrag ausgewählt"
  ></kfd-empty-msg>
</ng-template>
