import { Component, Input, ViewChild } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'kfd-responsive-menu',
  templateUrl: './responsive-menu.component.html',
  styleUrls: ['./responsive-menu.component.scss'],
})
export class ResponsiveMenuComponent {
  @ViewChild('menu') public menu: ContextMenu | undefined;

  private _menuItems: MenuItem[] = [];

  get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  @Input()
  set menuItems(value: MenuItem[]) {
    this._menuItems = value;
  }

  toggleMenu($event: MouseEvent) {
    if (this.menu) {
      this.menu.toggle($event);
    }
    $event.stopPropagation();
  }
}
