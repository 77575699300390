<kfd-dialog-layout
  (accept)="next()"
  (reject)="close()"
  [hideFooter]="true"
  [loading]="loading"
  dialogTitle="Einen Fehler melden"
>
  <ng-container *ngIf="step === 0">
    <p>
      Es hat etwas nicht wie erwartet funktioniert? Es ist eine Fehlermeldung aufgetreten?<br />
      Bitte füllen Sie das Formular möglichst ausführlich aus um uns bei der Fehlersuche zu helfen.
    </p>
    <p>Das Fehlerprotokoll enthält Interaktionen und Meldungen basierend auf ihren letzten Schritten.</p>
    <div class="grid-wrapper mt-2">
      <div class="grid">
        <div class="col-6 p-fluid">
          <button (click)="close()" class="p-button-secondary" label="Abbrechen" pButton type="button"></button>
        </div>
        <div class="col-6 p-fluid">
          <button (click)="next()" class="" label="Fehler melden" pButton type="button"></button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 1">
    <p-message
      class="mt-2"
      severity="info"
      text="Mit dem Absenden des Fehlerprotkolls, helfen Sie uns das Produkt zu verbessern."
    ></p-message>
    <kfd-form (submitted)="submitted($event)" [data]="formData" configuration="error_reporting"></kfd-form>
  </ng-container>
  <ng-container *ngIf="step === 2">
    <p-message severity="success" text="Vielen Dank für ihre Mithilfe"></p-message>
    <ng-container *ngIf="code"> Ticketnummer: {{ code }} </ng-container>
    <div class="grid-wrapper mt-2">
      <div class="grid">
        <div class="col-6 p-fluid"></div>
        <div class="col-6 p-fluid">
          <button (click)="close()" label="Fenster schließen" pButton type="button"></button>
        </div>
      </div>
    </div>
  </ng-container>
</kfd-dialog-layout>
