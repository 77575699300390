<div *ngIf="projects$ | async as projects" [kfdBlock]="loadingMandants">
  <div class="grid">
    <ng-container *ngFor="let project of projects">
      <p-card
        (click)="selection.emit(project)"
        [attr.data-automationId]="'mandant-name-' + project.name"
        class="teaser col-12 sm:col-6 md:col-4 lg:col-3 xl:col-2"
        styleClass="interactive"
      >
        <div class="logo mb-3">
          @if (project.ci.logo) {
            <img [src]="project.ci.logo.url" />
          } @else {
            <i class="placeholder pi pi-box"></i>
          }
        </div>
        <p-header>
          <div class="card-header">{{ project.label }}</div>
        </p-header>
      </p-card>
    </ng-container>
  </div>

  <ng-content *ngIf="projects.length === 0" select="[emptyList]"></ng-content>
</div>
