import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { CmsContextService } from '../../services/cms-context.service';
import { ApiConfigurationService } from '../../services/api/api-configuration.service';
import { ProjectService } from '../../services/project.service';
import { debounceTime, mergeMap } from 'rxjs/operators';

export enum Type {
  MANDANT_Name = 'mandant_name',
  CFG_NAME = 'cfg_name',
  CFG_CODE = 'cfg_code',
  ENTRY_NAME = 'entry_name',
}

@Directive({
  selector: '[kfdValidName]',
})
export class ValidNameDirective {
  @Output()
  public isUnique: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public minLength = 3;

  @Input()
  type: Type = Type.ENTRY_NAME;

  private term$ = new BehaviorSubject('');

  constructor(
    private _ctx: CmsContextService,
    private _configurationCmsService: ApiConfigurationService,
    private _mandantService: ProjectService,
  ) {
    this.term$
      .pipe(
        tap(() => {
          this.isLoading.emit(true);
        }),
        debounceTime(500),
        mergeMap((name) => this.checkName(name)),
      )
      .subscribe((nameExists) => {
        this.isUnique.emit(!nameExists);
        this.isLoading.emit(false);
      });
  }

  @Input()
  set name(value: string | undefined) {
    if (value === undefined || value.length < this.minLength) {
      return;
    }
    window.setTimeout(() => {
      this.term$.next(value);
    }, 0);
  }

  @Input()
  set projectId(value: string) {
    if (!value) {
      return;
    }
    this._ctx.projectId = value;
  }

  private checkName(name: string): Observable<boolean | undefined> {
    if (!name) {
      return of(false);
    }
    switch (this.type) {
      case Type.CFG_NAME:
        return this._configurationCmsService.checkCfgName(this._ctx.projectId, name);
      case Type.CFG_CODE:
        return this._configurationCmsService.checkCfgCode(this._ctx.projectId, name);
      case Type.ENTRY_NAME:
        return this._configurationCmsService.checkName(this._ctx.projectId, this._ctx.configuratorId, name);
      case Type.MANDANT_Name:
        return this._mandantService.checkName(name);
      default:
        throw new Error(`Unsupported type ${this.type}`);
    }
  }
}
