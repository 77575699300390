import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { BaseDialogComponent } from '../../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Create, DateValueTypes } from '@kfd/core';
import { DateValueComponent } from '../../date-value/date-value.component';

@Component({
  selector: 'kfd-date-selection-dialog',
  standalone: true,
  imports: [CommonModule, SharedModule, DateValueComponent],
  templateUrl: './date-selection-dialog.component.html',
  styleUrl: './date-selection-dialog.component.scss',
})
export class DateSelectionDialogComponent extends BaseDialogComponent {
  protected dateValue: DateValueTypes = Create.emptyInputValue();

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.dateValue) {
      this.dateValue = dialogConfig.data.dateValue;
    }
  }

  protected accept() {
    this.dialogRef.close(this.dateValue);
  }
}
