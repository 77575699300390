<kfd-dialog-layout
  (accept)="close(this.selectionData)"
  (reject)="close()"
  [headerMenu]="menuItems"
  [kfdBlock]="loading"
  acceptBtnLabel="Übernehmen"
  dialogTitle="Daten auswählen"
>
  <kfd-data-management
    (menuItemsChange)="menuItems = $event"
    (selectionChange)="selectionData = $event"
    [configuratorId]="configuratorId"
    [currentUser]="authorizedUser"
    [mode]="mode"
    [projectId]="projectId"
    [selectedIds]="selectedIds"
  >
  </kfd-data-management>
</kfd-dialog-layout>
