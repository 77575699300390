<div class="p-fluid">
  <div class="p-field">
    <label>Label</label>
    <kfd-label (labelChange)="onValueChange()" [(label)]="value.label"></kfd-label>
  </div>
  <div *ngIf="nestingLvl > 0" class="p-field">
    <label>Alias</label>
    <kfd-two-step-input
      (valueChange)="onValueChange()"
      [(value)]="value.alias"
      [inputType]="'save_string'"
      hint="Der Name wird als interne Referenz verwendet"
    >
    </kfd-two-step-input>
  </div>
  <div class="p-field p-field-checkbox">
    <p-checkbox
      (onChange)="onValueChange()"
      *ngIf="value.alias"
      [(ngModel)]="value.excludeFromResult"
      binary="true"
      hint="Das Ergebnis wird nur unter eine Verwendung des Aliases berücksichtigt"
      label="Ergebnis aus Berechnung ausschließen"
    ></p-checkbox>
  </div>
  <!--  <div class="p-field">-->
  <!--    <label for="operation">Condition</label>-->
  <!--    -&#45;&#45;-->
  <!--  </div>-->
  <div class="p-field">
    <label for="operation">Operation</label>
    <p-dropdown
      (ngModelChange)="onValueChange()"
      [(ngModel)]="value.op"
      [options]="calcOperationList"
      appendTo="body"
      id="operation"
    >
    </p-dropdown>
  </div>
  <div class="p-field">
    <label for="operation">Werte</label>
    <p-orderList
      (onReorder)="onValueChange()"
      [dragdrop]="true"
      [value]="paramList"
      id="paramsList"
      styleClass="order-list"
    >
      <ng-template let-index let-param pTemplate="item">
        <div class="text">
          <ng-container *ngIf="param.entry.cls === cls.FIELD_REF">
            <strong>Feld</strong> - {{ param.entry.name }}
          </ng-container>
          <ng-container *ngIf="param.entry.cls === cls.CALCULATION">
            <strong>Kalkulation</strong> - {{ param.entry.op }} ({{ param.entry.params.length }})
            <ng-container *ngIf="param.entry.alias">-> {{ param.entry.alias }}</ng-container>
          </ng-container>
          <ng-container *ngIf="param.entry.cls === cls.ALIAS_VALUE">
            <strong>Zwischenergebnis</strong> - {{ param.entry.name }}
          </ng-container>
          <ng-container *ngIf="param.entry.cls === cls.INPUT_VALUE">
            <strong>Wert</strong> - {{ param.entry.value }}
          </ng-container>
        </div>
        <button (click)="editParam(param)" class="p-button-sm" icon="pi pi-pencil" pButton type="button"></button>
        <button
          (click)="removeParam(param.index)"
          class="p-button-sm"
          icon="pi pi-times"
          pButton
          type="button"
        ></button>
      </ng-template>
    </p-orderList>
    <div class="p-inputgroup">
      <p-dropdown [(ngModel)]="paramOption" [options]="paramOptionList" appendTo="body" styleClass="auto-width">
      </p-dropdown>
      <button (click)="addParam(paramOption)" icon="pi pi-plus" iconPos="left" pButton type="button"></button>
    </div>
  </div>
</div>
<kfd-toggle-setting
  (disable)="change('fn', undefined)"
  (enable)="newFn()"
  [object]="value"
  [property]="'fn'"
  class="p-field"
  label="Ergebnis anpassen"
>
  <kfd-calculation-fn
    (fnChange)="change('fn', $event)"
    *ngIf="value?.fn !== undefined"
    [fn]="value.fn"
  ></kfd-calculation-fn>
</kfd-toggle-setting>
