import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CmsPage, ObjectUtil } from '@kfd/core';

@Component({
  selector: 'kfd-entry-page-settings',
  templateUrl: './entry-page-settings.component.html',
  styleUrls: ['./entry-page-settings.component.scss'],
})
export class EntryPageSettingsComponent {
  @Output() entryChange: EventEmitter<CmsPage> = new EventEmitter<CmsPage>();
  @Input()
  public isFirst: boolean | undefined;
  @Input()
  public isLast: boolean | undefined;

  private _entry: CmsPage | undefined;

  public get entry() {
    return this._entry;
  }

  @Input()
  public set entry(value) {
    if (!value) {
      return;
    }
    this._entry = ObjectUtil.clone(value);
  }

  change() {
    this.entryChange.emit(this.entry);
  }
}
