import {
  ChildWrapper,
  CLS,
  CmsChildWrapper,
  CmsConfigurator,
  CmsConfiguratorFieldGroup,
  CmsGenericEntry,
  CmsGenericParentEntry,
  Configuration,
  ConfiguratorFieldGroup,
  Id,
  Is,
} from '../dtos/index';

export type IterateWrapperCb = (wrapper: CmsChildWrapper, parentId: Id | undefined, level: number) => unknown;
export type IterateWrapperCb2 = (wrapper: ChildWrapper) => unknown;
export type StructureCb = (entry: CmsGenericEntry, cls: CLS) => unknown;

/**
 * @deprecated use CfgUtil instead
 */
export class ConfigurationUtil {
  public static getFields(entry: CmsGenericParentEntry): CmsGenericEntry[] {
    let result: CmsGenericEntry[] = [];
    if (entry.children && entry.children.length > 0) {
      for (const child of entry.children) {
        const subEntries = ConfigurationUtil.getFields(child.entry);
        result = result.concat(subEntries);
      }
    } else {
      if (Is.field(entry)) {
        result.push(entry);
      }
    }
    return result;
  }

  public static find<T = CmsGenericEntry>(configurator: CmsConfigurator, cb: CallableFunction): T | undefined {
    const res = cb(configurator, null);
    if (res) {
      return res as T;
    }
    return ConfigurationUtil.findWrapper(configurator, (wrapper, parent) => cb(wrapper.entry, parent));
  }

  public static findWrapper<T = CmsGenericEntry>(configurator: CmsConfigurator, cb: IterateWrapperCb): T | undefined {
    let res = undefined;
    ConfigurationUtil.iterateWrapper(
      configurator,
      (wrapper, parent, level) => {
        const cbRes = cb(wrapper, parent, level);
        if (cbRes) {
          res = cbRes;
          return false;
        }
        return;
      },
      false,
    );

    return res;
  }

  public static iterateWrapper(configurator: CmsConfigurator, cb: IterateWrapperCb, parentsFirst = true): void {
    for (const configuratorPage of configurator.children) {
      if (parentsFirst) {
        if (cb(configuratorPage, configurator._id, 1) === false) {
          return;
        }
      }
      for (const configuratorElement of configuratorPage.entry.children) {
        if (Is.fieldGroup(configuratorElement.entry)) {
          const configuratorQuestion = configuratorElement as CmsConfiguratorFieldGroup;
          if (parentsFirst) {
            if (cb(configuratorQuestion, configuratorPage.entry._id, 2) === false) {
              return;
            }
          }
          for (const configuratorField of configuratorQuestion.entry.children) {
            //field
            if (cb(configuratorField, configuratorQuestion.entry._id, 3) === false) {
              return;
            }
          }

          //question
          if (!parentsFirst) {
            if (cb(configuratorQuestion, configuratorPage.entry._id, 2) === false) {
              return;
            }
          }
        } else {
          //field
          if (cb(configuratorElement, configuratorPage.entry._id, 2) === false) {
            return;
          }
        }
      }
      if (!parentsFirst) {
        if (cb(configuratorPage, configurator._id, 1) === false) {
          return;
        }
      }
    }
  }

  // public static async iterateWrapperWithoutIdAsync(
  //   configurator: Configuration,
  //   cb: IterateWrapperCb2,
  //   parentsFirst = true,
  // ): Promise<void> {
  //   // return ConfigurationUtil.iterateWrapper(configurator, cb, parentsFirst)
  //   for (const configuratorPage of configurator.children) {
  //     if (parentsFirst) {
  //       if ((await cb(configuratorPage)) === false) {
  //         return;
  //       }
  //     }
  //     for (const configuratorElement of configuratorPage.entry.children) {
  //       if (Is.question(configuratorElement.entry)) {
  //         const configuratorQuestion = configuratorElement as CmsConfiguratorQuestion;
  //         if (parentsFirst) {
  //           if ((await cb(configuratorQuestion)) === false) {
  //             return;
  //           }
  //         }
  //         for (const configuratorField of configuratorQuestion.entry.children) {
  //           //field
  //           if ((await cb(configuratorField)) === false) {
  //             return;
  //           }
  //         }
  //
  //         //question
  //         if (!parentsFirst) {
  //           if ((await cb(configuratorQuestion)) === false) {
  //             return;
  //           }
  //         }
  //       } else {
  //         //field
  //         if ((await cb(configuratorElement)) === false) {
  //           return;
  //         }
  //       }
  //     }
  //     if (!parentsFirst) {
  //       if ((await cb(configuratorPage)) === false) {
  //         return;
  //       }
  //     }
  //   }
  // }

  //todo how to combine async and non async without code duplication
  public static async iterateWrapperAsync(
    configurator: CmsConfigurator,
    cb: IterateWrapperCb,
    parentsFirst = true,
  ): Promise<void> {
    // return ConfigurationUtil.iterateWrapper(configurator, cb, parentsFirst)
    for (const configuratorPage of configurator.children) {
      if (parentsFirst) {
        if ((await cb(configuratorPage, configurator._id, 1)) === false) {
          return;
        }
      }
      for (const configuratorElement of configuratorPage.entry.children) {
        if (Is.fieldGroup(configuratorElement.entry)) {
          const configuratorQuestion = configuratorElement as CmsConfiguratorFieldGroup;
          if (parentsFirst) {
            if ((await cb(configuratorQuestion, configuratorPage.entry._id, 2)) === false) {
              return;
            }
          }
          for (const configuratorField of configuratorQuestion.entry.children) {
            //field
            if ((await cb(configuratorField, configuratorQuestion.entry._id, 3)) === false) {
              return;
            }
          }

          //question
          if (!parentsFirst) {
            if ((await cb(configuratorQuestion, configuratorPage.entry._id, 2)) === false) {
              return;
            }
          }
        } else {
          //field
          if ((await cb(configuratorElement, configuratorPage.entry._id, 2)) === false) {
            return;
          }
        }
      }
      if (!parentsFirst) {
        if ((await cb(configuratorPage, configurator._id, 1)) === false) {
          return;
        }
      }
    }
  }

  public static structure(configurator: Configuration, cb: CallableFunction): void {
    for (const configuratorPage of configurator.children) {
      //page
      cb([configuratorPage, configurator]);
      for (const configuratorElement of configuratorPage.entry.children) {
        if (Is.fieldGroup(configuratorElement.entry)) {
          const configuratorQuestion = configuratorElement as ConfiguratorFieldGroup;
          //question
          cb([configuratorQuestion, configuratorPage, configurator]);
          for (const configuratorField of configuratorQuestion.entry.children) {
            //field
            cb([configuratorField, configuratorQuestion, configuratorPage, configurator]);
          }
        } else {
          //field
          cb([configuratorElement, configuratorPage, configurator]);
        }
      }
    }
  }
}
