<ng-container *ngIf="viewData$ | async as viewData">
  <div
    [ngClass]="{ selected: viewData.selected }"
    [style.margin]="contentMargin * 2 + 'px ' + contentMargin + 'px'"
    [style.width]="pageWidth - contentMargin * 2 + 'px'"
    [attr.data-automationId]="'cfg-element-' + viewData.page.entry.name"
    class="wrapper"
  >
    @if (
      (!viewData.dndActive && viewData.editMode && viewData.selected) || viewData.dndActive === viewData.page.entry.name
    ) {
      <div class="toolbar">
        <i class="pi drag-initiator {{ ICON.MOVE }}" #dragInitiator></i>
      </div>
    }
    <div
      (tap)="selectPage(viewData.page.entry.name)"
      [interactOptions]="{ eventPropagation: false }"
      data-automationId="element-content"
      class="page-element"
      interactPointer
    >
      <ng-container *ngIf="viewData.page.isNew !== true; else isNew">
        <div class="page-title text-center" data-automationId="element-label">
          <ng-container *ngIf="viewData.page.entry.hideLabel === true">
            <i>({{ viewData.page.entry.label }})</i>
          </ng-container>
          <ng-container *ngIf="viewData.page.entry.hideLabel !== true">
            {{ viewData.page.entry.label }}
          </ng-container>
        </div>

        @if (isVisible$ | async) {
          <div class="content">
            <kfd-condition-viewer [conditionalEntry]="viewData.page"></kfd-condition-viewer>

            <div class="flex-1 flex flex-column">
              <ng-container *ngIf="viewData.pageSkeleton?.children.length > 0; else empty">
                <kfd-preview-dropzone
                  [accepts]="allowedChildren[CLS.PAGE]"
                  [parent]="viewData.page.entry.name"
                  class="page-content-element"
                ></kfd-preview-dropzone>
                @for (pageItem of viewData.pageSkeleton?.children; track pageItem.name; let pagePosition = $index) {
                  <div class="page-content element-wrapper">
                    <kfd-dnd-area-group
                      *ngIf="pageItem.cls === CLS.FIELD_GROUP"
                      [attr.data-entry-name]="pageItem.name"
                      [groupName]="pageItem.name"
                    >
                    </kfd-dnd-area-group>
                    <kfd-dnd-area-field
                      *ngIf="pageItem.cls === CLS.FIELD"
                      [attr.data-entry-name]="pageItem.name"
                      [fieldName]="pageItem.name"
                      [style.width]="pageWidth - contentMargin * 2 + 'px'"
                    >
                    </kfd-dnd-area-field>
                  </div>
                  <kfd-preview-dropzone
                    [accepts]="allowedChildren[CLS.PAGE]"
                    [parent]="viewData.page.entry.name"
                    [position]="pagePosition + 1"
                    [area]="pagePosition === viewData.pageSkeleton.children.length - 1"
                    class="page-content-element"
                  ></kfd-preview-dropzone>
                }
              </ng-container>
            </div>
          </div>
          <div class="page-footer flex justify-content-between">
            <div *ngIf="!viewData.page.entry.noNavPrev && !viewData.isFirst" class="m-2">
              <button
                [disabled]="true"
                class="p-button-outlined p-button-sm p-button-secondary"
                icon="pi {{ ICON.BACK }}"
                label="Zurück"
                pButton
                type="button"
              ></button>
            </div>
            <div *ngIf="!viewData.page.entry.noNavNext && !viewData.isLast" class="m-2">
              <button
                [disabled]="true"
                class="p-button-outlined p-button-sm p-button-secondary"
                icon="pi {{ ICON.NEXT }}"
                iconPos="right"
                label="Weiter"
                pButton
                type="button"
              ></button>
            </div>
          </div>
        }
      </ng-container>
    </div>
    <!--        <div *ngIf="dndEnabled.enabled === true" class="disabled-overlay"></div>-->
  </div>
  <!--  </ng-container>-->

  <ng-template #empty>
    <kfd-preview-dropzone
      [accepts]="allowedChildren[CLS.PAGE]"
      [area]="true"
      [parent]="viewData.page.entry.name"
      class="flex-1"
    >
      <kfd-placeholder-msg
        (btnClick)="select(); cfgEditorService.createNewElement(CLS.PAGE)"
        [btnVisible]="viewData.editMode"
        [infoIcon]="ICON.PAGE"
        [interactOptions]="{ eventPropagation: false }"
        class="flex-1 justify-content-center editor-element"
        data-automationId="empty-group-info-msg"
        infoText="Kein Element vorhanden"
        interactPointer
      ></kfd-placeholder-msg>
    </kfd-preview-dropzone>
  </ng-template>

  <ng-template #isNew>
    <kfd-placeholder-msg
      (tap)="select()"
      [infoIcon]="ICON.INFO"
      [interactOptions]="{ eventPropagation: false }"
      class="flex-1 justify-content-center editor-element"
      infoText="Die Seite wurde noch nicht gespeichert"
      interactPointer
    ></kfd-placeholder-msg>
  </ng-template>
</ng-container>
