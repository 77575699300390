import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CfgStateService } from '../../cfg-state.service';
import { CmsConfigurator } from '@kfd/core';

import { CmsContextService } from '../../../services/cms-context.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'kfd-integration-dialog',
  templateUrl: './integration-dialog.component.html',
  styleUrls: ['./integration-dialog.component.scss'],
})
export class IntegrationDialogComponent extends BaseDialogComponent {
  protected environment = environment;
  protected usePreview = false;
  protected configuration: CmsConfigurator | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected ctx: CmsContextService,
    protected cfgStateService: CfgStateService,
  ) {
    super(dialogRef, dialogConfig);
    this.configuration = this.cfgStateService.getCfgUtil().getCfg();
  }
}
