import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Create, Page, PageRef } from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';

@Component({
  selector: 'kfd-page-ref',
  templateUrl: './page-ref.component.html',
  styleUrls: ['./page-ref.component.scss'],
})
export class PageRefComponent implements OnInit {
  @Output() pageRefChange: EventEmitter<PageRef> = new EventEmitter<PageRef>();
  options: Page[] = [];
  @Input()
  public emptyOptionLabel = ' - Bitte wählen - ';

  constructor(private cfgStateService: CfgStateService) {}

  private _pageRef: PageRef = Create.pageRef();

  get pageRef(): PageRef {
    return this._pageRef;
  }

  @Input()
  set pageRef(value: PageRef) {
    if (!value) {
      return;
    }
    this._pageRef = JSON.parse(JSON.stringify(value));
  }

  ngOnInit() {
    this.loadFields();
  }

  loadFields() {
    this.options = [
      {
        name: '',
        label: this.emptyOptionLabel,
      } as Page,
    ];
    this.options = this.options.concat(this.cfgStateService.getCfgUtil().getPages());
  }

  onChange(value: string): void {
    if (!value) {
      this._pageRef = Create.pageRef();
      this.pageRefChange.emit();
      return;
    }
    this._pageRef = Create.pageRef(value);
    this.pageRefChange.emit(this.pageRef);
  }
}
