<div class="flex">
  <kfd-data-value-type
    (typeChange)="onTypeChange($event)"
    [allowedTypes]="allowedTypes"
    [type]="type"
    class="flex-1"
  ></kfd-data-value-type>
  <kfd-data-value
    (valueChange)="onInputValueChange($event)"
    [type]="type"
    [value]="inputValue"
    class="flex-1"
  ></kfd-data-value>
  @if (showAcceptBtn) {
    <p-button icon="pi {{ ICON.ACCEPT }}" [outlined]="true" (click)="save()"></p-button>
  }
</div>
