<p-toolbar styleClass="no-border">
  <div class="flex-1 flex flex-column">
    <div class="flex-1 flex flex-row align-items-center justify-content-between">
      <div class="p-toolbar-group-left">
        <div [routerLink]="linkTarget ? linkTarget : undefined" class="flex-column header-group">
          <div class="subtitle text-sm">{{ subTitle }}</div>
          <div class="title text-xl font-semibold">{{ headline }}</div>
        </div>
        <ng-content select="[topLeft]"></ng-content>
      </div>
      <div class="p-toolbar-group-right">
        <ng-content></ng-content>
        <ng-content select="[topRight]"></ng-content>
        @if (menuItems) {
          <kfd-btn-menu [items]="menuItems" [endSeparator]="!!(showDocu && docuPage)"></kfd-btn-menu>
        }
        @if (showDocu && docuPage) {
          <kfd-docu-btn [pageId]="docuPage" [sectionId]="docuSection" class="ml-2"> </kfd-docu-btn>
        }
      </div>
    </div>
    <div *ngIf="secondRow" class="second-row">
      <ng-content select="[secondRow]"></ng-content>
    </div>
  </div>
</p-toolbar>
