import { Component } from '@angular/core';
import { BaseDialogComponent } from '../../../../common/base-dialog.component';
import { AssetUsage, CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS, ICON, USER_SCOPES } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssetService } from '../../../../services/asset.service';
import { Observable } from 'rxjs';
import { ContextRoutingService } from '../../../../services/context-routing.service';

@Component({
  selector: 'kfd-asset-usage-dialog',
  templateUrl: './asset-usage-dialog.component.html',
  styleUrl: './asset-usage-dialog.component.scss',
})
export class AssetUsageDialogComponent extends BaseDialogComponent {
  protected readonly ICON = ICON;
  protected usage$: Observable<AssetUsage>;
  protected readonly scopes = USER_SCOPES;

  constructor(
    protected readonly dialogRef: DynamicDialogRef,
    protected readonly dialogConfig: DynamicDialogConfig,
    protected readonly assetService: AssetService,
    protected readonly contextRoutingService: ContextRoutingService,
  ) {
    super(dialogRef, dialogConfig);

    if (!dialogConfig.data.projectId) {
      throw new Error('Cannot show usage without project id');
    }
    if (!dialogConfig.data.assetId) {
      throw new Error('Cannot show usage without asset id');
    }

    this.usage$ = this.assetService.getAssetUsages(dialogConfig.data.projectId, dialogConfig.data.assetId);
  }

  openSettings() {
    this.contextRoutingService.toProjectSettings(undefined, CMS_APP_ROUTE_PARTS_PROJECT_SETTINGS.BUSINESS_CARD);
    this.close();
  }

  openBaseData(entryId?: string) {
    this.contextRoutingService.toProjectBaseData({
      navigate: true,
      entryId,
    });
    this.close();
  }
}
