import { Component } from '@angular/core';
import { AliasValue, CalculationHandler, Create } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-alias-ref-dialog',
  templateUrl: './alias-ref-dialog.component.html',
  styleUrls: ['./alias-ref-dialog.component.scss'],
})
export class AliasRefDialogComponent extends BaseDialogComponent {
  aliasValue: AliasValue = Create.aliasValue();
  calculationHandler: CalculationHandler | undefined;

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.aliasValue) {
      this.aliasValue = dialogConfig.data.aliasValue;
    }
    if (dialogConfig.data.calculation) {
      this.calculationHandler = new CalculationHandler(dialogConfig.data.calculation);
    }
  }

  save() {
    this.close(this.aliasValue);
  }
}
