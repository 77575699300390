import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CfgEditorComponent } from './cfg-editor.component';
import { DndAreaComponent } from './dnd-area/dnd-area.component';
import { SettingsAreaComponent } from './settings-area/settings-area.component';
import { SharedModule } from '../shared/shared.module';
import { DndAreaPageComponent } from './dnd-area/dnd-area-page/dnd-area-page.component';
import { DndAreaGroupComponent } from './dnd-area/dnd-area-group/dnd-area-group.component';
import { DndAreaFieldComponent } from './dnd-area/dnd-area-field/dnd-area-field.component';
import { DropZoneDirective } from './dnd-area/drag-drop/drop-zone.directive';
import { DropzoneComponent } from './dnd-area/drag-drop/dropzone/dropzone.component';
import { DndAreaConfigurationComponent } from './dnd-area/dnd-area-configuration/dnd-area-configuration.component';
import { FormsModule } from '@angular/forms';
import { EditorHelpDialogComponent } from './dialogs/editor-help-dialog/editor-help-dialog.component';
import { PreviewDialogComponent } from './dialogs/preview-dialog/preview-dialog.component';
import { NewEntryComponent } from './new-entry/new-entry.component';
import { PublishingDialogComponent } from './dialogs/publishing-dialog/publishing-dialog.component';
import { PublishingComponent } from './dialogs/publishing/publishing.component';
import { ExportConfigurationDialogComponent } from './dialogs/export-configuration-dialog/export-configuration-dialog.component';
import { RemoveEntryDialogComponent } from './dialogs/remove-entry-dialog/remove-entry-dialog.component';
import { RemoveConfigurationDialogComponent } from './dialogs/remove-configuration-dialog/remove-configuration-dialog.component';
import { MoveEntryDialogComponent } from './dialogs/move-entry-dialog/move-entry-dialog.component';
import { ConditionDialogComponent } from './dialogs/condition-dialog/condition-dialog.component';
import { EntryPageSettingsComponent } from './settings-area/entry-page-settings/entry-page-settings.component';
import { EntryFieldGroupSettingsComponent } from './settings-area/entry-fieldgroup-settings/entry-fieldgroup-settings.component';
import { TextEntryFieldSettingsComponent } from './settings-area/entry-field-settings/text/text.component';
import { EntryFieldSettingsComponent } from './settings-area/entry-field-settings/entry-field-settings.component';
import { CfgEditorErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { BoolEntryFieldSettingsComponent } from './settings-area/entry-field-settings/bool/bool.component';
import { DateEntryFieldSettingsComponent } from './settings-area/entry-field-settings/date/date.component';
import { DateValueViewerComponent } from './components/date/date-value-viewer/date-value-viewer.component';
import { RequiredCfgSettingComponent } from './settings-area/common/required/required-cfg-setting.component';
import { HidelabelCfgSettingComponent } from './settings-area/common/hidelabel-cfg-setting/hidelabel-cfg-setting.component';
import { HintCfgSettingComponent } from './settings-area/common/hint-cfg-setting/hint-cfg-setting.component';
import { NumericEntryFieldSettingsComponent } from './settings-area/entry-field-settings/numeric/numeric.component';
import { DisplayDataEntryFieldSettingsComponent } from './settings-area/entry-field-settings/display-data/display-data.component';
import { FieldRefComponent } from './components/field-ref/field-ref.component';
import { CfgEntryLabelPipe } from './pipes/entry-label.pipe';
import { CalculationViewerComponent } from './components/calculation-viewer/calculation-viewer.component';
import { ResetBtnFieldSettingsComponent } from './settings-area/entry-field-settings/reset-btn/reset-btn.component';
import { SubmitBtnFieldSettingsComponent } from './settings-area/entry-field-settings/submit-btn/submit-btn.component';
import { PageRefComponent } from './components/page-ref/page-ref.component';
import { SelectEntryFieldSettingsComponent } from './settings-area/entry-field-settings/select/select.component';
import { BasedataHandlerDialogComponent } from './settings-area/common/basedata-handler-dialog/basedata-handler-dialog.component';
import { DisabledCfgSettingComponent } from './settings-area/common/disabled-cfg-setting/disabled-cfg-setting.component';
import { ConditionComponent } from './components/condition/condition.component';
import { ConditionGroupComponent } from './components/condition-group/condition-group.component';
import { ConditionViewerComponent } from './dnd-area/components/condition-viewer/condition-viewer.component';
import { CfgSettingsDialogComponent } from './dialogs/cfg-settings-dialog/cfg-settings-dialog.component';
import { IntegrationDialogComponent } from './dialogs/integration-dialog/integration-dialog.component';
import { DataUsageDialogComponent } from './dialogs/data-usage-dialog/data-usage-dialog.component';
import { AccordionModule } from 'primeng/accordion';
import { CalculationComponent } from './components/calculation/calculation.component';
import { CalculationDialogComponent } from './dialogs/calculation-dialog/calculation-dialog.component';
import { CalculationFnComponent } from './components/calculation-fn/calculation-fn.component';
import { FieldRefDialogComponent } from './dialogs/field-ref-dialog/field-ref-dialog.component';
import { EntryNameComponent } from './components/entry-name/entry-name.component';
import { CheckViewerComponent } from './components/check-viewer/check-viewer.component';
import { ConfigurationStateService, MemoryPersistence } from '@kfd/cfg-core';
import { ConfigurationValueEditorComponent } from './components/configuration-value-editor/configuration-value-editor.component';
import { CMS_APP_ROUTE_PARAMS, REQ_PARAMS } from '@kfd/core';
import { DateInputComponent } from './components/date/date-input/date-input.component';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InternalComponent } from './internal/internal.component';
import { NewEntryItemComponent } from './new-entry/new-entry-item/new-entry-item.component';

const routes: Routes = [
  {
    path: `:${REQ_PARAMS.CONFIGURATION_ID}`,
    component: CfgEditorComponent,
  },
  {
    path: `:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}/:${CMS_APP_ROUTE_PARAMS.ENTRY_ID}`,
    component: CfgEditorComponent,
  },
  {
    path: `:${CMS_APP_ROUTE_PARAMS.CONFIGURATION_ID}/internal`,
    component: InternalComponent,
  },
];

export const ENTRY_FIELD_SETTINGS_COMPONENTS = [
  EntryPageSettingsComponent,
  EntryFieldGroupSettingsComponent,
  EntryFieldSettingsComponent,
  BoolEntryFieldSettingsComponent,
  DateEntryFieldSettingsComponent,
  DisplayDataEntryFieldSettingsComponent,
  NumericEntryFieldSettingsComponent,
  ResetBtnFieldSettingsComponent,
  SelectEntryFieldSettingsComponent,
  SubmitBtnFieldSettingsComponent,
  TextEntryFieldSettingsComponent,
];

export const COMPONENTS = [
  CalculationComponent,
  CalculationDialogComponent,
  CalculationFnComponent,
  CalculationViewerComponent,
  CfgEditorComponent,
  ConditionComponent,
  ConditionGroupComponent,
  ConditionDialogComponent,
  ConfigurationValueEditorComponent,
  DndAreaComponent,
  SettingsAreaComponent,
  // DateValueComponent,
  DateInputComponent,
  DateValueViewerComponent,
  DndAreaPageComponent,
  DndAreaGroupComponent,
  DndAreaFieldComponent,
  DropZoneDirective,
  DropzoneComponent,
  DndAreaConfigurationComponent,
  EditorHelpDialogComponent,
  FieldRefComponent,
  FieldRefDialogComponent,
  PageRefComponent,
  PreviewDialogComponent,
  NewEntryComponent,
  EntryNameComponent,
  PublishingComponent,
  PublishingDialogComponent,
  ExportConfigurationDialogComponent,
  MoveEntryDialogComponent,
  RemoveEntryDialogComponent,
  RemoveConfigurationDialogComponent,
  CfgEditorErrorDialogComponent,
  RequiredCfgSettingComponent,
  HidelabelCfgSettingComponent,
  HintCfgSettingComponent,
  ResetBtnFieldSettingsComponent,
  BasedataHandlerDialogComponent,
  DisabledCfgSettingComponent,
  ConditionViewerComponent,
  CfgSettingsDialogComponent,
  IntegrationDialogComponent,
  DataUsageDialogComponent,
  ...ENTRY_FIELD_SETTINGS_COMPONENTS,
];

export const PIPES = [CfgEntryLabelPipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, CheckViewerComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    AccordionModule,
    InputGroupAddonModule,
    NewEntryItemComponent,
  ],
  exports: [CfgEntryLabelPipe, FieldRefComponent, FieldRefDialogComponent],
  providers: [
    {
      provide: ConfigurationStateService,
      useFactory: () => {
        return new ConfigurationStateService(new MemoryPersistence());
      },
      deps: [],
    },
  ],
})
export class CfgEditorModule {}
