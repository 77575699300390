<p-inputGroup data-automationId="relative-date-value">
  <p-inputGroupAddon>
    <i class="pi {{ ICON.DATE }}"></i>
  </p-inputGroupAddon>
  <p-inputGroupAddon class="flex-1 justify-content-start" styleClass="text-overflow-ellipsis">
    @if (Is.inputValue(dateValue)) {
      {{ dateValue.value }}
    } @else if (Is.relativeDate(dateValue)) {
      @if (dateManipulation) {
        <span>{{ startDateValue }}</span
        >&nbsp; <span>{{ dateManipulation }}</span
        >&nbsp;
      }
      @if (dateManipulationValue) {
        <span>{{ dateManipulationValue }}</span
        >&nbsp;
      }
      @if (dateManipulationUnit) {
        <span>{{ dateManipulationUnit }}</span
        >&nbsp;
      }
    } @else {
      <i>Kein Datum ausgewählt</i>
    }
  </p-inputGroupAddon>
  <button
    (click)="editBtnClick.emit()"
    class="p-button-outlined"
    data-automationId="config-date-edit"
    icon="pi {{ ICON.EDIT }}"
    pButton
    type="button"
  ></button>
</p-inputGroup>
