import { EventEmitter, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { filter, map } from 'rxjs/operators';
import { WsMessage, WsMessageType } from '@kfd/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesGatewayService {
  message = new EventEmitter<WsMessage>();

  constructor(private socket: Socket) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.socket.fromEvent('msgToClient').subscribe((message: WsMessage) => {
      this.message.emit(message);
    });
  }

  onMessageType<T>(type: WsMessageType): Observable<T> {
    return this.message.pipe(
      filter((message) => message.type === type),
      map((message) => message.data as T),
    );
  }

  // sendMessage(msg: string) {
  //   this.socket.emit('msgToServer', msg);
  // }
}
