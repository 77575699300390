<p-selectButton (onChange)="defaultValueTypeChange($event.value)" [ngModel]="valueType" [options]="dateBaseTypeOptions">
</p-selectButton>

@if (Is.inputValue(dateValue)) {
  <div class="mt-2">
    <p-calendar
      (ngModelChange)="defaultDateChange()"
      [(ngModel)]="dateObject"
      appendTo="body"
      dateFormat="dd.m.yy"
      showIcon="true"
    >
    </p-calendar>
  </div>
} @else if (Is.relativeDate(dateValue)) {
  <div class="mt-2 p-fluid" data-automationId="data-value-relative">
    <div class="p-inputgroup">
      <p-dropdown
        (onChange)="setStartDate($event.value)"
        [ngModel]="dateValue.start"
        [options]="startDateValueList"
        dataKey="name"
        appendTo="body"
        data-automationId="date-value-start"
        group="true"
        styleClass="auto-width"
      >
      </p-dropdown>
      <p-dropdown
        (onChange)="setDateManipulation($event.value)"
        [ngModel]="dateValue.manipulation"
        [options]="dateManipulationOptions"
        appendTo="body"
        styleClass="auto-width"
      >
      </p-dropdown>
      <p-inputNumber
        #manipulationValue
        (onInput)="setManipulationValue(manipulationValue.value)"
        [ngModel]="dateValue.value"
        buttonLayout="horizontal"
        decrementButtonIcon="pi pi-minus"
        incrementButtonIcon="pi pi-plus"
        min="0"
        showButtons="true"
        spinnerMode="horizontal"
        styleClass="auto-width"
      >
      </p-inputNumber>
      <p-dropdown [(ngModel)]="dateValue.unit" [options]="dateUnitOptions" appendTo="body" styleClass="auto-width">
      </p-dropdown>
    </div>
  </div>
} @else {
  <span>Kein Typ ausgewählt</span>
}
