import { Component } from '@angular/core';
import { Create, DATA_VALUE_TYPE, InputValue } from '@kfd/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseDialogComponent } from '../../../common/base-dialog.component';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'kfd-data-value-dialog',
  templateUrl: './data-value-dialog.component.html',
  styleUrls: ['./data-value-dialog.component.scss'],
})
export class DataValueDialogComponent extends BaseDialogComponent {
  dataValue: InputValue = Create.inputValue(DATA_VALUE_TYPE.EMPTY);
  allowedTypes: DATA_VALUE_TYPE[] = [];

  constructor(
    protected dialogRef: DynamicDialogRef,
    protected dialogConfig: DynamicDialogConfig,
    protected loggingService: LoggingService,
  ) {
    super(dialogRef, dialogConfig);
    if (dialogConfig.data.dataValue) {
      this.dataValue = dialogConfig.data.dataValue;
    }
    if (dialogConfig.data.allowedTypes) {
      this.allowedTypes = dialogConfig.data.allowedTypes;
    }
  }

  save() {
    this.close(this.dataValue);
  }
}
