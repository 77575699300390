import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'kfd-unhealthy-dialog',
  templateUrl: './unhealthy-dialog.component.html',
  styleUrls: ['./unhealthy-dialog.component.scss'],
})
export class UnhealthyDialogComponent {
  environment = environment;
}
