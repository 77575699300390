import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kfd-hint-cfg-setting',
  templateUrl: './hint-cfg-setting.component.html',
  styleUrls: ['./hint-cfg-setting.component.scss'],
})
export class HintCfgSettingComponent {
  public valueObject: {
    hint?: string;
  } = {};

  @Output()
  public valueChange = new EventEmitter<string | undefined>();

  public get value(): string | undefined {
    return this.valueObject.hint;
  }

  @Input()
  public set value(value: string | undefined) {
    this.valueObject.hint = value;
  }

  public disable() {
    this.valueObject.hint = undefined;
    this.valueChange.emit(undefined);
  }
}
