import { CLS, CmsConfigurator, CmsGenericEntry, GenericEntry } from '@kfd/core';
import { TreeNode } from 'primeng/api';

export interface CustomTreeNode extends TreeNode {
  pos?: number;
  data: GenericEntry;
}

export class PrimeTreeUtil {
  public static mapConfigToTreeStructure(configuration: CmsConfigurator): CustomTreeNode[] {
    const treeStructure = [];

    const rootNode = PrimeTreeUtil.treeNode(configuration._id.toString(), configuration);

    for (const configuratorPage of configuration.children) {
      //page
      const pageNode = PrimeTreeUtil.addEntryToParentNode(configuratorPage.entry, rootNode);

      for (const configuratorElement of configuratorPage.entry.children) {
        //question
        if (configuratorElement.cls === CLS.FIELD_GROUP_WRAPPER) {
          const questionNode = PrimeTreeUtil.addEntryToParentNode(configuratorElement.entry, pageNode);

          for (const configuratorField of configuratorElement.entry.children) {
            //field
            PrimeTreeUtil.addEntryToParentNode(configuratorField.entry, questionNode);
          }
        } else {
          // field
          PrimeTreeUtil.addEntryToParentNode(configuratorElement.entry, pageNode);
        }
      }
    }

    treeStructure.push(rootNode);
    return treeStructure;
  }

  public static addEntryToParentNode(entry: CmsGenericEntry, parentNode: TreeNode): TreeNode {
    const node = PrimeTreeUtil.treeNode(entry._id.toString(), entry);
    if (parentNode.children) {
      node.pos = parentNode.children.length;
      parentNode.children.push(node);
    }
    return node;
  }

  public static treeNode(id: string, data: CmsGenericEntry): CustomTreeNode {
    const node: CustomTreeNode = {
      key: data.name,
      label: data.label,
      expanded: true,
      children: [],
      selectable: true,
      draggable: true,
      droppable: false,
      data,
    };
    return node;
  }
}
