import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import interact from 'interactjs';
import { InteractEvent } from '@interactjs/types/index';

export interface InteractPointerConfig {
  eventPropagation: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[interactPointer]',
})
export class InteractPointerDirective {
  @Output()
  public readonly down = new EventEmitter<InteractEvent>();
  @Output()
  public readonly move = new EventEmitter<InteractEvent>();
  @Output()
  public readonly up = new EventEmitter<InteractEvent>();
  @Output()
  public readonly cancel = new EventEmitter<InteractEvent>();
  @Output()
  public readonly tap = new EventEmitter<InteractEvent>();
  @Output()
  public readonly doubletap = new EventEmitter<InteractEvent>();
  @Output()
  public readonly hold = new EventEmitter<InteractEvent>();

  @Input()
  public readonly interactOptions: InteractPointerConfig | undefined;

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {
    interact(elementRef.nativeElement)
      .on('down', (e) => this.handle(e, this.down))
      .on('move', (e) => this.handle(e, this.move))
      .on('up', (e) => this.handle(e, this.up))
      .on('cancel', (e) => this.handle(e, this.cancel))
      .on('tap', (e) => this.handle(e, this.tap))
      .on('doubletap', (e) => this.handle(e, this.doubletap))
      .on('hold', (e) => this.handle(e, this.hold));
  }

  private handle(e: InteractEvent, emitter: EventEmitter<InteractEvent>) {
    if (this.interactOptions?.eventPropagation === false) {
      e.stopPropagation();
    }
    emitter.emit(e);
  }
}
