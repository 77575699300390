import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { CLS, ICON } from '@kfd/core';
import { CFG_ENTRY_LIST } from '../../shared/global';
import { Observable, Subject } from 'rxjs';
import { DragDropService } from '../dnd-area/drag-drop/drag-drop.service';
import { map } from 'rxjs/operators';

export interface NewElementInfo {
  position?: number;
  parent?: CLS;
  cls?: CLS;
}

@Component({
  selector: 'kfd-cfg-new-entry',
  templateUrl: './new-entry.component.html',
  styleUrls: ['./new-entry.component.scss'],
})
export class NewEntryComponent implements OnDestroy {
  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();
  protected readonly icon = ICON;
  protected entryList = CFG_ENTRY_LIST;
  protected dndActive$: Observable<boolean>;
  protected searchString = '';
  private destroy$ = new Subject<boolean>();

  constructor(private readonly dragDropService: DragDropService) {
    this.dndActive$ = this.dragDropService.onDndActive.pipe(map((active) => !!active));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
