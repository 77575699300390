import {
  CLS,
  CmsField,
  CmsFieldGroup,
  CmsGenericEntry,
  CmsPage,
  Create,
  DATAHANDLER_TYPES,
  DynamicDataHandler,
  FIELD_TYPES,
  FIELD_TYPES_TO_DATA_VALUE_TYPES,
  GenericEntry,
  ResetButtonFieldConfig,
  SELECT_FIELD_LAYOUTS,
  SelectFieldConfig,
  StringUtil,
  SubmitButtonFieldConfig,
} from '../../index';

export class EntryFactory {
  static createEntry(entryType: string, label?: string, name?: string, fieldType?: FIELD_TYPES): CmsGenericEntry {
    switch (entryType) {
      case CLS.PAGE:
        return this.createPage(label, name);
      case CLS.FIELD_GROUP:
        return this.createQuestion(label, name);
      case CLS.FIELD:
        return this.createField(label, name, fieldType);
      default:
        throw new Error('Unsupported type ' + entryType);
    }
  }

  static createPage(label?: string, name?: string): CmsPage {
    const page = Create.page() as CmsPage;
    EntryFactory.setLabelAndName(page, 'Neue Seite', label, name);
    page.meta = Create.entryMeta();

    page.hideLabel = true;
    page['children'] = [];
    return page;
  }

  static createQuestion(label?: string, name?: string): CmsFieldGroup {
    const question = Create.fieldGroup() as CmsFieldGroup;
    EntryFactory.setLabelAndName(question, 'Neue Gruppe', label, name);
    question.meta = Create.entryMeta();

    question.hideLabel = true;
    question['children'] = [];
    return question;
  }

  static createField(label?: string, name?: string, type: FIELD_TYPES = FIELD_TYPES.DISPLAY_DATA): CmsField {
    const field: CmsField = {
      _id: '',
      cls: CLS.FIELD,
      name: '',
      config: {
        cls: CLS.FIELD_CONFIG,
        type,
        dataType: FIELD_TYPES_TO_DATA_VALUE_TYPES[type],
      },
      meta: Create.entryMeta(),
      isNew: true,
    };
    EntryFactory.setLabelAndName(field, 'Neues Feld', label, name);

    switch (type) {
      case FIELD_TYPES.SELECT:
        field.config = {
          ...field.config,
          layout: {
            type: SELECT_FIELD_LAYOUTS.DROPDOWN,
          },
          dataHandler: {
            cls: CLS.DATA_HANDLER,
            type: DATAHANDLER_TYPES.DYNAMIC,
            tags: [],
          } as DynamicDataHandler,
        } as SelectFieldConfig;
        break;
      case FIELD_TYPES.SUBMITBTN:
        field.hideLabel = true;
        field.config = {
          ...field.config,
          btnLabel: 'Absenden',
          successText: 'Vielen Dank',
        } as SubmitButtonFieldConfig;
        break;
      case FIELD_TYPES.RESETBTN:
        field.hideLabel = true;
        field.config = {
          ...field.config,
          btnLabel: 'Zurücksetzen',
        } as ResetButtonFieldConfig;
        break;
      case FIELD_TYPES.DISPLAY_DATA:
        field.hideLabel = true;
        break;
    }

    return field;
  }

  private static setLabelAndName(entry: GenericEntry, fallback: string, label?: string, name?: string): void {
    if (label && label.length > 3) {
      entry.label = label;
    } else {
      entry.label = fallback + ' ' + StringUtil.rand(4, true);
    }
    if (name && name.length > 3) {
      if (name !== StringUtil.toSaveString(name)) {
        throw new Error('Invalid name');
      }
      entry.name = StringUtil.toSaveString(name);
    } else if (label) {
      entry.name = StringUtil.toSaveString(label);
    }
  }
}
