<p-multiSelect
  #multiTagSelection
  (onChange)="onSelectionChange()"
  [(ngModel)]="tagSelection"
  [options]="existingTags"
  [showHeader]="false"
  appendTo="body"
>
  <ng-template let-value pTemplate="selectedItems">
    <ng-container *ngFor="let selectedTag of tagSelection">
      <p-chip [label]="selectedTag" class="mr-1"></p-chip>
    </ng-container>
  </ng-template>
  <ng-template let-tag pTemplate="item">
    <div [attr.data-tag]="tag" class="tag-item">
      <div>{{ tag }}</div>
    </div>
  </ng-template>
  <ng-template *ngIf="allowCreation === true" pTemplate="footer">
    <div class="m-2 pb-2">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi {{ ICON.TAG }}"></i></span>
        <input
          (keydown.enter)="createTag()"
          [(ngModel)]="newTagName"
          pInputText
          placeholder="Neuer Tag Name"
          type="text"
        />
        <button
          (click)="createTag()"
          [disabled]="newTagName.length < 3"
          icon="pi {{ ICON.ACCEPT }}"
          pButton
          type="button"
        ></button>
      </div>
    </div>
  </ng-template>
</p-multiSelect>
