import {
  AdvancedLicenceOptions,
  CustomLicenceOptions,
  LicenceOptions,
  LicencePackage,
  ProLicenceOptions,
  StarterLicenceOptions,
} from '../dtos';

export class LicenceUtil {
  public static licenceOptionsFromPackage(licencePackage: LicencePackage): LicenceOptions {
    switch (licencePackage) {
      case LicencePackage.STARTER:
        return new StarterLicenceOptions();
      case LicencePackage.ADVANCED:
        return new AdvancedLicenceOptions();
      case LicencePackage.PRO:
        return new ProLicenceOptions();
      case LicencePackage.CUSTOM:
        return new CustomLicenceOptions();
      default:
        throw new Error(`Unknown licence package name "${licencePackage}"`);
    }
  }
}
