import { Injectable } from '@angular/core';
import { FeCache, WebEndpointService } from '@kfd/web-core';
import { map, Observable, of } from 'rxjs';
import { CmsSelectionData, cmsServiceEP, Create, CreateBaseData, DataEntryUsage, DataRef, Id } from '@kfd/core';
import { DataFilterOptions } from '@kfd/cfg-core';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  private cache = new FeCache();

  constructor(private webEndpointService: WebEndpointService) {}

  public clearCache(): void {
    this.cache.clear();
  }

  public count(projectId: Id): Observable<number> {
    return this.webEndpointService.get(cmsServiceEP.baseData.count, [projectId.toString()]);
  }

  public getData(projectId: Id): Observable<CmsSelectionData[]> {
    return this.webEndpointService.get(cmsServiceEP.baseData.list, [projectId.toString()]);
  }

  public getEntryByName(projectId: Id, name: string): Observable<typeof cmsServiceEP.baseData.entryByName.response> {
    return this.webEndpointService.get(cmsServiceEP.baseData.entryByName, [projectId.toString(), name]);
  }

  public listTags(projectId: Id): Observable<string[]> {
    return this.webEndpointService.get(cmsServiceEP.baseData.tagsList, [projectId.toString()]);
  }

  public getDataByFilter(projectId: Id, dataFilterOptions: DataFilterOptions): Observable<CmsSelectionData[]> {
    const params: Record<string, string | boolean> = {
      templateName: dataFilterOptions.templateName ?? '',
      tags: dataFilterOptions.tags ? dataFilterOptions.tags.map((t) => t).join('+') : '',
      sort: dataFilterOptions.sort ? dataFilterOptions.sort.map((s) => `${s.field}:${s.direction}`).join('+') : '',
    };
    return this.webEndpointService.get(cmsServiceEP.baseData.dataByFilter, [projectId.toString()], {
      params,
    });
  }

  public getDataByNames(projectId: Id, names: string[]): Observable<CmsSelectionData[]> {
    return this.webEndpointService.get(cmsServiceEP.baseData.entriesByNames, [projectId.toString(), names.join('+')]);
  }

  public getDataByRefList(projectId: Id, refList: DataRef[]): Observable<CmsSelectionData[]> {
    if (!refList || refList.length === 0) {
      return of([]);
    }
    const names = refList.map((ref) => ref.name);
    return this.getDataByNames(projectId, names).pipe(
      map((res) => {
        //check for unavailable refs
        for (const name of names) {
          if (res.findIndex((data) => data.name === name) === -1) {
            res.push(Create.selectionData(name, `!Not found: ${name}!`) as unknown as CmsSelectionData);
          }
        }
        return res;
      }),
      map((res) =>
        //sort refs by name order
        res.sort((a, b) => names.indexOf(a.name) - names.indexOf(b.name)),
      ),
    );
  }

  public createEntry(
    projectId: Id,
    data: typeof cmsServiceEP.baseData.create.requestBody,
  ): Observable<typeof cmsServiceEP.baseData.create.response> {
    return this.webEndpointService.post(cmsServiceEP.baseData.create, [projectId.toString()], data);
  }

  public updateEntry(
    entryId: Id,
    projectId: Id,
    data: CreateBaseData,
  ): Observable<typeof cmsServiceEP.baseData.update.response> {
    return this.webEndpointService.put(cmsServiceEP.baseData.update, [projectId.toString(), entryId.toString()], data);
  }

  public removeById(projectId: Id, entryId: Id): Observable<void> {
    return this.webEndpointService.delete(cmsServiceEP.baseData.delete, [projectId.toString(), entryId.toString()]);
  }

  public dataUsageInfo(projectId: Id, name: string): Observable<DataEntryUsage> {
    return this.webEndpointService.get(cmsServiceEP.baseData.entryUsageInfo, [projectId.toString(), name]);
  }
}
