<kfd-licence-limit [configOption]="licenceLimitData">
  <div [kfdBlock]="loading" class="p-fluid">
    <div class="p-field">
      <label>Label</label>
      <input
        (change)="updateName()"
        (keyup)="updateName()"
        [(ngModel)]="selectionData.label"
        maxlength="50"
        pInputText
        type="text"
      />
    </div>

    <div class="p-field">
      <label>Name</label>
      <kfd-toggle-input-field
        (validityChange)="nameValid = $event"
        (valueChange)="changedName()"
        [asyncValidator]="asyncValidators"
        [validator]="saveNameValidators"
        [value]="selectionData.name"
        data-automationId="entry-name-name-input"
        hint="Der Name wird als interne referenz verwendet und darf nur einmal vorkommen"
      >
      </kfd-toggle-input-field>
    </div>
    <div class="mt-2">
      <button
        (click)="accept()"
        [disabled]="!selectionData.label || !nameValid"
        [loading]="loading"
        class="p-button-secondary p-button-outlined p-button-rounded"
        data-automationId="entry-name-accept-btn"
        icon="pi {{ icon.ACCEPT }}"
        label="Übernehmen"
        pButton
        type="button"
      ></button>
    </div>
  </div>
</kfd-licence-limit>
