<div [kfdBlock]="loading" class="table-wrapper overflow-auto">
  @if (dataCount === undefined) {
    @if (dataList$ | async) {
      <p-progressSpinner
        [style]="{ width: '50px', height: '50px' }"
        strokeWidth="8"
        fill="#007ad9"
        animationDuration=".5s"
      ></p-progressSpinner>
    }
  } @else if (dataCount === 0) {
    @if (dataList$ | async) {
      <kfd-placeholder-msg
        [btnVisible]="[scopes.ADMIN, scopes.OWNER, scopes.MODELLER, scopes.AUTHOR] | hasScope | async"
        data-automationId="empty-data-list-info"
        infoTitle="Es sind keine Datensätze vorhanden"
        btnLabel="Neuen Datensatz erstellen"
        (btnClick)="createBaseData()"
      ></kfd-placeholder-msg>
    }
  } @else {
    <p-table
      #dataTable
      (onFilter)="tableFilter()"
      (selectionChange)="onSelectionChange($event)"
      [selection]="selectedData"
      [value]="dataList$ | async"
      dataKey="_id"
      responsiveLayout="scroll"
      styleClass="p-datatable-striped"
    >
      <ng-template pTemplate="header">
        <tr *ngIf="(dataTable.filters | keyvalue).length > 0">
          <th colspan="6">
            <div class="flex">
              <div class="flex flex-row">
                <ng-container *ngFor="let filter of dataTable.filters | keyvalue">
                  <p-tag [value]="filterNames[filter.key] + ': ' + filter.value.value" class="mr-1"></p-tag>
                </ng-container>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th *ngIf="mode === 'select'">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>Label</th>
          <th>Name</th>
          <th>Tags</th>
          <th *ngIf="mode === 'manage'" class="action">&nbsp;</th>
        </tr>
      </ng-template>
      <ng-template let-data pTemplate="body">
        <tr [attr.data-automationId]="'dataRow-' + data.selectionData.name" data-automationTag="data-row">
          <td *ngIf="mode === 'select'">
            <p-tableCheckbox [value]="data.selectionData"></p-tableCheckbox>
          </td>
          <td class="label">
            <p-button
              (click)="viewEntry(data.selectionData.name, { layerY: $event.offsetY })"
              [text]="true"
              class="full-width"
              data-automationId="btn-show-data"
              icon="pi {{ ICON.SHOW }}"
              label="{{ data.selectionData.label }}"
            >
            </p-button>
          </td>
          <td class="name">{{ data.selectionData.name }}</td>
          <td>
            <ng-container *ngIf="data.selectionData.tags?.length > 0">
              {{ data.selectionData.tags.join(', ') }}
            </ng-container>
          </td>
          <td *ngIf="mode === 'manage'" class="action">
            <kfd-responsive-menu [menuItems]="data.menuItems"></kfd-responsive-menu>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        @if ([scopes.ADMIN, scopes.OWNER, scopes.MODELLER, scopes.AUTHOR] | hasScope | async) {
          <div class="flex justify-content-center">
            <p-button
              icon="pi {{ ICON.ADD }}"
              label="Neuen Datensatz erstellen"
              [rounded]="true"
              [text]="true"
              (click)="createBaseData()"
            ></p-button>
          </div>
        }
      </ng-template>
    </p-table>
  }
</div>

<p-overlayPanel #filterOverlay (onHide)="showFilter = false">
  <ng-template pTemplate>
    <div class="p-fluid flex flex-column">
      <div class="p-field">
        <label>Label</label>
        {{ dataTable.filters?.selectionData?.label }}
        <input
          (input)="applyFilter('label', $event.target.value)"
          [value]="dataTable.filters['selectionData.label']?.value"
          pInputText
          type="text"
        />
      </div>
      <div class="p-field">
        <label>Template</label>
        <p-dropdown
          (onChange)="applyFilter('templateName', $event.value)"
          [filter]="false"
          [ngModel]="dataTable.filters['selectionData.templateName']?.value"
          [options]="baseDataTemplates$ | async"
          appendTo="body"
        >
        </p-dropdown>
      </div>
      <div class="p-field">
        <label>Tags</label>
        <p-multiSelect
          (onChange)="applyFilter('tags', $event.value)"
          [filter]="false"
          [ngModel]="dataTable.filters['selectionData.tags']?.value"
          [options]="tags"
          appendTo="body"
        >
        </p-multiSelect>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
