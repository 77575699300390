<button
  (click)="deleteClicked()"
  *ngIf="waitForConfirm === false; else confirm"
  [disabled]="disabled"
  [icon]="icon"
  class="p-button-rounded p-button-text {{ btnClass }}"
  data-automationId="{{ dataId }}-prepare"
  iconPos="left"
  pButton
  type="button"
></button>

<ng-template #confirm>
  <button
    (click)="confirmed.emit($event)"
    [label]="confirmText"
    class="p-button-rounded {{ confirmClass }}"
    data-automationId="{{ dataId }}-confirm"
    icon="pi pi-check"
    iconPos="right"
    pButton
    type="button"
  ></button>
</ng-template>
