import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CmsField, FIELD_TYPES, FieldConfig, ObjectUtil } from '@kfd/core';

@Component({
  selector: 'kfd-entry-field-settings',
  templateUrl: './entry-field-settings.component.html',
})
export class EntryFieldSettingsComponent {
  @Output()
  public entryChange: EventEmitter<CmsField> = new EventEmitter<CmsField>();
  public fieldTypes = FIELD_TYPES;

  private _entry: CmsField | undefined;

  get entry(): CmsField | undefined {
    return this._entry;
  }

  @Input()
  set entry(value: CmsField | undefined) {
    if (value === undefined) {
      return;
    }
    this._entry = ObjectUtil.clone(value);
  }

  public configChange(config: FieldConfig): void {
    if (!this._entry) {
      return;
    }
    this._entry.config = config;
    this.change();
  }

  public change(): void {
    this.entryChange.emit(this.entry);
  }
}
