import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import { Asset, ICON, Id, Is } from '@kfd/core';
import { AssetManagerDialogComponent } from '../asset-manager-dialog/asset-manager-dialog.component';
import { AssetService } from '../../../../services/asset.service';
import { CmsContextService } from '../../../../services/cms-context.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kfd-asset-selector',
  templateUrl: './asset-selector.component.html',
  styleUrl: './asset-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AssetSelectorComponent,
    },
  ],
})
export class AssetSelectorComponent implements ControlValueAccessor {
  @Output()
  public assetSelection = new EventEmitter<Asset | undefined>();

  protected ICON = ICON;
  protected loading = false;
  protected error = false;
  protected asset: Asset | undefined;
  protected disabled = false;
  private onChanged: CallableFunction | undefined;
  private onTouched: CallableFunction | undefined;

  constructor(
    private readonly cmsDialogService: CmsDialogService,
    private ctx: CmsContextService,
    private readonly assetService: AssetService,
  ) {}

  @Input()
  public set assetId(value: Id | undefined) {
    this.loading = true;
    if (Is.id(value)) {
      this.assetService.getAsset(this.ctx.projectId, value).subscribe({
        next: (asset) => {
          this.asset = asset;
          this.loading = false;
          this.error = false;
        },
        error: () => {
          this.asset = undefined;
          this.error = true;
          this.loading = false;
        },
      });
    } else {
      this.asset = undefined;
      this.loading = false;
    }
  }

  public writeValue(value: Id): void {
    this.assetId = value;
  }

  public registerOnChange(onChange: CallableFunction): void {
    this.onChanged = onChange;
  }

  public registerOnTouched(onChange: CallableFunction): void {
    this.onTouched = onChange;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  protected openSelection(): void {
    const ref = this.cmsDialogService.open(AssetManagerDialogComponent);
    ref.onClose.subscribe((asset: Asset) => {
      if (asset) {
        this.asset = asset;
        this.assetSelection.emit(asset);
        if (this.onTouched) {
          this.onTouched(asset._id);
        }
        if (this.onChanged) {
          this.onChanged(asset._id);
        }
      }
    });
  }

  protected clearSelection(): void {
    this.asset = undefined;
    this.error = undefined;
    this.loading = undefined;
    this.assetSelection.emit(undefined);
  }
}
