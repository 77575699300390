import { Component, Input } from '@angular/core';
import { ConditionalEntry, ICON } from '@kfd/core';
import { CmsDialogService } from '../../../../services/cms-dialog.service';
import {
  ConditionDialogComponent,
  ModifyConditionData,
} from '../../../dialogs/condition-dialog/condition-dialog.component';
import { CfgStateService } from '../../../cfg-state.service';

@Component({
  selector: 'kfd-condition-viewer',
  templateUrl: './condition-viewer.component.html',
  styleUrls: ['./condition-viewer.component.scss'],
})
export class ConditionViewerComponent {
  public icon = ICON;

  @Input()
  public conditionalEntry: ConditionalEntry | undefined;

  constructor(
    private readonly cfgStateService: CfgStateService,
    private readonly cmsDialogService: CmsDialogService,
  ) {}

  protected showConditionDialog() {
    if (!this.conditionalEntry) {
      throw new Error('No entry set');
    }
    const parent = this.cfgStateService.getCfgUtil().getParentEntry(this.conditionalEntry.entry.name, true);
    const data: ModifyConditionData = {
      parentName: parent.name,
      childName: this.conditionalEntry.entry.name,
    };
    this.cmsDialogService.open(ConditionDialogComponent, data);
  }
}
