/* eslint-disable */

export interface ErrorDetail {
  key?: string[];
  message: string;
}

export interface JsonError {
  errInfo: {
    details: {
      schemaRulesNotSatisfied: SchemaRuleDetails[];
    };
  };
}

interface SchemaRuleDetails {
  operatorName: string;
  specifiedAs: any;
  additionalProperties: any[];
  missingProperties: any[];
  propertiesNotSatisfied: SchemaPropertyRule[];
  schemasNotSatisfied: SchemaRule[];
  consideredValue?: string;
  reason?: string;
}

interface SchemaRule {
  index: number;
  details: SchemaRuleDetails[];
}

interface SchemaPropertyRule extends SchemaRule {
  propertyName: string;
}

export class JsonSchemaError {
  public static analyzeError(schemaError: JsonError): ErrorDetail[] {
    const errorDetails: ErrorDetail[] = [];
    if (schemaError?.errInfo.details.schemaRulesNotSatisfied) {
      for (const details of schemaError?.errInfo.details.schemaRulesNotSatisfied) {
        const res = this.handleRule(details);
        if (res === false) {
          return errorDetails;
        } else {
          errorDetails.push(...res);
        }
      }
    }
    return errorDetails.map((errorDetail) => {
      errorDetail.key = errorDetail.key ? errorDetail.key.reverse() : undefined;
      return errorDetail;
    });
  }

  // //todo improve/test when providing functionality to user
  // private static getSimpleRule(entry, schemaRuleError: SchemaGroup): ErrorDetail[] {
  //   if (!entry.cls) {
  //     return [{
  //       key: "Unknown",
  //       message: "Unknown Entry"
  //     }];
  //   }
  //   switch (schemaRuleError.operatorName) {
  //     case "anyOf":
  //       for (const schemaNotSatisfied of schemaRuleError.schemasNotSatisfied) {
  //         const errors = this.findOperator(schemaNotSatisfied);
  //         if (errors && errors.length > 0) {
  //           return errors;
  //         }
  //       }
  //       break;
  //     default:
  //       return [{
  //         key: "root",
  //         message: "Unknown Problem"
  //       }];
  //   }
  // }

  private static getPropertiesNotSatisified(schemaPropertyRules: SchemaPropertyRule[]): ErrorDetail[] {
    const errorDetails: ErrorDetail[] = [];

    for (const property of schemaPropertyRules) {
      const propertyName = property.propertyName;
      // for (const propertyDetails of property.details) {
      const subErrorDetails = JsonSchemaError.handleProperty(property);
      if (subErrorDetails) {
        errorDetails.push(
          ...subErrorDetails.map((error) => {
            error.key === undefined ? (error.key = [propertyName]) : error.key.push(propertyName);
            return error;
          }),
        );
      }
      // }
    }
    return errorDetails;
  }

  private static handleProperty(schemaRule: SchemaRule): ErrorDetail[] {
    const errorDetails: ErrorDetail[] = [];

    // const propertyName = schemaPropertyRule.propertyName;

    for (const details of schemaRule.details) {
      const res = this.handleRule(details);
      if (res === false) {
        return errorDetails;
      } else {
        errorDetails.push(...res);
      }
    }
    return errorDetails;
  }

  private static handleRule(schemaRuleDetails: SchemaRuleDetails): ErrorDetail[] | false {
    switch (schemaRuleDetails.operatorName) {
      case 'anyOf':
        for (const schemaNotSatisfied of schemaRuleDetails.schemasNotSatisfied) {
          const errors = this.handleProperty(schemaNotSatisfied);
          if (errors && errors.length > 0) {
            return errors;
          }
        }
        break;
      case 'properties':
        //skip if cls property exists because we are only interested in problems in valid cls
        const clsProperty = schemaRuleDetails.propertiesNotSatisfied.filter(
          (property) => property.propertyName === 'cls',
        );
        if (clsProperty.length > 0) {
          return false;
        }
        return JsonSchemaError.getPropertiesNotSatisified(schemaRuleDetails.propertiesNotSatisfied);
      case 'additionalProperties':
        return [
          {
            key: [],
            message: `Unexpected properties: ${schemaRuleDetails.additionalProperties.join(',')}`,
          },
        ];
      case 'required':
        return [
          {
            key: [],
            message: `Missing properties: ${schemaRuleDetails.missingProperties.join(',')}`,
          },
        ];
      case 'pattern':
        return [
          {
            key: [],
            message: `Invalid text format for ${schemaRuleDetails.consideredValue}`,
          },
        ];
      default:
        return [
          {
            key: [],
            message: `Unhandled operator ${schemaRuleDetails.operatorName}`,
          },
        ];
    }
    return false;
  }
}
