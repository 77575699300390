import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpInterceptor } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { SharedModule } from './shared/shared.module';
import { AppErrorHandler } from './app-error-handler';
import { FullscreenLoginDialogComponent } from './common/fullscreen-login-dialog/fullscreen-login-dialog.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { UserNotificationsComponent } from './common/user-notifications/user-notifications.component';
import { NotificationsDialogComponent } from './common/user-notifications/notifications-dialog/notifications-dialog.component';
import { ErrorReportingDialogComponent } from './common/error-reporting-dialog/error-reporting-dialog.component';
import { FormComponent } from './common/form/form.component';
import { UnhealthyDialogComponent } from './common/unhealthy-dialog/unhealthy-dialog.component';
import { CfgEditorModule } from './cfg-editor/cfg-editor.module';
import { AuthModule, AuthService, EventService } from '@kfd/web-core';
import { App, Service } from '@kfd/core';
import { CmsDialogService } from './services/cms-dialog.service';
import { UserSessionInterceptor } from './common/interceptor/user-session.interceptor';
import { ServerErrorInterceptor } from './common/interceptor/server-error.interceptor';

const config: SocketIoConfig = {
  url: environment.services.cms_websocket.url,
  options: {
    path: environment.services.cms_websocket.path,
    extraHeaders: {
      authorization: document.cookie.replace(/.*ws_token=([a-zA-Z0-9-_.]+),?/, '$1'),
    },
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FullscreenLoginDialogComponent,
    UserNotificationsComponent,
    NotificationsDialogComponent,
    ErrorReportingDialogComponent,
    FormComponent,
    UnhealthyDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    SocketIoModule.forRoot(config),
    CfgEditorModule,
    AuthModule.forRoot({
      config: {
        services: {
          [Service.CMS_SERVICE]: environment.services.cms,
          [Service.AUTH_SERVICE]: environment.services.auth,
          [Service.CFG_SERVICE]: environment.services.cfg,
          [Service.CONTENT_SERVICE]: environment.services.content,
        },
        apps: {
          [App.CMS]: environment.apps.cms,
          [App.WEB]: environment.apps.web,
        },
      },
    }),
  ],
  providers: [
    ConfirmationService,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (
        eventService: EventService,
        cmsDialogService: CmsDialogService,
        authService: AuthService,
      ): HttpInterceptor =>
        new UserSessionInterceptor(eventService, cmsDialogService, authService, {
          urlsToIgnore: ['/api/auth'],
        }),
      multi: true,
      deps: [EventService, CmsDialogService, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (eventService: EventService, cmsDialogService: CmsDialogService): HttpInterceptor =>
        new ServerErrorInterceptor(eventService, cmsDialogService),
      multi: true,
      deps: [EventService, CmsDialogService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
