import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { USER_SCOPES } from '@kfd/core';
import { ScopeUtil } from '../scope.util';
import { Subject, takeUntil } from 'rxjs';
import { ProjectService } from '../../services/project.service';

/**
 * example usage to show an element
 * <div *solarisFeatureToggle="'MY_FEATURE'">
 *
 * example usage to hide an element
 * <div *solarisFeatureToggle="'MY_FEATURE'; hide: true">
 */
@Directive({
  selector: '[kfdShowForScopes]',
})
export class ShowForScopesDirective implements OnInit, OnDestroy {
  @Input()
  public kfdShowForScopes: USER_SCOPES[] = [];
  private destroy$ = new Subject<boolean>();

  constructor(
    private viewContainer: ViewContainerRef,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private projectService: ProjectService,
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public ngOnInit(): void {
    this.viewContainer.clear();

    this.projectService
      .getUserScopesForCurrentProject(true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userScopes) => {
        const isAllowed = ScopeUtil.scopeAllowed(userScopes, this.kfdShowForScopes);
        if (isAllowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }
}
