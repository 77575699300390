import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsetCtxGuard } from './project/unset-ctx.guard';
import { UserGuard } from './resolver/user.guard';
import { HealthGuard } from './resolver/health.guard';
import { CMS_APP_ROUTE_PARTS_ROOT, COMMON_APP_ROUTE_PARTS } from '@kfd/core';

/**
 * @deprecated use CMS_CTRL_EP and REQ_PARAMS (core) instead or directly use endpoints
 */
export enum RouteParams {
  projectId = 'projectId',
  configurationId = 'configuratorId',
  entryId = 'entryId',
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import(`./dashboard/dashboard.module`).then((m) => m.DashboardModule),
    canActivate: [HealthGuard, UserGuard],
  },
  {
    path: CMS_APP_ROUTE_PARTS_ROOT.PROJECT,
    canDeactivate: [UnsetCtxGuard],
    loadChildren: () => import(`./project/project.module`).then((m) => m.ProjectModule),
    canActivate: [HealthGuard],
  },
  {
    path: COMMON_APP_ROUTE_PARTS.AUTH,
    loadChildren: () => import(`@kfd/web-core`).then((m) => m.AuthModule),
    canActivate: [HealthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import(`./admin/admin.module`).then((m) => m.AdminModule),
    canActivate: [HealthGuard],
  },
  {
    path: 'internal',
    loadChildren: () => import(`./internal/internal.module`).then((m) => m.InternalModule),
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
