// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConfigurationStateService } from '../../../../../../../libs/cfg-core/src/lib/configuration/service';
import { Component } from '@angular/core';
import { CfgUtil, ConfigurationValueHandler, FormValueMap, ICON, PrintValue } from '@kfd/core';
import { CfgStateService } from '../../cfg-state.service';
import { from, mergeMap, Observable } from 'rxjs';

@Component({
  selector: 'kfd-configuration-value-editor',
  templateUrl: './configuration-value-editor.component.html',
  styleUrls: ['./configuration-value-editor.component.scss'],
})
export class ConfigurationValueEditorComponent {
  protected valueMap: FormValueMap | undefined;
  protected cfgUtil: CfgUtil | undefined;
  protected readonly ICON = ICON;
  protected printValueList$: Observable<PrintValue[]> | undefined;

  constructor(
    private readonly configurationStateService: ConfigurationStateService,
    private readonly cfgStateService: CfgStateService,
  ) {
    this.cfgStateService.onConfigurationChange.subscribe((configuration) => {
      if (configuration) {
        this.init();
      }
    });
  }

  protected init(): void {
    // this.configurationStateService.onValueChange().subscribe(() => {
    //   this.valueMap = this.configurationStateService.getValueMap();
    // });

    this.cfgUtil = this.cfgStateService.getCfgUtil();
    const configurationValueHandler = new ConfigurationValueHandler(this.cfgUtil);

    this.printValueList$ = this.configurationStateService.onValueChange().pipe(
      mergeMap(() => {
        configurationValueHandler.setValueMap(this.configurationStateService.getValueMap());
        return from(configurationValueHandler.printValueList(false, '- Kein Wert -'));
      }),
    );
  }

  protected refresh(): void {
    this.configurationStateService.removeAllValues();
  }
}
