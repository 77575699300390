import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AliasValue, CalculationHandler, Create } from '@kfd/core';

@Component({
  selector: 'kfd-alias-ref',
  templateUrl: './alias-ref.component.html',
  styleUrls: ['./alias-ref.component.scss'],
})
export class AliasRefComponent {
  @Output() fieldRefChange: EventEmitter<AliasValue> = new EventEmitter<AliasValue>();
  aliasse: string[] = [];

  private _aliasValue: AliasValue = Create.aliasValue();

  get aliasValue(): AliasValue {
    return this._aliasValue;
  }

  @Input()
  set aliasValue(value: AliasValue) {
    this._aliasValue = value;
  }

  private _calculationHandler: CalculationHandler | undefined;

  get calculationHandler(): CalculationHandler | undefined {
    return this._calculationHandler;
  }

  @Input()
  set calculationHandler(value: CalculationHandler | undefined) {
    if (value === undefined) {
      return;
    }
    this._calculationHandler = value;
    this.aliasse = this._calculationHandler.getAliases();
  }
}
